import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md"; // Import MdDone
import React, { useEffect, useState, useRef } from "react";
import {
  getResumeProfile,
  udpateResumeProfile,
} from "../../../Redux/ResumeBuilderSlice";
import { error, success } from "../../../Utilities/toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export default function PersonalInformation({ profile, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [profileState, setProfileState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    city: "",
    phone: "",
    linked_in: "",
    website: "",
  });
  const [activeField, setActiveField] = useState(null);
  const [hoveredField, setHoveredField] = useState(null);
  const editableRefs = useRef({});

  const handleFieldClick = (key) => {
    if (activeField && activeField !== key) handleFieldBlur(activeField);
    setActiveField(key);
    setTimeout(() => editableRefs.current[key]?.focus(), 0);
  };

  const handleFieldInput = (key, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[key];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setProfileState((prev) => ({ ...prev, [key]: value }));
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    } else {
      setProfileState((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleFieldBlur = (key) => {
    const trimmedValue = profileState[key].trim();
    setProfileState((prev) => ({
      ...prev,
      [key]: trimmedValue || "",
    }));
    setActiveField(null);
    submitChanges();
  };

  const handleSubmit = () => {
    handleFieldBlur(activeField);
    setActiveField(null);
  };

  const getUserInfo = () => {
    const resumeId = id;
    dispatch(getResumeProfile({ resumeId, onSuccess: () => {} }));
  };

  const submitChanges = () => {
    const hasChanges = Object.keys(profileState).some(
      (key) => profileState[key] !== profile[key]
    );
    if (hasChanges) {
      const userInfo = { contactId: profileState.id, data: profileState };
      dispatch(
        udpateResumeProfile({
          userInfo,
          onSuccess: (res) => {
            success(res);
            getUserInfo();
          },
        })
      ).catch((err) => error(err.response?.data?.detail || "Update failed"));
    }
  };

  const renderField = (key, fontSize) => {
    const hasChanges = profileState[key] !== profile[key];
    const isActive = activeField === key;
    const isHovered = hoveredField === key;

    const iconStyle = {
      position: "absolute",
      top: "-10px",
      borderRadius: "50%",
      padding: "4px",
      fontSize: "1.4rem",
      color: "#fff",
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
      cursor: "pointer",
    };

    return (
      <span
        className={`editable-field ${isActive || isHovered ? "active" : ""}`}
        onMouseEnter={() => setHoveredField(key)}
        onMouseLeave={() => setHoveredField(null)}
        style={{
          position: "relative",
          border: isActive || isHovered ? "1px solid #ffff" : "none",
          backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
          fontSize: fontSize || "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={isActive}
          ref={(el) => (editableRefs.current[key] = el)}
          onInput={(e) => handleFieldInput(key, e)}
          onBlur={() => handleFieldBlur(key)}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          className={`editable-field `}
          suppressContentEditableWarning={true}
          style={{
            fontSize: fontSize || "inherit",
            cursor: "text",
          }}
        >
          {profileState[key]}
        </span>
        {isActive && hasChanges && (
          <MdDone
            onClick={handleSubmit}
            style={{ ...iconStyle, left: "-15px", background: "#4caf50" }}
          />
        )}

        {isActive && (
          <MdClose
            onClick={() => {
              handleFieldBlur(key);
              setActiveField(null);
            }}
            style={{ ...iconStyle, right: "-16px", background: "#ff4d4d" }}
          />
        )}

        {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(key)}
            style={{ ...iconStyle, right: "-16px", background: "#2b6be2" }}
          />
        )}
      </span>
    );
  };

  useEffect(() => {
    if (profile) setProfileState(profile);
  }, [profile]);

  return (
    profile && (
      <header className="cv-header">
        <p
          className="cv-name text-uppercase text-field apply-font"
          style={{
            color: getSingleResumeDoc?.heading_color || "#2b6be2",
            fontSize: getSingleResumeDoc?.font_size || "",
          }}
        >
          {renderField("first_name")}
          &nbsp;
          {renderField("last_name")}
        </p>
        <p className="cv-email text-center text-field apply-font">
          {renderField("email")}
          {profileState.email &&
            (profileState.address || profileState.city) &&
            " | "}
          {renderField("address")}
          {profileState.address && profileState.city ? ", " : ""}
          {renderField("city")}
          {(profileState.address || profileState.city) &&
            profileState.phone &&
            " | "}
          {renderField("phone")}
          {profileState.phone && profileState.linked_in && " | "}
          {renderField("linked_in")}
          {profileState.linked_in && profileState.website && " | "}
          {renderField("website")}
        </p>
      </header>
    )
  );
}
