import Repository from "./Repository";
const CONTACT_INFO = "/resume/contact";
const BLURB = "/resume/blurb";
const EUDCATION = "/resume/education";
const CERTIFICATION = "/resume/certifications";
const PROJECT = "/resume/projects";
const COMPANIES = "/resume/companies";
const ROLES = "/resume/roles";
const RESUME = "/resume/";
const UPLOAD_EXISTING_RESUME = "/resume/upload_resume";
const RESUMEUPDATE = "/resume/update";
const MY_RESUME = "/resume/my";
const ADD_CATEGORY = "/resume/categories?resume_id=";
const UPDATE_DELETE_CATEGORY = "/resume/categories?category_id=";
const ADD_GET_SKILL = "/resume/skills?resume_id=";
const UPDATE_DELETE_SKILL = "/resume/skills?skill_id=";
const PUBLICATIONS = "/resume/publications?resume_id=";
const UPDATE_DELETE_PUBLICATION = "/resume/publications?publication_id=";
const GENERATE_RESUME = "/resume/generate_resume";
const GET_SINGLE_RESUME = "/resume/";
const INTEREST = "/resume/interest?resume_id=";
const INTEREST_UPDATE = "/resume/interest?interest_id=";
const LANGUAGE = "/resume/language?resume_id=";
const LANGUAGE_UPDATE = "/resume/language?language_id=";
const SOFT_SKILL = "/resume/soft_skill?resume_id=";
const SOFT_SKILL_UPDATE = "/resume/soft_skill?skill_id=";
const SAVE_RESUME = "/resume/save_resume";
const UPGRADE_RESUME = "/resume/copy/create";
const ADD_SECTION = "/resume/section";
const UPDATE_RESUME_POSITION = "/resume/position";
const GET_DELETED_SECTIONS = "/resume/deleted-positions";
const RESTORE_DELETED_SECTIONS = "/resume/position/restore";
const ResumeRepository = {
  // resume
  deleteResume(payload) {
    return Repository.delete(`/resume/delete/${payload}`);
  },
  createResume(payload) {
    return Repository.post(`${RESUME}`, payload);
  },
  uploadExisting(payload) {
    return Repository.post(`${UPLOAD_EXISTING_RESUME}`, payload);
  },
  upgradeResume(resumeId) {
    return Repository.post(`${UPGRADE_RESUME}?resume_id=${resumeId}`);
  },
  updateResume(resumeId, createResume) {
    return Repository.put(
      `${RESUMEUPDATE}?resume_id=${resumeId}`,
      createResume
    );
  },
  getDeletedSections(resumeId) {
    return Repository.get(`${GET_DELETED_SECTIONS}/${resumeId}`);
  },
  restoreDeletedSection(resumeId, section) {
    return Repository.get(`${RESTORE_DELETED_SECTIONS}/${resumeId}/${section}`);
  },
  updateResumePosition(resumeId, payload) {
    console.log("resumeId: ", resumeId);
    console.log("payload: ", payload);
    return Repository.put(
      `${UPDATE_RESUME_POSITION}?resume_id=${resumeId}`,
      payload
    );
  },
  deleteResumePosition(resumeId, heading) {
    return Repository.delete(
      `${UPDATE_RESUME_POSITION}/${resumeId}/${heading}`
    );
  },
  deleteAdditionalSections(sectionId) {
    return Repository.delete(
      `resume/section-data?section_data_id=${sectionId}`
    );
  },
  addSection(resumeId, payload) {
    return Repository.post(`${ADD_SECTION}?resume_id=${resumeId}`, payload);
  },
  updateSection(sectionId, payload) {
    return Repository.put(`${ADD_SECTION}?section_id=${sectionId}`, payload);
  },
  getAllResume() {
    return Repository.get(`${MY_RESUME}`);
  },
  getSingleResume(resumeId) {
    return Repository.get(`${GET_SINGLE_RESUME}?resume_id=${resumeId}`);
  },
  generateResume(resumeId) {
    return Repository.get(`${GENERATE_RESUME}?resume_id=${resumeId}`);
  },
  /////profile
  addContactInfo(payload, id) {
    return Repository.post(`${CONTACT_INFO}?resume_id=${id}`, payload);
  },
  updateContactInfo(payload) {
    const { contactId, data } = payload;
    return Repository.put(`${CONTACT_INFO}?contact_id=${contactId}`, data);
  },
  getContactInfo(payload) {
    return Repository.get(`${CONTACT_INFO}?resume_id=${payload}`);
  },
  //////BLURB
  addBlurb(payload, id) {
    console.log(payload);
    return Repository.post(`${BLURB}?resume_id=${id}`, payload);
  },
  updateBlurb(payload) {
    const { updateDocId, data } = payload;
    return Repository.put(`${BLURB}?blurb_id=${updateDocId}`, data);
  },
  getBlurb(payload) {
    return Repository.get(`${BLURB}?resume_id=${payload}`);
  },
  deleteBlurb(payload) {
    return Repository.delete(`${BLURB}?blurb_id=${payload}`);
  },
  ////Education
  addEducation(payload, id) {
    console.log(payload);
    return Repository.post(`${EUDCATION}?resume_id=${id}`, payload);
  },
  updateEducation(payload) {
    const { updateDocId, data } = payload;
    return Repository.put(`${EUDCATION}?education_id=${updateDocId}`, data);
  },
  getEducation(payload) {
    return Repository.get(`${EUDCATION}?resume_id=${payload}`);
  },
  deleteEducation(payload) {
    return Repository.delete(`${EUDCATION}?education_id=${payload}`);
  },
  ////Certificate
  addCertification(payload, id) {
    console.log(payload);
    return Repository.post(`${CERTIFICATION}?resume_id=${id}`, payload);
  },
  updateCertification(payload) {
    const { updateDocId, data } = payload;
    return Repository.put(
      `${CERTIFICATION}?certification_id=${updateDocId}`,
      data
    );
  },
  getCertification(payload) {
    return Repository.get(`${CERTIFICATION}?resume_id=${payload}`);
  },
  deleteCertification(payload) {
    return Repository.delete(`${CERTIFICATION}?certification_id=${payload}`);
  },
  ////projects
  addProject(payload, id) {
    console.log(payload);
    return Repository.post(`${PROJECT}?resume_id=${id}`, payload);
  },
  updateProject(payload) {
    const { updateDocId, data } = payload;
    return Repository.put(`${PROJECT}?project_id=${updateDocId}`, data);
  },
  getProject(payload) {
    return Repository.get(`${PROJECT}?resume_id=${payload}`);
  },
  deleteProject(payload) {
    return Repository.delete(`${PROJECT}?project_id=${payload}`);
  },
  ////Work Experience
  addExperience(payload, id) {
    console.log(payload);
    return Repository.post(`${COMPANIES}?resume_id=${id}`, payload);
  },
  addRoles(payload, id) {
    console.log(payload);
    return Repository.post(`${ROLES}?company_id=${id}`, payload);
  },
  getRoles(payload) {
    return Repository.get(`${ROLES}?resume_id=${payload}`);
  },
  udpateResumeCompaney(payload, id) {
    console.log(payload);
    return Repository.put(`${COMPANIES}?company_id=${id}`, payload);
  },
  udpateResumeRoles(payload, id) {
    console.log(payload);
    return Repository.put(`${ROLES}?role_id=${id}`, payload);
  },
  // updateExperience(payload) {
  //   const { updateDocId, data } = payload;
  //   return Repository.put(`${EXPERIENCE}?experience_id=${updateDocId}`, data);
  // },
  // getExperience(payload) {
  //   return Repository.get(`${EXPERIENCE}?resume_id=${payload}`);
  // },
  deleteCompaney(payload) {
    return Repository.delete(`${COMPANIES}?company_id=${payload}`);
  },
  deleteRole(payload) {
    return Repository.delete(`${ROLES}?role_id=${payload}`);
  },
  postCategory(ID, payload) {
    return Repository.post(`${ADD_CATEGORY}${ID}`, payload);
  },
  putCategory(ID, payload) {
    return Repository.put(`${UPDATE_DELETE_CATEGORY}${ID}`, payload);
  },
  delCategory(ID) {
    return Repository.delete(`${UPDATE_DELETE_CATEGORY}${ID}`);
  },
  getCategory(ID) {
    return Repository.get(`${ADD_CATEGORY}${ID}`);
  },
  postSkill(ID, payload) {
    return Repository.post(`${ADD_GET_SKILL}${ID}`, payload);
  },
  putSkill(ID, payload) {
    return Repository.put(`${UPDATE_DELETE_SKILL}${ID}`, payload);
  },
  delSkill(ID) {
    return Repository.delete(`${UPDATE_DELETE_SKILL}${ID}`);
  },
  getSkill(ID) {
    return Repository.get(`${ADD_GET_SKILL}${ID}`);
  },
  postPublication(ID, payload) {
    return Repository.post(`${PUBLICATIONS}${ID}`, payload);
  },
  getPublications(ID) {
    return Repository.get(`${PUBLICATIONS}${ID}`);
  },
  putPublication(ID, payload) {
    return Repository.put(`${UPDATE_DELETE_PUBLICATION}${ID}`, payload);
  },
  delPublication(ID) {
    return Repository.delete(`${UPDATE_DELETE_PUBLICATION}${ID}`);
  },
  getInterests(resumeId) {
    return Repository.get(`${INTEREST}${resumeId}`);
  },
  addInterests(resumeId, payload) {
    return Repository.post(`${INTEREST}${resumeId}`, payload);
  },
  updateInterests(interestId, payload) {
    return Repository.put(`${INTEREST_UPDATE}${interestId}`, payload);
  },
  deleteInterests(interestId) {
    return Repository.delete(`${INTEREST_UPDATE}${interestId}`);
  },
  getLanguages(resumeId) {
    return Repository.get(`${LANGUAGE}${resumeId}`);
  },
  addLanguages(resumeId, payload) {
    return Repository.post(`${LANGUAGE}${resumeId}`, payload);
  },
  updateLanguages(languageId, payload) {
    return Repository.put(`${LANGUAGE_UPDATE}${languageId}`, payload);
  },
  deleteLanguages(languageId) {
    return Repository.delete(`${LANGUAGE_UPDATE}${languageId}`);
  },
  getSoftSkills(resumeId) {
    return Repository.get(`${SOFT_SKILL}${resumeId}`);
  },
  addSoftSkills(resumeId, payload) {
    return Repository.post(`${SOFT_SKILL}${resumeId}`, payload);
  },
  updateSoftSkills(softskillId, payload) {
    return Repository.put(`${SOFT_SKILL_UPDATE}${softskillId}`, payload);
  },
  deleteSoftSkills(softskillId) {
    return Repository.delete(`${SOFT_SKILL_UPDATE}${softskillId}`);
  },
  saveAiGeneratedResume(resumeId) {
    return Repository.post(`${SAVE_RESUME}?resume_id=${resumeId}`);
  },
  getResumeFeedback(id) {
    return Repository.get(`/resume/generate_resume_feedback?resume_id=${id}`);
  },
  ///// Add Work experiece history
  addWorkExperienceFun(payload, resumeId) {
    return Repository.post(`/resume/companies?resume_id=${resumeId}`, payload);
  },
  ///// update Work experiece history
  updateWorkExperienceFun(payload, workId) {
    return Repository.put(`/resume/companies?company_id=${workId}`, payload);
  },

  // ============== Get All Templates
  getAllTemplates() {
    return Repository.get(`resume/section-templates`);
  },
  // ============== Create New Additional Section
  createAdditionalSection(payload, resumeId) {
    return Repository.post(`resume/section?resume_id=${resumeId}`, payload);
  },

  // ============== Create New Section
  createNewSection(payload) {
    const { sectionId, data } = payload;
    return Repository.post(`/resume/section-data?section_id=${sectionId}`, data[0]);
  },

    // ============== Update Section
    updateAdditionalSection(payload) {
      const { sectionId, data } = payload;
      return Repository.put(`/resume/section-data?section_data_id=${sectionId}`, data[0]);
    },
};

export default ResumeRepository;
