import { Button, Form, Input, InputNumber, Select } from "antd";
import axios from "axios";
import React from "react";
import Sec4 from "../../assets/Images/sec4.png";

export default function Section4() {
  const { TextArea } = Input;

  // Updated onFinish function
  const onFinish = async (values) => {
    console.log("Success:", values);

    // Prepare the data in Attio's expected format
    const data = {
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
      phone: values.mobileNumber,
      company: values.companyName,
      organizationType: values.organizationType,
      useCase: values.comments,
    };

    try {
      const response = await axios.post("https://api.attio.com/v2/objects/people/records", data, {
        headers: {
          Authorization: `Bearer f597b3cbd002c17728840b298a0e1f49bd6f32afa45c42d1e943da6bf8f86072`,
          "Content-Type": "application/json",
        },
      });
      console.log("Attio Response:", response.data);
      // Optionally display a success message
    } catch (error) {
      console.error("Error sending data to Attio:", error);
      // Optionally display an error message
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="sec4-bg my-20 py-10">
      <div className="flex justify-center items-center">
        <h1 className="Poppins text-heading text-center h-12">Talk to</h1>
        <div className="h-12">
          <h1
            className="Poppins text-heading text-center"
            style={{ color: "#2B6BE2" }}
          >
            Sales Today
          </h1>
          <img className="mx-auto img-line -my-3" src={Sec4} alt="" />
        </div>
      </div>

      <p className="OpenSans text-lg text-center leading-5 font-normal width-para mx-auto mt-7 mb-10">
        Transform your hiring and training with end-to-end solutions. Contact
        our sales team today to explore how we can help.
      </p>

      <div className="form flex OpenSans justify-center">
        <Form
          name="validateOnly"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="flex justify-center flex-wrap gap-x-4">
            <div className="">
              <p className="text-base mb-2 capitalize">First Name</p>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter your first name"
                  className="input-width py-4 rounded-xl border-none"
                />
              </Form.Item>
            </div>
            <div className="">
              <p className="text-base mb-2 capitalize">Last Name</p>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter your last name"
                  className="input-width py-4 rounded-xl border-none"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex justify-center flex-wrap gap-x-4">
            <div className="">
              <p className="text-base mb-2 capitalize">Mobile Number</p>
              <Form.Item
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Enter your mobile number"
                  className="input-width py-4 rounded-xl border-none"
                />
              </Form.Item>
            </div>
            <div className="">
              <p className="text-base mb-2 capitalize">Email</p>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Enter your email"
                  className="input-width py-4 rounded-xl border-none"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex justify-center flex-wrap gap-x-4">
            <div className="">
              <p className="text-base mb-2 capitalize">Company Name</p>
              <Form.Item
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter your company name"
                  className="input-width py-4 rounded-xl border-none"
                />
              </Form.Item>
            </div>
            <div className="">
              <p className="text-base mb-2 capitalize">Organization Type</p>
              <Form.Item
                name="organizationType"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select your organization type"
                  dropdownClassName="custom-dropdown"
                  className="custom-select1"
                >
                  <Select.Option value="option1">Option 1</Select.Option>
                  <Select.Option value="option2">Option 2</Select.Option>
                  <Select.Option value="option3">Option 3</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="">
            <p className="text-base mb-2 capitalize">Nexa Use Case</p>
            <Form.Item
              name="comments"
              rules={[
                {
                  required: true,
                  message: "Please enter your comments",
                },
              ]}
            >
              <TextArea
                style={{ resize: "none" }}
                rows={4}
                placeholder="Enter your use case"
                className="w-full py-4 rounded-xl border-none"
              />
            </Form.Item>
          </div>

          {/* Submit Button */}
          <div className="mt-4 flex justify-center">
            <Form.Item>
              <Button
                style={{
                  background:
                    "linear-gradient(94.37deg, #2B6BE2 0%, #1E4B9E 100%)",
                }}
                htmlType="submit"
                className="px-6 text-white rounded-xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 Satoshi "
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}
