import React, { useEffect, useRef, useState } from "react";
import textToSpeech from "../../../Utilities/textToSpeech";
import ProgressBar from "../../../Components/ProgressBar";
import "./interview.css";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../Components/modal";
import EndingScreen from "../../../Components/EndingScreen";
import Loader from "../../../Components/main-loader";
import CircularLoader from "../../../Components/loader";

import {
  getInterviewResponse,
  submitAnswers,
} from "../../../Redux/DashboardSlice";
import { error } from "../../../Utilities/toast";
import { useNavigate } from "react-router-dom";
import { setInterviewSession } from "../../../Redux/GlobalStates";
import InterviewTimer from "../../../Utilities/InterviewTimer";
import ReactMarkdown from "react-markdown";

const Interview = ({
  allQuestions = [],
  audio,
  type,
  interviewTrainingSessionId,
  setGetQuestionOnStart,
  setInterviewTimer,
  interviewTimer,
}) => {
  console.log(
    allQuestions,
    audio,
    type,
    interviewTrainingSessionId,
    "typetype"
  );
  // const ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.dashboard);
  const [progress, setProgress] = useState(0);
  const [screen, setScreen] = useState(0);
  const [questions, setQuestions] = useState(allQuestions);
  console.log(progress, "questquestionsion");
  const [answers, setAnswers] = useState([]);
  const [isCollapse, setIsCollapse] = useState(false);
  const [answerMode, setAnswerMode] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [voiceAnswer, setVoiceAnswer] = useState(null);
  const [textAnswer, setTextAnswer] = useState(null);
  const [submitAnswer, setSubmitAnswer] = useState(null);
  const [storeResult, setStoreResult] = useState(null);
  const [storeResultScore, setStoreResultScore] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [endScreen, setEndScreen] = useState(false);
  const [quickLoading, setquickLoading] = useState(false);
  const [getFeedbackState, setGetFeedbackState] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(300); // 300 seconds = 5 minutes
  const [endSession, setEndSession] = useState(false);
  const [userAgent, setUserAgent] = useState("");
  const [showInterviewStartButton, setShowInterviewStartButton] =
    useState(false);

  useEffect(() => {
    // Detect user agent
    const ua = navigator.userAgent;

    if (/iPhone|iPad|iPod/i.test(ua)) {
      console.log("iOS device detected");
      setUserAgent("iOS");
    } else if (/Android/i.test(ua)) {
      console.log("Android device detected");
      setUserAgent("Android");
    } else if (/Chrome/i.test(ua)) {
      console.log("Chrome browser detected");
      setUserAgent("Chrome");
    } else if (/Firefox/i.test(ua)) {
      console.log("Firefox browser detected");
      setUserAgent("Firefox");
    } else if (/Safari/i.test(ua) && !/Chrome/i.test(ua)) {
      console.log("Safari browser detected");
      setUserAgent("Safari");
    }
    // Add more checks as needed
  }, []);

  console.log(storeResult, "storeResult");

  const recognition = useRef(null);

  const startInterviewText = `Hi! Let's practice an interview for ${type}`;
  const speakText = startInterviewText.split(" ");
  const isMockQuestion = false;

  useEffect(() => {
    setScreen(1);
  }, []);

  useEffect(() => {
    setQuestions(allQuestions);
  }, [allQuestions]);

  useEffect(() => {
    if (screen === 1 && audio) {
      setIsRunning(false);
      textToSpeech(startInterviewText, playVoice, null, () => {
        setIsRunning(true);
      });
    }
  }, [screen]);

  const playVoice = () => {
    setquickLoading(true);
    testingfunc();
  };

  const testingfunc = () => {
    const myInterval = setTimeout(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      } else {
        clearTimeout(myInterval);
        if (!audio) startQuestions();
      }
    }, 40);

    return () => {
      clearTimeout(myInterval);
    };
  };

  useEffect(() => {
    if (progress > 0 && progress !== 100) {
      testingfunc();
    } else if (progress === 100) {
      startQuestions();
    }
  }, [progress]);

  const startQuestions = () => {
    if (questions.length > 0) {
      if (userAgent === "iOS" || userAgent === "Safari") {
        setShowInterviewStartButton(true);
        return;
      }
      setScreen(2);
      dispatch(setInterviewSession(true));
      setGetFeedbackState(false);
      setIsRunning(false);
      if (audio) {
        textToSpeech(questions[currentSlide]?.text, null, null, () => {
          changeShowOptions();
          setIsRunning(true);
        });
      } else {
        changeShowOptions();
      }
    } else {
      error("Questions not found");
      navigate(-1);
    }
  };

  const changeShowOptions = () => {
    setAnswers([...answers, { id: questions[currentSlide].id }]);
  };

  const hasAnswer = (id) => {
    const ans = answers.find((item) => item.id === id);
    if (ans && ans.answer) return true;
    else return false;
  };

  const handleCollapse = (id) => {
    if (isCollapse) {
      setIsCollapse(false);
      setAnswerMode(null);
      setVoiceAnswer(null);
    } else {
      const ansIndex = answers?.findIndex((item) => item.id === id);
      setTextAnswer(answers[ansIndex]?.answer || "");
      setIsCollapse(true);
      setAnswerMode("keyboard");
    }
  };

  const handleAnswer = (id) => {
    let answer = "";
    if (answerMode === "voice") {
      recognition.current.stop();
      answer = voiceAnswer;
    } else {
      answer = textAnswer;
    }

    if (answer != null) {
      setSubmitAnswer(answer);
      const ansIndex = answers.findIndex((item) => item.id === id);
      const ansArr = answers;
      if (ansIndex !== -1) {
        ansArr[ansIndex].answer = answer;
      } else {
        ansArr.push({ id, answer });
      }
      setAnswers(ansArr);
    }
    setVoiceAnswer(null);
    setTextAnswer(null);
    setIsCollapse(false);
    setAnswerMode(null);
  };

  const submitPayload = () => {
    const payload = {
      interviewTrainingSessionId: interviewTrainingSessionId,
      questionId: questions[currentSlide].id,
      response: submitAnswer || answers[currentSlide].answer || "",
    };
    dispatch(submitAnswers(payload))
      .unwrap()
      .then((res) => {
        setSubmitAnswer(null);
        if (currentSlide === questions.length - 1) {
          setGetFeedbackState(true);
        }
      });
  };

  const nextQuestion = () => {
    if (currentSlide < questions.length) {
      console.log("Hy question submit sucessfully");
      submitPayload();
      const activeSlide = currentSlide + 1;
      setCurrentSlide(activeSlide);

      if (!answers[activeSlide]) {
        if (audio) {
          setIsRunning(false);
          textToSpeech(questions[activeSlide].text, null, null, () => {
            setAnswers([...answers, { id: questions[activeSlide].id }]);
            setIsRunning(true);
          });
        } else {
          setAnswers([...answers, { id: questions[activeSlide].id }]);
        }
      }
    }
  };

  const previousQuestion = () => {
    if (currentSlide > 0) {
      const activeSlide = currentSlide - 1;
      setCurrentSlide(activeSlide);
    }
  };

  // const addAnswer = (e, index) => {
  //   const { value } = e.target;
  //   const ansArr = answers;
  //   ansArr[index].answer = value;
  //   setAnswers(ansArr);
  // };

  const speechToText = (index) => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      recognition.current = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      setAnswerMode("voice");
      recognition.current.lang = "en-US";
      recognition.current.interimResults = true;
      recognition.current.continuous = true;
      recognition.current.start();

      recognition.current.onresult = (event) => {
        const answer = Array.from(event.results)
          .filter((result) => result[0].confidence > 0.5) // Check for 100% confidence
          .map((alts) => alts[0].transcript)
          .join("");
        setVoiceAnswer(answer);
        setTextAnswer(answer);
      };
      recognition.current.onerror = (event) => {
        // this.$toast.error(event.error);
        setIsCollapse(false);
        setAnswerMode(null);
        setVoiceAnswer(null);
      };
    } else {
      // this.$toast.error('Speech recognition not supported');
    }
  };

  const handleResult = (type) => {
    console.log({ type });
    if (type === "submit") {
      submitPayload();
    } else {
      handleGetFeedback();
      // console.log(answers, "ahsaiqbal sia w ehjwjk");
    }
  };

  const handleGetFeedback = () => {
    setFeedbackLoading(true);
    dispatch(getInterviewResponse(interviewTrainingSessionId))
      .unwrap()
      .then((resolvedData) => {
        setFeedbackLoading(false);
        setStoreResult(resolvedData?.feedbackList);
        setStoreResultScore(resolvedData?.averageScore);
        setIsModal(true);
        dispatch(setInterviewSession(true));
        setGetFeedbackState(false);
        setEndSession(false);
        setSeconds(300);
        setIsRunning(false);
        setInterviewTimer(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
    // console.log(answers, "ahsaiqbal sia w ehjwjk");
  };

  const handleCloseModal = () => {
    setIsModal(false);
    setEndScreen(true);
  };

  const getModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => handleCloseModal()}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-12 order-md-1 order-2">
          {storeResult?.map((item, index) => (
            <div className="cardBody" key={index + 100}>
              <h5 className="mx-3 my-3 ">Question:</h5>
              <hr />
              <p className="mx-3 my-3">{item?.question}</p>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Answer:</h5>
              <hr />
              <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
              <ReactMarkdown className="mx-3 my-3">
                {item?.feedback}
              </ReactMarkdown>{" "}
            </div>
          ))}
        </div>
      </div>
    );

    const footer = (
      <div className="footer_scrore d-flex">
        <h5 className="my-0">Nexa Grading Score:</h5>
        <span className="score">{storeResultScore?.toFixed(2)}</span>
      </div>
    );

    return (
      <Modal
        show={isModal}
        header={header}
        content={content}
        footer={footer}
        type="feedback"
      />
    );
  };

  useEffect(() => {
    if (endSession && interviewTimer) {
      error("Interview time is over");
      handleGetFeedback();
    }
  }, [endSession, interviewTimer]);

  let videoArr = [
    "https://files2.heygen.ai/aws_pacific/avatar_tmp/df6f2c3cc54e4d7f826f9ff0682cbd92/25b26e853aa341a2b50a1ce9866c2a98.mp4?Expires=1720676003&Signature=U6n~IzwbaM4O9SUKqw1tm7JbjuPhkyjk19ji6vaAkPjCo9dQeZEnGcpTBByuyEWGiHwKE6v-QykTP-vrDcv25RuabiGZesgvVg0PPcAdQJJtsjKjSNpZtpF75mK8I4XDsws8RqpRtqmkryeLHOkPWovWGjtuhgn-GwKXrnY5y3FC2tYY-aqGUwDktyRe4qnadCnblJxyXj-HxX9FkbBOktlHDmNoumYHgointyfvIE7vbG210DTyJa3tGuMKb-z~D0lOpv0nIJKLhhixRxE90XXikzpyRvT~J7rSgobSvwPR75PoOmAYfDXBWU84uMuAmuGqXBjt5FzooKebKaY3pQ__&Key-Pair-Id=K38HBHX5LX3X2H",
    "https://files2.heygen.ai/aws_pacific/avatar_tmp/df6f2c3cc54e4d7f826f9ff0682cbd92/3c3c035b44eb4047b0081473a2d48e52.mp4?Expires=1720676191&Signature=lxCdnikjpFi7bp~S8jpsgWu1mY~846zkuY68giqqoIfoiQpSTvvxNkw8MkTRbaVjCXm6pxEUqVomewVwUGRQEDIomNxNv4NeaNG5~Tz2B3NK~tjZKUbyOYpZVHbsW9xBp4calF3vNIoyCJF8qnOpj5URdDy~rSSbazheU8gv4Yl097GWG-Tp0VhJLl4PUdUwwzN1hHMMh3vV4xYRRp~nLBA4E6ofGVAFEYfXhCTOgW4FQVrh6wG94rYB8VbefT9J9DkiPAvsm6fno~L3Qa8n0pewf12RdaTwb~YdfEzIIKZvUPRbSsqOMR93DYFX~hq5HuTuksqDjGgP5gLpPIIPRQ__&Key-Pair-Id=K38HBHX5LX3X2H",
    "https://files2.heygen.ai/aws_pacific/avatar_tmp/df6f2c3cc54e4d7f826f9ff0682cbd92/ea32458116a04bdd839def0452e2ccde.mp4?Expires=1720676372&Signature=opRSmd8Mp22a9vEHbFCgekhOH5yxGzr-9mpwB36KENUfFZW4G315jfD8xYNYtDfXsqISDYPcDcaPOjbPlB99RIrXRYYL0HDU~v1JFeXlz6msia4rd6oyc4zjXOcFXXUwhr1q3E4aqhyRsYOz5MwblIpEo1BngKk3Qb4BPz5nxyxpahvFTjtt7qYApir5-QInBLB8nz1LP0Y~X5USKPs8gLvxqdBPvEfFLkk1D2sbkrt4-k9IpCZjvEn71as439zlxfwIdTM5uBbKQpV7tWpUFqPCOnCobcukeuDppBOpJJ3j5jFnu-BqSVeHs1kOYnfyLhesDW3HEaluOR-DBKD4RA__&Key-Pair-Id=K38HBHX5LX3X2H",
  ];

  const startQuestioning = () => {
    setScreen(2);
    dispatch(setInterviewSession(true));
    setGetFeedbackState(false);
    setIsRunning(false);
    if (audio) {
      textToSpeech(questions[currentSlide]?.text, null, null, () => {
        changeShowOptions();
        setIsRunning(true);
      });
    } else {
      changeShowOptions();
    }
  };

  return (
    <div>
      {/* {type === "cfo" && (
        <video
          src={
            "https://files2.heygen.ai/aws_pacific/avatar_tmp/df6f2c3cc54e4d7f826f9ff0682cbd92/21b4bbc9d08d4715ab14b3b528b85423.mp4?Expires=1720243306&Signature=dgsqVcsuJ9HS0ONcOKz9XwdOjyVsQ-8ej0NBuLeBb2xCLbfWyt4dRh-XPyu92hpOx~dHGhxxcKHDALyIVwKnwv8rmtgnmUuQQe9EKmHtZ-9QYdjBN0vw-Iky-yGQW6llCA1Gf2Y261UYD6Lf3OcANwvq9nsQGSnAw7vasnrCbf-AWAs1EAvzvMWPWDio4IKZqNljuWkRZZhmSCNm5WvsLIbJrs2McF5JYpYABc2wyfgTYC544svLOzTTMjhm8Y4Ywvg1GhUtcuDebEq~Bd-bAZMI4Hm~dgeXCHlMTP8CDpvfxyNptWk3-OMKNGdqNwi1ShzJHgh-tlHcfUtb3xxoCw__&Key-Pair-Id=K38HBHX5LX3X2H"
          }
          controls
          className="w-4/5 lg:w-2/5 rounded-lg mt-20 xl:mt-12"
        />
      )} */}

      {!feedbackLoading ? (
        <>
          {!quickLoading && <Loader />}
          <div name="slide-fade">
            <div className="ic-start-interview">
              {quickLoading && screen === 1 && (
                <div>
                  <div className="ic-first-face">
                    <img
                      src="/images/icon.png"
                      style={{ maxWidth: "80px" }}
                      alt=""
                    />
                    <h3>
                      {speakText.map((item, index) => (
                        <span
                          key={index}
                          className={`speak-text ${
                            index === 0 || index === 3 || index === 4
                              ? "primary"
                              : ""
                          }`}
                          style={{
                            animationDelay: `${index * 0.408}s`,
                            animationDuration: `${item.length * 0.136}s`,
                          }}
                        >
                          {item}&nbsp;
                        </span>
                      ))}
                    </h3>
                  </div>
                  <ProgressBar completed={progress} />
                  {showInterviewStartButton && (
                    <div className="text-center">
                      <button
                        className="ic-btn my-3"
                        onClick={startQuestioning}
                      >
                        Start Questioning
                      </button>
                    </div>
                  )}
                </div>
              )}
              {screen === 2 &&
                (endScreen ? (
                  <EndingScreen
                    setEndScreen={setEndScreen}
                    mainRoute={`/interview/role`}
                  />
                ) : isLoading ? (
                  <Loader />
                ) : (
                  <div className="carousel-wrap-div">
                    {interviewTimer && (
                      <div className="carousel-div">
                        <div className="timer-div">
                          <InterviewTimer
                            isRunning={isRunning}
                            setIsRunning={setIsRunning}
                            seconds={seconds}
                            setSeconds={setSeconds}
                            setEndSession={setEndSession}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className="carousel-div"
                      style={{ left: `-${currentSlide * 100}%` }}
                    >
                      {questions.map((item, index) => (
                        <div key={index} className="slide-div">
                          <div className="slide">
                            <div
                              className={`ic-question-start ${
                                answers[index] ? "bg-white" : "border-0"
                              }`}
                            >
                              {type === "cfo" && videoArr[index] && (
                                <video
                                  src={videoArr[index]}
                                  controls
                                  className="w-4/5 lg:w-2/4 rounded-lg xl:mt-12 items-center m-auto"
                                  style={{ marginBottom: 20 }}
                                />
                              )}

                              {!isMockQuestion && (
                                <div className="question-header">
                                  <span className="question-tags">
                                    <i className="ri-error-warning-line"></i>
                                    {item.type}
                                  </span>
                                  <span>{`${index + 1}/${
                                    questions.length
                                  }`}</span>
                                </div>
                              )}

                              <h4>{item.text}</h4>

                              {answers[index] && (
                                <div className="ic-record-buttons">
                                  <div
                                    className={`ic-according-items ${
                                      isCollapse ? "active" : ""
                                    }`}
                                  >
                                    {hasAnswer(item.id) ? (
                                      <div className="ic-according-header">
                                        <p>
                                          <i className="ri-list-radio" /> Your
                                          Answer
                                        </p>
                                        <button
                                          className="btn-collapse"
                                          onClick={() =>
                                            handleCollapse(item.id)
                                          }
                                        >
                                          <i className="ri-arrow-down-s-line" />
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className={`ic-keyboard-body ${
                                        isCollapse ? "show" : ""
                                      }`}
                                    >
                                      <textarea
                                        className="ic-body-text"
                                        placeholder="Type your answer here"
                                        value={textAnswer || ""}
                                        onChange={(e) =>
                                          setTextAnswer(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="ic-record-keyboard-btn">
                                    <div className="answer-btn">
                                      {answerMode ? (
                                        <button
                                          type="button"
                                          onClick={() => handleAnswer(item.id)}
                                        >
                                          Done{" "}
                                          <i className="ri-check-double-line" />
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            handleCollapse(item.id);
                                            speechToText(index);
                                          }}
                                        >
                                          {!hasAnswer(item.id) ? (
                                            <span>
                                              Answer{" "}
                                              <i className="ri-mic-fill" />
                                            </span>
                                          ) : (
                                            <span>
                                              Redo <i className="ri-mic-fill" />
                                            </span>
                                          )}
                                        </button>
                                      )}

                                      {answerMode === "voice" && (
                                        <div id="voice_bars">
                                          <div className="voice_bar"></div>
                                          <div className="voice_bar"></div>
                                          <div className="voice_bar"></div>
                                          <div className="voice_bar"></div>
                                          <div className="voice_bar"></div>
                                        </div>
                                      )}

                                      {!answerMode && (
                                        <div
                                          className="keyboard"
                                          onClick={() =>
                                            handleCollapse(item.id)
                                          }
                                        >
                                          {hasAnswer(item.id) ? (
                                            <i className="ri-edit-line" />
                                          ) : (
                                            <i className="ri-keyboard-box-line" />
                                          )}
                                        </div>
                                      )}
                                    </div>

                                    {!answerMode && (
                                      <div
                                        className="slider-arrows"
                                        style={{ display: "flex" }}
                                      >
                                        {index !== 0 && (
                                          <div
                                            className="right-arrow"
                                            onClick={previousQuestion}
                                          >
                                            Prev
                                          </div>
                                        )}
                                        {index < questions.length - 1 && (
                                          <div
                                            className="right-arrow"
                                            onClick={nextQuestion}
                                          >
                                            Next
                                          </div>
                                        )}
                                      </div>
                                    )}

                                    {index === questions.length - 1 && (
                                      <div
                                        onClick={() =>
                                          getFeedbackState
                                            ? handleResult("response")
                                            : handleResult("submit")
                                        }
                                        className="ic-btn"
                                      >
                                        {getFeedbackState
                                          ? "Get Feedback"
                                          : "Submit"}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {getModal()}
        </>
      ) : (
        <CircularLoader
          title="Nexa is hard at work to analyze your results, give us a minute"
          icon={true}
        />
      )}
    </div>
  );
};

export default Interview;
