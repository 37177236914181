import Repository from "./Repository";

const EXECUTE_CODE = "/octagon/execute";
const SAVE_PROGRESS = "/octagon/save-progress";
const LOAD_PROGRESS = "/octagon/load-progress";

const octagonRepository = {
  executeCode(payload) {
    return Repository.post(`${EXECUTE_CODE}`, payload);
  },
  saveProgress(projectId, payload) {
    return Repository.post(`${SAVE_PROGRESS}`, payload);
  },
  loadProgress(projectId) {
    return Repository.get(`${LOAD_PROGRESS}`);
  },
};

export default octagonRepository;
