import React, { useEffect, useRef } from "react";
import CountUp from "react-countup";
import { FaHandshake, FaHouseUser, FaUsers } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Typed from "typed.js";
import newElevate from "../../assets/Images/newElevate.png";

const HeroSection = () => {
  const navigate = useNavigate();
  const typedRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedRef.current, {
      strings: [
        "Cybersecurity",
        "IT",
        "Data Analytics",
        "Software",
        "Sales",
        "Finance",
        "Marketing",
      ],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 2000,
      startDelay: 500,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="hero-container">
      <div className="container hero-internal-container">
        <div className="gradient-right"></div>
        <div className="text-center hero-content">
          <h1 className="hero-heading color-dark">
            Unlock Faster Job Placements for Your Candidates with Nexa’s
            All-in-One Platform
          </h1>
          <p className="hero-description color-dark mt-4">
            Nexa Elevate® provides a comprehensive, AI-powered toolkit that
            takes care of every step from career preparation to job placement,
            seamlessly integrating into your training programs. We address your
            key pain points by offering personalized training paths, real-time
            feedback, and job-matching capabilities, ensuring that your learners
            are fully prepared and positioned to land jobs in
            <span>
              <h2 className="color-primary">
                <span ref={typedRef}></span>
              </h2>
            </span>
          </p>
          <br />
          <div
            className="button-group"
            style={{
              display: "flex",
              justifyContent: "center", // Center the buttons horizontally
              alignItems: "center", // Ensure vertical alignment is centered
              gap: "20px", // Gap between the buttons
              flexWrap: "wrap", // Allow wrapping on smaller screens
              marginTop: "20px",
            }}
          >
            <Link
              to="/register"
              className="ic-btn"
              style={{
                background: "none",
                flex: "1 1 auto",
                maxWidth: "320px",
              }}
            >
              <button
                className="hero-button my-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px",
                  borderRadius: "25px",
                  textAlign: "center",
                  background: "linear-gradient(135deg, #4a6fe9, #7f5be0)",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  border: "none",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.3s ease",
                  margin: "0",
                  width: "100%",
                  height: "70px",
                  maxWidth: "320px", // Same max-width to ensure buttons are identical
                }}
                onMouseOver={(e) => {
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseOut={(e) => {
                  e.target.style.transform = "scale(1)";
                }}
              >
                Start For Free
              </button>
            </Link>

            <a
              href="https://tally.so/r/3yDKdg"
              className="ic-btn"
              style={{
                background: "none",
                flex: "1 1 auto",
                maxWidth: "320px",
              }}
            >
              <button
                className="my-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px 20px", // Adjust padding to match the size
                  borderRadius: "25px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  lineHeight: "1.4",
                  background: "linear-gradient(135deg, #4a6fe9, #7f5be0)",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  border: "none",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.3s ease",
                  margin: "0",
                  width: "100%",
                  height: "70px",
                  maxWidth: "320px", // Same max-width for both buttons
                }}
                onMouseOver={(e) => {
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseOut={(e) => {
                  e.target.style.transform = "scale(1)";
                }}
              >
                Career Center <br />
                Efficiency Assessment
              </button>
            </a>

            {/* Add styling for mobile responsiveness */}
            <style jsx>{`
              @media (max-width: 768px) {
                .button-group {
                  flex-direction: column; /* Stack buttons vertically */
                  align-items: center; /* Center buttons on mobile */
                }

                .ic-btn {
                  width: 100%; /* Make buttons fill the available space */
                  max-width: none; /* Ensure they use full width */
                  margin-bottom: 10px; /* Add spacing between stacked buttons */
                }
              }
            `}</style>
          </div>
        </div>
        <div className="gradient-left" style={{ marginTop: "3em" }}></div>
        <div className="mt-5 px-0 px-sm-5">
          <div className="mt-5 px-0 px-sm-5 h-sm-[500px] h-md-[750px] flex justify-center items-center">
            <img
              src={newElevate}
              className="transform transition-transform hover:scale-125 rounded-xl duration-500 h-[80%] w-[90%] md:w-[100%] bg-cover"
            />
          </div>
        </div>
        <div className="hero-counter-card d-flex justify-content-center align-items-center gap-6 flex-col md:flex-row">
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaUsers size={65} />
              <CountUp end={724} duration={4} />
              <p>People helped land jobs</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaHouseUser size={65} />
              <CountUp end={52} duration={4} />
              <p>Training Institutes that use nexa</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaHandshake size={65} />
              <CountUp end={565} duration={4} />
              <p>Active job seekers</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
