// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel-track {
    display: flex;
    animation: scroll 20s linear infinite;
    width: calc(200%);
    /* Ensures track is wide enough to loop smoothly */
  }

  .carousel-slide {
    flex: 0 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-image {
    max-width: 195px;
    max-height: 56px;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    .carousel-track {
      animation: scroll 15s linear infinite;
    }

    .logo-image {
      max-width: 120px;
      max-height: 40px;
    }
  }

  @media (max-width: 768px) {
    .carousel-track {
      animation: scroll 15s linear infinite;
    }

    .logo-image {
      max-width: 100px;
      max-height: 35px;
    }
  }

  @media (max-width: 480px) {
    .carousel-track {
      animation: scroll 15s linear infinite;
    }

    .logo-image {
      max-width: 80px;
      max-height: 30px;
    }
  }

  @keyframes scroll {
    0% {
    transform: translateX(0);
    }
    
    
    
    100% {
    transform: translateX(-50%);
    }
    }`, "",{"version":3,"sources":["webpack://./src/Components/Landing/slider.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,iBAAiB;IACjB,kDAAkD;EACpD;;EAEA;IACE,cAAc;IACd,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE;MACE,qCAAqC;IACvC;;IAEA;MACE,gBAAgB;MAChB,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,qCAAqC;IACvC;;IAEA;MACE,gBAAgB;MAChB,gBAAgB;IAClB;EACF;;EAEA;IACE;MACE,qCAAqC;IACvC;;IAEA;MACE,eAAe;MACf,gBAAgB;IAClB;EACF;;EAEA;IACE;IACA,wBAAwB;IACxB;;;;IAIA;IACA,2BAA2B;IAC3B;IACA","sourcesContent":[".carousel-container {\n    overflow: hidden;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n  }\n\n  .carousel-track {\n    display: flex;\n    animation: scroll 20s linear infinite;\n    width: calc(200%);\n    /* Ensures track is wide enough to loop smoothly */\n  }\n\n  .carousel-slide {\n    flex: 0 0 auto;\n    padding: 0 20px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .logo-image {\n    max-width: 195px;\n    max-height: 56px;\n    object-fit: contain;\n  }\n\n  @media (max-width: 1024px) {\n    .carousel-track {\n      animation: scroll 15s linear infinite;\n    }\n\n    .logo-image {\n      max-width: 120px;\n      max-height: 40px;\n    }\n  }\n\n  @media (max-width: 768px) {\n    .carousel-track {\n      animation: scroll 15s linear infinite;\n    }\n\n    .logo-image {\n      max-width: 100px;\n      max-height: 35px;\n    }\n  }\n\n  @media (max-width: 480px) {\n    .carousel-track {\n      animation: scroll 15s linear infinite;\n    }\n\n    .logo-image {\n      max-width: 80px;\n      max-height: 30px;\n    }\n  }\n\n  @keyframes scroll {\n    0% {\n    transform: translateX(0);\n    }\n    \n    \n    \n    100% {\n    transform: translateX(-50%);\n    }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
