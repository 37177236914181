import React, { useEffect } from "react";
import Footer from "../../Components/footer_new/footer";
import Header from "../../Components/header_new/navbar";

const StartInterview = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "46093892",
          formId: "224c07e7-fdcc-4a63-8b71-fc2f254d6096",
          target: "#hubspotForm",
        });
      }
    });

    // Add Google Font dynamically
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="interview" style={{ fontFamily: "'Poppins', sans-serif" }}>
      <Header />
      <div
        className="ic-banner"
        style={{
          backgroundImage: `url(${"/images/ic-banner.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container-fluid position-relative px-5 py-4">
          <div className="row">
            <div className="col-md-6 py-4 ml-40px px-6 text-black interview-text">
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "2.8rem",
                  lineHeight: "1.2",
                  color: "#2C3E50",
                  textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                  marginBottom: "20px",
                }}
              >
                <b>
                  Whether you are training or hiring future candidates, Nexa
                  simplifies career services across your organization
                </b>
              </h1>
              <p
                className="support-text"
                style={{
                  fontSize: "1.2rem",
                  lineHeight: "1.6",
                  letterSpacing: "0.5px",
                  color: "#34495E",
                  marginBottom: "30px",
                }}
              >
                What if you could empower your staff with the best career
                services in the world?
              </p>
            </div>
            <div className="col-md-6 mt-0 px-4 py-2">
              <div className="row">
                <div className="col-md-11 col-sm-4 bg-light shadow-lg rounded start-interview-form p-4">
                  <div id="hubspotForm"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StartInterview;
