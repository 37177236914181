import { Tag } from "antd";
import { MdCancel } from "react-icons/md";

const PreferenceTag = ({ label, value, onClick }) => (
  <Tag className="relative w-full sm:w-fit" color="processing">
    {label}: {value}
    <MdCancel
      color="red"
      size={18}
      className="absolute -top-2 -right-2 cursor-pointer bg-white rounded-full"
      onClick={onClick}
    />
  </Tag>
);

const PreferenceBox = ({
  jobsPreference,
  setPreferenceModal,
  handleChangePreference,
}) => {
  return (
    <>
      {jobsPreference?.title && Object.keys(jobsPreference).length !== 0 && (
        <div className="flex flex-col nexaIqTab-container bg-transparent border rounded-lg">
          <div className="flex flex-row border-b px-3 py-2">
            <h2 className="text-bold text-xl">
              Job Preference{" "}
              <span
                className="text-xs cursor-pointer font-medium text-primary hover:underline"
                onClick={() => setPreferenceModal(true)}
              >
                (manage)
              </span>
            </h2>
          </div>{" "}
          <div className="flex flex-wrap gap-2 capitalize px-3 py-4">
            {jobsPreference === null && (
              <p className="text-sm">No Job Preference set</p>
            )}

            {jobsPreference?.title && (
              <Tag
                className=" relative flex w-full sm:w-fit"
                color="processing"
              >
                Title: {jobsPreference?.title}{" "}
              </Tag>
            )}
            {jobsPreference?.location && (
              <PreferenceTag
                label="Location"
                value={jobsPreference?.location}
                onClick={() => handleChangePreference("location")}
              />
            )}

            {jobsPreference?.workplace_type && (
              <PreferenceTag
                label="Workplace Type"
                value={jobsPreference?.workplace_type}
                onClick={() => handleChangePreference("workplace_type")}
              />
            )}

            {jobsPreference?.work_schedule && (
              <PreferenceTag
                label="Work Schedule"
                value={jobsPreference?.work_schedule}
                onClick={() => handleChangePreference("work_schedule")}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PreferenceBox;
