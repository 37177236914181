import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";
import axios from "axios";
const iqRepo = RepositoryFactory.get("nexaIq");

// Async actions
export const getStates = createAsyncThunk(
  "nexaIq/getStates",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/states",
        {
          country: "canada",
        }
      );

      return response.data.data.states;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const getCountryStates = createAsyncThunk(
  "nexaIq/getCountryStates",
  async ({ state, onSuccess }, { rejectWithValue }) => {
    try {
      if (state == null) return;
      const response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/state/cities",
        {
          country: "canada",
          state: state,
        }
      );

      return response.data.data;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const getJobListings = createAsyncThunk(
  "nexaIq/getJobListings",
  async (
    { payload, page, jobApplied, smartScreen, onSuccess },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await iqRepo.getJobListings(
        payload,
        page,
        jobApplied,
        smartScreen
      );
      if (data?.status_code === 200) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const scrapJobs = createAsyncThunk(
  "nexaIq/scrapJobs",
  async ({ payload, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.scrapJobs(payload);
      if (data?.status_code === 200) {
        onSuccess();
        // success(data?.reason);
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      onError();
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const getSingleJob = createAsyncThunk(
  "nexaIq/getSingleJob",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.getSingleJob(id);
      if (data?.status_code === 200) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const applyOnJob = createAsyncThunk(
  "nexaIq/applyOnJob",
  async ({ formData, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.applyJob(formData);
      if (data?.status_code === 201) {
        onSuccess();
        success(data?.detail);
        return data;
      }
      throw new Error("apply failed");
    } catch (err) {
      console.log("err: ", err);
      onSuccess();
      error(err.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
//// Applied Job Count
export const appliedJobCount = createAsyncThunk(
  "nexaIq/appliedJobCount",
  async ({ job_id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await iqRepo.appliedJobCount(job_id);
      if (data?.status_code === 200) {
        onSuccess();
        return data;
      }
      throw new Error("apply failed");
    } catch (err) {
      console.log("err: ", err);
      onError();
      error(err.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

const NexaIqSlice = createSlice({
  name: "nexaIq",
  initialState: {
    loading: false,
    countryStates: [],
    stateCities: [],
    jobs: [],
    getSingleJobData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStates.fulfilled, (state, action) => {
        state.countryStates = action.payload;
      })
      .addCase(appliedJobCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(appliedJobCount.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(appliedJobCount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCountryStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountryStates.fulfilled, (state, action) => {
        state.loading = false;
        state.stateCities = action.payload;
      })
      .addCase(getCountryStates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getJobListings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobListings.fulfilled, (state, action) => {
        state.reason = action.payload?.reason;
        state.jobs_found = action.payload?.data?.jobs_found;
        state.currentPage = action?.payload?.data?.page;
        state.totalPages = action?.payload?.data?.total_pages;
        state.jobs = [
          ...(action?.payload?.data?.smart_screen_jobs || []).map((job) => ({
            ...job,
            source: "smart_screen_jobs",
          })),
          ...(action?.payload?.data?.iq_jobs || []).map((job) => ({
            ...job,
            source: "iq_jobs",
          })),
        ];
        state.loading = false;
      })
      .addCase(getJobListings.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSingleJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleJob.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleJobData = action.payload.data;
      })
      .addCase(getSingleJob.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default NexaIqSlice.reducer;
