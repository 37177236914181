// File: CodeProjects.js
import { Alert, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/header";
import { OctagonCards } from "../octagon/octagonCards"; // Ensure the correct path
import "./CodeProjects.css"; // Optional: Import custom CSS for styling

const CodeProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null); // To handle errors
  const navigate = useNavigate();

  useEffect(() => {
    // Define an asynchronous function to fetch projects
    const fetchProjects = async () => {
      try {
        // Retrieve the token from localStorage (adjust the key if different)
        const token = localStorage.getItem("auth");
        const base = process.env.REACT_APP_API_URL;
        if (!token) {
          setError("No authentication token found. Please log in.");
          setLoading(false);
          return;
        }

        // Make the GET request to fetch projects
        const response = await axios.get(`${base}/octagon/projects`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        });

        // Destructure the response data
        const { status_code, reason, detail, data } = response.data;

        // Check if the response status is successful
        if (status_code === 200) {
          setProjects(data);
        } else {
          setError(`Failed to retrieve projects: ${detail}`);
        }
      } catch (error) {
        // Handle errors (e.g., network issues, server errors)
        if (error.response) {
          // Server responded with a status other than 2xx
          setError(`Error: ${error.response.data.detail || "Server Error"}`);
        } else if (error.request) {
          // Request was made but no response received
          setError("No response received from server.");
        } else {
          // Something else caused the error
          setError(`Error: ${error.message}`);
        }
      } finally {
        setLoading(false); // Ensure loading state is set to false after request completes
      }
    };

    // Call the fetchProjects function
    fetchProjects();
  }, []); // Empty dependency array ensures this runs once on mount

  // Function to transform project_id for display (replace "-" with " ")
  const transformProjectIdForDisplay = (projectId) => {
    return projectId
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Function to handle card click and navigate to the project route
  const handleCardClick = (projectId) => {
    // Replace "-" with "/" for navigation
    const navigationPath = projectId.replace(/-/g, "/");
    navigate(`/intervieworcustom/codingdojo/${navigationPath}`);
  };

  if (loading) {
    return (
      <div className="code-projects-loading">
        <Spin tip="Loading projects..." size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="code-projects-error">
        <Alert message="Error" description={error} type="error" showIcon />
      </div>
    );
  }

  if (projects.length === 0) {
    return (
      <div className="code-projects-empty">
        <Alert
          message="No Projects Found"
          description="You have no saved projects."
          type="info"
          showIcon
        />
      </div>
    );
  }

  return (
    <div className="code-projects-container">
      <Header />

      <div className="code-projects-content">
        <div className="text-center text-2xl mb-12 px-8 max-w-2xl mx-auto font-bold text-gray-800 leading-relaxed transition-all duration-300 ease-in-out transform hover:scale-105">
          <p className="text-gray-700">
            Nexa's Projects allows you to view previous projects
          </p>
        </div>
        <div className="ic-interview-card-container">
          {projects.map((project) => (
            <OctagonCards
              key={project.id}
              className="ic-interview-items"
              card={{
                label: transformProjectIdForDisplay(project.project_id),
                delay: 900,
                image: "/images/icon.png",
                link: `/intervieworcustom/codingdojo/${project.project_id}`,
                projectId: project.project_id,
              }}
              onCardClick={() => handleCardClick(project.project_id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CodeProjects;
