import { useEffect, useMemo, useState } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";

function DebounceSelect({
  data,
  fetchOptions,
  currentPage,
  totalPages,
  placeholder,
  debounceTimeout = 1500,
  ...props
}) {
  const [searchValue, setSearchValue] = useState("");
  const [searched, setSearched] = useState(false);
  const [loader, setLoader] = useState({
    data: false,
    btn: false,
  });

  const fetchData = (value, page) => {
    setLoader((prev) => ({ ...prev, data: true }));
    fetchOptions(value, page).then(() => {
      setLoader((prev) => ({ ...prev, data: false }));
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      const isValuePresent = data?.some((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      );
      if (isValuePresent) {
        return;
      }
      setSearchValue(value);
      fetchData(value, 1);
      setSearched(true);
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const handleLoadmore = () => {
    const page = currentPage + 1;
    const value = searchValue ? searchValue : "";
    setLoader((prev) => ({ ...prev, btn: true }));
    fetchOptions(value, page).then(() => {
      setLoader((prev) => ({ ...prev, btn: false }));
    });
  };

  const handleBlur = () => {
    if (searched) {
      fetchData("", 1);
      setSearched(false);
    }
  };

  useEffect(() => {
    fetchData("", 1);
  }, []);

  return (
    <Select
      showSearch
      filterOption={true}
      onSearch={debounceFetcher}
      onBlur={handleBlur}
      notFoundContent={
        loader.data ? (
          <p className="flex text-xs justify-center w-full">Loading...</p>
        ) : (
          <p className="flex text-xs justify-center w-full">no results found</p>
        )
      }
      {...props}
    >
      {data?.length > 0 && (
        <Select.Option value="">{placeholder}</Select.Option>
      )}
      {data?.length > 0 &&
        data?.map((option, ind) => (
          <Select.Option className="capitalize" key={ind} value={option.value}>
            {option.label}
          </Select.Option>
        ))}

      {currentPage < totalPages && data.length > 0 && (
        <Select.Option disabled className="p-0 m-0 bg-red-500">
          <div
            className={`flex items-center justify-center h-full w-full ${
              !loader.btn
                ? "bg-primary cursor-pointer"
                : "bg-[#1e508d] cursor-default"
            } hover:bg-[#1e508d] text-white text-center font-bold`}
            onClick={!loader.btn ? handleLoadmore : null}
          >
            Load More {loader.btn && <Spin size="small" className="ml-2" />}
          </div>
        </Select.Option>
      )}
    </Select>
  );
}

export default DebounceSelect;
