import React, { useEffect } from "react";
import { SidebarProvider } from "../../context/sidebarContext";
import Sidebar from "../dashboardComponents/layout/Sidebar/Sidebar";
import Footer from "../footer";
import "../../assets/CSS/index.css";
import "../../Css/dashboard.css";
import "./layout.css";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { MobileSideMenuProvider } from "../../context/mobilesidemenuContext";

const LayoutNoFooter = ({ children }) => {
  const { user, auth } = useSelector((state) => state.authenticate);
  console.log("useruser", user, auth);
  const navigate = useNavigate();

  // useEffect(() => {
  if (!user) {
    console.log("===going");
    navigate("/");
  }
  // }, []);

  return (
    <>
      <SidebarProvider>
        <MobileSideMenuProvider>
          <div
            className="app"
            style={{
              backgroundImage: `url(${"/images/interview-banner.png"})`,
              backgroundSize: "cover",
              height: "100vh",
              position: "relative",
            }}
          >
            <Sidebar />
            <div className="main-content position-relative bg-transparent p-0">
              {children}
            </div>
          </div>
        </MobileSideMenuProvider>
      </SidebarProvider>
    </>
  );
};

export default LayoutNoFooter;
