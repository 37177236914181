import React from "react";
import dumyUser from "../../assets/media/images/dumyUser.png";
import dayjs from "dayjs";
import {
  getChatMessages,
  updateActiveConversation,
} from "../../Redux/ChatSlice";
import { useDispatch, useSelector } from "react-redux";
const ConversationItem = ({ conversation, index, setMessagesLoading }) => {
  const dispatch = useDispatch();
  const { activeConversation, userId } = useSelector((state) => state.chat);
  let uid = 38;
  const truncateText = (text, characterLimit) => {
    if (text?.length > characterLimit) {
      return text?.slice(0, characterLimit) + "...";
    }
    return text;
  };
  const handleChatClick = (conversation) => {
    if (conversation?.id == activeConversation?.id) return;
    const updatedUnreadMessages = conversation?.unread_messages?.map(
      (message) =>
        message?.user_id === userId?.user_id
          ? { ...message, unread_messages: 0 }
          : message
    );
    let activeConversations = {
      ...conversation,
      unread_messages: updatedUnreadMessages,
      active: true,
    };
    dispatch(updateActiveConversation(activeConversations));
    dispatch(
      getChatMessages({
        id: conversation?.id,
        onSuccess: () => setMessagesLoading(false),
      })
    );
  };
  const formatMessageDate = (lastMessageAt) => {
    if (lastMessageAt) {
      const isToday = dayjs().isSame(dayjs(lastMessageAt + "Z"), "day");
      return isToday
        ? dayjs(lastMessageAt + "Z").format("h:mm a")
        : dayjs(lastMessageAt + "Z").format("MM/DD/YYYY ");
    }
  };
  return (
    <div
      role="button"
      key={index}
      onClick={() => {
        handleChatClick(conversation);
      }}
      className={`flex p-1 pr-3 items-center mt-2 rounded-lg ${
        conversation?.unread_messages !== 0 ? "bg-[#fffc3b]" : "bg-[#f0f0f0]"
      } bg-opacity-10`}
    >
      {conversation?.members[0]?.image ? (
        <img
          alt="dumy"
          src={conversation?.members[0]?.image}
          className="h-[3rem] w-[3rem] border rounded-full mr-2"
          height={50}
          width={50}
        />
      ) : (
        <img
          alt="dumy"
          src={dumyUser}
          className="h-[3rem] w-[3rem]  rounded-full mr-2"
        />
      )}
      <div className="w-full">
        <div className="flex items-center w-full">
          <h4
            className="font-semibold text-md text-white"
            title={conversation?.members[0]?.name}
          >
            {truncateText(conversation?.members[0]?.name, 15)}
          </h4>
          <small className="inline-block ml-auto text-white">
            {formatMessageDate(conversation.last_message?.created_at)}
          </small>
        </div>
        <div className="flex mt-1 items-center">
          <small
            className="text-white"
            title={
              conversation?.last_message?.content != null
                ? conversation?.last_message?.content
                : "This message is related to media"
            }
          >
            {truncateText(
              conversation?.last_message?.content != null ||
                conversation?.last_message?.media != null
                ? conversation?.is_group
                  ? conversation?.last_message.sender_id === userId?.user_id
                    ? // If group and sender is the current user
                      `You: ${
                        conversation?.last_message?.content ||
                        "This message contains media content"
                      }`
                    : // If group and sender is someone else
                      `${
                        conversation?.last_message?.sender_name?.split(" ")[0]
                      }: ${
                        conversation?.last_message?.content ||
                        "This message contains media content"
                      }`
                  : // If not a group, show the message or media content
                  conversation?.  last_message?.content ||
                    "This message contains media content"
                : "",
              32
            )}
          </small>
          {conversation?.unread_messages?.some(
            (message) =>
              message.user_id === userId?.user_id &&
              message.unread_messages !== 0
          ) ? (
            <span className="w-5 h-5 bg-blue-600 flex items-center ml-auto justify-center text-sm rounded-full text-white">
              {conversation.unread_messages
                .filter(
                  (message) =>
                    message.user_id === userId?.user_id &&
                    message.unread_messages !== 0
                )
                .map((message) => message.unread_messages)}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ConversationItem;
