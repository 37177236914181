import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { selectCategories } from "../../features/Skills/SkillsSlice";
// import { selectPublications } from "../../features/publications/PublicationSlice";
import Loader from "../main-loader";
import moment from "moment";
import {
  checkSkip,
  selectStep,
} from "../../features/companyinformation/StepsSlice";
import CVTemplate from "./CVTemplate";

const AICVTemplate = ({ tempRef, setSaveButton }) => {
  const containerRef = useRef(null);
  // const { profile } = useSelector((state) => state.profileForm);
  const { AiResume, getSingleResumeDoc } = useSelector(
    (state) => state.ResumeBuilder
  );
  const step = useSelector(selectStep);
  const skipped = useSelector(checkSkip);
  // console.log(
  //   workExperience,
  //   professionalSummaries,
  //   "professionalSummariesprofessionalSummaries"
  // );
  const [activePage, setActivePage] = useState(null);
  console.log(activePage, "activePage");
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (getSingleResumeDoc?.font_style) {
      console.log("Applying font:", getSingleResumeDoc.font_style);

      // Generate the Google Fonts link URL
      const fontName = getSingleResumeDoc.font_style.replace(" ", "+");
      const fontLinkHref = `https://fonts.googleapis.com/css2?family=${fontName}&display=swap`;

      // Check if the font link already exists to avoid duplicates
      let fontLink = document.querySelector(`link[href="${fontLinkHref}"]`);
      if (!fontLink) {
        fontLink = document.createElement("link");
        fontLink.href = fontLinkHref;
        fontLink.rel = "stylesheet";
        document.head.appendChild(fontLink);
        console.log("Font link added:", fontLinkHref);
      }

      // Apply the font style as a CSS variable
      document.documentElement.style.setProperty(
        "--dynamic-font-family",
        getSingleResumeDoc.font_style
      );

      // Cleanup: optional if you want to remove the link on unmount
      return () => {
        document.head.removeChild(fontLink);
        console.log("Font link removed:", fontLinkHref);
      };
    }
  }, [getSingleResumeDoc?.font_style]);

  const GetAITemplate = () => {
    return (
      <>
        <div
          className="cv-child-container mb-50 border-none"
          ref={tempRef}
          style={{
            fontFamily: `var(--dynamic-font-family, ${getSingleResumeDoc?.font_style})`,
          }}
        >
          {/* Render profile data */}
          {AiResume?.profile && (
            <header className="cv-header">
              <p
                className="cv-name text-uppercase apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                {AiResume?.profile?.first_name} {AiResume?.profile?.last_name}
              </p>
              <p className="cv-email apply-font text-center">
                {AiResume?.profile?.email && `${AiResume?.profile?.email}`}
                {AiResume?.profile?.email &&
                  (AiResume?.profile?.address ||
                    AiResume?.profile?.city ||
                    AiResume?.profile?.phone ||
                    AiResume?.profile?.linked_in ||
                    AiResume?.profile?.website) &&
                  " | "}

                {(AiResume?.profile?.address || AiResume?.profile?.city) &&
                  `${AiResume?.profile?.address ?? ""}${
                    AiResume?.profile?.address && AiResume?.profile?.city
                      ? ", "
                      : ""
                  }${AiResume?.profile?.city ?? ""}`}
                {(AiResume?.profile?.address || AiResume?.profile?.city) &&
                  (AiResume?.profile?.phone ||
                    AiResume?.profile?.linked_in ||
                    AiResume?.profile?.website) &&
                  " | "}

                {AiResume?.profile?.phone && `${AiResume?.profile?.phone}`}
                {AiResume?.profile?.phone &&
                  (AiResume?.profile?.linked_in ||
                    AiResume?.profile?.website) &&
                  " | "}

                {AiResume?.profile?.linked_in &&
                  `${AiResume?.profile?.linked_in}`}
                {AiResume?.profile?.linked_in &&
                  AiResume?.profile?.website &&
                  " | "}

                {AiResume?.profile?.website && `${AiResume?.profile?.website}`}
              </p>
            </header>
          )}
          {/* <section className="cv-professional_summary"> */}
          {AiResume?.professionalSummaries &&
            AiResume?.professionalSummaries?.length > 0 && (
              <section className="cv-professional_summary">
                <h2
                  className="cv-section-title apply-font"
                  style={{
                    color: `${
                      getSingleResumeDoc?.heading_color
                        ? getSingleResumeDoc?.heading_color
                        : "#2b6be2"
                    }`,
                    fontSize: `${
                      getSingleResumeDoc?.font_size
                        ? getSingleResumeDoc?.font_size
                        : ""
                    }`,
                  }}
                >
                  Professional Summary
                </h2>
                {AiResume?.professionalSummaries?.map((item, index) => (
                  <div
                    key={index}
                    className="cv-professional_summary-item"
                    ref={containerRef}
                  >
                    <p
                      className="text-field editable-field apply-font"
                      dangerouslySetInnerHTML={{ __html: item?.text }}
                    />
                  </div>
                ))}
              </section>
            )}

          {AiResume?.certificates && AiResume?.certificates?.length > 0 && (
            <section className="cv-certifications">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Certifications
              </h2>

              <ul className="cv-skills-list d-flex justify-content-start">
                {AiResume?.certificates?.map((certificate, index) => (
                  <li className="cv-skill_top apply-font" key={index}>
                    <span className="font-bold text-lg">•</span>{" "}
                    {certificate?.name}
                  </li>
                ))}
              </ul>
            </section>
          )}
          {AiResume.skills.length > 0 && (
            <section className="cv-skills">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Top Skills
              </h2>
              <ul className="cv-skills-list d-flex justify-content-start">
                {AiResume?.skills?.map((category, index) => (
                  <React.Fragment key={index}>
                    {category.name == "Top Skills" ? (
                      <>
                        {/* <ul className="cv-skills-list d-flex justify-content-start"> */}
                        {category?.skills &&
                          category?.skills.map((skill, index) => (
                            <li className="cv-skill_top apply-font" key={index}>
                              <span className="font-bold text-lg">•</span>{" "}
                              {skill?.name}
                            </li>
                          ))}
                        {/* </ul> */}
                      </>
                    ) : (
                      <>
                        {/* <ul className="cv-skills-list d-flex justify-content-start"> */}
                        <li className="cv-skill_top apply-font" key={index}>
                          <span className="font-bold text-lg">•</span>{" "}
                          {category?.name}
                        </li>
                        {/* </ul> */}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </section>
          )}

          {/* {AiResume.skills?.[0]?.id == null && (
            <section className="cv-skills">
              <h2 className="cv-section-title">Top Skills</h2>
              <ul className="cv-skills-list d-flex justify-content-start">
              {AiResume?.skills?.map((category, index) => (
                  <li className="cv-skill_top" key={index}>
                    <span className="font-bold text-lg">•</span> {category.name}
                  </li>
              ))}
              </ul>
            </section>
          )} */}

          {AiResume?.workExperience && AiResume?.workExperience?.length > 0 && (
            <section className="cv-professional_summary">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Work Experiences
              </h2>
              {AiResume?.workExperience?.map((experience, index) => (
                <>
                  <div
                    key={index}
                    className="cv-professional_summary-item d-flex justify-content-between align-items-center"
                  >
                    <div className="text-capitalize">
                      <h3
                        className="cv-item-title mb-0 apply-font"
                        style={{
                          color: `${
                            getSingleResumeDoc?.subheading_color
                              ? getSingleResumeDoc?.subheading_color
                              : "#000"
                          }`,
                        }}
                      >
                        {experience?.company?.name}
                      </h3>
                      {experience?.roles?.map((item, index) => (
                        <>
                          <p className="cv-item-date mb-0 apply-font">
                            {item?.name}
                          </p>
                        </>
                      ))}
                    </div>
                    <div className="text-capitalize">
                      {experience?.roles?.map((item, index) => (
                        <>
                          <p className="cv-item-date apply-font">
                            {formatDate(item?.started_at)}
                            {item?.ended_at == null
                              ? " - present"
                              : `${""} - ${formatDate(item?.ended_at)}`}
                          </p>
                        </>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p
                      className="cv-item-date apply-font"
                      dangerouslySetInnerHTML={{
                        __html: experience?.company?.description,
                      }}
                    />
                  </div>
                </>
              ))}
            </section>
          )}

          {AiResume?.educations && AiResume?.educations?.length > 0 && (
            <section className="cv-professional_summary">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Educations
              </h2>
              {AiResume?.educations.map((education, index) => (
                <div
                  key={index}
                  className="cv-professional_summary-item d-flex align-items-center justify-content-between"
                  // ref={containerRef}
                >
                  <div>
                    <h3
                      className="apply-font"
                      style={{
                        color: `${
                          getSingleResumeDoc?.subheading_color
                            ? getSingleResumeDoc?.subheading_color
                            : "#000"
                        }`,
                      }}
                    >
                      {education.school_name}
                    </h3>
                    <p className="cv-item-date apply-font">
                      {education.degree &&
                        education.degree + " Degree " + " | "}
                      {education?.field && education?.field}
                    </p>
                  </div>
                  <div>
                    <p className="cv-item-date text-capitalize apply-font">
                      {education?.ended_at == null
                        ? " present"
                        : `${formatDate(education?.ended_at)}`}
                    </p>
                  </div>
                </div>
              ))}
            </section>
          )}

          {AiResume?.projects && AiResume?.projects?.length > 0 && (
            <section className="cv-projects">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Projects
              </h2>
              {AiResume?.projects?.map((pro, index) => (
                <div className="cv-project-item" key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h3
                        className="cv-item-title mb-0 apply-font"
                        style={{
                          color: `${
                            getSingleResumeDoc?.subheading_color
                              ? getSingleResumeDoc?.subheading_color
                              : "#000"
                          }`,
                        }}
                      >
                        {pro?.project_name}
                      </h3>
                      <p className="cv-item-date apply-font">
                        {pro?.organization}
                      </p>
                    </div>
                    <p className="cv-item-date apply-font">
                      {formatDate(pro?.start_date)} -{" "}
                      {formatDate(pro?.end_date)}
                    </p>
                  </div>
                  <p
                    className="cv-item-date px-2 apply-font"
                    dangerouslySetInnerHTML={{
                      __html: pro?.additional_information,
                    }}
                  />
                </div>
              ))}
            </section>
          )}

          {AiResume?.publications && AiResume?.publications?.length > 0 && (
            <section className="cv-certifications">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Publications
              </h2>

              {AiResume?.publications.map((publication, index) => {
                return (
                  <div
                    className="cv-publication-item d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <div>
                      <h3
                        className="cv-item-title mb-0 apply-font"
                        style={{
                          color: `${
                            getSingleResumeDoc?.subheading_color
                              ? getSingleResumeDoc?.subheading_color
                              : "#000"
                          }`,
                        }}
                      >
                        {publication.title}
                      </h3>
                      <p className="cv-item-date apply-font">
                        {publication.publisher}
                      </p>
                    </div>
                    <p className="cv-item-description apply-font">
                      {formatDate(publication?.date)}
                    </p>
                  </div>
                );
              })}

              {/* Add more certification items as needed */}
            </section>
          )}
          {AiResume?.interests?.interests?.length > 0 && (
            <section className="cv-interests">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Interests
              </h2>
              <ul className="cv-skills-list d-flex justify-content-start">
                {AiResume?.interests?.interests &&
                  AiResume?.interests?.interests.map((interest, index) => (
                    <li className="cv-skill_top apply-font" key={index}>
                      <span className="font-bold text-lg">•</span> {interest}
                    </li>
                  ))}
              </ul>
            </section>
          )}

          {AiResume?.softskills?.skills?.length > 0 && (
            <section className="cv-languages">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Soft Skills
              </h2>
              <ul className="cv-skills-list d-flex justify-content-start">
                {AiResume?.softskills?.skills &&
                  AiResume?.softskills?.skills?.map((skill, index) => (
                    <li className="cv-skill_top apply-font" key={index}>
                      <span className="font-bold text-lg">•</span> {skill}
                    </li>
                  ))}
              </ul>
            </section>
          )}
          {AiResume?.languages?.languages?.length > 0 && (
            <section className="cv-languages">
              <h2
                className="cv-section-title apply-font"
                style={{
                  color: `${
                    getSingleResumeDoc?.heading_color
                      ? getSingleResumeDoc?.heading_color
                      : "#2b6be2"
                  }`,
                  fontSize: `${
                    getSingleResumeDoc?.font_size
                      ? getSingleResumeDoc?.font_size
                      : ""
                  }`,
                }}
              >
                Languages
              </h2>
              <ul className="cv-skills-list d-flex justify-content-start">
                {AiResume?.languages?.languages &&
                  AiResume?.languages?.languages.map((language, index) => (
                    <li className="cv-skill_top apply-font" key={index}>
                      <span className="font-bold text-lg">•</span> {language}
                    </li>
                  ))}
              </ul>
            </section>
          )}
          {/* Add more sections as needed */}
        </div>
      </>
    );
  };
  useEffect(() => {
    if (skipped == true) {
      setActivePage(1);
      setSaveButton({ val: true, page: 1 });
    } else {
      setSaveButton(false);
      setActivePage(null);
    }
  }, [skipped]);
  return (
    <>
      {step == 3 && (
        <div
          className={` grid ${
            skipped == true ? "xl:grid-cols-1" : "xl:grid-cols-2"
          }  grid-cols gap-8`}
        >
          <div
            className={`h-auto ${
              skipped == true ? "" : "w-full xl:w-full 2xl:w-[760px]"
            }  min-[760px]:w-[760px] `}
          >
            {skipped == false && <h2 className="cv-template_title">Before</h2>}
            <div
              className={`cv-main-container cv-main-container_select-cover ${
                activePage == 1 ? "active_page" : ""
              }`}
              onClick={() => {
                setActivePage(1);
                setSaveButton({ val: true, page: 1 });
              }}
            >
              <CVTemplate />
            </div>
          </div>
          {skipped == false && (
            <div className="h-auto w-full min-[760px]:w-[760px] xl:w-full  2xl:w-[760px] ">
              <h2 className="cv-template_title">After</h2>
              <div
                className={`cv-main-container cv-main-container_select-cover ${
                  activePage == 2 ? "active_page" : ""
                }`}
                onClick={() => {
                  setActivePage(2);
                  setSaveButton({ val: true, page: 2 });
                }}
              >
                {GetAITemplate()}
              </div>
            </div>
          )}
        </div>
      )}

      {step == 4 &&
        (activePage == 2 ? (
          <div className="cv-main-container w-full min-[760px]:w-[760px]">
            {GetAITemplate()}
          </div>
        ) : (
          <div className="cv-main-container w-full min-[760px]:w-[760px] ">
            <CVTemplate tempRef={tempRef} />
          </div>
        ))}
    </>
  );
};

export default AICVTemplate;
