import React from "react";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import { StepStateAtom } from "../../../../recoil/ResumeAdditionalSection/StepsState";

const DeleteConfirmationModal = () => {
  const [stepState, setStepState] = useRecoilState(StepStateAtom);
  const selectedTempPerArr = stepState?.selectedTheme?.permissions;
  const selectedSectionArr = stepState?.selectedTheme?.sectionDetail;
  const selectedSectionErrorObj = stepState?.formErrors;
  return (
    <div
      className={`ic-modal ${stepState?.isDeleteSection?.value ? "show" : ""}`}
    >
      <div className="ic-modal-dialog">
        <div className="modal-main-content w-100">
          <div className="ic-modal-body">
            <div className="ic-modal-content">
              <p className="text-xl">
                Are you sure you want to Delete this Section.
              </p>
            </div>
            <div className="ic-footer-modal d-flex justify-content-end">
              <button
                onClick={() => {
                  setStepState(
                    produce((draft) => {
                      draft.isDeleteSection.value = false;
                    })
                  );
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  const perPayload = [...selectedTempPerArr];
                  const secPayload = [...selectedSectionArr];
                  const errorPayload = { ...selectedSectionErrorObj };
                  perPayload.splice(stepState?.isDeleteSection?.index, 1);
                  secPayload.splice(stepState?.isDeleteSection?.index, 1);
                  // Remove the key from the object
                  const deleteIndex = stepState?.isDeleteSection?.index;
                  delete errorPayload[deleteIndex];
                  setStepState(
                    produce((draft) => {
                      draft.selectedTheme.permissions = perPayload;
                      draft.selectedTheme.sectionDetail = secPayload;
                      draft.formErrors = errorPayload;
                      draft.isDeleteSection.value = false;
                    })
                  );
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
