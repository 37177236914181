import React from "react";
import { Link, useNavigate } from "react-router-dom";
import l1 from "../../assets/Images/l1.png";
import l2 from "../../assets/Images/l2.png";
import l3 from "../../assets/Images/l3.png";
import l4 from "../../assets/Images/l4.png";
import l5 from "../../assets/Images/l5.png";
import logo from "../../assets/Images/logo1.png";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-flex gap-y-10">
        <div>
          <img className="object-contain" src={logo} alt="logo" />
          <p className="text-white text-2xl mt-4">
            Career Services That Impact the World
          </p>
        </div>

        <div className="space-y-4">
          <h1 className="Poppins text-white font-normal text-lg">Resources</h1>
          <ul className="space-y-2">
            <li>
              <a
                href="https://tally.so/r/3yDKdg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 font-light"
              >
                Career Center Efficiency Assessment
              </a>
            </li>

            <li>
              <Link
                to="/"
                className="text-white hover:text-gray-300 font-light"
              >
                Case Studies
              </Link>
            </li>
            <li
              className="text-white hover:text-gray-300 font-light"
              onClick={() => navigate("/start-interview")}
            >
              Contact us
            </li>
            <li>
              <Link
                to="/faq"
                className="text-white hover:text-gray-300 font-light"
              >
                FAQ
              </Link>
            </li>
            <li>
              <a
                href="https://share.hsforms.com/1OqLkWvR5SHCqlZOUvRD9-Arfy9w"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 font-light cursor-pointer "
              >
                Help center
              </a>
            </li>
            <li>
              <Link
                to="/"
                className="text-white hover:text-gray-300 font-light"
              >
                Job Board
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className="text-white hover:text-gray-300 font-light"
              >
                Post a Job for FREE
              </Link>
            </li>

            <li>
              <Link
                to="/"
                className="text-white hover:text-gray-300 font-light "
              >
                ROI Calculator
              </Link>
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <h1 className="Poppins text-white font-normal text-lg">Products</h1>
          <ul className="space-y-2">
            <li>
              <Link
                to="/landing"
                className="text-white hover:text-gray-300 font-light "
              >
                Elevate
              </Link>
            </li>
            <li>
              <Link
                to="/rucoming"
                className="text-white hover:text-gray-300 font-light "
              >
                Nexa RU
              </Link>
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <h1 className="Poppins text-white font-normal text-lg">Company</h1>
          <ul className="space-y-2">
            <li>
              <Link
                to="/about-us"
                className="text-white hover:text-gray-300 font-light"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                className="text-white hover:text-gray-300 font-light"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/terms-and-condition"
                className="text-white hover:text-gray-300 font-light"
              >
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <h1 className="Poppins text-white font-normal text-lg">Social</h1>
          <div className="flex space-x-8">
            <a
              href="https://www.tiktok.com/@learnnexa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={l1}
                className="object-contain cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="TikTok"
              />
            </a>
            <a
              href="https://twitter.com/divyn7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={l2}
                className="object-contain cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="Twitter"
              />
            </a>
            <a
              href="https://www.instagram.com/learnnexa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={l3}
                className="object-contain cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/learnnexa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={l4}
                className="object-contain cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="Instagram"
              />
            </a>
            <a
              href="https://discord.gg/6t7Mq2EJ9p"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={l5}
                className="object-contain cursor-pointer filter brightness-0 invert transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
                alt="Discord"
              />
            </a>
          </div>
        </div>
      </div>

      <hr style={{ border: "1px solid #FFFFFF" }} className="m-10" />
      <p className="mx-auto text-white text-center">
        © 2024 Nexa Inc. Copyright and rights reserved
      </p>
    </div>
  );
}
