import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import chevronDoubleRightIcon from "../../assets/icons/chevronDoubleRight.svg";
import pricingLeftShadow from "../../assets/icons/pricing-left-shadow.svg";
import pricingRightShadow from "../../assets/icons/pricing-right-shadow.svg";
import Footer from "../../Components/footer_new/footer";
import Header from "../../Components/header_new/navbar";
import Loader from "../../Components/main-loader";
import Plans from "../../Components/pricing/Plans";
import {
  cancelUserSubscription,
  getUserSubscription,
} from "../../Redux/authenticate/authenticate.action";
import { getAllPaymentCards } from "../../Redux/PaymentsSlice";
import CancelModal from "./CancelModal";

const Pricing = () => {
  // const ENVOIRMENT = process.env.REACT_APP_ENVIRONMENT;
  const [isOrganization, setisOrganization] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.authenticate);
  const [switchIndividual, setSwitchIndividual] = useState(false);
  const [cancelModal, SetCancelModal] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(getUserSubscription());
    }
  }, []);
  const toggleModal = () => {
    SetCancelModal(!cancelModal);
  };

  const cancelSubscription = () => {
    dispatch(cancelUserSubscription())
      .unwrap()
      .then((res) => {
        console.log({ res });
        dispatch(getUserSubscription());
        SetCancelModal(!cancelModal);
      });
  };
  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }
  const handleGoBack = () => {
    navigate(-1); // Goes back to the previous route
  };
  useEffect(() => {
    if (user) {
      dispatch(getAllPaymentCards());
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header />
          <button
            onClick={handleGoBack}
            className={`absolute top-[110px] cursor-pointer	z-30 left-10 bg-white rounded-full size-10 p-1 rotate-180`}
          >
            <img src={chevronDoubleRightIcon} alt="" />
          </button>
          <div className="pricing-part pricing-main-part">
            <div className="pricing-left-shadow">
              <img src={pricingLeftShadow} alt="shadow" />
            </div>
            <div className="pricing-right-shadow">
              <img src={pricingRightShadow} alt="shadow" />
            </div>
            <div className="container">
              <div className="pricing-heading-container">
                <h2>
                  Supercharge Your Career Center And <br />
                  <br /> Boost Job Placement Permanently
                </h2>
                <br />
                <p>
                  We guarantee a <b>25% increase</b> in job placements within 3
                  months, or we’ll let you use the platform for free until you
                  do
                </p>
              </div>
              {switchIndividual && user && user?.organizationId !== null && (
                <>
                  <Plans isOrganization={isOrganization} orgUser={true} />
                </>
              )}
              {user == null ? (
                <>
                  <div className="switch-plan">
                    <div className="form-check form-switch">
                      <span
                        className={`plan-text ${
                          isOrganization ? "" : "text-dark"
                        }`}
                      >
                        Individual
                      </span>
                      <div className="switch-pricing-plan">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isOrganization}
                            role="switch"
                            id="checkbox"
                            onChange={(e) =>
                              setisOrganization(e.target.checked)
                            }
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                      <span
                        className={`plan-text ${
                          isOrganization ? "text-dark" : ""
                        }`}
                      >
                        Organization
                      </span>
                    </div>
                  </div>
                  <Plans isOrganization={isOrganization} />
                </>
              ) : (
                <>
                  {user?.organizationId == null ? (
                    <>
                      <Plans isOrganization={isOrganization} />
                    </>
                  ) : (
                    <div className="pricing-heading-container py-5">
                      <p className="text-capitalize">
                        You have been subscribed to organisation plan under
                        organisation <u>{user?.organizationName}</u>
                      </p>
                    </div>
                  )}

                  {/* {ENVOIRMENT == "test" && ( */}
                  <div className="flex justify-center">
                    {user?.organizationId !== null ? (
                      <>
                        <button
                          type="button"
                          onClick={() => setSwitchIndividual(!switchIndividual)}
                          className="ic-btn"
                          style={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          {!switchIndividual
                            ? "Switch To Individual Plan"
                            : "Not Now"}
                        </button>
                      </>
                    ) : (
                      <>
                        {" "}
                        {user?.subscriptionCancelled ? (
                          <h4 className="text-center mt-4">
                            Your Subscription will end at{" "}
                            <span className="text-danger">
                              {convertTimestampToDate(user?.cancelTime)}
                            </span>
                          </h4>
                        ) : user?.activeBundle !== "2lNbMnPO8W" ? (
                          <button
                            type="button"
                            onClick={() => toggleModal()}
                            disabled={user?.subscriptionCancelled}
                            className="ic-btn mt-5"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              background: "red",
                            }}
                          >
                            Cancel Subscription
                          </button>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {/* )} */}
                </>
              )}
            </div>
            <CancelModal
              cancelModal={cancelModal}
              toggleModal={toggleModal}
              cancelSubscription={cancelSubscription}
            />
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default Pricing;
