import React from "react";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import { StepStateAtom } from "../../../../recoil/ResumeAdditionalSection/StepsState";

const AdditionalModalFooter = ({ steps, handleSubmit }) => {
  const [stepState, setStepState] = useRecoilState(StepStateAtom);
  const setActiveStepFun = (step) => {
    setStepState(
      produce((draft) => {
        draft.activeStep = step;
      })
    );
  };

  const handleNext = () => {
    if (stepState?.activeStep < steps.length - 1) {
      setActiveStepFun(stepState?.activeStep + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (stepState?.activeStep > 0) {
      setActiveStepFun(stepState?.activeStep - 1);
    }
  };
  return (
    <div
      className={`flex items-center fixed bottom-0 left-0 right-0 h-16 w-full z-50 px-6 bg-white pt-1 ${
        stepState?.activeStep === 0 ? "justify-end" : "justify-between"
      }`}
    >
      {stepState?.activeStep !== 0 && (
        <button
          type="button"
          onClick={handlePrevious}
          className={`text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-3 py-2.5 flex gap-2 justify-center items-center ${
            stepState?.activeStep === 0 && "opacity-50 cursor-not-allowed"
          }`}
        >
          <FaLongArrowAltLeft size={22} />
          Previous
        </button>
      )}
      <button
        type="button"
        onClick={handleNext}
        disabled={!stepState?.selectedTheme?.id}
        className={`font-medium rounded-lg text-sm px-3 py-2.5 text-white flex gap-2 justify-center items-center 
   ${
     !stepState?.selectedTheme?.id
       ? "bg-blue-400 cursor-not-allowed"
       : "bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
   }`}
      >
        {stepState?.activeStep === steps.length - 1 ? "Submit" : "Next"}
        {stepState?.activeStep !== steps.length - 1 && (
          <FaLongArrowAltRight size={22} />
        )}
      </button>
    </div>
  );
};

export default AdditionalModalFooter;
