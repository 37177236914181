import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { success, error } from "../../../Utilities/toast";
import { useDispatch } from "react-redux";
import {
  addJobPreference,
  deleteJobPreference,
} from "../../../Redux/UserProfileSlice";
import { getCurrentUser } from "../../../Redux/authenticate/authenticate.action";
import { useNavigate } from "react-router-dom";

const JobPreference = ({ activeStep, setActiveStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const [initialUserJob, setInitialUserJob] = useState([]);
  const [preferences, setPreferences] = useState([
    {
      job_role: "",
      skill_level: "",
      specialization_tags: [],
      job_type: "",
      id: null,
    },
  ]);

  const jobRoles = ["Software Engineer", "Data Scientist", "UX Designer"];
  const skillLevels = ["Entry", "Mid", "Senior"];
  const jobType = ["Full Time", "Part Time", "Internship"];

  const handleChange = (index, event) => {
    const values = [...preferences];
    values[index][event.target.name] = event.target.value;
    setPreferences(values);
  };

  const handleAddPreference = () => {
    setPreferences([
      ...preferences,
      {
        job_role: "",
        skill_level: "",
        specialization_tags: [],
        job_type: "",
        id: null,
      },
    ]);
  };

  const handleRemovePreference = (index, obj) => {
    const selectedData = preferences.find((item) => item == obj);
    if (selectedData?.id) {
      dispatch(
        deleteJobPreference({
          id: selectedData?.id,
          onSuccess: () => {
            success("Job Preferences deleted successfully");
            dispatch(getCurrentUser());
          },
        })
      );
    } else {
      const newpreferences = [...preferences];
      newpreferences.splice(index, 1);
      setPreferences(newpreferences);
    }
  };

  const handleAddSpecialization = (index, newSpecialization) => {
    if (newSpecialization.trim() === "") return;

    const values = [...preferences];
    values[index].specialization_tags = [
      ...(values[index].specialization_tags || []),
    ];

    if (!values[index].specialization_tags.includes(newSpecialization)) {
      values[index].specialization_tags.push(newSpecialization.trim());
      setPreferences(values);
    }
  };

  const handleRemoveSpecialization = (index, specializationToRemove) => {
    const values = [...preferences];
    values[index].specialization_tags = values[
      index
    ].specialization_tags.filter((spec) => spec !== specializationToRemove);
    setPreferences(values);
  };

  const handleSave = () => {
    const hasChanged =
      JSON.stringify(preferences) !== JSON.stringify(initialUserJob);

    if (hasChanged) {
      if (
        preferences.some(
          (preference) =>
            !preference.job_role ||
            !preference.skill_level ||
            !preference.specialization_tags.length > 0 ||
            !preference.job_type
        )
      ) {
        return error("All fields are required");
      }

      const formattedData = {
        job_preferences: preferences,
      };
      dispatch(
        addJobPreference({
          payload: formattedData,
          onSuccess: () => {
            success("Job Preferences added successfully");
            dispatch(getCurrentUser());
            navigate(-1);
          },
        })
      );
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (user && user.job_preferences && user?.job_preferences?.length > 0) {
      setPreferences(user.job_preferences?.map((cert) => ({ ...cert })));
      setInitialUserJob(user.job_preferences?.map((cert) => ({ ...cert })));
    }
  }, [user]);

  return (
    <div className="p-4">
      {preferences?.map((pref, index) => (
        <div key={index} className="mb-4 p-4 border rounded-lg shadow">
          <div className="mb-2">
            <label className="block mb-1">Job Role:</label>
            <select
              name="job_role"
              value={pref.job_role}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              required
            >
              <option value="">Select Job Role</option>
              {jobRoles?.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-2">
            <label className="block mb-1">Skill Level:</label>
            <select
              name="skill_level"
              value={pref.skill_level}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              required
            >
              <option value="">Select Skill Level</option>
              {skillLevels?.map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-2">
            <label className="block mb-1">Job Type:</label>
            <select
              name="job_type"
              value={pref.job_type}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              required
            >
              <option value="">Select Job Type</option>
              {jobType?.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-2">
            <label className="block mb-1">Specialization:</label>
            <div className="flex gap-2">
              <input
                type="text"
                placeholder="Enter specialization"
                className="border rounded p-2 w-full"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAddSpecialization(index, e.target.value);
                    e.target.value = "";
                  }
                }}
              />
              <button
                type="button"
                onClick={() => {
                  const input = document.querySelector(`input[type="text"]`);
                  handleAddSpecialization(index, input.value);
                  input.value = "";
                }}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Add
              </button>
            </div>
            <div className="mt-2 flex gap-2 flex-wrap">
              {pref?.specialization_tags?.map((spec) => (
                <span
                  key={spec}
                  className="bg-gray-200 text-gray-700 rounded-full px-3 py-1 flex items-center"
                >
                  {spec}
                  <button
                    type="button"
                    onClick={() => handleRemoveSpecialization(index, spec)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
          </div>
          {preferences?.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemovePreference(index, pref)}
              className="text-white bg-red-600 mt-3 px-4 py-2 rounded-lg"
            >
              Remove Preference
            </button>
          )}
        </div>
      ))}
      <div className="w-full gap-2 flex justify-end">
        <button
          type="button"
          onClick={handleAddPreference}
          className="mt-2 bg-blue-500 text-white p-2 rounded"
        >
          Add Another Preference
        </button>
      </div>
      <div className={`w-full mt-4 flex justify-between`}>
        <button
          className="ic-btn"
          onClick={() => {
            setActiveStep((prev) => prev - 1);
          }}
        >
          Previous
        </button>
        <button className="ic-btn" onClick={handleSave}>
          Finish
        </button>
      </div>
    </div>
  );
};

export default JobPreference;
