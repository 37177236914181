import React from "react";
import CVTemplate from "../../Components/Resume/CVTemplate";
// import "./cv.css";

const CV = () => {
  return (
    <div className="cv-main-container w-full min-[760px]:w-[760px]">
      <CVTemplate />;
    </div>
  );
};

export default CV;
