import React, { useEffect, useState } from "react";
import JobListing from "../../Components/Autoapply/JobListing";
import "./style.css";
import FilterComponent from "../../Components/Autoapply/FilterComponent";
import Header from "../../Components/header";
import { useDispatch, useSelector } from "react-redux";
import { getJobListings, getStates, scrapJobs } from "../../Redux/NexaIqSlice";
import Loader from "../../Components/main-loader";
import Pagination from "../../Utilities/Pagination";
import { Button } from "antd";
import { error, success } from "../../Utilities/toast";
import { IoFilter } from "react-icons/io5";
import PreferenceModal from "../../Components/Autoapply/PreferenceModal";
import {
  getPreferenceforJobs,
  setPreference,
  updatePreferenceforJobs,
} from "../../Redux/UserProfileSlice";
import PreferenceBox from "../../Components/Autoapply/PreferenceBox";
import { useLocation } from "react-router-dom";

const AutoApply = () => {
  const dispatch = useDispatch();
  const { jobs, reason, jobs_found, totalPages, currentPage } = useSelector(
    (state) => state.nexaIq
  );

  const { jobsPreference } = useSelector((state) => state.UserProfile);

  const location = useLocation();
  useEffect(() => {
    if (location.state?.tab) {
      setActiveTab(location.state.tab);
    }
  }, [location]);

  const [activeTab, setActiveTab] = useState("myJobs");
  const [storeActivePagination, setStoreActivePagination] = useState(1);
  const [loading, setLoading] = useState(false);
  const [preferenceModal, setPreferenceModal] = useState(false);
  const [preferenceLoader, setPreferenceLoader] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [postedDate, setPostedDate] = useState(null);
  // const [workplaceType, setWorkplaceType] = useState(null);
  const [experienceLevel, setExperienceLevel] = useState(null);
  const [jobSearch, setJobSearch] = useState("");
  const [filterNexaJobs, setFilterNexaJobs] = useState(null);
  const [preferenceStep, setPreferenceStep] = useState(1);
  const [scrapText, setScrapText] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    industry_preference: "",
    location_type: "",
    location: "",
    workplace_type: "",
    work_schedule: "full-time",
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handlePaginationChange = (value) => {
    setLoading(true);
    setStoreActivePagination(value);
    let payload = {};
    if (jobSearch) {
      payload.title = jobSearch.toLowerCase();
    } else if (formData?.title) {
      payload.title = formData?.title;
    }
    if (selectedCity) {
      payload.location = selectedCity;
    } else if (formData?.location) {
      payload.location = formData?.location;
    }
    if (postedDate) payload.from_date = postedDate;
    if (experienceLevel) payload.experience_level = experienceLevel;
    if (formData.workplace_type)
      payload.workplace_type = formData.workplace_type;
    dispatch(
      getJobListings({
        payload: payload,
        page: value,
        jobApplied: activeTab === "Applied Jobs" ? true : false,
        smartScreen: activeTab === "Smart Screen Jobs" ? true : false,
        nexa_jobs: filterNexaJobs ? true : false,
        onSuccess: () => {
          setLoading(false);
        },
      })
    );
  };
  const handleScrapJobs = () => {
    if (
      !postedDate ||
      !jobSearch ||
      !selectedCity ||
      !formData.workplace_type ||
      !experienceLevel
    ) {
      error("To start your Personal Career Search please select all filters");
    } else {
      setLoading(true);
      let payload = {
        from_date: postedDate,
        title: jobSearch.toLowerCase(),
        location: selectedCity,
        workplace_type: formData?.workplace_type,
        experience_level: experienceLevel,
      };
      dispatch(
        scrapJobs({
          payload: payload,
          onSuccess: () => {
            success(
              "Thank You For Creating A Personal Career Search Alert, We Will Now Be Your Personal Recruiter And Search Everywhere To Find The Perfect Jobs For You. We Will Notify You When Your Results Are Ready In 15 Minutes ."
            );
            // setSelectedState(null);
            setSelectedCity("");
            setPostedDate(null);
            setJobSearch("");
            setExperienceLevel(null);
            dispatch(
              getJobListings({
                payload: null,
                page: 1,
                jobApplied: false,
                smartScreen: activeTab === "Smart Screen Jobs" ? true : false,
                nexa_jobs: filterNexaJobs ? true : false,
                onSuccess: () => {
                  setLoading(false);
                },
              })
            );
          },
          onError: () => {
            setLoading(false);
          },
        })
      );
    }
  };
  const handleNexaIqClick = () => {
    setFilterNexaJobs(true);
    setLoading(true);
    dispatch(
      getJobListings({
        payload: null,
        page: 1,
        jobApplied: activeTab === "Applied Jobs" ? true : false,
        smartScreen:
          activeTab === "Applied Jobs"
            ? true
            : activeTab === "Smart Screen Jobs"
            ? true
            : false,
        nexa_jobs: true,
        onSuccess: () => {
          setLoading(false);
        },
      })
    );
  };

  const handleChangePreference = (name) => {
    let payload = { ...jobsPreference };
    delete payload.id;
    delete payload.customer_id;
    payload[name] = "";
    if (name === "location") {
      payload.location_type = "";
    }
    dispatch(
      updatePreferenceforJobs({
        id: jobsPreference?.id,
        payload,
        onSuccess: () => {
          dispatch(
            setPreference({
              ...payload,
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    if (activeTab) {
      if (jobsPreference?.title && Object.keys(jobsPreference).length !== 0) {
        let payload = {};
        if (jobSearch) {
          payload.title = jobSearch;
        } else if (jobsPreference?.title) {
          payload.title = jobsPreference?.title;
        }
        if (selectedCity) {
          payload.location = selectedCity;
        } else if (jobsPreference?.location) {
          payload.location = jobsPreference?.location;
        }
        if (jobsPreference?.workplace_type)
          payload.workplace_type = jobsPreference?.workplace_type;
        if (jobsPreference?.work_schedule)
          payload.work_schedule = jobsPreference?.work_schedule;
        if (postedDate) payload.from_date = postedDate;
        if (experienceLevel) payload.experience_level = experienceLevel;
        setLoading(true);
        dispatch(
          getJobListings({
            payload: activeTab === "Applied Jobs" ? null : payload,
            page: 1,
            jobApplied: activeTab === "Applied Jobs" ? true : false,
            smartScreen:
              activeTab === "Applied Jobs"
                ? true
                : activeTab === "Smart Screen Jobs"
                ? true
                : false,
            nexa_jobs: filterNexaJobs ? true : false,
            onSuccess: () => {
              setLoading(false);
              setScrapText(false);
            },
          })
        );
      }
    }
  }, [activeTab, jobsPreference]);

  // useEffect(() => {
  //   if (activeTab) {
  //     setSelectedCity(null);
  //     setPostedDate(null);
  //     setJobSearch("");
  //     setExperienceLevel(null);
  //     setWorkplaceType(null);
  //     setLoading(true);
  //     dispatch(getStates({ onSuccess: () => {} }));
  //     dispatch(
  //       getJobListings({
  //         payload: null,
  //         page: 1,
  //         jobApplied: activeTab === "Applied Jobs" ? true : false,
  //         smartScreen:
  //           activeTab === "Applied Jobs"
  //             ? true
  //             : activeTab === "Smart Screen Jobs"
  //             ? true
  //             : false,
  //         nexa_jobs: filterNexaJobs ? true : false,
  //         onSuccess: () => {
  //           setLoading(false);
  //         },
  //       })
  //     );
  //   }
  // }, [activeTab]);

  useEffect(() => {
    setPreferenceLoader(true);
    dispatch(
      getPreferenceforJobs({
        onSuccess: () => {
          setPreferenceLoader(false);
        },
      })
    );
  }, []);

  useEffect(() => {
    if (jobsPreference?.title && Object.keys(jobsPreference).length !== 0) {
      setFormData(jobsPreference);
      setPreferenceModal(false);
    } else {
      if (jobsPreference !== null) {
        setPreferenceModal(true);
      }
    }
    setPreferenceStep(1);
  }, [jobsPreference]);

  return (
    <div className="">
      {(loading || preferenceLoader) && <Loader />}

      <Header />
      <div className="h-[120px]" />
      {/* {activeTab === "Jobs" && ( */}
      <FilterComponent
        storeActivePagination={storeActivePagination}
        activeTab={activeTab}
        setSelectedState={setSelectedState}
        setSelectedCity={setSelectedCity}
        setPostedDate={setPostedDate}
        setJobSearch={setJobSearch}
        selectedCity={selectedCity}
        selectedState={selectedState}
        postedDate={postedDate}
        experienceLevel={experienceLevel}
        setExperienceLevel={setExperienceLevel}
        jobSearch={jobSearch}
        setLoading={setLoading}
        filterNexaJobs={filterNexaJobs}
        setFilterNexaJobs={setFilterNexaJobs}
        formData={formData}
        setFormData={setFormData}
      />
      {/* )} */}

      <PreferenceBox
        jobsPreference={jobsPreference}
        setPreferenceModal={setPreferenceModal}
        handleChangePreference={handleChangePreference}
      />

      <div className="flex justify-end items-center  bg-transparent nexaIqTab-container border-b">
        {/* <h3 className="text-bold text-capitalize text-xl">
          {activeTab === "Applied Jobs"
            ? "Nexa Applied Jobs"
            : "Current Opportunities"}
        </h3> */}
        <ul className="list-reset flex border-b">
          {/* <li className="-mb-px mr-1">
            <a
              className={`border-b inline-block py-2 px-4 font-semibold ${
                activeTab === "Smart Screen Jobs"
                  ? " border-l border-t border-r rounded-t text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => handleTabClick("Smart Screen Jobs")}
            >
              Smart Screen Jobs
            </a>
          </li> */}
          <li className="-mb-px mr-1">
            <a
              className={`border-b inline-block py-2 px-4 font-semibold ${
                activeTab === "myJobs"
                  ? " border-l border-t border-r rounded-t text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => handleTabClick("myJobs")}
            >
              Current Open Opportunities
            </a>
          </li>

          <li className="mr-1">
            <a
              className={`inline-block py-2 px-4 font-semibold ${
                activeTab === "Applied Jobs"
                  ? " border-l border-t border-r rounded-t text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => handleTabClick("Applied Jobs")}
            >
              Applied Jobs
            </a>
          </li>
        </ul>
      </div>
      <div className="parent-container-wrapper bg-transparent">
        {activeTab === "myJobs" && (
          <div className="w-full flex justify-center">
            <div className="flex justify-end max-w-[800px] w-full">
              <button
                onClick={() => handleNexaIqClick()}
                disabled={filterNexaJobs} // Button is disabled when filterNexaJobs is false
                className={`${
                  !filterNexaJobs
                    ? "bg-transparent text-black border cursor-pointer"
                    : "bg-[#2B6BE2] border-none text-white cursor-not-allowed"
                }  hover:text-[#2B6BE2] hover:border-[#2B6BE2] shadow rounded-lg py-2 px-6  text-sm flex items-center gap-2 mt-1`}
              >
                <IoFilter />
                Nexa IQ Quick Apply
              </button>
            </div>
          </div>
        )}
        <div
          className="parent-container bg-transparent"
          style={{ position: "relative" }}
        >
          {jobs && jobs.length > 0 ? (
            (() => {
              // Filter the jobs based on the activeTab
              // const filteredJobs = jobs.filter((job) =>
              //   activeTab === "Jobs"
              //     ? job.applied_status === null
              //     : activeTab === "Applied Jobs"
              //     ? job.applied_status !== null
              //     : true
              // );
              // if (jobs.length === 0) {
              //   return (
              //     <h2 className="text-center text-red-600 bg-[#f5f5f5] pt-6 text-2xl px-12 leading-10">
              //       {activeTab === "Jobs"
              //         ? "No Jobs Found"
              //         : activeTab === "Applied Jobs"
              //         ? "No Applied Jobs Yet"
              //         : "No Jobs Found"}
              //     </h2>
              //   );
              // }

              // Map through the filtered jobs and render JobListing components
              return jobs.map((job) => {
                return (
                  <JobListing
                    key={job?.id}
                    id={job?.id}
                    jobTitle={job.title}
                    compensation={job.company || job?.organization?.name}
                    location={job.location}
                    descriptionPoints={job.description || job.job_description}
                    companyName={job?.organization?.name}
                    companyTagline={job?.organization?.address}
                    CompanyDesc={job.CompanyDesc}
                    experienceLevel={job.experience_level}
                    logoUrl={job?.organization?.logo}
                    postedDate={job.employment_type}
                    createdOn={job?.created_at}
                    badge={job.applied_status}
                    jobProvider={job.source}
                    activeTab={activeTab}
                    organizationNumber={job?.organization?.phone}
                    how_to_conduct={job.how_to_conduct}
                    jobStatus={job.status}
                    jobTonality={job.tonality}
                    interview_deadline={job.interview_deadline}
                    prescreening_deadline={job.prescreening_deadline}
                    setLoading={setLoading}
                    filterNexaJobs={filterNexaJobs}
                    rejectionText={
                      job?.applicants?.length > 0
                        ? job?.applicants[0]?.reason
                        : ""
                    }
                  />
                );
              });
            })()
          ) : (
            <>
              <h2 className="text-center text-red-600  pt-6 text-2xl px-12 leading-10">
                {activeTab === "myJobs"
                  ? !scrapText
                    ? "You Are Unique, Let Us Personally Find The Perfect Careers That Match What YOU Are Looking For"
                    : "Thank you for updating your target career preference, we will now populate your feed with jobs related to your preference. If no results are found, please check your email in a few minutes."
                  : activeTab === "Applied Jobs"
                  ? "No Applied Jobs Yet"
                  : ""}
              </h2>
              {activeTab != "Applied Jobs" && !scrapText && (
                <div className="w-full h-20  flex justify-center items-center ">
                  <Button
                    className="bg-primary text-white"
                    onClick={() => {
                      handleScrapJobs();
                    }}
                  >
                    Start My Personal Career Search
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        {jobs && jobs.length > 0 && (
          <div className="d-flex justify-center items-center w-100">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePaginationChange}
            />
          </div>
        )}
      </div>
      {preferenceModal && (
        <PreferenceModal
          isOpen={preferenceModal}
          onClose={() => {
            setPreferenceModal(false);
            setPreferenceStep(1);
            setScrapText("changed");
          }}
          formData={formData}
          setFormData={setFormData}
          step={preferenceStep}
          setStep={setPreferenceStep}
          setScrapText={setScrapText}
        />
      )}
    </div>
  );
};

export default AutoApply;
