import React, { useEffect, useRef, useState } from "react";
import TemplateSelctedFields from "../../Components/ResumeAdditionalSection/components/ModifyTemplate/TemplateSelctedFields";
import { useRecoilState } from "recoil";
import { StepStateAtom } from "../../recoil/ResumeAdditionalSection/StepsState";
import { produce } from "immer";

const AdditionalSections = ({
  section,
  setRemoveInnerSection,
  setSelectedSection,
}) => {
  const formRef = useRef(null);
  const [stepState, setStepState] = useRecoilState(StepStateAtom);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [activeSessionId, setActiveSessionId] = useState(null);
  const [activeSessionDataId, setActiveSessionDataId] = useState(null);

  const AddNewSection = (section) => {
    setActiveSessionId(section?.id);
    if (stepState?.selectedTheme?.id !== section?.template?.id) {
      setStepState(
        produce((draft) => {
          draft.selectedTheme = {
            ...section?.template,
            permissions: [section?.template?.fields],
            sectionDetail: [
              Object.keys(section?.template?.fields || {}).reduce(
                (acc, key) => ({ ...acc, [key]: "" }),
                {}
              ),
            ],
            isNewSection: true,
          };
        })
      );
    }

    setIsFormVisible(true);
    setTimeout(() => {
      formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 0);
  };

  const handleEditSection = (index, item) => {
    setActiveSessionDataId(item?.id);
    const allowFieldsData = {};

    Object.keys(section?.template?.fields || {}).forEach((key) => {
      allowFieldsData[key] = item[key] !== undefined ? item[key] : null;
    });

    const additionalPermissions = Object.keys(allowFieldsData)
      .filter((key) => key !== "id")
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: allowFieldsData[key] !== null,
        }),
        {}
      );
    const sectionDetailObject = Object.keys(additionalPermissions)
      .filter((key) => additionalPermissions[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: item[key],
        }),
        {}
      );

    setStepState(
      produce((draft) => {
        draft.selectedTheme = {
          ...section?.template,
          permissions: [additionalPermissions],
          sectionDetail: [sectionDetailObject],
          isNewSection: false,
        };
      })
    );
    setIsFormVisible(true);
    setTimeout(() => {
      formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 0);
  };
  useEffect(() => {
    if (isFormVisible && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isFormVisible]);
  return (
    <>
      {isFormVisible && (
        <div ref={formRef}>
          <TemplateSelctedFields
            setIsFormVisible={setIsFormVisible}
            activeSessionId={activeSessionId}
            activeSessionDataId={activeSessionDataId}
            setActiveSessionDataId={setActiveSessionDataId}
          />
        </div>
      )}
      {!isFormVisible && (
        <div className="professionalsummary-item ">
          <button
            className="professionalitem-button ml-2 mt-2"
            onClick={() => {
              AddNewSection(section);
            }}
          >
            <i className="ri-add-circle-line"></i>
            <span className="ml-2">Add New Section</span>
          </button>
        </div>
      )}
      {section?.data &&
        section?.data.map((item, index) => {
          const dynamicValues = Object.keys(section.template.fields).reduce(
            (acc, key) => {
              if (section.template.fields[key]) {
                acc[key] = item[key] || "";
              }
              return acc;
            },
            {}
          );

          const populateTemplate = (html, values) => {
            if (!html || !values) return html;
            return html.replace(/{(.*?)}/g, (_, key) => {
              return values[key.trim()];
            });
          };

          const populatedTemplate = populateTemplate(
            section.template.template_ui,
            dynamicValues
          );
          return (
            <div
              key={index}
              className={`rounded-xl shadow-lg mt-2 relative ${
                activeSessionDataId === item?.id
                  ? "inset-0 bg-blue-500 bg-opacity-40 shadow-2xl"
                  : "bg-white"
              }`}
            >
              <div className="professionalsummary-companey p-3">
                <div className="AdditionalSectionCardHeading">
                  <div
                    dangerouslySetInnerHTML={{ __html: populatedTemplate }}
                  />
                </div>
                {/* ///actions */}
                <div className="professionalitem-actions rounded-tr-xl gap-5">
                  <button
                    className="professionalitem-button"
                    onClick={() => handleEditSection(index, item)}
                  >
                    <i className="ri-edit-line"></i>
                  </button>
                  <button
                    className="professionalitem-button"
                    onClick={() => {
                      setSelectedSection(item?.id);
                      setRemoveInnerSection(true);
                    }}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default AdditionalSections;
