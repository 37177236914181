import React from "react";
import { useRecoilState } from "recoil";
import { StepStateAtom } from "../../../../recoil/ResumeAdditionalSection/StepsState";

const Stepper = ({ steps }) => {
  const [stepState, _] = useRecoilState(StepStateAtom);

  return (
    <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg shadow-sm sm:text-base sm:p-4 sm:space-x-4">
      {steps.map((step, index) => {
        const textColor =
          index < stepState?.activeStep
            ? "text-green-600"
            : index === stepState?.activeStep
            ? "text-blue-600"
            : "text-gray-500";

        const borderColor =
          index < stepState?.activeStep
            ? "border-green-600"
            : index === stepState?.activeStep
            ? "border-blue-600"
            : "border-gray-300";

        return (
          <li key={index} className={`flex items-center ${textColor}`}>
            <span
              className={`flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0 ${borderColor}`}
            >
              {index + 1}
            </span>
            {step?.label}
            {index !== steps.length - 1 && (
              <svg
                className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 12 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m7 9 4-4-4-4M1 9l4-4-4-4"
                />
              </svg>
            )}
          </li>
        );
      })}
    </ol>
  );
};

export default Stepper;
