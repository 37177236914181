import React from "react";
import Badges from "../Components/Landing/Badges";
import FeaturesSection from "../Components/Landing/FeaturesSection";
import HeroSection from "../Components/Landing/HeroSection";
import Testimonials from "../Components/Landing/Testimonials";
import TopPriority from "../Components/Landing/TopPriority";
import UseCases from "../Components/Landing/UseCases";
import Footer from "../Components/footer_new/footer";
import Header from "../Components/header_new/navbar";

const Landing = () => {
  return (
    <>
      <Header />
      <div className="landing">
        <HeroSection />
        <FeaturesSection />
        <UseCases />
        <Testimonials />
        <TopPriority />
        <Badges />
      </div>
      <Footer />
    </>
  );
};

export default Landing;
