import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const taskRepo = RepositoryFactory.get("task");

// Async actions
export const getTasks = createAsyncThunk(
  "task/getTasks",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.getAllTasks(payload);
      if (data?.status_code === 200) {
        console.log("data.data: ", data.data);
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const getSingleTaskDetail = createAsyncThunk(
  "task/getSingleTaskDetail",
  async ({ taskID, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.getSingleTaskDetail(taskID);
      if (data?.status_code === 200) {
        onSuccess();
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.data.detail || err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const addComment = createAsyncThunk(
  "task/addComment",
  async ({ taskID, comment, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.addComment(taskID, comment);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const AddAttachmentFile = createAsyncThunk(
  "task/AddAttachmentFile",
  async ({ payload, paramId, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.AddAttachmentFile(payload, paramId);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

const TaskSlice = createSlice({
  name: "task",
  initialState: {
    loading: false,
    tasks: [],
    singleTask: null,
    totalPages: 0,
    currentPage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks = action.payload;
        state.totalPages = action.payload.pagination?.total_pages;
        state.currentPage = action?.payload?.pagination?.page
      })
      .addCase(getTasks.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleTaskDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleTaskDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.singleTask = action.payload;
      })
      .addCase(getSingleTaskDetail.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addComment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AddAttachmentFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddAttachmentFile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddAttachmentFile.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default TaskSlice.reducer;
