import DescriptionField from "./TemplateFields/DescriptionField";
import FromDateField from "./TemplateFields/FromDateField";
import SubTitleField from "./TemplateFields/SubTitleField";
import TitleField from "./TemplateFields/TitleField";
import ToDateField from "./TemplateFields/ToDateField";

export const fieldComponents = [
  {
    key: "title",
    component: TitleField,
    render: () => "Executive Member",
    builderRender: (item) => (
      <h4 className="font-bold text-base">{item?.title}</h4>
    ),
  },
  {
    key: "subtitle",
    component: SubTitleField,
    render: () => "AIESEC",
    builderRender: (item) => (
      <p className="font-medium text-sm">{item?.subtitle}</p>
    ),
  },
  {
    key: "fromDate",
    component: FromDateField,
    render: () => "Present",
    builderRender: (item) => <span className="text-sm">{item?.fromDate}</span>,
  },
  {
    key: "toDate",
    component: ToDateField,
    render: () => "09/2014",
    builderRender: (item) => <span className="text-sm">{item?.toDate}</span>,
  },
  {
    key: "description",
    component: DescriptionField,
    render: () =>
      "AIESEC is an international non-governmental organization that provides young people with leadership.",
    builderRender: (item) => (
      <p className="font-medium text-sm">{item?.description}</p>
    ),
  },
];
