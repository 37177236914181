import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getResumeBlurb,
  udpateResumeBlurb,
} from "../../../Redux/ResumeBuilderSlice";
import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md";
import { success } from "../../../Utilities/toast";
import RichTextEditor from "../../../features/RichTextEditor";
import { createMarkup } from "../../../Utilities/CreateMarkup";

export default function ProfessionalSummary({
  professionalSummaries,
  getSingleResumeDoc,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [summariesState, setSummariesState] = useState([]);
  const [activeField, setActiveField] = useState(null);
  const [hoveredField, setHoveredField] = useState(null);
  const [defaultContent, setDefaultContent] = useState("");
  const [updateDocId, setUpdateDocId] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const editableRefs = useRef([]);

  const handleFieldClick = (index, id) => {
    setUpdateDocId(id);
    const foundObj = summariesState?.find((obj, idx) => idx === index);
    setDefaultContent(foundObj.text);
    setEditorContent(foundObj.text);
    setActiveField(index);
    setTimeout(() => editableRefs.current[index]?.focus(), 0);
  };

  const getBlorbInfo = () => {
    const resumeId = id;
    dispatch(getResumeBlurb({ resumeId, onSuccess: () => {} }));
  };
  const handleBlur = () => {
    setActiveField(null);
    setHoveredField(null);
  };
  const submitChanges = () => {
    const hasChanges = editorContent !== defaultContent;
    if (hasChanges) {
      handleBlur();
      const data = {
        is_active: "false",
        text: editorContent !== "" ? editorContent : defaultContent,
      };
      const payload = {
        updateDocId,
        data,
      };
      dispatch(
        udpateResumeBlurb({
          payload,
          onSuccess: (res) => {
            success(res);
            getBlorbInfo();
          },
        })
      );
    } else {
      handleBlur();
    }
  };

  const renderField = (index, item) => {
    const isActive = activeField === index;
    const isHovered = hoveredField === index;
    const hasChanges = editorContent !== defaultContent;

    const iconStyle = {
      position: "absolute",
      top: "-10px",
      borderRadius: "50%",
      padding: "4px",
      fontSize: "1.4rem",
      color: "#fff",
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
      cursor: "pointer",
    };

    return (
      <div style={{ position: "relative" }}>
        <div
          ref={(el) => (editableRefs.current[index] = el)}
          onBlur={() => handleBlur()}
          onMouseEnter={() => setHoveredField(index)}
          onMouseLeave={() => setHoveredField(null)}
          suppressContentEditableWarning
          className={`editable-field mt-2 ${
            isActive || isHovered ? "active" : ""
          }`}
          style={{
            border: isActive || isHovered ? "1px solid ##ffff" : "none",
            backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
            fontSize: "inherit",
            cursor: "text",
          }}
        >
          {isActive ? (
            <div className=" customized-editor mt-2  ">
              <RichTextEditor
                defaultContent={defaultContent}
                setEditorContent={setEditorContent}
              />
            </div>
          ) : (
            <p dangerouslySetInnerHTML={createMarkup(item?.text)} />
          )}

          {isActive && hasChanges && (
            <MdDone
              onClick={() => submitChanges()}
              style={{ ...iconStyle, left: "-15px", background: "#4caf50" }}
            />
          )}
          {isActive && (
            <MdClose
              onClick={() => submitChanges(index)}
              style={{ ...iconStyle, right: "-16px", background: "#ff4d4d" }}
            />
          )}
          {isHovered && !isActive && (
            <MdOutlineModeEdit
              onClick={() => handleFieldClick(index, item?.id)}
              style={{ ...iconStyle, right: "-16px", background: "#2b6be2" }}
            />
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (professionalSummaries?.length > 0) {
      setSummariesState(professionalSummaries || []);
    }
  }, [professionalSummaries]);
  return (
    summariesState.length > 0 && (
      <section className="cv-professional_summary">
        {summariesState.map((item, index) => renderField(index, item))}
      </section>
    )
  );
}
