import React, { useEffect, useRef, useState } from "react";
import textToSpeech, {
  stopMachineSpeaksGlobal,
} from "../../../Utilities/textToSpeech";
// import ProgressBar from "../../../Components/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../Components/modal";
// import {
//   getSimultorResponse,
//   nextSimulationsAction,
//   submitAnswers,
//   updateSimuldationsAction,
// } from "../../../store/Actions/dashboardActions";
import Loader from "../../../Components/main-loader";
import CircularLoader from "../../../Components/loader";

import {
  getSimulatorResponse,
  nextSimulationsAction,
  updateSimulationsAction,
} from "../../../Redux/DashboardSlice";
import { error } from "../../../Utilities/toast";
import InterviewTimer from "../../../Utilities/InterviewTimer";
import { setInterviewSession } from "../../../Redux/GlobalStates";
import EndingScreen from "../../../Components/EndingScreen";
import ReactMarkdown from "react-markdown";

const Interview = ({
  allQuestions = [],
  audio,
  setAudio,
  setIsFirstQ,
  isFirstQ,
  conversationLogId,
  setConversationLogId,
  interviewSimulationSessionId,
  setInterviewTimer,
  interviewTimer,
}) => {
  // //  console.log(
  //   allQuestions,
  //   audio,
  //   conversationLogId,
  //   interviewSimulationSessionId,
  //   "typetype"
  // );
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.dashboard);

  const [question, setQuestions] = useState(allQuestions);
  const [answers, setAnswers] = useState();
  const [animationClass, setAnimationClass] = useState("slide-from-right");
  const [isCollapse, setIsCollapse] = useState(false);
  const [answerMode, setAnswerMode] = useState(null);
  const [voiceAnswer, setVoiceAnswer] = useState(null);
  const [textAnswer, setTextAnswer] = useState(null);
  const [storeResult, setStoreResult] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [endScreen, setEndScreen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [submitted, setSubmitted] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [feedbackScore, setFeedbackScore] = useState(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(300); // 300 seconds = 5 minutes
  const [endSession, setEndSession] = useState(false);
  const recognition = useRef(null);

  // const ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT;

  const hasAnswer = () => {
    if (answers && answers !== " ") return true;
    else return false;
  };

  const removeKeyWords = (phrase) => {
    const updatedPhrase = phrase.replace(
      /\n\s*\\?(question|follow[- ]?up question|small_talk|response|feedback):?/gi,
      ""
    );
    return updatedPhrase;
  };

  const handleCollapse = () => {
    if (isCollapse) {
      setIsCollapse(false);
      setAnswerMode(null);
      setVoiceAnswer(null);
    } else {
      setTextAnswer(answers || "");
      setIsCollapse(true);
      setAnswerMode("keyboard");
    }
  };

  const handleAnswer = () => {
    let answer = "";
    if (answerMode === "voice") {
      recognition.current.stop();
      answer = voiceAnswer;
    } else {
      answer = textAnswer;
    }

    if (answer != null) {
      setAnswers(answer);
    }
    setVoiceAnswer(null);
    setTextAnswer(null);
    setIsCollapse(false);
    setAnswerMode(null);
  };

  const submitPayload = async () => {
    if (!endScreen) {
      const payload = {
        interviewSimulationSessionId: interviewSimulationSessionId,
        conversationLogId: conversationLogId,
        answer: answers,
      };
      await dispatch(nextSimulationsAction(payload))
        .unwrap()
        .then((res) => {
          setCurrentQuestion({
            question: res.question,
            conversationLogId: res?.conversationLogId,
            interviewSimulationSessionId: res?.interviewSimulationSessionId,
          });
          setQuestions(res?.question);
          setIsFirstQ(false);
          setConversationLogId(res?.conversationLogId);
          setAnswers();
          setCurrentIndex(currentIndex + 1);
          setSubmitted((prev) => {
            const newAnswers = [
              ...prev,
              {
                ...payload,
                question,
              },
            ];
            return newAnswers;
          });
          // navigate("/login");
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error(rejectedValueOrSerializedError);
        });
    } else {
      error(
        "Your Interview session has been ended please try restart the interview"
      );
    }
  };

  const nextQuestion = () => {
    setAnimationClass("slide-from-right");
    submitPayload("next");
  };
  const nextQuestionLocal = async () => {
    setAnimationClass("slide-from-right");
    if (!endScreen) {
      const payload = {
        interviewSimulationSessionId: interviewSimulationSessionId,
        conversationLogId: conversationLogId,
        answer: answers,
      };
      await dispatch(updateSimulationsAction(payload))
        .unwrap()
        .then((res) => {
          submitted[currentIndex].answer = payload.answer;
          setAnswers();
          setIsFirstQ(false);
          if (currentIndex < submitted.length - 1) {
            const newIndex = currentIndex + 1;
            const nextAnswer = submitted[newIndex];
            if (nextAnswer) {
              setQuestions(nextAnswer.question);
              setTextAnswer(nextAnswer.answer);
              setAnswers(nextAnswer.answer);
              setCurrentIndex(newIndex);
            }
          } else {
            setCurrentIndex(currentIndex + 1);
            if (currentQuestion) {
              setQuestions(currentQuestion.question);
              setConversationLogId(currentQuestion.conversationLogId);
              setAnswers(" ");
              setEditMode(false);
            }
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error(rejectedValueOrSerializedError);
        });
    } else {
      error(
        "Your Interview session has been ended please try restart the interview"
      );
    }
  };

  // const nextQuestionLocal = () => {
  //   setAnswers();

  //   if (currentIndex < submitted.length - 1) {
  //     if (answers == textAnswer) {
  //       const newIndex = currentIndex + 1;
  //       const nextAnswer = submitted[newIndex];
  //       if (nextAnswer) {
  //         //  console.log({ nextAnswer });
  //         setQuestions(nextAnswer.question);
  //         setTextAnswer(nextAnswer.answer);
  //         setAnswers(nextAnswer.answer);
  //         // textToSpeech(nextAnswer.question, null, null, () => {
  //         // });
  //         setCurrentIndex(newIndex);
  //       }
  //     } else {
  //       submitPayload("update");
  //     }
  //   } else {
  //     setEditMode(false);
  //     if (currentQuestion) {
  //       setQuestions(currentQuestion.question);
  //       setConversationLogId(currentQuestion.conversationLogId);
  //       setAnswers(" ");
  //       // textToSpeech(currentQuestion.question, null, null, () => {
  //       // });
  //     }
  //   }
  // };

  const previousQuestion = () => {
    if (!endScreen) {
      setAnimationClass("slide-from-left");
      setEditMode(true);
      setAnswers();
      console.log({ currentIndex });
      if (currentIndex >= 0) {
        console.log("inside");
        if (currentIndex == 1) {
          setIsFirstQ(true);
        }
        const newIndex = currentIndex - 1;
        const previousAnswer = submitted[newIndex];
        console.log({ previousAnswer });
        if (previousAnswer) {
          setQuestions(previousAnswer.question);
          setTextAnswer(previousAnswer.answer);
          setAnswers(previousAnswer.answer);
          setConversationLogId(previousAnswer.conversationLogId);
          setCurrentIndex(newIndex);
        }
      }
    } else {
      error(
        "Your Interview session has been ended please try restart the interview"
      );
    }
  };

  const speechToText = () => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      recognition.current = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      setAnswerMode("voice");
      recognition.current.lang = "en-US";
      recognition.current.interimResults = true;
      recognition.current.continuous = true;
      recognition.current.start();

      recognition.current.onresult = (event) => {
        const answer = Array.from(event.results)
          .filter((result) => result[0].confidence > 0.5) // Check for 100% confidence
          .map((alts) => alts[0].transcript)
          .join("");
        setVoiceAnswer(answer);
        setTextAnswer(answer);
      };
      recognition.current.onerror = (event) => {
        setIsCollapse(false);
        setAnswerMode(null);
        setVoiceAnswer(null);
      };
    } else {
    }
  };

  const handleResult = () => {
    if (!endScreen) {
      if (answers !== " ") {
        setFeedbackLoading(true);
        const payload = {
          interviewSimulationSessionId: interviewSimulationSessionId,
          conversationLogId: conversationLogId,
          answer: answers,
        };
        dispatch(updateSimulationsAction(payload))
          .unwrap()
          .then((res) => {
            setSubmitted((prev) => {
              const newAnswers = [
                ...prev,
                {
                  ...payload,
                  question,
                },
              ];
              return newAnswers;
            });
            handleGetFeedback();
          })
          .catch((rejectedValueOrSerializedError) => {
            console.error(rejectedValueOrSerializedError);
          });
      } else {
        error(
          "Please enter an answer and click done before getting a feedback."
        );
      }
    } else {
      error(
        "Your Interview session has been ended please try restart the interview"
      );
    }
  };
  const handleGetFeedback = () => {
    dispatch(getSimulatorResponse(interviewSimulationSessionId))
      .unwrap()
      .then((res) => {
        const reversedFeedbackList = res.feedbackList.reverse();
        setStoreResult(reversedFeedbackList);
        setFeedbackScore(res.averageScore);
        setFeedbackLoading(false);
        setIsModal(true);
        setEndScreen(true);
        dispatch(setInterviewSession(false));
        setEndSession(false);
        setSeconds(300);
        setIsRunning(false);
        setInterviewTimer(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    if (interviewTimer && endSession) {
      error("Interview Time is Over");
      handleGetFeedback();
    }
  }, [endSession, interviewTimer]);

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const getModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => handleCloseModal()}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-12 order-md-1 order-2">
          {storeResult?.map((item, index) => (
            <div className="cardBody" key={index + 100}>
              <h5 className="mx-3 my-3 ">Question:</h5>
              <hr />
              <p className="mx-3 my-3">{item?.question}</p>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Answer:</h5>
              <hr />
              <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
              <ReactMarkdown className="mx-3 my-3">
                {item?.feedback}
              </ReactMarkdown>{" "}
            </div>
          ))}
        </div>
      </div>
    );

    const footer = (
      <div className="footer_scrore d-flex">
        <h5 className="my-0">Nexa Grading Score:</h5>
        <span className="score">{feedbackScore?.toFixed(2)}</span>
      </div>
    );

    return (
      <Modal
        show={isModal}
        header={header}
        content={content}
        footer={footer}
        type="feedback"
      />
    );
  };

  useEffect(() => {
    dispatch(setInterviewSession(true));
    setAudio(true);
    setQuestions(allQuestions);
    setCurrentIndex(0);
  }, []);

  useEffect(() => {
    if (question && audio && !editMode) {
      setIsRunning(false);
      console.log("Question=>>", question);
      const updatedPhrase = removeKeyWords(question);
      console.log("Phrase=>>", updatedPhrase);

      textToSpeech(updatedPhrase, null, null, () => {
        setAnswers(" ");
        setIsRunning(true);
      });
    }
  }, [question, audio]);

  useEffect(() => {
    return () => {
      if (audio) {
        stopMachineSpeaksGlobal();
        setAudio(false);
      }
    };
  }, [audio]);
  return (
    <>
      {!feedbackLoading ? (
        <>
          <div name="slide-fade">
            <div className="ic-start-interview">
              {endScreen ? (
                <EndingScreen
                  setEndScreen={setEndScreen}
                  mainRoute={`mock-interview`}
                />
              ) : isLoading ? (
                <Loader />
              ) : (
                <div className="carousel-wrap-div">
                  {interviewTimer && (
                    <div className="carousel-div">
                      <div className="timer-div">
                        <InterviewTimer
                          isRunning={isRunning}
                          setIsRunning={setIsRunning}
                          seconds={seconds}
                          setSeconds={setSeconds}
                          setEndSession={setEndSession}
                        />
                      </div>
                    </div>
                  )}
                  <div className="carousel-div">
                    <div className={`slide-div ${animationClass}`}>
                      <div className="slide">
                        <div className={`ic-question-start bg-white`}>
                          <h4>{removeKeyWords(question)}</h4>
                          {answers && (
                            <div className="ic-record-buttons">
                              <div
                                className={`ic-according-items ${
                                  isCollapse ? "active" : ""
                                }`}
                              >
                                {hasAnswer() ? (
                                  <div className="ic-according-header">
                                    <p>
                                      <i className="ri-list-radio" /> Your
                                      Answer
                                    </p>
                                    <button
                                      className="btn-collapse"
                                      onClick={() => handleCollapse()}
                                    >
                                      <i className="ri-arrow-down-s-line" />
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className={`ic-keyboard-body ${
                                    isCollapse ? "show" : ""
                                  }`}
                                >
                                  <textarea
                                    className="ic-body-text"
                                    placeholder="Type your answer here"
                                    value={textAnswer || ""}
                                    onChange={(e) =>
                                      setTextAnswer(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="ic-record-keyboard-btn">
                                <div className="answer-btn">
                                  {answerMode ? (
                                    <button
                                      type="button"
                                      onClick={() => handleAnswer()}
                                    >
                                      Done{" "}
                                      <i className="ri-check-double-line" />
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        handleCollapse();
                                        speechToText();
                                      }}
                                    >
                                      {!hasAnswer() ? (
                                        <span>
                                          Answer <i className="ri-mic-fill" />
                                        </span>
                                      ) : (
                                        <span>
                                          Redo <i className="ri-mic-fill" />
                                        </span>
                                      )}
                                    </button>
                                  )}
                                  {answerMode === "voice" && (
                                    <div id="voice_bars">
                                      <div className="voice_bar"></div>
                                      <div className="voice_bar"></div>
                                      <div className="voice_bar"></div>
                                      <div className="voice_bar"></div>
                                      <div className="voice_bar"></div>
                                    </div>
                                  )}
                                  {!answerMode && (
                                    <div
                                      className="keyboard"
                                      onClick={() => handleCollapse()}
                                    >
                                      {hasAnswer() ? (
                                        <i className="ri-edit-line" />
                                      ) : (
                                        <i className="ri-keyboard-box-line" />
                                      )}
                                    </div>
                                  )}
                                </div>
                                {!isCollapse && answerMode !== "voice" && (
                                  <>
                                    <div
                                      className="slider-arrows"
                                      style={{ display: "flex" }}
                                    >
                                      {!isFirstQ && (
                                        <button
                                          className="right-arrow "
                                          onClick={previousQuestion}
                                        >
                                          Prev
                                        </button>
                                      )}
                                      <div
                                        className="right-arrow"
                                        onClick={
                                          editMode
                                            ? nextQuestionLocal
                                            : nextQuestion
                                        }
                                      >
                                        Next
                                      </div>
                                    </div>
                                    {!editMode && (
                                      <div
                                        onClick={handleResult}
                                        className="ic-btn"
                                      >
                                        Get Feedback
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {getModal()}
        </>
      ) : (
        <CircularLoader
          title="Nexa is hard at work to analyze your results, give us a minute"
          icon={true}
        />

        // <div name="slide-fade">
        //   <div className="ic-start-interview">
        //     <div className="carousel-wrap-div">
        //       <div className="carousel-div">
        //         <div className="slide-div">
        //           <div className="slide">
        //             <div className={`ic-question-start `}>
        //               <h4>
        // Nexa is hard at work to analyze your results, give us a
        // minute
        //               </h4>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
};

export default Interview;
