import React, { useState } from "react"; // Ensure to import your styles
import firstImage from "../../assets/Images/thenewlanding.png";
//import secondImage from "../../assets/Images/realpractice.png";

const VideoPlayer = ({ videoUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentImage, setCurrentImage] = useState(firstImage);
  const [fade, setFade] = useState(true);

  // Extract the video ID from the YouTube URL
  const getVideoId = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/
    );
    return match ? match[1] : null;
  };

  const videoId = getVideoId(videoUrl);

  // Construct the thumbnail URL
  const thumbnailSrc = videoId
    ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    : "";

  const togglePlay = () => {
    setIsPlaying(true); // Start playing the video
  };

  const [isHovered, setIsHovered] = useState(false);

  const containerStyle = {
    width: "600px",
    height: "500px",
    backgroundImage: `url(${currentImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "transform 0.3s ease-in-out",
    transform: isHovered ? "scale(1.2)" : "scale(1)",
  };

  return (
    <div
      className={` transform transition-transform hover:scale-110 video-frame my-6 mt-3 ${fade ? "fade-in" : "fade-out"}`}
      style={{ containerStyle, backgroundImage: `url(${currentImage})` }}
    >
    {/*  <div className="absolute word-animtaion break-words">
        <p className="text font-bold text-sm break-words">
          Career Preparation <br />
          To Placement
        </p>
      </div>
      <div
        className="absolute word-animtaion1 break-words"
        style={{ left: "23%" }}
      >
        <p className="text font-bold text-sm break-words">
          0 Friction
          <br /> Recruitment
        </p>
      </div>
      <div className="absolute word-animtaion2 break-words">
        <p className="text font-bold text-sm break-words">
          Full Observability <br />& Realtime Insights
        </p>
      </div>

      {/* {isPlaying ? (
            <iframe
                // Video iframe details
            />
        ) : (

        )} */}
      {/* <div className="button-container">
                <button className="play-button" onClick={togglePlay}>
                    <img className='widtn-btn' src={v1} alt="" />
                </button>
            </div>    */}
    </div>
  );
};

export default VideoPlayer;