import Repository from "./Repository";

const UserProfileRepository = {
  /// Add New Profile Skills
  addNewSkill(payload) {
    return Repository.post(`/customer/skills`, payload);
  },

  //// Delete profile Skills
  deleteSkill(id) {
    return Repository.delete(`/customer/skill/${id}`);
  },

  /// Add New Profile Certificated
  AddNewCertifications(payload) {
    console.log(payload, "hello payload");

    const extractPath = (url) => {
      const match = url.match(/nexa-web-portal\.s3\.amazonaws\.com\/([^?]+)/);
      return match ? match[1] : url;
    };
    const updatedPayload = payload.map((item) => {
      if (item.file_path) {
        const extractedPath = extractPath(item.file_path);
        console.log(extractedPath, "hello extractedPath");
        return { ...item, file_path: extractedPath };
      }
      return item;
    });

    const formattedData = {
      certifications: updatedPayload,
    };

    return Repository.post(`/customer/certifications`, formattedData);
  },

  ////Delete Profile Certificates
  deleteCertification(id) {
    return Repository.delete(`/customer/certification/${id}`);
  },

  /// Add New Profile Project
  AddWorkSamples(payload) {
    return Repository.post(`/customer/project-samples`, payload);
  },

  ////Delete Profile Project
  deleteProjects(id) {
    return Repository.delete(`/customer/project-sample/${id}`);
  },
  /// Add New Work Experience History
  AddExperienceHistory(payload) {
    return Repository.post(`/customer/experience`, payload);
  },

  ////Delete Work Experience History
  deleteExperienceHistory(id) {
    return Repository.delete(`/customer/experience/${id}`);
  },

  /// Add New Job Preference
  addJobPreference(payload) {
    return Repository.post(`/customer/job-preferences`, payload);
  },

  ////Delete Job Preference
  deleteJobPreference(id) {
    return Repository.delete(`/customer/job-preference/${id}`);
  },

  ////Get Complete Simulator count
  getCompletedSimulatorCount() {
    return Repository.get(`/customer/get-completed-simulation-count`);
  },

  ////Get Complete Interview Sessions
  getCompletedInterviewSessions() {
    return Repository.get(`/customer/get-completed-interview-sessions`);
  },

  /// update SHOWCASE TALENT
  saveShowcaseTalent(payload) {
    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value !== "")
    );
    return Repository.post(`/customer/customer-stats`, filteredPayload);
  },
  /// Upload Certificate FILE
  uploadCertiticateFiles(payload) {
    console.log(payload, "certificationsbyrepo");
    const formData = new FormData();
    formData.append("file", payload);
    return Repository.post(`/customer/file-upload-certifications`, formData);
  },

  /// DELETE Certificate FILE
  deleteCertificationFile(payload) {
    const extractPath = (url) => {
      const match = url.match(/nexa-web-portal\.s3\.amazonaws\.com\/(.+?)\?/);
      return match ? match[1] : null;
    };
    const extractedPath = extractPath(payload);
    return Repository.delete(
      `/customer/file-delete-certifications?file_path=${extractedPath}`
    );
  },

  ////Get Job Roles
  getJobRoles(page, search) {
    return Repository.get(
      `/nexa_iq/professions-only?page=${page}&limit=10&search=${search}`
    );
  },
  
  ////Get Key Skills
  getkeySkills(payload) {
    return Repository.get(`customer/generate_skills?education=${payload}`);
  },
  
  //Get Professions
  getProfessions(page, search) {
    return Repository.get(
      `nexa_iq/job-professions?page=${page}&limit=10&search=${search}`
    );
  },
  
  //Get Preference for Jobs
  getPreferenceforJobs() {
    return Repository.get(`nexa_iq/job_preference/get`);
  },
  
  //Add Preference for Jobs
  addPreferenceforJobs(payload) {
    return Repository.post(`nexa_iq/job_preference/add`, payload);
  },
  
  //Update Preference for Jobs
  updatePreferenceforJobs(id, payload) {
    return Repository.put(`nexa_iq/job_preference/update/${id}`, payload);
  },
};

export default UserProfileRepository;
