import React from "react";

const DescriptionField = ({ handleInputChange, fieldValue }) => {
  return (
    <>
      <label
        htmlFor="description"
        className="block text-sm font-medium text-gray-700"
      >
        Description
      </label>
      <textarea
        name="description"
        id="description"
        rows="4"
        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        placeholder="Describe your experience..."
        value={fieldValue || ""}
        onChange={handleInputChange}
      ></textarea>
    </>
  );
};

export default DescriptionField;
