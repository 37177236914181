import React from "react";
import ChatBox from "./ChatBox";

const ChatBody = ({ chatMessages }) => {
  return (
    <div className="flex flex-col justify-end pb-3 w-full h-[calc(100vh-calc(112px+142px))]">
      <div className="h-full overflow-auto">
        <div className=""></div>
        {chatMessages?.map((group, idx) => {
          return (
            <div>
              <div className="flex sticky top-0 justify-center">
                <div className="border bg-white border-primary mt-4 text-sm text-primary font-semibold rounded-3xl p-2 px-7">
                  {group?.date}
                </div>
              </div>

              {group?.groupedMessages?.map((message, index) => {
                return <ChatBox message={message} index={index} />;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChatBody;
