import React, { useState } from "react";
import "./style.css";
import nexaLogo from "../../assets/icons/logo.jpeg";
import googleLogo from "../../assets/icons/googleIcon.webp";
import NexaIqSideBar from "../NexaIqSideBar";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { appliedJobCount, getSingleJob } from "../../Redux/NexaIqSlice";

const JobListing = ({
  id,
  jobTitle,
  compensation,
  location,
  descriptionPoints,
  companyName,
  companyTagline,
  CompanyDesc,
  logoUrl,
  organizationNumber,
  postedDate,
  badge,
  jobProvider,
  activeTab,
  how_to_conduct,
  jobStatus,
  jobTonality,
  interview_deadline,
  prescreening_deadline,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const [showMore, setShowMore] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [activegoogleJobLink, setActivegoogleJobLink] = useState(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    if (jobId) {
      searchParams.delete("jobId");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="back">
      <div className="outside-container cursor-pointer">
        <div
          className="flex flex-row items-center justify-between rounded-t-[20px] bg-white group-hover:bg-[#FAFBFF] px-4 py-3"
          onClick={() => {
            setSearchParams({ jobId: id });
            dispatch(getSingleJob({ id, onSuccess: () => toggleSidebar() }));
          }}
        >
          <div className="w-auto">
            <h3 className="flex flex-row items-center justify-between">
              <div className="flex w-full flex-row items-center justify-between text-base font-semibold sm:justify-start sm:text-lg">
                <div className="clamp h-[28px] relative mb-[4px] sm:mb-[8px] cursor-default group-hover:text-[#7593FF] sm:flex sm:flex-row flex w-full sm:items-center">
                  <span className="w-full absolute overflow-hidden text-ellipsis whitespace-nowrap sm:text-xl pr-[40px] sm:pr-0 hover-text">
                    {jobTitle}
                  </span>
                </div>
              </div>
            </h3>
            <div className="flex flex-col  sm:flex-row sm:items-center items-left text-sm">
              <div className="flex  flex-row items-center">
                {compensation !== "N/A" && (
                  <div className="list-item__compensation flex min-w-max flex-row items-center font-semibold text-[#4D4D4D]">
                    {compensation}
                    <svg
                      width="21"
                      height="20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m15.031 6.666-5.599 5.417-2.916-2.917L5.5 10.182l3.932 3.906 6.51-6.51-.91-.912Z"
                        fill="#1A7F23"
                      ></path>
                    </svg>
                  </div>
                )}
                {compensation !== "N/A" && (
                  <span className="ml-[9px] mr-[9px]">•</span>
                )}
                <div
                  title={location}
                  className="list-item__location align-center flex "
                >
                  {location.length > 40
                    ? location.substring(0, 40) + "..."
                    : location}
                </div>
              </div>
              <div className="sm:ml-[8px] mt-[8px] sm:mt-0 flex flex-row flex-wrap items-center overflow-hidden"></div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            {activeTab == "Applied Jobs" && (
              <span
                className={`question-tags5 mr-3 text-white bg-success 
                ${
                  badge == "qualified" || badge == "hired" || badge == "passed"
                    ? "bg-success"
                    : badge == "waiting for interview" ||
                      badge == "applied" ||
                      badge == "interview in progress"
                    ? "bg-warning"
                    : "bg-danger"
                }
             `}
              >
                {badge}
              </span>
            )}
            {jobProvider == "iq_jobs" ? (
              <span className={``}>
                <img src={googleLogo} alt="NEXA LOGO" height={40} width={40} />
              </span>
            ) : (
              <span className={``}>
                <img src={nexaLogo} alt="NEXA LOGO" height={40} width={40} />
              </span>
            )}
          </div>
        </div>

        {showMore && (
          <>
            <div className="flex flex-col white-back">
              <div className="py-2 overflow-hidden block h-auto">
                <div>
                  <ul className="relative list-none px-4 list-item">
                    <li
                      className="text-[#4d4d4d] list-item"
                      dangerouslySetInnerHTML={{
                        __html: descriptionPoints || descriptionPoints,
                      }}
                    >
                      {/* {descriptionPoints} */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bg-white p-[8px]">
              <div className="text-sm text-[#000] flex-row items-center ml-3 -mt-2 mb-2 flex font-bold">
                {postedDate}
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col items-stretch justify-between border-t-[#e6e6e6] sm:border-t sm:border-t-0 grey-2">
          {jobProvider == "smart_screen_jobs" && (
            <div className="pb-3 pt-4">
              <div className="flex flex-col items-center justify-between sm:flex-row">
                <div className="items-top flex w-full flex-row pb-[4px] pl-4 pr-[16px] sm:mb-0 sm:w-auto sm:pb-0 sm:pr-0">
                  <img
                    src={logoUrl || nexaLogo}
                    className="mr-3 h-[48px] w-[48px] rounded-[12px]"
                    alt={`${companyName} logo`}
                  />
                  <div className="list-item__company-info">
                    <div className="list-item__company-title">
                      <div className="mb-1 text-[18px] flex flex-row items-center font-semibold text-[#4d4d4d]">
                        <span>{companyName}</span>
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="text-sm mb-2 text-[#4d4d4d]">
                          {companyTagline}
                        </div>
                        <div className="hidden sm:block">
                          <div className="flex flex-row flex-wrap sm:flex-nowrap items-center">
                            {organizationNumber && (
                              <div className="mr-2 mb-2 sm:mb-0 flex w-max items-center text-xs flex-row whitespace-nowrap rounded-[8px] border px-[6px] py-[1px] border-topInvestorsBorder bg-topInvestorsBg">
                                {organizationNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {jobProvider == "smart_screen_jobs" && showMore && (
            <div className="flex flex-row items-start justify-stretch">
              <div className="flex flex-col items-start w-full">
                <h3 className="mb-2 text-base font-semibold px-4 text-[#4d4d4d]">
                  Overview
                </h3>
                <div className="h-50px flex w-full flex-row items-center border-y border-t-[#e6e6e6] sm:border-none sm:px-4 mb-3">
                  <div className="flex w-full flex-col items-stretch rounded-[8px] border-[#e6e6e6] bg-[#f5f5f5] sm:border">
                    <div className="flex flex-row items-center items-stretch">
                      <div className="flex w-full flex-col items-start justify-between border-r border-r-[#e6e6e6] pt-2 pl-3 pr-2">
                        <div className="text-sm text-[#4d4d4d]">Status</div>
                        <div className="flex h-[36px] items-center text-sm font-semibold">
                          <span
                            className={`question-tags5 mr-3 text-white bg-success 
                               ${
                                 jobStatus == "open"
                                   ? "bg-success"
                                   : "bg-danger"
                               }
                                 `}
                          >
                            {jobStatus}
                          </span>
                        </div>
                      </div>
                      <div className="flex w-full flex-col items-start px-3 pt-2">
                        <div className="text-sm text-[#4d4d4d]">Tonality</div>
                        <div className="flex h-[36px] flex-row items-center">
                          <div className="flex text-sm font-semibold">
                            {jobTonality}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row items-center items-stretch">
                      <div className="flex w-2/4 flex-col items-start justify-between border-r border-r-[#e6e6e6] pt-2 pl-3 pr-2">
                        <div className="text-sm text-[#4d4d4d]">
                          PreScreen Deadline
                        </div>
                        <div className="flex h-[36px] items-center text-sm font-semibold">
                          {dayjs(prescreening_deadline).format("DD MMM YYYY")}
                        </div>
                      </div>
                      <div className="flex w-2/4 flex-col items-start px-3 pt-2">
                        <div className="text-sm text-[#4d4d4d]">
                          Interview Deadline
                        </div>
                        <div className="flex h-[36px] flex-row items-center">
                          <div className="flex text-sm font-semibold">
                            {dayjs(interview_deadline).format("DD MMM YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                    {how_to_conduct && (
                      <div className="w-full flex items-center justify-between border-t border-t-[#e6e6e6] pl-3 pr-2">
                        <div className="text-sm text-[#4d4d4d]">
                          How To Conduct:
                        </div>
                        <div className="flex h-[36px] items-center text-sm font-semibold">
                          {how_to_conduct}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full sm:px-4 sm:pb-4">
                  <div className="text-sm prose desc-text">{CompanyDesc}</div>
                </div>
              </div>
            </div>
          )}

          <div className="apply-me-mobile-container">
            <div className="apply-me-mobile">
              <svg
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-[8px]"
              >
                <path
                  d="M7.167 1.332a.5.5 0 0 0-.439.26L3.062 8.257a.5.5 0 0 0 .438.74L6.8 8.4l-3.096 5.576a.5.5 0 0 0 .816.544l8.334-8.333a.5.5 0 0 0-.354-.854l-2.9.309 2.665-3.557a.5.5 0 0 0-.432-.752H7.167Z"
                  fill="#F7AB38"
                ></path>
              </svg>
              <div>Apply Me</div>
            </div>
          </div>
        </div>

        <div
          className="w-full h-[40px] border-t-[#E6E6E6] border-t sm:flex flex-row items-center justify-center cursor-pointer"
          onClick={() => setShowMore(!showMore)}
        >
          <div className="flex flex-row items-center justify-center underline text-[#4D4D4D]">
            {showMore ? "Hide" : "Show More"}{" "}
            <svg
              width="17"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`ml-1 ${showMore ? "rotate-180" : ""}`}
            >
              <path
                d="m4.5 5.334-.715.781L8.5 10.83l4.714-4.714-.714-.781-4 4-4-4Z"
                fill="#4D4D4D"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <NexaIqSideBar
        isOpen={isOpen}
        toggleSidebar={toggleSidebar}
        status={badge}
        setLoading={setLoading}
        setIsOpenConfirmModal={setIsOpenConfirmModal}
        isOpenConfirmModal={isOpenConfirmModal}
        setActivegoogleJobLink={setActivegoogleJobLink}
      />
      <div className={`ic-modal ${isOpenConfirmModal ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            <div className="ic-modal-body">
              <div className="ic-modal-content">
                <p className="text-xl">
                  Are you sure you want to Apply this Job?
                </p>
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setIsOpenConfirmModal(false);
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    setLoading(true);
                    dispatch(
                      appliedJobCount({
                        job_id: jobId,
                        onSuccess: () => {
                          setLoading(false);
                        },
                        onError: () => {
                          setLoading(false);
                        },
                      })
                    );
                    window.open(activegoogleJobLink, "_blank");
                    setIsOpenConfirmModal(false);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-50"
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
};

export default JobListing;
