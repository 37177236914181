import { Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveShowcaseTalent } from "../../../Redux/UserProfileSlice";
import { getCurrentUser } from "../../../Redux/authenticate/authenticate.action";
import { success } from "../../../Utilities/toast";
import { useNavigate } from "react-router-dom";
import ResumeUpload from "./ResumeUpload";
const { Option } = Select;

const ShowcaseTalent = ({ activeStep, setActiveStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authenticate);
  const { allResume } = useSelector((state) => state.ResumeBuilder);
  const { simulatorCount, interviewSessions } = useSelector(
    (state) => state.UserProfile
  );
  const [resumeFile, setResumeFile] = useState(null);
  const [selectedResumeID, setSelectedResumeID] = useState("");
  const [selectedInterviewSimulator, setSelectedInterviewSimulator] =
    useState("");
  const [practiceRound, setPracticeRound] = useState("");

  const handleSaveShowcase = () => {
    if (
      practiceRound === user?.customer_stats?.interview_training_session_id &&
      selectedInterviewSimulator ===
        user?.customer_stats?.interview_simulator_id &&
      selectedResumeID ===
        (user?.customer_stats?.resume_feedback_id !== null
          ? user?.customer_stats?.resume_feedback_id
          : "")
    ) {
      navigate(-1);
      return;
    }
    const data = {
      resume_feedback_id: selectedResumeID,
      interview_simulator_id: selectedInterviewSimulator,
      interview_training_session_id: practiceRound,
    };
    const isEmpty = Object.values(data).every((value) => value === "");
    if (isEmpty) {
      navigate(-1);
      return;
    }
    dispatch(
      saveShowcaseTalent({
        payload: data,
        onSuccess: () => {
          success("Show Case added successfully");
          dispatch(getCurrentUser());
          navigate(-1);
        },
      })
    );
  };

  useEffect(() => {
    if (user && user.customer_stats) {
      setPracticeRound(
        user?.customer_stats?.interview_training_session_id || ""
      );
      setSelectedInterviewSimulator(
        user?.customer_stats?.interview_simulator_id || ""
      );
      setSelectedResumeID(user?.customer_stats?.resume_feedback_id || "");
    }
  }, [user]);

  return (
    <div className="py-4">
      <div className="mb-4 p-4 border rounded-lg shadow">
        <div className="mb-2">
          <label className="block mt-2">Practice Round:</label>
          <Select
            placeholder="Select Practice Round"
            value={practiceRound || "Select Practice Round"}
            onChange={(value) => setPracticeRound(value)}
            className="h-[46px] mt-1 w-full"
            optionFilterProp="children"
            showSearch
            required
          >
            {interviewSessions?.map((resume, index) => {
              return (
                <Option key={index} value={resume.id}>
                  {resume.category_name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="mb-2">
          <label className="block mt-2">Interview Simulator:</label>
          <Select
            placeholder="Select Interview Simulator"
            value={selectedInterviewSimulator || "Select Interview Simulator"}
            onChange={(value) => setSelectedInterviewSimulator(value)}
            className="h-[46px] mt-1 w-full"
            optionFilterProp="children"
            showSearch
            required
          >
            {simulatorCount?.map((resume, index) => {
              return (
                <Option key={index} value={resume?.id}>
                  {resume?.job_description}
                </Option>
              );
            })}
          </Select>
        </div>

        <div className="mb-2">
          <label className="block mt-2">Resume:</label>
          <ResumeUpload
            allResume={allResume}
            setSelectedResumeID={setSelectedResumeID}
            selectedResumeID={selectedResumeID}
            resumeFile={resumeFile}
            setResumeFile={setResumeFile}
          />
        </div>
      </div>
      <div className={`w-full mt-2 flex justify-between`}>
        <button
          className="ic-btn"
          onClick={() => {
            setActiveStep((prev) => prev - 1);
          }}
        >
          Previous
        </button>
        <button
          className="ic-btn"
          onClick={() => {
            handleSaveShowcase();
          }}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default ShowcaseTalent;
