const Progress = ({ percentage }) => {
  const percentageValue = parseFloat(percentage);
  return (
    <div className="w-[80%] bg-gray-200 rounded-full">
      <div
        className={`bg-blue-600 text-xs font-medium ${
          percentage === "0%" ? "text-black" : "text-white"
        }  text-center p-0.5 leading-none rounded-full`}
        style={{ width: `${percentage}` }}
      >
        {Math.floor(percentageValue)}%
      </div>
    </div>
  );
};

export default Progress;
