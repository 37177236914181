import { useState } from "react";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

const DashboardHome = () => {
  const [lineChart] = useState({
    series: [
      {
        name: "TEAM A",
        type: "area",
        data: [44, 55, 31, 47, 31, 43, 26, 41, 31, 47, 33],
      },
      {
        name: "TEAM B",
        type: "line",
        data: [55, 69, 45, 61, 43, 54, 37, 52, 44, 61, 43],
      },
    ],
    options: {
      title: {
        text: "Nexa Career Analytics",
      },
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        type: "solid",
        opacity: [0.05, 1],
      },
      labels: [
        "Dec 01",
        "Dec 02",
        "Dec 03",
        "Dec 04",
        "Dec 05",
        "Dec 06",
        "Dec 07",
        "Dec 08",
        "Dec 09 ",
        "Dec 10",
        "Dec 11",
      ],
      markers: {
        size: 0,
      },
      yaxis: [
        {
          title: {
            text: "Series A",
          },
        },
        {
          opposite: true,
          title: {
            text: "Series B",
          },
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " points";
            }
            return y;
          },
        },
      },
    },
  });

  return (
    <>
      <div className="content-wrapper">
        <div className="d-xl-flex justify-content-between align-items-start">
          <h2 className="text-dark font-weight-bold mb-2">
            {" "}
            Welcome Back, Archer{" "}
          </h2>
        </div>
        <div>
          <p className="text-dark mb-2"> Dashboard Overview </p>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="tab-content tab-transparent-content">
              <div
                className="tab-pane fade show active"
                id="business-1"
                role="tabpanel"
                aria-labelledby="business-tab"
              >
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body text-center">
                        <h5 className="mb-2 text-dark font-weight-normal">
                          Users
                        </h5>
                        <h2 className="mb-4 text-dark font-weight-bold">10</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body text-center">
                        <h5 className="mb-2 text-dark font-weight-normal">
                          Practice Rounds
                        </h5>
                        <h2 className="mb-4 text-dark font-weight-bold">5</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3  col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body text-center">
                        <h5 className="mb-2 text-dark font-weight-normal">
                          Resumes Generated
                        </h5>
                        <h2 className="mb-4 text-dark font-weight-bold">10</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body text-center">
                        <h5 className="mb-2 text-dark font-weight-normal">
                          Career Satisfaction
                        </h5>
                        <h2 className="mb-4 text-dark font-weight-bold">400</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-8  grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-xl-flex justify-content-between mb-2">
                          {/* <h4 className="card-title">Page views analytics</h4> */}
                          <Chart
                            options={lineChart.options}
                            series={lineChart.series}
                            type="line"
                            className="mt-4"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <canvas id="page-view-analytic"></canvas>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-offset-1"></div> */}
                  <div className="col-sm-4 grid-margin stretch-card">
                    <div className="card card-danger-gradient">
                      <div className="card-body mb-4">
                        <h4 className="card-title text-white">
                          Account Retention
                        </h4>
                        <canvas id="account-retension"></canvas>
                      </div>
                      <div className="card-body bg-white pt-4">
                        <div className="row pt-4">
                          <div className="col-sm-6">
                            <div className="text-center border-right border-md-0">
                              <h4>Conversion</h4>
                              <h1 className="text-dark font-weight-bold mb-md-3">
                                $306
                              </h1>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="text-center">
                              <h4>Cancellation</h4>
                              <h1 className="text-dark font-weight-bold">
                                $1,520
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h4 className="card-title mb-0">Project Overview</h4>
                    </div>
                  </div>

                  <div className="col-lg-12 col-sm-8 grid-margin  grid-margin-lg-0">
                    <div className="pl-0 pl-lg-4 ">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>Product</th>
                            <th>Sale</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Jacob</td>
                            <td>Photoshop</td>
                            <td className="text-danger">
                              {" "}
                              28.76% <i className="mdi mdi-arrow-down"></i>
                            </td>
                            <td>
                              <label className="badge badge-danger">
                                Pending
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Messsy</td>
                            <td>Flash</td>
                            <td className="text-danger">
                              {" "}
                              21.06% <i className="mdi mdi-arrow-down"></i>
                            </td>
                            <td>
                              <label className="badge badge-warning">
                                In progress
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>John</td>
                            <td>Premier</td>
                            <td className="text-danger">
                              {" "}
                              35.00% <i className="mdi mdi-arrow-down"></i>
                            </td>
                            <td>
                              <label className="badge badge-info">Fixed</label>
                            </td>
                          </tr>
                          <tr>
                            <td>Peter</td>
                            <td>After effects</td>
                            <td className="text-success">
                              {" "}
                              82.00% <i className="mdi mdi-arrow-up"></i>
                            </td>
                            <td>
                              <label className="badge badge-success">
                                Completed
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>Dave</td>
                            <td>53275535</td>
                            <td className="text-success">
                              {" "}
                              98.05% <i className="mdi mdi-arrow-up"></i>
                            </td>
                            <td>
                              <label className="badge badge-warning">
                                In progress
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHome;
