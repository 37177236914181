// components/CollapsibleSection.jsx
import React, { useState } from 'react';

const CollapsibleSection = ({ title, icon, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '10px 15px',
      transition: 'color 0.3s ease',
      borderBottom: '1px solid #ddd',
    },
    iconContainer: {
      position: 'relative',
      width: '20px',
      height: '20px',
      marginRight: '10px',
    },
    headerText: {
      flex: 1,
      fontSize: '14px',
      color: '#333',
    },
    toggleIcon: {
      fontSize: '20px',
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease',
      color: '#aaa',
    },
    content: {
      overflow: 'hidden',
      height: isOpen ? 'auto' : '0',
      transition: 'height 0.3s ease',
      paddingLeft: isOpen ? '15px' : '0px',
      margin: '10px 0',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <div style={styles.iconContainer}>{icon}</div>
        <div style={styles.headerText}>{title}</div>
        <div style={styles.toggleIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.293 9.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L12 13.586l4.293-4.293z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
      <div style={styles.content}>{children}</div>
    </div>
  );
};

export default CollapsibleSection;
