import React from "react";

const TitleField = ({ handleInputChange, fieldValue }) => {
  return (
    <>
      <label
        htmlFor="title"
        className="block text-sm font-medium text-gray-700"
      >
        Title
      </label>
      <input
        type="text"
        name="title"
        id="title"
        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        placeholder="Executive Member"
        value={fieldValue || ""}
        onChange={handleInputChange}
      />
    </>
  );
};

export default TitleField;
