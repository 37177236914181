import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import dummy from "../../assets/media/images/person_three.jpg";
import TaskChatSection from "../../Components/TaskChatSection";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addComment, getSingleTaskDetail } from "../../Redux/TaskSlice";
import moment from "moment";
import Loader from "../../Components/main-loader";
import { error, success } from "../../Utilities/toast";
import { IoCloudDownloadOutline } from "react-icons/io5";

const TaskDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const { singleTask, loading } = useSelector((state) => state.tasks);
  const [queryLength, setqueryLength] = useState("");
  const [query, setQuery] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!query) {
      error("Please enter your comment");
      return;
    }
    dispatch(
      addComment({
        taskID: id,
        comment: query,
        onSuccess: (res) => {
          success(res);
          setQuery("");
          dispatch(getSingleTaskDetail({ taskID: id, onSuccess: () => {} }));
        },
      })
    );
  };

  useEffect(() => {
    if (!user?.organizationId) {
      error("This Section is only for organization users");
      return navigate(-1);
    }
    dispatch(getSingleTaskDetail({ taskID: id, onSuccess: () => {} }));
  }, []);
  return (
    <>
      <Header />
      {loading && <Loader />}
      <div
        className="ic-interview-full-body overflow-y-scroll max-h-screen"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
          paddingTop: "6em",
        }}
      >
        <div className="content pb-5 pt-14 px-5">
          <div className="career_slide-cover mt-10 md:!mt-2">
            <div className="ic-task-detail-start border-0 mt-4">
              <div className="w-100">
                <div className="question-header2 mb-2 w-full">
                  <div className="flex justify-end w-full">
                    <span
                      className={`question-tags4 mr-3 text-white ${
                        singleTask?.task_details?.task_status == "COMPLETED"
                          ? "bg-success"
                          : singleTask?.task_details?.task_status ==
                            "IN_PROGRESS"
                          ? "bg-warning"
                          : "bg-danger"
                      }`}
                    >
                      {singleTask?.task_details?.task_status}
                    </span>
                    <span
                      className={`question-tags4 text-white ${
                        singleTask?.task_details?.priority == "LOW"
                          ? "bg-success"
                          : singleTask?.task_details?.priority == "HIGH"
                          ? "bg-danger"
                          : "bg-warning"
                      }`}
                      title="If is an high piority task needed to handle first."
                    >
                      <i className="ri-error-warning-line"></i>
                      {singleTask?.task_details?.priority}
                    </span>
                  </div>
                </div>

                <div className="d-flex justify-between items-center w-full">
                  <div className="w-full">
                    <div className="w-full">
                      <h2 className="mb-0 text-3xl">
                        {singleTask?.task_details?.title}
                      </h2>
                    </div>
                    <div className="flex my-3">
                      <div className="flex justify-center items-center gap-2">
                        <img
                          src={
                            singleTask?.task_details?.organization_logo || dummy
                          }
                          alt="admin"
                          width={40}
                          height={40}
                          className="rounded-full"
                        />
                        <h2>
                          {singleTask?.task_details?.organization_name}&nbsp;
                        </h2>
                      </div>
                      <div className="flex justify-center items-center font-medium">
                        -&nbsp; Due-Date:{" "}
                        {moment(singleTask?.task_details?.due_date).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </div>
                    <p className="mb-0 text-1xl font-medium mt-2">
                      {singleTask?.task_details?.description}
                    </p>
                    <div className="mt-3">
                      {singleTask?.task_details?.task_file && (
                        <div className="d-flex justify-between">
                          <div>
                            <h1 className="text-2xl">File:</h1>
                            <div className="task_profile-pic">
                              <img
                                src="https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"
                                height={100}
                                width={100}
                              />
                              <a
                                href={singleTask.task_details?.task_file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="task_file-edit">
                                  <IoCloudDownloadOutline
                                    size={23}
                                    color="black"
                                  />
                                </div>
                              </a>
                            </div>
                          </div>
                          {singleTask?.completed_task_attachments[0] && (
                            <div>
                              <h1 className="text-2xl">Attachment:</h1>
                              <div
                                className="bg-white py-2 px-4 rounded-xl mt-2"
                                style={{ width: "400px" }}
                              >
                                <div className="flex my-1 gap-3 ">
                                  <div
                                    className="flex justify-center items-center gap-3"
                                    style={{ width: "25%" }}
                                  >
                                    <img
                                      src={
                                        singleTask
                                          ?.completed_task_attachments[0]
                                          ?.image_url
                                      }
                                      alt="Profile Image"
                                      style={{ width: "70px", height: "70px" }}
                                      className="rounded-full"
                                    />
                                  </div>
                                  <div
                                    className="flex font-medium justify-center flex-col"
                                    style={{ width: "80%" }}
                                  >
                                    <div className="flex items-center">
                                      <h2>
                                        {
                                          singleTask
                                            ?.completed_task_attachments[0]
                                            ?.customer_name
                                        }{" "}
                                        &nbsp;
                                      </h2>{" "}
                                      <span>
                                        -&nbsp;
                                        {moment(
                                          singleTask
                                            ?.completed_task_attachments[0]
                                            ?.created_at
                                        ).format("hh:mm A")}
                                      </span>
                                    </div>
                                    <div>
                                      <div className="task_profile-pic2">
                                        <img
                                          src="https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"
                                          height={50}
                                          width={50}
                                        />
                                        <a
                                          href={
                                            singleTask
                                              ?.completed_task_attachments[0]
                                              ?.task_file
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <div className="task_file-edit2">
                                            <IoCloudDownloadOutline
                                              size={16}
                                              color="black"
                                            />
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="mt-3">
                      {singleTask?.task_details?.link && (
                        <div className="d-flex justify-between">
                          <div>
                            <h1 className="text-2xl mb-2">Link:</h1>
                            <a
                              href={singleTask?.task_details?.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-base mt-3"
                            >
                              {singleTask?.task_details?.link}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <div className="flex justify-between items-center my-2">
                    <h2 className="text-2xl">{queryLength} Comments:</h2>
                  </div>
                  <div>
                    <TaskChatSection setqueryLength={setqueryLength} />
                  </div>
                </div>
                <hr />
                <div className="flex flex-col justify-between gap-5 sm:flex-row sm:items-center sm:gap-2 mt-4">
                  <h4 className="paragraph-semibold text-dark400_light800">
                    leave a comment here:
                  </h4>
                </div>

                <form
                  className="mt-3 flex w-full flex-col gap-10"
                  onSubmit={handleSubmit}
                >
                  <textarea
                    rows={5}
                    className="w-full py-3 px-4 border border-black rounded-2xl"
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                    value={query}
                  />
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className={` py-2 px-6 border-none rounded-lg text-white bg-primary`}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskDetail;
