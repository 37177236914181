import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import Methods from "./data/Methods";
import History from "./data/History";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { error } from "../../Utilities/toast";
import chevronDoubleRightIcon from "../../assets/icons/chevronDoubleRight.svg";

const Payments = ({ type }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("methods");
  const { user } = useSelector((state) => state.authenticate);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleGoBack = () => {
    navigate(-1); // Goes back to the previous route
  };
  useEffect(() => {
    if (user?.organizationId !== null) {
      error("You are not allowed to access this route");
      navigate(-1);
    }
  }, [user]);

  return (
    <>
      {type == "expireSubscription" ? (
        <div
          // style={{ marginTop: "20px" }}
          className="w-full grid-margin stretch-card"
        >
          <div className="card border-rounded-card card-padding-profile2 !overflow-scroll">
            <div className="card-body text-center justify-content-between card-body-mobile">
              <div className="flex justify-content-between items-center">
                <h3 className="text-bold text-capitalize text-xl">
                  Billing {activeTab}
                </h3>
                <ul className="list-reset flex border-b">
                  <li className="-mb-px mr-1">
                    <a
                      className={`bg-white border-b inline-block py-2 px-4 font-semibold  ${
                        activeTab === "methods"
                          ? " border-l border-t border-r rounded-t  text-primary"
                          : "text-dark"
                      }`}
                      href="#"
                      onClick={() => handleTabClick("methods")}
                    >
                      Billing Methods
                    </a>
                  </li>
                  <li className="mr-1">
                    <a
                      className={`bg-white inline-block py-2 px-4 font-semibold ${
                        activeTab === "history"
                          ? " border-l border-t border-r rounded-t text-primary"
                          : "text-dark"
                      }`}
                      href="#"
                      onClick={() => handleTabClick("history")}
                    >
                      Billing History
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                {activeTab === "methods" ? (
                  <>
                    <Methods />
                  </>
                ) : (
                  <History />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Header />

          <div
            className="ic-interview-full-body overflow-hidden"
            style={{
              backgroundImage: `url(${"/images/interview-banner.png"})`,
              marginTop: "6em",
              padding: "1rem",
            }}
          >
            <button
              onClick={handleGoBack}
              className={`mt-2 ml-2 cursor-pointer bg-white rounded-full size-10 p-1 rotate-180`}
            >
              <img src={chevronDoubleRightIcon} alt="" />
            </button>
            <div className="w-full grid-margin stretch-card my-5">
              <div
                className="card border-rounded-card card-padding-profile2 !overflow-scroll"
                style={{ boxShadow: "10px 10px 40px gray" }}
              >
                <div className="card-body text-center justify-content-between card-body-mobile">
                  <div className="flex justify-content-between items-center">
                    <h3 className="text-bold text-capitalize text-xl">
                      Billing {activeTab}
                    </h3>
                    <ul className="list-reset flex border-b">
                      <li className="-mb-px mr-1">
                        <a
                          className={`bg-white border-b inline-block py-2 px-4 font-semibold  ${
                            activeTab === "methods"
                              ? " border-l border-t border-r rounded-t  text-primary"
                              : "text-dark"
                          }`}
                          href="#"
                          onClick={() => handleTabClick("methods")}
                        >
                          Billing Methods
                        </a>
                      </li>
                      <li className="mr-1">
                        <a
                          className={`bg-white inline-block py-2 px-4 font-semibold ${
                            activeTab === "history"
                              ? " border-l border-t border-r rounded-t text-primary"
                              : "text-dark"
                          }`}
                          href="#"
                          onClick={() => handleTabClick("history")}
                        >
                          Billing History
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {activeTab === "methods" ? (
                      <>
                        <Methods />
                      </>
                    ) : (
                      <History />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Payments;
