
import React from 'react';
import CollapsibleSection from './CollapsibleSection';

const Topics = () => {
  const tagStyles = {
    display: 'inline-flex',
    padding: '5px 10px',
    borderRadius: '15px',
    backgroundColor: '#f1f1f1',
    fontSize: '12px',
    color: '#333',
    textDecoration: 'none',
    cursor: 'pointer',
  };

  return (
    <CollapsibleSection
      title="Topics"
      icon={
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="tag"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          style={{ width: '16px', height: '16px' }}
        >
          <path
            fill="currentColor"
            d="M197.5 32c17 0 33.3 6.7 45.3 18.7l176 176c25 25 25 65.5 0 90.5L285.3 450.7c-25 25-65.5 25-90.5 0l-176-176C6.7 262.7 0 246.5 0 229.5V80C0 53.5 21.5 32 48 32H197.5zM48 229.5c0 4.2 1.7 8.3 4.7 11.3l176 176c6.2 6.2 16.4 6.2 22.6 0L384.8 283.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-3-3-7.1-4.7-11.3-4.7H48V229.5zM112 112a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
          ></path>
        </svg>
      }
    >
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        <a href="/tag/hash-table/" style={tagStyles}>
          Hash Table
        </a>
        <a href="/tag/string/" style={tagStyles}>
          String
        </a>
        <a href="/tag/sliding-window/" style={tagStyles}>
          Sliding Window
        </a>
      </div>
    </CollapsibleSection>
  );
};

export default Topics;
