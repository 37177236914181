import React, { useState } from "react";

const QuestionInfo = ({ handleMode, developer, role, setInterviewTimer }) => { 
  // const ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT;
  const [startTimer, setStartTimer] = useState(false);

  const showAll = () => {
    handleMode("all_questions");
  };


  const start = () => {
    developer
      ? handleMode("interview_start_developer")
      : handleMode("interview_start");
  };
  const startWithTimer = () => {
    developer
      ? handleMode("interview_start_developer")
      : handleMode("interview_start");
    setInterviewTimer(true);
  };

  return (
    <div>
      <div
        className="ic-interview-start"
        style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
      >
        <div className="ic-start-interview-content text-center">
          <button
            type="button"
            onClick={() => showAll()}
            className="btn question-all"
            style={{ background: "none", cursor: "pointer" }}
          >
            <img src="/images/question.png" alt="" style={{ width: "auto" }} />{" "}
            See all the questions
          </button>

          {developer ? (
            <>
              {" "}
              <h4>Answer 5 technical questions</h4>
              <p className="interview-info">
                After finishing, go over your responses and gain understanding.
                When you're done, review your answers and discover insights.
              </p>
              <button
                type="button"
                onClick={() => start()}
                className="ic-btn-white"
                style={{ fontWeight: "500", fontSize: "16px" }}
              >
                {" "}
                Start Your Technical Question{" "}
                <i className="ri-file-text-line"></i>
              </button>
              {/* {ENVIORNMENT == "test" && ( */}
              <button
                type="button"
                onClick={() => startWithTimer()}
                className="ic-btn-white"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  marginTop: "20px",
                }}
              >
                {" "}
                Start Your Interview with timer <i className="ri-time-line"></i>
              </button>
              {/* )} */}
            </>
          ) : (
            <>
              {" "}
              <h4>You will Answer 5 {role} questions</h4>
              <p className="interview-info">
                After finishing, go over your responses and gain understanding.
                When you're done, review your answers and discover insights.
              </p>
              {/* {ENVIORNMENT == "test" && ( */}
              <div className="flex justify-center mb-3 items-center">
                <input
                  type="checkbox"
                  checked={startTimer}
                  className="h-4 w-4 accent-green-500 text-white rounded border-gray-300 focus:ring-green-500 "
                  id="timerCheckbox"
                  name="checkbox"
                  onChange={(e) => setStartTimer(!startTimer)}
                />
                <label
                  className="font-light text-sm text-white ml-2"
                  for="timerCheckbox"
                >
                  {" "}
                  Start with Timer <i className="ri-time-line"></i>
                </label>
              </div>
              {/* )} */}
              <button
                type="button"
                onClick={() => (!startTimer ? start() : startWithTimer())}
                className="ic-btn-white"
                style={{ fontWeight: "500", fontSize: "16px" }}
              >
                {" "}
                Start Your Interview <i className="ri-file-text-line"></i>
              </button>
              {/* {ENVIORNMENT == "test" && (
                <button
                  type="button"
                  onClick={() => startWithTimer()}
                  className="ic-btn-white"
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  Start Your Interview with timer{" "}
                  <i className="ri-time-line"></i>
                </button>
              )} */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionInfo;
