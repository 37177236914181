import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/header";
import { Link } from "react-router-dom";
import Loader from "../../Components/main-loader";
import { plans } from "../../DummyData/PricingData";
import { getCurrentUser } from "../../Redux/authenticate/authenticate.action";

const Setting = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.authenticate);
  const [currentBundleData, setCurrentBundleData] = useState(null);

  const findBundle = (plans, bundleId) => {
    console.log(bundleId, "bundleId");
    let bundle = null;

    for (const planType in plans.individual) {
      bundle = plans.individual[planType].find((item) => item.id === bundleId);
      if (bundle) return bundle;
    }
    for (const planType in plans.organization) {
      bundle = plans.organization[planType].find(
        (item) => item.id === bundleId
      );
      if (bundle) return bundle;
    }

    return bundle;
  };

  useEffect(() => {
    const bundle = findBundle(plans, user?.activeBundle);
    console.log(bundle, "bundlebundle");
    setCurrentBundleData(bundle);
    dispatch(getCurrentUser());
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Header />
      <div
        className="ic-interview-full-body"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
          marginTop: "6em",
        }}
      >
        <main
          role="main"
          className="ml-sm-auto col-lg-12 px-4 bg-cover"
          style={{
            backgroundImage: `url(${"/images/interview-banner.png"})`,
          }}
        >
          <div className="pt-3 pb-2 mb-3">
            <h1 className="h2">Settings</h1>
          </div>
          <div className="career_slide-cover">
            <div className="ic-card_container border-0">
              <div className="profile-form_cover">
                <div className="profile-form">
                  <span className="d-flex justify-center align-middle items-center">
                    <b>Current Plan: </b>
                    <p className="ml-1">
                      {currentBundleData
                        ? `You have been subscribed the ${currentBundleData?.name} plan`
                        : user?.organizationName
                        ? `You have been subscribed to organisation plan under
                        organisation ${user?.organizationName}`
                        : "There's no plan subscribed, please subscribe the plan by clicking below"}
                    </p>
                  </span>
                  <div
                    className="flex justify-center items-center w-100 pt-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }} // Flex container to align buttons side by side
                  >
                    <Link
                      to="/pricing"
                      style={{
                        display: "inline-block",
                        backgroundColor: "#007bff", // Primary color
                        color: "#ffffff", // Text color
                        padding: "10px 20px", // Adjust padding for button size
                        borderRadius: "30px", // Curved edges
                        textDecoration: "none", // Remove underline
                        textAlign: "center",
                        fontWeight: "bold", // Bold text
                        transition: "background-color 0.3s ease", // Smooth hover effect
                        width: "140px", // Width for text fit
                        height: "40px", // Match height with Save button
                        lineHeight: "40px", // Center text vertically
                        fontSize: "14px", // Match font size with Save button
                        display: "flex", // Use flexbox for centering
                        alignItems: "center", // Vertically center the text
                        justifyContent: "center", // Horizontally center the text
                      }}
                    >
                      Go to Billing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Setting;
