import React, { useState } from "react";
import ReactModal from "react-modal";
import { useRecoilState } from "recoil";
import { error, success } from "../../Utilities/toast";
import { useDispatch } from "react-redux";
import {
  createAdditionalSection,
  getSingleResume,
} from "../../Redux/ResumeBuilderSlice";
import { useParams } from "react-router-dom";
import TemplatePreviews from "./components/ChooseTemplate";
import Stepper from "./components/TemplateAssets/Stepper";
import ExitConfirmationModal from "./components/TemplateAssets/ExitConfirmationModal";
import {
  defaultState,
  StepStateAtom,
} from "../../recoil/ResumeAdditionalSection/StepsState";
import { IoClose } from "react-icons/io5";
import DeleteConfirmationModal from "./components/TemplateAssets/DeleteConfirmationModal";
import ModifyTemplate from "./components/ModifyTemplate";
import AdditionalModalFooter from "./components/TemplateAssets/AdditionalModalFooter";
import { produce } from "immer";

const AddAdditionalUser = ({
  additionalSectionModal,
  setAdditionalSectionModal,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [stepState, setStepState] = useRecoilState(StepStateAtom);
  const permissionArr = stepState?.selectedTheme?.permissions;
  const submitHeading = stepState?.selectedTheme?.sectionHeading;
  const sectionDetailArr = stepState?.selectedTheme?.sectionDetail;
  const [confirmationModal, setConfirmationModal] = useState(false);

  // ================ Stepper Steps =================
  const steps = [
    { label: "Choose Template", component: <TemplatePreviews /> },
    { label: "Modify Template", component: <ModifyTemplate /> },
  ];

  // ================ Submit Validation =================
  const validateAllFields = () => {
    let hasErrors = false;
    const newErrors = {};
    sectionDetailArr?.forEach((section, index) => {
      Object.keys(section).forEach((fieldKey) => {
        const permission = permissionArr?.[index]?.[fieldKey];
        const value = section[fieldKey];
        if (permission) {
          let error = "";
          if (!value) {
            error = "This field is required.";
            hasErrors = true;
          }
          if (!newErrors[index]) {
            newErrors[index] = {};
          }
          newErrors[index][fieldKey] = error;
        }
      });
    });
    setStepState(
      produce((draft) => {
        draft.formErrors = newErrors;
      })
    );
    return !hasErrors;
  };

  //============ Submit Form ==============
  const handleSubmit = () => {
    if (!submitHeading.trim()) {
      error("Heading is required.");
      return;
    }
    if (!validateAllFields()) {
      return error("Please fill in all required fields.");
    }
    const filteredSections = stepState?.selectedTheme?.sectionDetail?.map(
      (section, index) => {
        return Object.keys(permissionArr[index])
          .filter((key) => permissionArr[index][key])
          .reduce((acc, key) => {
            acc[key] = section[key];
            return acc;
          }, {});
      }
    );
    const payload = {
      heading: submitHeading,
      template_id: stepState?.selectedTheme?.id,
      data: filteredSections,
    };
    dispatch(
      createAdditionalSection({
        payload,
        resumeId: id,
        onSuccess: (res) => {
          if (res?.status_code === 409) {
            error(res?.detail);
          } else {
            success(res?.detail);
            setStepState(defaultState);
            setAdditionalSectionModal(false);
            dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
          }
        },
      })
    );
  };

  return (
    <>
      <ReactModal
        isOpen={additionalSectionModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "55%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "800px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            maxHeight: "75vh",
            overflow: "hidden",
          },
        }}
      >
        <div className="max-h-screen relative">
          <button
            onClick={() => {
              if (stepState?.selectedTheme) {
                setConfirmationModal(true);
              } else {
                setAdditionalSectionModal(false);
              }
            }}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <IoClose size={24} />
          </button>
          <h2>Additional Section</h2>
          <hr />
          <Stepper steps={steps} />
          <div className="h-[calc(75vh-120px)] overflow-y-auto">
            {steps[stepState?.activeStep]?.component}
          </div>
          <AdditionalModalFooter steps={steps} handleSubmit={handleSubmit} />
        </div>
      </ReactModal>

      {/* =============Closing Confirmation Modal============= */}
      <ExitConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        setAdditionalSectionModal={setAdditionalSectionModal}
      />
      {/* =============Delete Section Confirmation Modal============= */}
      <DeleteConfirmationModal />
    </>
  );
};

export default AddAdditionalUser;
