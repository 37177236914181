import React, { useEffect, useState } from "react";
import { cardBrands } from "./paymentCardsData.js";
import AddCardModal from "./AddCardModal.js";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePaymentCard,
  getAllPaymentCards,
  setDefaultPaymentCard,
} from "../../../Redux/PaymentsSlice.js";
import Loader from "../../../Components/main-loader";

const Methods = ({ role }) => {
  const disptach = useDispatch();
  const { paymentCards, paymentLoading } = useSelector(
    (state) => state.paymentMethods
  );
  const [addModal, setAddModal] = useState(false);
  const toggleModal = () => {
    setAddModal(!addModal);
  };

  useEffect(() => {
    if (!role) {
      disptach(getAllPaymentCards());
    }
  }, []);

  if (paymentLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="my-4 px-4 flex justify-between items-center">
        <p className=" text-lg text-dark ">Manage your payment methods</p>
        <button
          className="mx-3 bg-primary px-3 py-2 text-md rounded-md font-medium text-white"
          onClick={toggleModal}
        >
          Add New Card
        </button>
      </div>
      {paymentCards?.length > 0 ? (
        paymentCards?.map((item) => {
          return (
            <div className="flex justify-between items-center p-4 rounded-md border mb-2">
              <div className="flex items-center">
                <img
                  src={
                    cardBrands.find((brand) => brand?.brand === item?.brand)
                      ?.img
                  }
                  className="w-[50px] h-[40px] rounded mr-4"
                />
                <p>
                  {" "}
                  ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                  {item.last4}
                </p>
              </div>
              <div>
                {item?.isDefault ? (
                  <span className="inline-flex mx-3 items-center rounded-md bg-green-50 px-2 py-1 text-md font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Default
                  </span>
                ) : (
                  <button
                    className="mx-3 bg-primary px-3 py-2 text-md rounded-md font-medium text-white"
                    onClick={() =>
                      disptach(setDefaultPaymentCard(item.id))
                        .unwrap()
                        .then(() => disptach(getAllPaymentCards()))
                        .catch((err) => console.error(err))
                    }
                  >
                    Make Default
                  </button>
                )}
                {paymentCards?.length > 1 && (
                  <i
                    className="ri-delete-bin-7-fill cursor-pointer text-lg"
                    style={{ color: "#BA0707" }}
                    onClick={() =>
                      disptach(deletePaymentCard(item.id))
                        .unwrap()
                        .then(() => disptach(getAllPaymentCards()))
                        .catch((err) => console.error(err))
                    }
                  />
                )}
              </div>
            </div>
          );
        })
      ) : (
        <p className="text-center text-lg text-dark my-6">
          No payment methods found
        </p>
      )}
      <AddCardModal addModal={addModal} toggleModal={toggleModal} />
    </>
  );
};

export default Methods;
