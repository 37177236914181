import React, { useState } from "react";
import { MdDownload, MdEdit, MdOutlineDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoIosStats } from "react-icons/io";
import { error, success, warning } from "../../Utilities/toast";
import { MyDocument } from "./AiGeneration";
import { pdf } from "@react-pdf/renderer";
import ReactModal from "react-modal";
import { SaveExitIcon } from "./svgimages";
import { addResumeJob, getResume } from "../../Redux/ResumeBuilderSlice";
import { useDispatch } from "react-redux";

const ResumePreview = ({
  item,
  handleResumeClick,
  handleDelete,
  handleGetFeedback,
}) => {
  console.log(item, "itembycodebyahsan");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let {
    contact,
    blurbs,
    certifications,
    categories,
    work_experience,
    educations,
    projects,
    publications,
    interests,
    soft_skills,
    languages,
    skills,
  } = item;
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedResume, setSelectedResume] = useState("");
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  const reset = () => {
    setName("");
    setTitle("");
    setDescription("");
    setSelectedResume("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const payload = {
        name,
        title,
        description,
      };
      dispatch(
        addResumeJob({
          jobID: selectedResume,
          payload,
        })
      )
        .unwrap()
        .then((res) => {
          success(res.detail);
          setModal(false);
          reset();
          dispatch(getResume({ onSuccess: () => {} }));
        });
    } catch (errors) {
      error(errors.message);
    }
  };
  const handleDownload = async () => {
    const isValidField = (field) => {
      return (
        field !== null &&
        field !== undefined &&
        (!Array.isArray(field) || field.length > 0)
      );
    };

    let resume = {};

    resume = {
      // professionalSummaries: blurbs,
      // workExperience: work_experience,
      // educations,
      // projects,
      // certificates: certifications,
      // profile: contact,
      // // categories,
      // publications,
      // interests: interests[0],
      // softskills: soft_skills[0],
      // languages: languages[0],

      ...(isValidField(blurbs) && { professionalSummaries: blurbs }),
      ...(isValidField(work_experience) && { workExperience: work_experience }),
      ...(isValidField(educations) && { educations }),
      ...(isValidField(projects) && { projects }),
      ...(isValidField(certifications) && { certificates: certifications }),
      ...(isValidField(contact) && { profile: contact }),
      ...(isValidField(skills) && { categories: skills }),
      ...(isValidField(publications) && { publications }),
      ...(isValidField(interests) && { interests: interests[0] }),
      ...(isValidField(soft_skills) && { softskills: soft_skills[0] }),
      ...(isValidField(languages) && { languages: languages[0] }),
    };

    const doc = <MyDocument AiResume={resume} />;
    // Create the document component with data

    // Generate the PDF blob
    const pdfBlob = await pdf(doc).toBlob();

    // Create a download link and trigger it programmatically
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = `${
      item?.resume_name ? item?.resume_name : item?.person_name
    }.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the link after download
  };
  return (
    <>
      <div className="w-[300px] min-h-[300px] mt-1">
        <div
          className={`cv-child-container-preview pt-0 px-0 bg-white shadow-lg rounded-lg h-full overflow-hidden   relative`}
        >
          <div
            role="button"
            onClick={() => navigate(`/resume-builder/view/${item?.id}`)}
            className="px-[15px] pt-[15px] pb-[15px] border-bottom h-full cursor-pointer"
          >
            {contact && (
              <header className="cv-header-preview">
                <p className="cv-name-preview text-uppercase">
                  {contact?.first_name} {contact?.last_name}
                </p>
                <p className="cv-email-preview  text-center">
                  {contact?.email && `${contact.email}`}
                  {contact?.email &&
                    (contact?.address ||
                      contact?.city ||
                      contact?.phone ||
                      contact?.linked_in ||
                      contact?.website) &&
                    " | "}

                  {(contact?.address || contact?.city) &&
                    `${contact?.address ?? ""}${
                      contact?.address && contact?.city ? ", " : ""
                    }${contact?.city ?? ""}`}
                  {(contact?.address || contact?.city) &&
                    (contact?.phone ||
                      contact?.linked_in ||
                      contact?.website) &&
                    " | "}

                  {contact?.phone && `${contact.phone}`}
                  {contact?.phone &&
                    (contact?.linked_in || contact?.website) &&
                    " | "}

                  {contact?.linked_in && `${contact.linked_in}`}
                  {contact?.linked_in && contact?.website && " | "}

                  {contact?.website && `${contact.website}`}
                </p>
              </header>
            )}
            {blurbs && blurbs.length > 0 && (
              <section className="cv-professional_summary-preview">
                <h2 className="cv-section-title-preview">
                  Professional Summary
                </h2>
                {blurbs?.map((item, index) => (
                  <div
                    key={index}
                    className="cv-professional_summary-item-preview mb-0"
                  >
                    <p dangerouslySetInnerHTML={{ __html: item?.text }} />
                  </div>
                ))}
              </section>
            )}
            {certifications && certifications?.length > 0 && (
              <section className="cv-certifications-preview">
                <h2 className="cv-section-title-preview">Certifications</h2>

                <ul className="cv-skills-list-preview d-flex justify-content-start">
                  {certifications?.map((certificate, index) => (
                    <li className="cv-skill_top-preview" key={index}>
                      <span className="font-bold text-xs">•</span>{" "}
                      {certificate?.name}
                    </li>
                  ))}
                </ul>

                {/* {certifications.map((certification, index) => (
                <div
                  className="cv-certification-item-preview d-flex justify-content-between align-items-center"
                  key={index}
                >
                  <div>
                    <h3 className="cv-item-title-preview mb-0">
                      {certification.name}
                    </h3>
                    <p className="cv-item-description-preview text-capitalize">
                      {certification?.provider}
                    </p>
                  </div>
                  <p className="cv-item-date-preview">
                    {formatDate(certification?.start_date)} -{" "}
                    {formatDate(certification?.end_date)}
                  </p>
                </div>
              ))} */}

                {/* Add more certification items as needed */}
              </section>
            )}
            {skills && skills?.find((item) => item.name == "Top Skills") && (
              <section className="cv-skills">
                <h2 className="cv-section-title-preview">Top Skills</h2>
                {skills?.map((category, index) => (
                  <React.Fragment key={index}>
                    {category.name == "Top Skills" ? (
                      <>
                        <ul className="cv-skills-list-preview">
                          {category?.skills &&
                            category?.skills.map((skill, index) => (
                              <li className="cv-skill_top-preview" key={index}>
                                <span className="font-bold text-xs">•</span>{" "}
                                {skill?.name}
                              </li>
                            ))}
                        </ul>
                      </>
                    ) : (
                      <>
                        <ul className="cv-skills-list">
                          {category?.skills &&
                            category?.skills.map((skill, index) => (
                              <li className="cv-skill" key={index}>
                                {skill?.name}
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </section>
            )}
            {work_experience && work_experience?.length > 0 && (
              <section className="cv-professional_summary">
                <h2 className="cv-section-title-preview">Work Experiences</h2>
                {work_experience?.map((experience, index) => (
                  <>
                    <div
                      key={index}
                      className="cv-professional_summary-item-preview d-flex justify-content-between align-items-center"
                    >
                      <div className="text-capitalize">
                        <h3 className="cv-item-title-preview mb-0">
                          {experience?.company?.name}
                        </h3>
                        {experience?.roles?.map((item, index) => (
                          <>
                            <p className="cv-item-date mb-0">{item?.name}</p>
                          </>
                        ))}
                      </div>
                      <div className="text-capitalize">
                        {experience?.roles?.map((item, index) => (
                          <>
                            <p className="cv-item-date">
                              {formatDate(item?.started_at)}
                              {item?.ended_at == null
                                ? " - present"
                                : `${""} - ${formatDate(item?.ended_at)}`}
                            </p>
                          </>
                        ))}
                      </div>
                    </div>
                    {/* <div>
                    <p
                      className="cv-item-date"
                      dangerouslySetInnerHTML={{
                        __html: experience?.company?.description,
                      }}
                    />
                  </div> */}
                  </>
                ))}
              </section>
            )}
            {educations && educations?.length > 0 && (
              <section className="cv-professional_summary-preview -mt-1">
                <h2 className="cv-section-title-preview">Educations</h2>
                {educations.map((education, index) => (
                  <div
                    key={index}
                    className="cv-professional_summary-item-preview d-flex align-items-center justify-content-between"
                  >
                    <div>
                      <h3 className="cv-item-title-preview m-0">
                        {education.school_name}
                      </h3>
                      <p className="cv-item-date-preview m-0">
                        {education.degree &&
                          education.degree + " Degree " + " | "}
                        {education?.field && education?.field}
                      </p>
                    </div>
                    <div>
                      <p className="cv-item-date-preview text-capitalize">
                        {education?.ended_at == null
                          ? " present"
                          : `${formatDate(education?.ended_at)}`}
                      </p>
                    </div>
                  </div>
                ))}
              </section>
            )}

            {projects && projects?.length > 0 && (
              <section className="cv-projects">
                <h2 className="cv-section-title-preview">Projects</h2>
                {projects?.map((pro, index) => (
                  <div className="cv-project-item" key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h3 className="cv-item-title-preview mb-0">
                          {pro?.project_name}
                        </h3>
                        <p className="cv-item-title-preview">
                          {pro?.organization}
                        </p>
                      </div>
                      <p className="cv-item-date-preview">
                        {formatDate(pro?.start_date)} -{" "}
                        {formatDate(pro?.end_date)}
                      </p>
                    </div>
                    <div className="cv-professional_summary-item-preview">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: pro?.additional_information,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </section>
            )}

            {publications && publications?.length > 0 && (
              <section className="cv-certifications">
                <h2 className="cv-section-title-preview">Publications</h2>

                {publications.map((publication, index) => {
                  // const timestamp = publication?.date;
                  // console.log(timestamp, "timestamp>>>>>>>>>>>>>>>");
                  // const formattedDateTime = moment(timestamp).format("DD/MM/YYYY ");
                  // console.log(formattedDateTime, "formattedDateTime>>>>>>>>>>>");
                  return (
                    <div
                      className="cv-publication-item d-flex justify-content-between align-items-center"
                      key={index}
                    >
                      <div>
                        <h3 className="cv-item-title-preview mb-0">
                          {publication.title}
                        </h3>
                        <p className="cv-item-date-preview">
                          {publication.publisher}
                        </p>
                      </div>
                      <p className="cv-item-description-preview">
                        {formatDate(publication?.date)}
                      </p>
                    </div>
                  );
                })}

                {/* Add more certification items as needed */}
              </section>
            )}
            {interests?.[0]?.interests?.length > 0 && (
              <section className="cv-interests">
                <h2 className="cv-section-title-preview">Interests</h2>
                <ul className="cv-skills-list-preview">
                  {interests?.[0]?.interests &&
                    interests?.[0]?.interests.map((interest, index) => (
                      <li className="cv-skill_top-preview" key={index}>
                        <span className="font-bold text-xs">•</span> {interest}
                      </li>
                    ))}
                </ul>
              </section>
            )}
            {soft_skills?.[0]?.skills?.length > 0 && (
              <section className="cv-languages">
                <h2 className="cv-section-title-preview">Soft Skills</h2>
                <ul className="cv-skills-list-preview">
                  {soft_skills?.[0]?.skills &&
                    soft_skills?.[0]?.skills?.map((skill, index) => (
                      <li className="cv-skill_top-preview" key={index}>
                        <span className="font-bold text-xs">•</span> {skill}
                      </li>
                    ))}
                </ul>
              </section>
            )}
            {languages?.[0]?.languages?.length > 0 && (
              <section className="cv-languages">
                <h2 className="cv-section-title-preview">Languages</h2>
                <ul className="cv-skills-list-preview">
                  {languages?.[0]?.languages &&
                    languages?.[0]?.languages.map((language, index) => (
                      <li className="cv-skill_top-preview" key={index}>
                        <span className="font-bold text-xs">•</span> {language}
                      </li>
                    ))}
                </ul>
              </section>
            )}
          </div>

          <div
            style={{
              textAlign: "center",
              position: "absolute",
              left: "50%",
              bottom: "10px",
              transform: "translateX(-50%)",
            }}
            className="w-max"
          >
            <h2 className="name leading-none mb-1 line-clamp-1">
              {item?.resume_name
                ? `${item.resume_name}${
                    item.company_name ? ` - ${item.company_name}` : ""
                  }`
                : `${contact?.first_name} ${contact?.last_name}`}
            </h2>
            <p className="text-center text-md leading-none">
              {item?.iterations}
            </p>
            <div>
              <button
                onClick={() => handleResumeClick(item.id)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  color: "blue",
                }}
                title="Edit Item"
              >
                <MdEdit style={{ fontSize: "15px", color: "#2B6BE2" }} />
              </button>
              <button>
                <MdOutlineDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(item.id);
                  }}
                  style={{
                    marginLeft: "8px",
                    marginTop: "4px",
                    cursor: "pointer",
                    color: "red",
                    fontSize: "15px",
                  }}
                  title="Delete Resume"
                />
              </button>
              {/* {item?.isCompleted && ( */}
              <button
                onClick={(e) => {
                  if (!item?.isCompleted) {
                    const missingSections = [
                      !contact && "personal information",
                      blurbs?.length == 0 && "profile summary",
                      educations?.length == 0 && "education",
                      work_experience?.length == 0 && "work experience",
                    ].filter(Boolean);
                    if (missingSections.length) {
                      warning(
                        `Complete the ${missingSections.join(
                          ", "
                        )} to get feedback.`
                      );
                    } else {
                      warning(
                        "Job description is mandatory for get the feedback"
                      );
                      setModal(true);
                      setSelectedResume(item.id);
                    }
                  } else {
                    e.stopPropagation();
                    handleGetFeedback(item.id);
                  }
                }}
                // disabled={!item?.isCompleted}
                color="success"
                className={`text-success
               cursor-pointer
              `}
              >
                <IoIosStats
                  style={{
                    marginLeft: "8px",
                    marginTop: "4px",
                    // color: "lightgreen",
                    fontSize: "15px",
                  }}
                  title="Get Feedback"
                />
              </button>
              <button>
                <MdDownload
                  onClick={(e) => {
                    if (!item?.isCompleted) {
                      const missingSections = [
                        !contact && "personal information",
                        blurbs?.length == 0 && "profile summary",
                        educations?.length == 0 && "education",
                        work_experience?.length == 0 && "work experience",
                      ].filter(Boolean);
                      if (missingSections.length) {
                        warning(
                          `Complete the ${missingSections.join(
                            ", "
                          )} to get download document`
                        );
                      }
                    } else {
                      e.stopPropagation();
                      handleDownload();
                    }
                  }}
                  style={{
                    marginLeft: "8px",
                    marginTop: "4px",
                    cursor: "pointer",
                    color: "blue",
                    fontSize: "15px",
                  }}
                  title="Download Resume"
                />
              </button>
              {/* )} */}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <p className="p-0 mb-0">
          Upload the Job Description You Would Like Us To Enhance Your Resume
          For
        </p>
        <hr />
        <br />
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="d-flex flex-column mb-1">
            <div className="form-group d-flex justify-content-between mb-3">
              <div style={{ width: "45%" }}>
                <label htmlFor="companyname">Company Name</label>
                <input
                  type="text"
                  id="companyname"
                  className="form-control"
                  placeholder=""
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div style={{ width: "45%" }}>
                <label htmlFor="jobtitle">Job Title</label>
                <input
                  type="text"
                  id="jobtitle"
                  className="form-control"
                  placeholder=""
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group w-100">
              <label htmlFor="jobdescription">Job Description</label>
              <textarea
                id="jobdescription"
                className="workcontrol"
                placeholder=""
                style={{ height: "150px" }}
                value={description}
                required
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center py-2 px-2">
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "150px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <SaveExitIcon></SaveExitIcon>
              Save
            </button>
            <button
              type="button"
              className="rounded-xl text-white bg-red-600 mx-2"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "150px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
              }}
              onClick={() => setModal(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </ReactModal>
    </>
  );
};

export default ResumePreview;
