import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdDone, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getResumeEducation,
  udpateResumeEducation,
} from "../../../Redux/ResumeBuilderSlice";
import { success } from "../../../Utilities/toast";

export default function Educations({ educations, getSingleResumeDoc }) {
  console.log("educations: ", educations);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeField, setActiveField] = useState({ index: null, field: null });
  const [hoveredField, setHoveredField] = useState({
    index: null,
    field: null,
  });
  const [educationsState, setEducationsState] = useState(educations || []);
  console.log("educationsState: ", educationsState);
  const [datePickerOpen, setDatePickerOpen] = useState(null);
  const editableRefs = useRef({});

  const iconStyle = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  const handleFieldClick = (index, field) => {
    if (activeField.index !== null) submitChanges();
    setActiveField({ index, field });
    if (field === "ended_at") setDatePickerOpen(index);
    setTimeout(() => {
      editableRefs.current[`${index}-${field}`]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, field, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[`${index}-${field}`];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setEducationsState((prev) =>
        prev.map((pub, idx) =>
          idx === index ? { ...pub, [field]: value } : pub
        )
      );
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    }
  };

  const handleDateChange = (index, field, selectedDate) => {
    const date = new Date(selectedDate); // Local date
    date.setHours(19, 0, 0, 0); // Set the time to 19:00:00 (local time)
    const formattedDate = date.toISOString();
    setEducationsState((prev) =>
      prev.map((pub, idx) =>
        idx === index ? { ...pub, [field]: formattedDate } : pub
      )
    );
  };
  const getEducationInfo = () => {
    dispatch(getResumeEducation({ resumeId: id, onSuccess: () => {} }));
  };
  const submitChanges = () => {
    const originalEducation = educations[activeField.index];
    const updatedEducation = educationsState[activeField.index];
    const hasChanges =
      originalEducation[activeField.field]?.trim() !==
      updatedEducation[activeField.field]?.trim();

    if (hasChanges) {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
      const payload = {
        updateDocId: updatedEducation?.id,
        data: updatedEducation,
      };
      dispatch(
        udpateResumeEducation({
          payload,
          onSuccess: (res) => {
            success(res);
            getEducationInfo();
          },
        })
      );
    } else {
      setActiveField({ index: null, field: null });
      setHoveredField({ index: null, field: null });
      setDatePickerOpen(null);
    }
  };

  const renderField = (education, index, field) => {
    const isActive = activeField.index === index && activeField.field === field;
    const isHovered =
      hoveredField?.index === index && hoveredField?.field === field;

    const originalEducation = educations[index];
    const updatedEducation = educationsState[index];

    const hasChanges =
      originalEducation[field]?.trim() !== updatedEducation[field]?.trim();

    return (
      <span
        className={`editable-field ${isActive || isHovered ? "active" : ""}`}
        onMouseEnter={() => setHoveredField({ index, field })}
        onMouseLeave={() => setHoveredField({ index: null, field: null })}
        style={{
          position: "relative",
          border: isActive || isHovered ? "1px solid #ffff" : "none",
          backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={isActive}
          ref={(el) => (editableRefs.current[`${index}-${field}`] = el)}
          onInput={(e) => handleFieldInput(index, field, e)}
          onBlur={activeField.field != "ended_at" ? submitChanges : ""}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
          className={`editable-field`}
        >
          {field === "ended_at" && datePickerOpen === index ? (
            <input
              type="date"
              id="start"
              name="trip-start"
              value={
                education && !isNaN(new Date(education).getTime())
                  ? new Date(education).toLocaleDateString("en-CA") // Converts to 'YYYY-MM-DD' format
                  : ""
              }
              onChange={(e) => handleDateChange(index, field, e.target.value)}
            />
          ) : (
            education
          )}
        </span>
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...iconStyle, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && (
          <MdClose
            onClick={() => {
              submitChanges();
            }}
            style={{ ...iconStyle, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index, field)}
            style={{ ...iconStyle, right: "-20px", background: "#2b6be2" }}
          />
        )}
      </span>
    );
  };

  useEffect(() => {
    if (educations?.length > 0) setEducationsState(educations);
  }, [educations]);
  return (
    educationsState &&
    educationsState?.length > 0 && (
      <section className="cv-professional_summary">
        {educationsState?.map((education, index) => (
          <>
            <div
              key={index}
              className="cv-professional_summary-item d-flex mt-2 align-items-center justify-content-between"
            >
              <div>
                <h5
                  className="cv-item-title text-md  mb-0 apply-font"
                  style={{
                    color: `${
                      getSingleResumeDoc?.subheading_color
                        ? getSingleResumeDoc?.subheading_color
                        : "#000"
                    }`,
                  }}
                >
                  {renderField(
                    education.school_name && education?.school_name,
                    index,
                    "school_name"
                  )}
                </h5>
                <p className="cv-item-date apply-font mt-2">
                  {renderField(education?.degree, index, "degree")} {"Degree |"}{" "}
                  {renderField(education?.field, index, "field")}
                </p>
              </div>
              <div>
                <p className="cv-item-date text-capitalize apply-font">
                  {renderField(
                    formatDate(education?.ended_at),
                    index,
                    "ended_at"
                  )}
                </p>
              </div>
            </div>
            <hr />
          </>
        ))}
      </section>
    )
  );
}
