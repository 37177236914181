import ReactModal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";

const GoogleJobAppreciationModal = ({ isOpen, onClose }) => {
  const [appreciationMsg, setAppreciationMsg] = useState(false);
  return (
    <ReactModal
      isOpen={isOpen}
      // onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0px",
          maxWidth: "500px",
          width: "90%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <div>
        <div className="flex border-b border-gray-300 py-3 px-4 w-full justify-end">
          <IoMdClose onClick={onClose} className="cursor-pointer" />
        </div>
        {appreciationMsg ? (
          <div className="flex flex-col py-3 px-4 w-full">
            <p className="pb-2">
              <span className="text-[#2B6BE2] text-lg">Thank you</span> for
              using Nexa IQ to apply for your next big opportunity! Your
              application has been submitted, and we’re rooting for your
              success.
            </p>
            <p className="pb-2">
              Keep an eye on your dashboard for updates, and remember—you’re
              building something amazing, one step at a time.
            </p>
            <p className="pb-2">Good luck!</p>
            <p className="pb-2">The Nexa Team</p>
          </div>
        ) : (
          <div className="flex flex-col py-3 px-4 w-full">
            <p>Have you successfully applied for this job?</p>
            <div className="flex gap-3 w-full pt-3 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="modal-button cancel-button"
              >
                No
              </button>

              <button
                type="button"
                onClick={() => setAppreciationMsg(true)}
                className="modal-button save-button"
              >
                Yes
              </button>
            </div>
          </div>
        )}
      </div>
    </ReactModal>
  );
};

export default GoogleJobAppreciationModal;
