import React, { useState } from "react";
import { error } from "../../../Utilities/toast";
import questionType from "../../../DummyData/questionType.json";
import { useDispatch } from "react-redux";
import { generateQuestions } from "../../../Redux/DashboardSlice";
import BackButton from "../../../Components/BackButton";

const QuestionType = ({ 
  roleCategoryId,
  handleMode,
  setSelectedQuestionType,
  developer,
  setQuestions,
  setInterviewTrainingSessionId,
  setLoader,
  title,
  setInterviewTimer,
}) => {
  // const ENVOIRMENT = process.env.REACT_APP_ENVIRONMENT;
  const dispatch = useDispatch();
  const [interviewType, setInterviewType] = useState(false);
  const [showSelectQuestion, setShowSelectQuestion] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [inputs, setInputs] = useState({
    technical: 1, // default minimum value
    situational: 1,
    background: 1,
  });
  console.log(developer);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleInterviewType = () => {
    console.log({ interviewType });
    setInterviewType(!interviewType);
  };

  const handleSubmit = (type) => {
    setLoader(true);

    const total =
      Number(inputs.technical) +
      Number(inputs.situational) +
      Number(inputs.background);
    if (total !== 5) {
      error("A total of 5 questions should be selected");
    } else {
      let payload = {
        roleCategoryId,
        questionCount: total,
        weightage: [
          { questionTypeId: "2lNbMnPO8W", count: inputs.technical },
          { questionTypeId: "BvWb83Dkgr", count: inputs.situational },
          { questionTypeId: "ey3PX9En4R", count: inputs.background },
        ],
      };
      if (type == "timer") {
        setInterviewTimer(true);
      }
      dispatch(generateQuestions(payload))
        .unwrap()
        .then((resolvedData) => {
          setQuestions(resolvedData?.questions);
          setInterviewTrainingSessionId(
            resolvedData?.interviewTrainingSessionId
          );
          setLoader(false);
          handleMode("interview_start");
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error("Register failed", rejectedValueOrSerializedError);
        });
    }

    setLoader(false);
  };

  const onQuestionTypeSelect = (name) => {
    console.log(name, "selected Question NAme");
    setSelectedQuestionType(name);  
    if (name === "Weighted") {
      setShowSelectQuestion(true);
    } else {
      setShowSelectQuestion(false);
      developer
        ? handleMode("question_info_developer")
        : handleMode("question_info");
    }
  };

  return (
    <div className="ic-interviews">
      <div className="container">
        <div className="ic-interview-container">
          <h3 className="text-center text-2xl">
            Which kind of category are you interested in practicing?
          </h3>
          <h6 className="text-center px-5 mt-3 text-neutral-500">
            {" "}
            {title ||
              `The most realistic questions will always fall under three main categories: Technical, Behavioral and Scenario Based. Here you can have us pick a bunch randomly for you or you can use the custom option to create your own practice set. Enjoy!`}
          </h6>

          <div className="ic-interview-card-container">
            {questionType.map((item, index) => (
              <button
                key={index}
                onClick={() => onQuestionTypeSelect(item.name)}
                className={`ic-interview-items ${
                  item.name === "Weighted" && showSelectQuestion == true
                    ? "active"
                    : ""
                }`}
              >
                <div className="ic-icons">
                  <img
                    src={`/images/${item.icon}`}
                    className="img-fluid"
                    alt={item.label}
                    style={{ width: "36px", height: "36px" }}
                  />
                </div>
                <p>{item.label}</p>
              </button>
            ))}
          </div>

          {showSelectQuestion && (
            <div className="pre-defined">
              <form className="text-center">
                <div className="input-group mb-3 d-flex">
                  <span className="input-group-text">Technical Question</span>
                  <input
                    type="number"
                    min={1}
                    max={5}
                    required
                    className="form-control"
                    name="technical"
                    value={inputs.technical}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group mb-3 d-flex">
                  <span className="input-group-text">Situational Question</span>
                  <input
                    type="number"
                    min={1}
                    max={5}
                    required
                    className="form-control"
                    name="situational"
                    value={inputs.situational}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group d-flex">
                  <span className="input-group-text">Background Question</span>
                  <input
                    type="number"
                    min={1}
                    max={5}
                    required
                    className="form-control"
                    name="background"
                    value={inputs.background}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-text text-info">
                  Note: Five questions can be generated in total.
                </div>

                {/* <button
                  type="submit"
                  className={`ic_arrows_line ${interviewType ? "d-none" : ""}`}
                  disabled={processing}
                  onClick={(e) => {
                    // if (ENVOIRMENT !== "test") {
                    //   e.preventDefault();
                    //   handleSubmit("test");
                    // } else {
                    e.preventDefault();
                    handleInterviewType();
                    // }
                  }}
                >
                  <i className="ri-arrow-right-line"></i>
                </button> */}
                {/* {ENVOIRMENT == "test" && interviewType && (
                  <> */}
                <div className="my-4 flex justify-between items-center gap-3">
                  <div className="flex justify-center items-center">
                    <input
                      type="checkbox"
                      checked={startTimer}
                      className="h-4 w-4 accent-green-500 text-white rounded border-gray-300 focus:ring-green-500 "
                      id="timerCheckbox"
                      name="checkbox"
                      value="1"
                      onChange={(e) => setStartTimer(!startTimer)}
                    />
                    <label
                      className="font-light text-md text-black ml-2"
                      for="timerCheckbox"
                    >
                      {" "}
                      Start with Timer <i className="ri-time-line"></i>
                    </label>
                  </div>
                  {/* <div className="">
                    <input
                      type="checkbox"
                      checked={startTimer}
                      id="timerCheckbox"
                      name="checkbox"
                      value="1"
                      onChange={(e) => setStartTimer(!startTimer)}
                    />
                    <label for="timerCheckbox"> Start with Timer</label>
                  </div> */}
                  <div className="flex my-4">
                    <button
                      type="button"
                      onClick={() =>
                        startTimer
                          ? handleSubmit("timer")
                          : handleSubmit("noTimer")
                      }
                      className="ic-btn"
                      style={{ fontWeight: "500", fontSize: "16px" }}
                    >
                      Start Interview
                      <i className="ri-file-text-line"></i>
                    </button>
                    {/* <button
                        type="button"
                        onClick={() => handleSubmit("timer")}
                        className="ic-btn mx-3"
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Start with Timer <i className="ri-time-line"></i>
                      </button> */}
                  </div>
                </div>
                {/* </>
                )} */}
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionType;
