import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addResumeInterests,
  getResumeInterests,
  updateResumeInterests,
} from "../../Redux/ResumeBuilderSlice";
import { success, error } from "../../Utilities/toast";

const InterestForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { interests } = useSelector((state) => state.ResumeBuilder);
  const [Interests, setInterests] = useState([]);
  const [interestId, setInterestId] = useState("");
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  useEffect(() => {
    if (interests?.interests?.length > 0 || interests?.id) {
      setInterests(interests?.interests);
      setInterestId(interests?.id);
    }
  }, [interests]);
  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setInterests([...Interests, value]);
    e.target.value = "";
    setShowPlaceholder(false);
  };

  const handleRemoveChip = (index) => {
    setInterests(Interests.filter((_, i) => i !== index));
  };
  const fetchInterests = () => {
    const resumeId = id;
    dispatch(getResumeInterests({ resumeId, onSuccess: () => {} }));
  };

  const update = () => {
    const inputField = document.querySelector(".tags-input");
    const value = inputField.value.trim();
    if (value) {
      setInterests([...Interests, value]);
      inputField.value = "";
    }
    // if (Interests.length == 0 && !value) {
    //   error("Please add atleast one interest and press enter to add");
    //   return;
    // }
    const payload = {
      interests: [...Interests, value].filter(Boolean),
    };
    if (interestId) {
      dispatch(
        updateResumeInterests({
          interestId,
          payload,
          onSuccess: (res) => {
              success(res);
            fetchInterests();
          },
        })
      );
    } else {
      const resumeId = id;
      dispatch(
        addResumeInterests({
          resumeId,
          payload,
          onSuccess: (res) => {
            if (Interests.length > 0) {
              success(res);
            }
            fetchInterests();
          },
        })
      );
    }
  };
  return (
    <div className="mydetails">
      <div className="row mt-4 pb-4">
        <div className="col-md-12">
          <div
            className="tags-input-container"
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {Interests?.length > 0 &&
              Interests?.map((chip, index) => (
                <div
                  key={index}
                  className="chip"
                  style={{
                    margin: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {chip}
                  <button
                    className="remove-button"
                    //   style={{ marginLeft: '5px' }}
                    onClick={() => handleRemoveChip(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            <input
              type="text"
              className="tags-input"
              onKeyDown={handleKeyDown}
              placeholder={
                Interests?.length === 0 ? "Type Interest and press Enter" : ""
              }
              style={{ flex: "1", margin: "5px", minWidth: "150px" }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end  py-2 px-2   border-bottom border-primary  ">
        <button
          onClick={() => update()}
          className="save-btn mr-1"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            width: "100px",
            height: "40px",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default InterestForm;
