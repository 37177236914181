

import "../../assets/CSS/index.css";
export const WavesBackground = () => {
  return (
    <div className="feather-background">
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
    </div>
  );
};
