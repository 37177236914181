import React, { useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RichTextEditor = ({ setEditorContent, defaultContent }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML(state); // Immediate update
  };

  useEffect(() => {
    if (defaultContent) {
      const blocksFromHtml = htmlToDraft(defaultContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [defaultContent]);

  const convertContentToHTML = (state) => {
    const currentContentAsHTML = convertToHTML(state.getCurrentContent());
    setEditorContent(currentContentAsHTML);
  };

  return (
    <Editor
      editorState={editorState}
      editorStyle={{
        padding: "2px 8px",
        height: "100%",
      }}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "history",
        ],
        inline: {
          inDropdown: false,
          options: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "monospace",
          ],
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
            "Barlow",
          ],
        },
      }}
      onEditorStateChange={handleEditorChange}
    />
  );
};

export default RichTextEditor;
