import React from "react";
import blueTick from "../../assets/icons/blueTick.svg";

const PricingCard = ({ plan, getPricingButton, planType }) => {
  console.log("price>>", plan);
  return (
    <div className="pricing-plan-card">
      {plan?.name === "Nexa Career" && (
        <div className="price-overlay-div">20% off</div>
      )}

      <div className="top-section">
        <h2>{plan.name}</h2>
        <p>{plan.title}</p>

        <p className="price-des mt-8">{plan?.description}</p>
      </div>

      <div className="description-section">
        {plan.name === "Nexa Career" && (
          <h3
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              fontStyle: "italic",
              fontSize: "1.2em",
            }}
          >
            Starting At
          </h3>
        )}
        <div className="price">
          {plan.price !== "Custom" && <p className="dollar">$</p>}
          {plan.price !== "Custom Pricing" ? (
            <h2>{plan.price}</h2>
          ) : (
            <h4>{plan.price}</h4>
          )}
        </div>
        {/* <div className="div-box">User/{planType}</div> */}
        <div className="pricing-content" style={{ paddingBottom: 150 }}>
          {plan.features.map((item, i) => {
            console.log("item>>>", item?.split(":"));

            if (item?.split(":")?.length === 2) {
              return (
                <div key={i}>
                  <span className="mb-2 font-bold block">
                    {item?.split(":")?.[0]}
                  </span>
                  <div className="item">
                    <div className="blue-tick-icon-wrapper">
                      <img src={blueTick} alt="icon" />
                    </div>

                    <p className="flex-1">{item?.split(":")?.[1]}</p>
                  </div>
                </div>
              );
            }
            return (
              <div key={i} className="item">
                <div className="blue-tick-icon-wrapper">
                  <img src={blueTick} alt="icon" />
                </div>
                <p className="flex-1">{item}</p>
              </div>
            );
          })}
        </div>

        <div className="pricing-button">{getPricingButton(plan)}</div>
      </div>
    </div>
    // </div>
  );
};

export default PricingCard;
