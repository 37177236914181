import React, { Fragment } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import ISO from "../../assets/media/Landing/ISO.svg";
import SOC from "../../assets/media/Landing/SOC.svg";
import arrowBlue from "../../assets/media/Landing/arrowBlue.svg";
import arrowWhite from "../../assets/media/Landing/arrowWhite.svg";

const Badges = () => {
  const navigate = useNavigate();
  const images = [ISO, SOC, ISO, SOC, ISO, SOC];

  const handleRedirect = () => {
    window.location.href = "https://trynexa.com/contact-us";
  };

  return (
    <div className="section text-center">
      <div className="gradient-right"></div>
      <h1 className="section-heading color-dark">Compliance Is Our Priority</h1>
      <div className="container-fluid px-5 my-5">
        <Marquee delay={0} loop={0} autoFill={true} className="mt-4">
          {images?.map((item, i) => {
            return (
              <Fragment key={i}>
                {" "}
                <div className="mx-5">
                  <img src={item} alt="ISO" />
                </div>
              </Fragment>
            );
          })}
        </Marquee>

        <div className="hero-counter-card badges_elevate">
          <div className="row flex-column flex-xl-row justify-content-between align-items-center my-5">
            <div className="col col-12 col-xl-5">
              <h1 className="elevate-heading mb-3">
                elevate your training program with nexa
              </h1>
              <p className="elevate-subheading">
                Join leading training institutions that trust nexa to have
                state-of-the-art outcomes
              </p>
            </div>
            <div
              className="col col-12 col-xl-5 d-flex flex-column mt-3 mt-xl-0 flex-lg-row"
              style={{ gap: "16px" }} // Adds spacing between buttons
            >
              <button
                className="elevate-btn elevate-btn-white mt-3 mt-lg-0 mr-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "12px 20px",
                  border: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  backgroundColor: "#ffffff",
                  color: "#007bff",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s ease-in-out",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "500px",
                  margin: "0 auto",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#f8f9fa";
                  e.currentTarget.style.transform = "translateY(-2px)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#ffffff";
                  e.currentTarget.style.transform = "translateY(0)";
                }}
              >
                <div onClick={handleRedirect}>
                  <p
                    style={{ margin: "0", fontSize: "16px", fontWeight: "600" }}
                  >
                    Start a free institutional trial
                  </p>
                  <img
                    src={arrowBlue}
                    alt="Arrow Icon"
                    style={{ marginLeft: "8px", width: "16px", height: "16px" }}
                  />
                </div>
              </button>

              <button
                className="elevate-btn mt-3 mt-lg-0 mr-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "12px 20px",
                  border: "none",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  backgroundColor: "#007bff",
                  color: "#ffffff",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                  transition: "all 0.3s ease-in-out",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "500px",
                  margin: "0 auto",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#0056b3";
                  e.currentTarget.style.transform = "translateY(-2px)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#007bff";
                  e.currentTarget.style.transform = "translateY(0)";
                }}
              >
                <a
                  href="https://tally.so/r/3yDKdg"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Claim Your Free Career Services Improvement Report
                    </p>
                    <img
                      src={arrowWhite}
                      alt="Arrow Icon"
                      style={{
                        marginLeft: "8px",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </div>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Badges;
