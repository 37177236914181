import gif from "../../assets/Images/ai-web.gif";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import { useInView } from "react-intersection-observer";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import "./home.css";
import Header from "../header_new/navbar";
import { useState } from "react";
import Footer from "../footer_new/footer";

export default function Home() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const userProfileData = {
    name: "John Doe",
    email: "john@example.com",
  };

  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true, // Allow animation to trigger multiple times
    threshold: 0.3,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true, // Allow animation to trigger multiple times
    threshold: 0.3,
  });

  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true, // Allow animation to trigger multiple times
    threshold: 0.3,
  });

  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true, // Allow animation to trigger multiple times
    threshold: 0.3,
  });

  const { ref: ref5, inView: inView5 } = useInView({
    triggerOnce: true, // Allow animation to trigger multiple times
    threshold: 0.3,
  });

  const { ref: ref6, inView: inView6 } = useInView({
    triggerOnce: true, // Allow animation to trigger multiple times
    threshold: 0.3,
  });
  return (
    <div>
      <Header onOpenPopup={handleOpenPopup} />
      {isPopupOpen && (
        <userProfileData
          userProfileData={userProfileData}
          onClose={handleClosePopup}
        />
      )}
      ̦
      <div ref={ref1} className={`section ${inView1 ? "slide-up" : ""}`}>
        <Section1 />
      </div>
      <div ref={ref2} className={`section ${inView2 ? "slide-up" : ""}`}>
        <Section2 />
      </div>
      <div ref={ref3} className={`section ${inView3 ? "slide-up" : ""}`}>
        <Section3 />
      </div>
      <div ref={ref6} className={`section ${inView6 ? "slide-up" : ""}`}>
        <Section6 />
      </div>
      <div ref={ref4} className={`section ${inView4 ? "slide-up" : ""}`}>
        <Section4 />
      </div>
      <div ref={ref5} className={`section ${inView5 ? "slide-up" : ""}`}>
        <Section5 />
      </div>
      <Footer />
    </div>
  );
}
