import "swiper/css";
import "swiper/css/pagination";
import logo from "../../assets/Images/landing1.png";
import logo1 from "../../assets/Images/landing2.png";
import logo2 from "../../assets/Images/landing3.png";
import logo3 from "../../assets/Images/landing4.png";
import logo4 from "../../assets/Images/landing5.png";
import logo5 from "../../assets/Images/landing6.png";
import logo7 from "../../assets/Images/logo7.png";
import "./slider.css";

// import required modules
import { useRef, useState } from "react";

export default function SliderSection() {
  const logos = [
    { id: 1, src: logo, alt: "Logo 1" },
    { id: 2, src: logo1, alt: "Logo 2" },
    { id: 3, src: logo2, alt: "Logo 3" },
    { id: 4, src: logo3, alt: "Logo 4" },
    { id: 5, src: logo4, alt: "Logo 5" },
    { id: 6, src: logo5, alt: "Logo 6" },
    { id: 7, src: logo7, alt: "Logo 7" },
    // Add more logos as needed
  ];
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="w-full slide-in max-w-[1600px] m-auto">
      <h1 className="Poppins text-heading text-center mt-20 mb-12">
        Trusted By{" "}
        <span
          style={{
            color: "#2B6BE2",
            borderBottom: "2px dashed  #2B6BE2",
            paddingBottom: "1px",
            borderRadius: "0 0 10px 10px",
          }}
        >
          Top Organizations
        </span>
      </h1>

      <div className="carousel-container">
        <div className="carousel-track">
          {/* Repeat logos to make the loop appear continuous */}
          {logos.concat(logos).map((logo, index) => (
            <div className="carousel-slide" key={index}>
              <img
                src={logo.src}
                alt={logo.alt}
                className="logo-image"
                width={195}
                height={56}
                style={{ objectFit: "contain" }}
              />
            </div>
          ))}
          {/* Duplicate the logos for seamless scrolling */}
          {logos.map((logo, index) => (
            <div className="carousel-slide" key={index + logos.length}>
              <img src={logo.src} alt={logo.alt} className="logo-image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
