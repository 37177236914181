import { Select, Spin } from "antd";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { error } from "../../../Utilities/toast";
import { all } from "axios";

const ResumeSection = ({
  allResume,
  value,
  handleDropdown,
  resumeFile,
  setResumeFile,
}) => {
  const fileInput = useRef(null);

  const [activeTab, setActiveTab] = useState("existing_resume");

  const handleTabClick = (tab) => {
    if (fileInput.current) {
      fileInput.current.value = null;
      setResumeFile(null);
    }
    setActiveTab(tab);
  };

  const setFile = (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      if (!file) return;
      if (file && file.size > 2.5 * 1024 * 1024) {
        error("File size exceeds 2.5MB, please select a smaller file.");
        return;
      }
      setResumeFile(file);
    } else {
      error("Only PDF file Supported");
    }
  };

  return (
    <div className="rounded bg-white shadow-lg w-full px-4 pb-4">
      <div className="grid divide-neutral-200">
        <div className="flex justify-content-between items-center nexaInterviewTab-container border-b">
          <ul className="list-reset flex border-b">
            <li className="mr-1">
              <a
                className={`inline-block py-2 px-4 font-semibold cursor-pointer ${
                  activeTab === "existing_resume"
                    ? " border-l border-t border-r rounded-t text-primary"
                    : "text-dark"
                }`}
                // href="#"
                // onClick={() => handleTabClick("existing_resume")}
              >
                Select Resume
              </a>
            </li>
            {/* <li className="-mb-px mr-1">
              <a
                className={`inline-block py-2 px-4 font-semibold ${
                  activeTab === "upload_resume"
                    ? " border-l border-t border-r rounded-t text-primary"
                    : "text-dark"
                }`}
                href="#"
                onClick={() => handleTabClick("upload_resume")}
              >
                Upload Resume
              </a>
            </li> */}
          </ul>
        </div>
        <div>
          {activeTab === "upload_resume" ? (
            <>
              <div className="group-open:animate-fadeIn">
                <form action="#">
                  <input
                    type="file"
                    name="file"
                    ref={fileInput}
                    accept=".pdf"
                    hidden
                    onChange={(e) => setFile(e)}
                  />
                  <div
                    className="flex flex-col pb-2 items-center justify-center cursor-pointer rounded-2xl border-dashed border-[1px] border-gray-300 bg-white p-[7px] text-center"
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  >
                    <div className="p-3 rounded-full bg-primary">
                      <img
                        src="/images/document-upload.png"
                        alt="upload-icon"
                      />
                    </div>
                    <p className="text-primary text-[20px]">
                      {resumeFile?.name ? resumeFile?.name : "Click to Upload"}
                    </p>
                    <span className="text-[12px]">
                      (
                      {resumeFile?.size
                        ? `${resumeFile?.size} Bytes`
                        : "Max. File size: 2.5 MB"}
                      )
                    </span>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="py-4">
                <span className="font-medium">
                  Select your resume to showcase in your SkillPrint
                </span>
              </div>
              <form className="w-full py-1 mb-3 -mt-3 group-open:animate-fadeIn">
                <fieldset>
                  <div className="text-gray-800">
                    {allResume?.filter((item) => item?.isCompleted).length ==
                    0 ? (
                      <p className="text-yellow-600 p-1">
                        You currently don't have any completed resumes in your
                        profile. Please visit the{" "}
                        <Link
                          to="/resumes"
                          className="text-yellow-600 underline"
                        >
                          Resume Page
                        </Link>{" "}
                        to create and complete a resume for use in Nexa.
                      </p>
                    ) : (
                      <Select
                        placeholder="Select Resume"
                        value={
                          allResume?.filter((item) => item?.isCompleted)
                            .length > 0
                            ? value
                            : "Select Resume"
                        }
                        onChange={(value) => handleDropdown(value)}
                        className="w-full mt-1 h-[48px]"
                        optionFilterProp="children"
                        disabled={!allResume}
                        required
                      >
                        {allResume?.filter((item) => item?.isCompleted).length >
                          0 &&
                          allResume
                            ?.filter((item) => item?.isCompleted)
                            ?.map((resume) => {
                              return (
                                <Select.Option
                                  key={resume.id}
                                  value={resume.id}
                                >
                                  {resume?.resume_name
                                    ? `${resume.resume_name}(${
                                        resume?.iterations
                                      })${
                                        resume.company_name
                                          ? ` - ${resume.company_name}`
                                          : ""
                                      }`
                                    : `${resume?.contact?.first_name} ${resume?.contact?.last_name}`}
                                </Select.Option>
                              );
                            })}
                      </Select>
                    )}
                  </div>
                </fieldset>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeSection;
