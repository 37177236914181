import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import chevronDoubleRightIcon from "../../../../assets/icons/chevronDoubleRight.svg";
import userIcon from "../../../../assets/icons/userIcon.svg";
import { SidebarContext } from "../../../../context/sidebarContext";
import BackButton from "../../../BackButton";
import { navigationLinks } from "../../data/data";
import { personsImgs } from "../../utils/images";
import "./Sidebar.css";

const Sidebar = () => {
  const {
    interViewMode,
    interviewCompleted,
    sidebar_colorBtn,
    sidebar_colorBg,
  } = useSelector((state) => state.globalState);
  console.log(sidebar_colorBtn, sidebar_colorBg, "Hello colors");
  const { user } = useSelector((state) => state.authenticate);
  const { pathname } = useLocation();
  const [sidebarClass, setSidebarClass] = useState("");
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);

  const getActiveLinkIndex = (pathname) => {
    for (let i = 0; i < navigationLinks.length; i++) {
      if (pathname === navigationLinks[i].link) return navigationLinks[i].id;
    }
    return 1;
  };

  const [activeLinkIdx, setActiveLinkIdx] = useState(
    getActiveLinkIndex(pathname)
  );

  useEffect(() => {
    if (isSidebarOpen) {
      setSidebarClass("sidebar-change");
    } else {
      setSidebarClass("");
    }
  }, [isSidebarOpen]);

  if (interViewMode === "interview_start_developer" && !interviewCompleted)
    return;

  const filteredNavigationLinks = navigationLinks.filter(
    (link) =>
      (link.id !== 10 || user?.organizationId) &&
      (link.visible === undefined ||
        link.visible === process.env.REACT_APP_ENVIRONMENT)
  );

  return (
    <div className="relative">
      <button
        onClick={toggleSidebar}
        className={`absolute top-12 z-50 -right-5 bg-white rounded-full size-10 p-1 transition-all duration-500 ${
          isSidebarOpen ? "" : "-translate-x-5 rotate-180"
        }`}
      >
        <img src={chevronDoubleRightIcon} alt="" />
      </button>
      <div
        className={`dashboard-sidebar md:rounded-r-[50px] rounded-none h-screen relative ${sidebarClass}`}
        style={{ backgroundColor: sidebar_colorBg }}
      >
        <div className="user-info">
          <div className="info-img img-fit-cover">
            <img
              src={user?.picture || userIcon}
              alt={"profile" + personsImgs.person_two}
            />
          </div>
          <div className="flex flex-col">
            <span
              className={`info-name ${
                sidebar_colorBg == "#000000" ? "text-white" : ""
              }`}
            >
              {user ? user?.fullName : "alice-doe"}
            </span>
            <span
              className={` ${sidebar_colorBg == "#000000" ? "text-white" : ""}`}
            >
              {user ? user?.organizationName : "organization-name"}
            </span>
          </div>
        </div>

        <nav className="navigation">
          <BackButton />
          <ul className="nav-list">
            {filteredNavigationLinks?.map(({ Image, ...navigationLink }) => {
              console.log(Image, "Imageisbyahsan");
              return (
                <li
                  className={`nav-item ${
                    navigationLink.visible !== "test" &&
                    navigationLink.visible !== undefined
                      ? "hidden"
                      : "block"
                  }`}
                  key={navigationLink.id}
                >
                  <div onClick={() => setActiveLinkIdx(navigationLink.id)}>
                    <Link
                      to={navigationLink?.link}
                      className={`nav-link ${
                        navigationLink.id === activeLinkIdx
                          ? `active text-white`
                          : sidebar_colorBg == "#000000"
                          ? "text-white"
                          : ""
                      }`}
                      style={{
                        backgroundColor:
                          navigationLink.id === activeLinkIdx
                            ? sidebar_colorBtn
                            : "",
                      }}
                    >
                      {typeof Image === "string" ? (
                        <img
                          src={Image}
                          className={`nav-link-icon ${
                            navigationLink.id === activeLinkIdx
                              ? `active bg-transparent"`
                              : sidebar_colorBg == "#000000"
                              ? "text-white"
                              : ""
                          } ${
                            sidebar_colorBg == "#000000" ? "icon_white" : ""
                          }`}
                          alt={navigationLink.title}
                        />
                      ) : (
                        <Image
                          className={`nav-link-icon ${
                            navigationLink.id === activeLinkIdx
                              ? `active bg-transparent`
                              : sidebar_colorBg == "#000000"
                              ? "text-white"
                              : ""
                          } ${
                            sidebar_colorBg == "#000000" ? "icon_whiteSvg" : ""
                          }`}
                        />
                      )}
                      <span className="nav-link-text">
                        {navigationLink.title}
                      </span>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
