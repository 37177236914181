import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useSpeechRecognition } from "react-speech-recognition";
import "regenerator-runtime";
// import soundWave from "../../../assets/media/sound1.gif";
import machineSpeaks, { stopMachineSpeaks } from "./MachineSpeaks";
import { useDispatch, useSelector } from "react-redux";
import { simulatorProSave } from "../../../Redux/SimulatorProSlice";
import SpeechRecognition from "react-speech-recognition";
import { success } from "../../../Utilities/toast";
import FeedbackModal from "./FeedbackModal";
import { useNavigate } from "react-router-dom";

const Dictaphone = ({
  handleEndCall,
  jobDescription,
  muted,
  setMuted,
  // loading,
  // feedbackLoading,
  setFeedbackLoading,
  sessionId,
  setLoading,
  quickQuit,
  setQuickQuit,
  contactDetails,
  handleCamera,
  sessionsId,
  interviewTone,
  setInterviewTone,
}) => {
  const {
    // finalTranscript,
    transcript,
    listening,
    resetTranscript,
  } = useSpeechRecognition();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSingleResumeDoc } = useSelector((state) => state.ResumeBuilder);
  const { prompt, sessionValidation } = useSelector(
    (state) => state.SimulatorProSession
  );
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [counter, setCounter] = useState(10); // Start from 10
  const [speaking, setSpeaking] = useState(true);
  const [noResponseModel, setNoResponseModel] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState(false);
  // const [contactDetails, setContactDetails] = useState(`
  //         Name: ${getSingleResumeDoc?.contact?.first_name} ${getSingleResumeDoc?.contact?.last_name},
  //         Email: ${getSingleResumeDoc?.contact?.email},
  //         Phone: ${getSingleResumeDoc?.contact?.phone},
  //         City: ${getSingleResumeDoc?.contact?.city}, ${getSingleResumeDoc?.contact?.state},
  //         LinkedIn: ${getSingleResumeDoc?.contact?.linked_in},
  //         Website: ${getSingleResumeDoc?.contact?.website}
  //       `);

  const [isModal, setIsModal] = useState(false);

  const replacePlaceholders = (prompt, values) => {
    return prompt.replace(/\$\{(\w+)\}/g, (_, key) => values[key] || "");
  };

  const dynamicValues = {
    jobDescription: jobDescription,
    contactDetails: contactDetails,
    type: interviewTone,
  };
  const [liveInterview, setLiveInterview] = useState([
    {
      role: "assistant",
      content: replacePlaceholders(prompt, dynamicValues),
    },
  ]);
  async function callAssistant() {
    if (liveInterview?.length <= 1) {
      setLoading(true);
    }
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
          body: JSON.stringify({
            messages: liveInterview,
            model: "gpt-4o",
          }),
        }
      );

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (err) {
      console.error("Error:", err);
      return "An error occurred while fetching the response.";
    }
  }

  function cleanResponse(resStr) {
    let input;

    // Remove ```json``` if it exists
    if (typeof resStr === "string") {
      resStr = resStr.replace(/```json\s*|\s*```/g, ""); // Remove ```json``` and ```
    }

    try {
      input = typeof resStr === "string" ? JSON.parse(resStr) : resStr;
    } catch (e) {
      if (typeof resStr === "string") {
        input = resStr;
      } else {
        throw new Error("Input must be a string or JSON-formatted string.");
      }
    }

    let values = [];

    function extractFromObject(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (key === "") {
          continue;
        }
        if (typeof value === "object" && value !== null) {
          extractFromObject(value);
        } else {
          values.push(value);
        }
      }
    }

    function extractFromArray(arr) {
      for (const item of arr) {
        if (typeof item === "object" && item !== null) {
          extractFromObject(item);
        } else {
          values.push(item);
        }
      }
    }

    if (typeof input === "string") {
      values.push(input);
    } else if (Array.isArray(input)) {
      extractFromArray(input);
    } else if (typeof input === "object" && input !== null) {
      extractFromObject(input);
    } else {
      throw new Error("Input must be a string, object, or an array.");
    }

    const zeroIndexVal = values[0];
    return zeroIndexVal;
  }

  const questionsData = async () => {
    setNoResponseModel(false);
    setCounter(10);
    // Step 1: Remove the first object where role is 'assistant' and content starts with 'Simulate a realistic'
    const filteredData = liveInterview.filter(
      (item, index) =>
        !(
          item.role === "assistant" &&
          item.content.startsWith("Simulate a realistic")
        )
    );

    // Step 2: Create the questions array by pairing 'assistant' and 'user' objects
    const questions = [];

    for (let i = 0; i < filteredData.length; i++) {
      if (
        filteredData[i].role === "assistant" &&
        filteredData[i + 1] &&
        filteredData[i + 1].role === "user"
      ) {
        questions.push({
          question: filteredData[i].content,
          answer: filteredData[i + 1].content,
        });
      }
    }
    const payload = {
      simulation_session_id: !sessionsId ? sessionId : sessionsId,
      questions: questions,
    };
    setFeedbackLoading(true);
    dispatch(
      simulatorProSave({
        payload,
        sessionsId,
        onSuccess: (res) => {
          setFeedbackLoading(false);
          handleCamera();
          if (!quickQuit) {
            setIsModal(true);
          } else {
            handleEndCall();
            setQuickQuit(false);
            success(res?.detail);
          }
          SpeechRecognition.stopListening();
          resetTranscript();
          setFinalTranscript(false);
          setLiveInterview(null);
          stopMachineSpeaks();
          setMuted(undefined);
          setInterviewTone("");
          if (sessionsId) {
            navigate("/");
          }
        },
        onError: () => {
          setFeedbackLoading(false);
        },
      })
    );
  };

  console.log(speaking, listening);

  const speak = async (text) => {
    setSpeaking(true);
    machineSpeaks(text, null, null, () => {
      setSpeaking(false);
      resetTranscript();
      setFinalTranscript(false);
      SpeechRecognition.startListening({ continuous: true });
      setMuted(false);
    });
  };

  useEffect(() => {
    if (!noResponseModel) {
      const timer = setTimeout(() => {
        if (!transcript && (listening || muted) && !speaking) {
          SpeechRecognition.stopListening();
          stopMachineSpeaks(); // Stop speaking if the user starts talking
          setSpeaking(false);
          resetTranscript();
          setFinalTranscript(false);
          setNoResponseModel(true);
          setMuted(false);
          setCounter(10);
        }
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [muted, transcript, listening]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCount) => {
        if (prevCount > 0 && noResponseModel) {
          return prevCount - 1;
        } else {
          clearInterval(timer);
          return prevCount;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [noResponseModel]);

  useEffect(() => {
    if (muted === true) {
      SpeechRecognition.stopListening();
    } else if (muted === false) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [muted]);

  useEffect(() => {
    if (counter === 0) {
      setCounter(10);
      questionsData();
    }
  }, [counter]);

  useEffect(() => {
    if (finalTranscript) {
      // if (speaking) {
      //   stopMachineSpeaks(); // Stop speaking if the user starts talking
      //   setSpeaking(false);
      // }
      setLiveInterview((prev) => [
        ...prev,
        {
          role: "user",
          content: transcript,
        },
      ]);
    }
  }, [finalTranscript]);

  useEffect(() => {
    const delay = 1500;
    if (transcript && !finalTranscript) {
      const timer = setTimeout(() => {
        SpeechRecognition.stopListening();
        // setTimeout(() => {
        setFinalTranscript(true); // Set final transcript
        stopMachineSpeaks();
        setSpeaking(false);
        // }, 500);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [transcript]);

  useEffect(() => {
    return () => {
      if (speaking) {
        stopMachineSpeaks();
        setSpeaking(false);
      }
    };
  }, [speaking]);

  useEffect(() => {
    if (quickQuit) {
      questionsData();
    }
  }, [quickQuit]);

  useEffect(() => {
    if (!listening && finalTranscript && currentPrompt && !speaking) {
      callAssistant(`${currentPrompt}\nUser: ${liveInterview}`).then(
        (response) => {
          if (response) {
            let filteredRes = cleanResponse(response);
            setLiveInterview((prev) => [
              ...prev,
              {
                role: "assistant",
                content: filteredRes,
              },
            ]);
            speak(filteredRes);
            setFinalTranscript(false);
            if (filteredRes.includes("interview has concluded")) {
              setTimeout(() => {
                questionsData();
              }, 2000);
            }
          } else {
            console.error("No response received from callAssistant");
          }
        }
      );
    }
  }, [liveInterview, currentPrompt, speaking]);

  // Useeffect initially called on component mount
  useEffect(() => {
    if (jobDescription) {
      // setContactDetails(`
      //   Name: ${getSingleResumeDoc.contact.first_name} ${getSingleResumeDoc.contact.last_name},
      //   Email: ${getSingleResumeDoc.contact.email},
      //   Phone: ${getSingleResumeDoc.contact.phone},
      //   City: ${getSingleResumeDoc.contact.city}, ${getSingleResumeDoc.contact.state},
      //   LinkedIn: ${getSingleResumeDoc.contact.linked_in},
      //   Website: ${getSingleResumeDoc.contact.website}
      // `);
      const initialPrompt = replacePlaceholders(prompt, dynamicValues);

      setCurrentPrompt(initialPrompt);
      callAssistant().then((response) => {
        setLoading(false);
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveInterview((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
        } else {
          console.error("No response received from callAssistant");
        }
      });
    }
  }, [jobDescription]);

  const getModal = () => {
    return (
      <FeedbackModal
        open={isModal}
        handleClose={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
        }}
        handleNavigation={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
          navigate("/user/analytics");
        }}
      />
    );
  };

  return (
    <div>
      <div className="absolute max-w-36 w-full max-h-36 h-full bottom-10 right-14">
        <div className="rounded-xl p-2  flex flex-col gap-3 h-full justify-center items-center relative backdrop-blur bg-black bg-opacity-20">
          <div className=" relative">
            <FaUser size={48} className="object-cover text-white" />
          </div>
          {speaking ? (
            <button
              onClick={() => {
                stopMachineSpeaks();
                setSpeaking(false);
                if (!muted) {
                  SpeechRecognition.startListening({ continuous: true });
                }
                resetTranscript();
                setFinalTranscript(false);
              }}
              className="text-white text-xs"
            >
              Tap to interrupt
            </button>
          ) : listening ? (
            <button className="text-white  text-xs">
              Hiring Manager is listening...
            </button>
          ) : (
            <button className="text-white  text-xs"></button>
          )}
        </div>
      </div>
      <div className={`ic-modal ${noResponseModel ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            <div className="ic-modal-body">
              <div className="ic-modal-content">
                Due to no respond from you, we will end this interview in{" "}
                {counter} seconds
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setNoResponseModel(false);
                    SpeechRecognition.startListening({ continuous: true });
                    setCounter(10);
                    setMuted(false);
                    resetTranscript();
                    setFinalTranscript(false);
                  }}
                >
                  Continue
                </button>
                <button
                  onClick={() => {
                    questionsData();
                  }}
                >
                  End
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getModal()}
    </div>
  );
};

export default Dictaphone;

// You are a voice assistant for Mary's Dental, a dental office located at 123 North Face Place, Anaheim, California. The hours are 8 AM to 5PM daily, but they are closed on Sundays.

// Mary's dental provides dental services to the local Anaheim community. The practicing dentist is Dr. Mary Smith.

// You are tasked with answering questions about the business, and booking appointments. If they wish to book an appointment, your goal is to gather necessary information from callers in a friendly and efficient manner like follows:

// 1. Ask for their full name.
// 2. Ask for the purpose of their appointment.
// 3. Request their preferred date and time for the appointment.
// 4. Confirm all details with the caller, including the date and time of the appointment.

// - Be sure to be kind of funny and witty!
// - Keep all your responses short and simple. Use casual language, phrases like "Umm...", "Well...", and "I mean" are preferred.
// - This is a voice conversation, so keep your responses short, like in a real conversation. Don't ramble for too long.
