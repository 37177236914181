import { iconsImgs, personsImgs } from "../utils/images";

const {
  Budget,
  Home,
  Plane,
  Wallet,
  adows,
  octagon,
  Human,
  resumeImg,
  interviewPractice,
  inerviewSimulator,
  simulatorPro,
  liveSimulatorPro,
  nexaIq,
  autoPilot,
} = iconsImgs;


export const navigationLinks = [
  { id: 1, title: "Home", Image: Home, link: "/home" },
  { id: 6, title: "Nexa Resume", Image: resumeImg, link: "/resumes" },
  {
    id: 3,
    title: "Interview Practice",
    Image: interviewPractice,
    link: "/interview/role/",
  },
  {
    id: 4,
    title: "Interview Simulator",
    Image: inerviewSimulator,
    link: "/mock-interview",
    visible: process.env.REACT_APP_ENVIRONMENT,
  },
  {
    id: 5,
    title: "Simulator Pro",
    Image: simulatorPro,
    link: "/simulator-pro",
    // visible: process.env.REACT_APP_ENVIRONMENT,
  },
  {
    id: 12,
    title: "Live Simulator Pro",
    Image: liveSimulatorPro,
    link: "/live-simulator-pro",
    visible: process.env.REACT_APP_ENVIRONMENT,
  },
  {
    id: 7,
    title: "Nexa Octagon (Beta)",
    Image: octagon,
    link: "/octagon",
  },
  { id: 8, title: "Nexa Voyce", Image: Human, link: "/nexa-voyce" },
  {
    id: 9,
    title: "Nexa IQ",
    Image: nexaIq,
    link: "/IQ/jobboard",
    // visible: process.env.REACT_APP_ENVIRONMENT,
  },
  {
    id: 11,
    title: "NexaAutopilot (Coming Soon)",
    Image: autoPilot,
    link: "/nexa-autopilot",
    visible: process.env.REACT_APP_ENVIRONMENT,
  },
];

export const navigationHomeLinks = [
  {
    id: 1,
    title: "My Profile",
    Image: "ri-dashboard-2-line",
    link: "/user/profile",
  },

  {
    id: 2,
    title: "Analytics",
    Image: "ri-dashboard-2-line",
    link: "/user/analytics",
  },
  {
    id: 3,
    title: "Skills Gap Assessment",
    Image: "ri-dashboard-2-line",
    link: "/career-goals",
  },

  // {
  //   id: 4,
  //   title: "Resume",
  //   Image: "ri-draft-line",
  //   link: "/resume",
  // },

  {
    id: 6,
    title: "Chats",
    Image: "ri-message-2-line",
    link: "/chats",
  },
  {
    id: 7,
    title: "Tasks",
    Image: "ri-list-check-3",
    link: "/tasks",
  },
  { id: 8, title: "Billing", Image: "ri-bank-card-line", link: "/pricing" },
  {
    id: 9,
    title: "payments",
    Image: "ri-settings-line",
    link: "/payments",
    // visible: process.env.REACT_APP_ENVIRONMENT,
    // isIndividual: true,
  },
];

export const transactions = [
  {
    id: 11,
    name: "Sarah Parker",
    image: personsImgs.person_four,
    date: "23/12/04",
    amount: 22000,
  },
  {
    id: 12,
    name: "Krisitine Carter",
    image: personsImgs.person_three,
    date: "23/07/21",
    amount: 20000,
  },
  {
    id: 13,
    name: "Irene Doe",
    image: personsImgs.person_two,
    date: "23/08/25",
    amount: 30000,
  },
];

export const reportData = [
  {
    id: 14,
    month: "Jan",
    value1: 45,
    value2: null,
  },
  {
    id: 15,
    month: "Feb",
    value1: 45,
    value2: 60,
  },
  {
    id: 16,
    month: "Mar",
    value1: 45,
    value2: null,
  },
  {
    id: 17,
    month: "Apr",
    value1: 45,
    value2: null,
  },
  {
    id: 18,
    month: "May",
    value1: 45,
    value2: null,
  },
];

export const budget = [
  {
    id: 19,
    title: "Subscriptions",
    type: "Automated",
    amount: 22000,
  },
  {
    id: 20,
    title: "Loan Payment",
    type: "Automated",
    amount: 16000,
  },
  {
    id: 21,
    title: "Foodstuff",
    type: "Automated",
    amount: 20000,
  },
  {
    id: 22,
    title: "Subscriptions",
    type: null,
    amount: 10000,
  },
  {
    id: 23,
    title: "Subscriptions",
    type: null,
    amount: 40000,
  },
];

export const subscriptions = [
  {
    id: 24,
    title: "LinkedIn",
    due_date: "23/12/04",
    amount: 20000,
  },
  {
    id: 25,
    title: "Netflix",
    due_date: "23/12/10",
    amount: 5000,
  },
  {
    id: 26,
    title: "DSTV",
    due_date: "23/12/22",
    amount: 2000,
  },
];

export const savings = [
  {
    id: 27,
    image: personsImgs.person_one,
    saving_amount: 250000,
    title: "Pay kid bro’s fees",
    date_taken: "23/12/22",
    amount_left: 40000,
  },
];
