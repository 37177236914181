import React, { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import Home from "../Components/Home/home";
import LayoutNoFooter from "../Components/LayoutNoFooter";
import UserLayout from "../Components/layout/UserSidebar";
import Loader from "../Components/main-loader";
import AboutUs from "../Pages/AboutUs";
import Announcements from "../Pages/Announcements";
import AutoApply from "../Pages/AutoApply";
import Autopilot from "../Pages/Autopilot";
import CreateAutoPilot from "../Pages/Autopilot/CreateAutoPilot";
import BlogDetail from "../Pages/BlogDetail";
import Blogs from "../Pages/Blogs";
import Chats from "../Pages/Chats";
import CodeProjects from "../Pages/Codeprojects";
import ComingSoon from "../Pages/ComingSoon";
import ContactUs from "../Pages/ContactUs";
import CookiePolicy from "../Pages/CookiePolicy";
import Dashboard from "../Pages/Dashboard";
import DashboardHome from "../Pages/DashboardHome";
import DelegateAccess from "../Pages/DelegateAccess";
import Faq from "../Pages/Faq";
import ForgotPassword from "../Pages/ForgotPassword";
import HowItWork from "../Pages/HowItWork";
import InterviewRole from "../Pages/InterviewRole";
import InterviewSimulation from "../Pages/InterviewSimulation";
import Landing from "../Pages/Landing";
import Login from "../Pages/Login";
import Onboarding from "../Pages/Onboarding";
import Payments from "../Pages/Payments";
import Pricing from "../Pages/Pricing";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import ProfilePage from "../Pages/Profile";
import Register from "../Pages/Register";
import InviteUser from "../Pages/Register/InviteUser";
import Resume from "../Pages/Resume";
import ResumeBuilder from "../Pages/ResumeBuilder";
import Rucoming from "../Pages/Ru/Ru";
import SimulatorPro from "../Pages/SimulatorPro/index";
import TaskDetail from "../Pages/TaskDetail";
import Tasks from "../Pages/Tasks";
import TermsConditions from "../Pages/TermsConditions";
import UserDashboard from "../Pages/User";
import UserDashboard2 from "../Pages/User2";
import Setting from "../Pages/User2/Setting";
import VoyceSimulation from "../Pages/VoyceSimulation";
import CV from "../Pages/cv";
import Codingdojo from "../Pages/octagon/codingdojo";
import Custominput from "../Pages/octagon/custominput";
import Hospitality from "../Pages/octagon/hospitality";
import Intervieworcustom from "../Pages/octagon/intervieworcustom";
import Management from "../Pages/octagon/management";
import Octagon from "../Pages/octagon/octagon";
import Coldcall from "../Pages/octagon/sales/coldcall";
import SalesOptions from "../Pages/octagon/salesoptions";
import Techstacks from "../Pages/octagon/techstacks";
import SmartScreenInterview from "../Pages/smart-screen";
import ThankYou from "../Pages/thank-you";
import { MobileSideMenuProvider } from "../context/mobilesidemenuContext";

import LiveSimulatorPro from "../Pages/LiveSimulatorPro";
import UserProfile from "../Pages/UserProfile";
const SpecialRoute = lazy(() => import("./SpecialRoute"));
const PrivateRoutes = lazy(() => import("./PrivateRoutes"));
const Layout = lazy(() => import("../Components/layout"));
const PublicPagesLayout = lazy(() =>
  import("../Components/layout/PublicPagesLayout")
);

const AppRoutes = () => {
  const { user } = useSelector((state) => state.authenticate);
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver loop")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.remove("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <MobileSideMenuProvider>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<PublicPagesLayout />}>
            <Route path="/landing" element={<Landing />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/terms-and-condition" element={<TermsConditions />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/how-it-work" element={<HowItWork />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="/rucoming" element={<Rucoming />} />
            <Route index element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/invite/:id?" element={<InviteUser />} />
            <Route path="/reset-password/:id?" element={<ForgotPassword />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/delegate-access" element={<DelegateAccess />} />
            <Route
              path="/simulator-pro-interview/:token"
              element={<SmartScreenInterview />}
            />
          </Route>
          {/* <Route element={<SpecialRoute />}>
            <Route
              path="/simulator-pro-interview/:token"
              element={<SmartScreenInterview />}
            />
          </Route> */}
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<App />}>
              <Route
                path="/interview/role/:role?/:category?"
                element={
                  <Layout>
                    <InterviewRole />
                  </Layout>
                }
              />
              <Route
                path="/home"
                element={
                  <Layout>
                    <DashboardHome />
                  </Layout>
                }
              />
              <Route
                path="/career-goals"
                element={
                  <UserLayout>
                    <UserDashboard2 />
                  </UserLayout>
                }
              />
              <Route
                path="/code-projects"
                element={
                  <Layout>
                    <CodeProjects />
                  </Layout>
                }
              />
              <Route
                path="/payments"
                element={
                  <UserLayout>
                    <Payments />
                  </UserLayout>
                }
              />
              {/* <Route
                path="/resume"
                element={
                  <UserLayout>
                    <Resume />
                  </UserLayout>
                }
              /> */}
              <Route
                path="/mock-interview"
                element={
                  <Layout>
                    <InterviewSimulation />
                  </Layout>
                }
              />
              <Route
                path="/simulator-pro"
                element={
                  <Layout>
                    <SimulatorPro />
                  </Layout>
                }
              />
              <Route
                path="/live-simulator-pro"
                element={
                  <Layout>
                    <LiveSimulatorPro />
                  </Layout>
                }
              />
              <Route
                path="/resumes"
                element={
                  <Layout>
                    <Resume />
                  </Layout>
                }
              />
              <Route
                path="/octagon/:category?/:subcategory?/"
                element={
                  <Layout>
                    <Octagon />
                  </Layout>
                }
              />
              <Route
                path="/nexa-voyce"
                element={
                  <Layout>
                    <VoyceSimulation />
                  </Layout>
                }
              />
              <Route
                path="/IQ/jobboard"
                element={
                  <LayoutNoFooter>
                    <AutoApply />
                  </LayoutNoFooter>
                }
              />
              <Route
                path="/nexa-autopilot"
                element={
                  <LayoutNoFooter>
                    <Autopilot />
                  </LayoutNoFooter>
                }
              />
              <Route
                path="/nexa-autopilot/create-new"
                element={
                  <LayoutNoFooter>
                    <CreateAutoPilot />
                  </LayoutNoFooter>
                }
              />
              <Route
                path="/codingdojos"
                element={
                  <Layout>
                    <Codingdojo />
                  </Layout>
                }
              />

              <Route
                path="/custominput"
                element={
                  <Layout>
                    <Custominput />
                  </Layout>
                }
              />

              <Route
                path="/techstacks"
                element={
                  <Layout>
                    <Techstacks />
                  </Layout>
                }
              />
              <Route
                path="/octagon/salesoptions"
                element={
                  <Layout>
                    <SalesOptions />
                  </Layout>
                }
              />
              <Route
                path="/octagon/hospitality"
                element={
                  <Layout>
                    <Hospitality />
                  </Layout>
                }
              />

              <Route
                path="/octagon/management"
                element={
                  <Layout>
                    <Management />
                  </Layout>
                }
              />

              <Route
                path="/sales/coldcall"
                element={
                  <Layout>
                    <Coldcall />
                  </Layout>
                }
              />
              <Route
                path="/intervieworcustom/:category/:subcategory/:role"
                element={
                  <Layout>
                    <Intervieworcustom />
                  </Layout>
                }
              />
              <Route
                path="/announcements"
                element={
                  <Layout>
                    <Announcements />
                  </Layout>
                }
              />
              {user?.organizationId && (
                <Route
                  path="/tasks"
                  element={
                    <UserLayout>
                      <Tasks />
                    </UserLayout>
                  }
                />
              )}
              <Route
                path="/news"
                element={
                  <UserLayout>
                    <Blogs />
                  </UserLayout>
                }
              />
              {user?.organizationId && (
                <Route
                  path="/chats"
                  element={
                    <UserLayout>
                      <Chats />
                    </UserLayout>
                  }
                />
              )}

              <Route path="/blog/view/:id" element={<BlogDetail />} />
              <Route
                path="/interview/simulation"
                element={<InterviewSimulation />}
              />
              <Route
                path="/coming-soon"
                element={
                  <Layout>
                    <ComingSoon />
                  </Layout>
                }
              />
              <Route
                path="/resume-builder/create/:id"
                element={<ResumeBuilder />}
              />
              <Route
                path="/resume-builder/view/:id"
                element={<ResumeBuilder />}
              />
              {user?.organizationId && (
                <Route
                  path="/task/view/:id"
                  element={
                    <UserLayout>
                      <TaskDetail />
                    </UserLayout>
                  }
                />
              )}

              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/cv" element={<CV />} />
              <Route path="/profpage" element={<ProfilePage />} />

              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route
                path="/user/analytics"
                element={
                  <UserLayout>
                    <UserDashboard />
                  </UserLayout>
                }
              />
              <Route
                path="/user/profile"
                element={
                  <UserLayout>
                    <UserProfile />
                  </UserLayout>
                }
              />
              <Route
                path="/user2/home"
                element={
                  <UserLayout>
                    <UserDashboard2 />
                  </UserLayout>
                }
              />
              <Route
                path="/user2/settings"
                element={
                  <UserLayout>
                    <Setting />
                  </UserLayout>
                }
              />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </MobileSideMenuProvider>
  );
};

export default AppRoutes;
