import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../Components/Resume/Select";
import DatePicker from "../../Components/Resume/DatePicker";
import {
  addWorkExperienceFun,
  deleteResumeCompaney,
  getSingleResume,
  updateWorkExperienceFun,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";

const WorkExperienceForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { workExperience } = useSelector((state) => state.ResumeBuilder);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [updateDocId, setUpdateDocId] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleAddWork = () => {
    setIsFormVisible(true);
  };

  const handleEditingIndex = (index, companeyId, roleId) => {
    setIsFormVisible(true);
    setUpdateDocId(companeyId);
    const foundWorkObj = workExperience.find((obj, idx) => idx === index);
    reset({
      companyPosition: foundWorkObj?.company?.name,
      companyDescription: foundWorkObj?.company?.description,
      companyName: foundWorkObj?.roles?.[0]?.name,
      companyLocation: foundWorkObj?.roles?.[0]?.location,
      checked: !foundWorkObj?.roles?.[0]?.ended_at ? true : false,
      startDate: foundWorkObj?.roles?.[0]?.started_at,
      endDate: foundWorkObj?.roles?.[0]?.ended_at,
    });
    setSelectedType(foundWorkObj?.roles?.[0]?.type);
    setSelectedStartDate(new Date(foundWorkObj?.roles?.[0]?.started_at));
    setSelectedEndDate(new Date(foundWorkObj?.roles?.[0]?.ended_at));
  };

  const onCancel = () => {
    setUpdateDocId(null);
    setIsFormVisible(false);
    reset({
      companyName: "",
      companyDescription: "",
      companyPosition: "",
      companyLocation: "",
      checked: "",
      startDate: "",
      endDate: "",
    });
    setSelectedType("");
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
  };

  const onSubmit = (data) => {
    data.jobType = selectedType;
    const timeZoneOffset = selectedStartDate.getTimezoneOffset() * 60000;

    data.startDate = new Date(selectedStartDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    data.endDate = new Date(selectedEndDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];
    const payload = {
      company_data: {
        name: data?.companyPosition,
        description: data?.companyDescription,
      },
      role_data: {
        name: data?.companyName,
        started_at: data?.startDate,
        ended_at: data?.checked == true ? null : data?.endDate,
        location: data?.companyLocation,
        type: data?.jobType,
      },
    };
    const nowData = new Date();
    const endedAtDate = new Date(payload?.role_data?.ended_at);
    if (payload?.role_data?.started_at >= payload?.role_data?.ended_at) {
      error("Start date cannot be greater than or equal to end date");
      return;
    }
    if (endedAtDate > nowData) {
      error("End date cannot be greater than current date");
      return;
    }
    if (updateDocId) {
      dispatch(
        updateWorkExperienceFun({
          payload,
          workExperienceId: updateDocId,
          onSuccess: (res) => {
            success(res);
            getExperienceInfo();
          },
        })
      );
    } else {
      dispatch(
        addWorkExperienceFun({
          payload,
          resumeId: id,
          onSuccess: (res) => {
            success(res);
            getExperienceInfo();
          },
        })
      );
    }
    onCancel();
  };

  const getExperienceInfo = () => {
    dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
  };

  return (
    <div className="mydetails">
      {isFormVisible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column mb-1">
            <div className="form-group w-100">
              <label htmlFor="companyPosition">Job Title</label>
              <input
                type="text"
                id="companyPosition"
                className="form-control"
                {...register("companyPosition", {
                  required: "Company Position is required",
                })}
              />
              {errors.companyPosition && (
                <p className="text-danger">{errors.companyPosition.message}</p>
              )}
            </div>

            <div className="form-group w-100">
              <label htmlFor="companyDescription">Job Description</label>
              <textarea
                id="companyDescription"
                className="workcontrol"
                style={{ height: "150px" }}
                {...register("companyDescription", {
                  required: "Company Description is required",
                })}
              />
              {errors.companyDescription && (
                <p className="text-danger">
                  {errors.companyDescription.message}
                </p>
              )}
            </div>
          </div>
          <div className="form-group w-100">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              className="form-control"
              placeholder="Facebook"
              {...register("companyName", {
                required: "Company Name is required",
              })}
            />
            {errors.companyName && (
              <p className="text-danger">{errors.companyName.message}</p>
            )}
          </div>
          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50">
              <label htmlFor="location">Location</label>
              <input
                type="text"
                id="location"
                className="form-control"
                {...register("companyLocation", {
                  required: "Company Location is required",
                })}
              />
              {errors.companyLocation && (
                <p className="text-danger">{errors.companyLocation.message}</p>
              )}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="companyType">Type</label>
              <SelectComponent
                onSelect={setSelectedType}
                selectedValue={selectedType}
              />
            </div>
          </div>
          <div className="d-flex mb-1 flex-col md:flex-row">
            <div className="form-group mr-2 w-full md:w-50">
              <label htmlFor="startDate">Start Date</label>
              <DatePicker
                selectedDate={selectedStartDate}
                onSelect={setSelectedStartDate}
              />
            </div>
            <div className="form-group ml-0 md:ml-3 w-full md:w-50">
              <label htmlFor="endDate">End Date</label>
              <DatePicker
                selectedDate={selectedEndDate}
                onSelect={setSelectedEndDate}
              />
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className="form-check ml-1">
              <input
                className="custom-check-input"
                type="checkbox"
                id="stillworking"
                {...register("checked")}
              />
              <label className="form-check-label" htmlFor="stillworking">
                I am currently in this position
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-end py-2 px-2 border-bottom border-primary">
            <button
              type="button"
              className="cancel-btn mr-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
            >
              Save
            </button>
          </div>
        </form>
      )}
      {!isFormVisible && (
        <div className="professionalsummary-item ">
          <button className="professionalitem-button" onClick={handleAddWork}>
            <i className="ri-add-circle-line"></i>
            <span className="ml-2">Add Work Experience</span>
          </button>
        </div>
      )}
      {workExperience &&
        workExperience?.map((experience, index) => (
          <div className="bg-white rounded-xl shadow-lg mt-2">
            <div className="professionalsummary-companey p-3">
              <div className="d-flex flex-col">
                <h4 className="bold">{experience?.company?.name}</h4>
                <p>{experience?.company?.description}</p>
              </div>
              <div className="professionalitem-actions rounded-tr-xl">
                <button
                  className="relative group professionalitem-button"
                  onClick={() =>
                    handleEditingIndex(
                      index,
                      experience?.company?.id,
                      experience?.roles?.[0]?.id
                    )
                  }
                >
                  <i className="ri-edit-line"></i>
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Edit Work Experiece
                  </div>
                </button>
                <button
                  className="relative group professionalitem-button"
                  onClick={() => {
                    dispatch(
                      deleteResumeCompaney({
                        payload: experience?.company?.id,
                        onSuccess: (res) => {
                          success(res);
                          getExperienceInfo();
                        },
                      })
                    );
                  }}
                >
                  <i className="ri-delete-bin-line"></i>
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-sm rounded-md py-1 px-2 bottom-full mb-2 w-max left-1/2 transform -translate-x-1/2">
                    Delete Work Experiece
                  </div>
                </button>
              </div>
            </div>
            {experience?.roles?.map((item, keey) => (
              <div key={keey} className="professionalsummary-item px-4 py-3">
                <div className="d-flex justify-between items-center w-full">
                  <p className="mr-2 font-bold">{item?.name}</p>
                  <p>{item?.location}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default WorkExperienceForm;
