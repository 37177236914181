import "swiper/css";
import "swiper/css/pagination";
import logo from "../../assets/Images/Logo-box.png";
import logo1 from "../../assets/Images/Logo-box1.png";
import logo2 from "../../assets/Images/Logo-box2.png";
import logo3 from "../../assets/Images/Logo-box3.png";
import logo4 from "../../assets/Images/Logo-box4.png";
import VideoPlayer from "./video";

// import required modules
import { useRef, useState } from "react";

export default function Section2() {
  const logos = [
    { id: 1, src: logo, alt: "Logo 1" },
    { id: 2, src: logo1, alt: "Logo 2" },
    { id: 3, src: logo2, alt: "Logo 3" },
    { id: 4, src: logo3, alt: "Logo 4" },
    { id: 5, src: logo4, alt: "Logo 5" },
    // Add more logos as needed
  ];
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="w-full slide-in max-w-[1600px] m-auto">
      <h1 className="Poppins text-heading text-center mt-20 mb-12">
        Trusted By{" "}
        <span
          style={{
            color: "#2B6BE2",
            borderBottom: "2px dashed  #2B6BE2",
            paddingBottom: "1px",
            borderRadius: "0 0 10px 10px",
          }}
        >
          Hiring Teams
        </span>{" "}
        At
      </h1>
      {/* <div className="flex justify-center w-full">
                <Swiper
                    slidesPerView={5}
                    spaceBetween={3}
                    centeredSlides={true}
                    loop={true}
                    freeMode={true}
                    autoplay={{
                        delay: 0, // Slows down the autoplay delay (2000ms = 2s)
                        disableOnInteraction: false,
                    }}
                    speed={2000} // Slower transition speed for smoothness (in ms)
                    modules={[FreeMode, Autoplay]}
                    className="mySwiper"
                >
                    {
                        logos.map((v, i) => (
                            <SwiperSlide key={i}>
                                <img className="object-contain w-36" src={v.src} alt={v.alt} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div> */}

      <div className="carousel-container">
        <div className="carousel-track">
          {/* Repeat logos to make the loop appear continuous */}
          {logos.concat(logos).map((logo, index) => (
            <div className="carousel-slide" key={index}>
              <img src={logo.src} alt={logo.alt} className="logo-image" />
            </div>
          ))}
          {/* Duplicate the logos for seamless scrolling */}
          {logos.map((logo, index) => (
            <div className="carousel-slide" key={index + logos.length}>
              <img src={logo.src} alt={logo.alt} className="logo-image" />
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: "50px" }}>
        <VideoPlayer
          videoUrl="https://www.youtube.com/embed/BZP1rYjoBgI?si=57SXqYfBAqpb_iBX" // Replace with the path to your thumbnail
        />
      </div>
    </div>
  );
}
