import { useNavigate } from "react-router-dom";
import "./styles.css";
import React, { useEffect, useRef, useState } from "react";
import Terms2 from "../../Components/onboarding/terms2";
import { OnboardingLayout } from "../../Components/onboarding/layout";
import { error, success } from "../../Utilities/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  generateSkillsGapAnalysis,
  getCurrentUser,
  getOnBoardingAction,
  onBoardingAction,
  updateOnboarding,
  updateOnboardingUser,
} from "../../Redux/authenticate/authenticate.action";
import Loader from "../../Components/main-loader";
import { setOnboardStep } from "../../Redux/authenticate/authenticate.slicer";
import CircularLoader from "../../Components/loader";
import ProfileInfo from "./ProfileInfo";
import YoutubeEmbed from "../../Components/youtubeEmbed/YoutubeEmbed";

export default function Onboarding() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, isloading, onboardingData, onBoardStep, user } = useSelector(
    (state) => state.authenticate
  );
  const [activeStep, setActiveStep] = useState(0);
  const [doLaterLoading, setDoLaterLoading] = useState(false);
  const [savedAnswer, setSavedAnswer] = useState(null);
  const [answer, setAnswer] = useState("");
  const [resume, setResume] = useState(null);
  const [profileInfo, setProfileInfo] = useState({
    image: null,
    // name: "",
    address: "",
    education: "",
    contact: "",
    employment_status: "",
    linkedIn_profile: "",
  });
  const [errors, setErrors] = useState("");

  const validateFields = () => {
    const newErrors = {};
    // if (!profileInfo.image) {
    //   newErrors.image = "Profile image is required.";
    // }
    // if (!profileInfo.name.trim()) {
    //   newErrors.name = "Name field is required.";
    // }
    if (!profileInfo.address.trim()) {
      newErrors.address = "Address field is required.";
    }
    // if (!profileInfo.education.trim()) {
    //   newErrors.education = "Education field is required.";
    // }
    // if (!profileInfo.contact.trim()) {
    //   newErrors.contact = "Phone number is required.";
    // }
    if (profileInfo.contact && !/^\d+$/.test(profileInfo.contact)) {
      newErrors.contact = "Phone number must be a valid number.";
    }
    // if (
    //   profileInfo.linkedIn_profile &&
    //   !/^https?:\/\/(www\.)?linkedin\.com\/.*$/.test(
    //     profileInfo.linkedIn_profile
    //   )
    // ) {
    //   newErrors.linkedIn_profile = "Please enter a valid LinkedIn profile URL.";
    // }
    if (!profileInfo.employment_status) {
      newErrors.employment_status = "Employment status is required.";
    }
    return newErrors;
  };

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };
  const handleProfileChange = (e) => {
    const { name, value, type, files } = e.target;
    if (name === "contact" && !/^\d*$/.test(value)) {
      return;
    }
    if (type === "file") {
      const file = files[0];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeInMB = 2; // 2MB
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file && !validImageTypes.includes(file.type)) {
        error("Please upload a valid image file (jpg, jpeg, or png).");
        return;
      }

      if (file.size > maxSizeInBytes) {
        error(`File size must be less than ${maxSizeInMB}MB.`);
        return;
      }

      setProfileInfo((prev) => ({
        ...prev,
        [name]: file,
      }));
    } else {
      setProfileInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      // if (name === "image") {
      //   delete newErrors.image;
      // }
      if (name === "contact" && /^\d*$/.test(value)) {
        delete newErrors.contact;
      }
      // if (name === "name" && value.trim() !== "") {
      //   delete newErrors.name;
      // }
      if (name === "address" && value.trim() !== "") {
        delete newErrors.address;
      }
      if (name === "education" && value.trim() !== "") {
        delete newErrors.education;
      }
      // if (
      //   name === "linkedIn_profile" &&
      //   /^https?:\/\/(www\.)?linkedin\.com\/.*$/.test(value)
      // ) {
      //   delete newErrors.linkedIn_profile;
      // }
      if (name === "employment_status" && value) {
        delete newErrors.employment_status;
      }

      return newErrors;
    });
  };
  const slugs = [
    {
      id: 1,
      slug: "profile-info",
    },
    {
      id: 2,
      slug: "confident-skills-target-role",
    },
    {
      id: 3,
      slug: "areas-of-low-confidence",
    },
    {
      id: 4,
      slug: "preferred-learning-methods",
    },
    {
      id: 5,
      slug: "desired-certifications-tools",
    },
    {
      id: 6,
      slug: "ultimate-career-goal",
    },
    {
      id: 7,
      slug: "past-learning-challenges",
    },
    {
      id: 8,
      slug: "additional-information-for-career-goal",
    },
    {
      id: 9,
      slug: "your-resume",
    },
  ];

  const fileInput = useRef(null);
  const goBack = () => {
    if (activeStep > 0) {
      dispatch(setOnboardStep(onBoardStep - 1));
      setActiveStep((prev) => prev - 1);
    }
  };
  const handleNext = (step) => {
    console.log("Called", step);
    let payload = {
      question_slug: slugs[step - 2].slug,
      file: resume,
    };
    if (step === 2) {
      const newErrors = validateFields();

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return; // Stop if there are validation errors
      }

      let formData = new FormData();
      Object.entries(profileInfo).forEach(([key, val]) => {
        if (key === "image") {
          if (typeof val === "object") {
            formData.append(key, val);
          }
          return;
        } else {
          formData.append(key, val);
        }
      });
      dispatch(
        updateOnboardingUser({
          payload: formData,
          onSuccess: (res) => {
            success(res);
            setActiveStep(step);
            dispatch(setOnboardStep(step));
          },
        })
      );
      return;
    }
    if (step > 9) {
      if (resume) {
        if (resume.size > 2.7 * 1024 * 1024) {
          error("The file size exceeds the limit of 2.7 MB.");
        } else {
          payload.file = resume;
        }
      }
    } else {
      if (!answer) {
        return error("Value Can't be empty");
      } else {
        payload.answer = answer;
      }
    }
    const handleResponse = (response) => {
      console.log("response: ", response);
      if (response) {
        if (step > 9) {
          const modalElement = document.getElementById("exampleModal");
          if (modalElement) {
            modalElement.setAttribute("data-toggle", "modal");
            modalElement.setAttribute("data-target", "#exampleModal");
            modalElement.click(); // Simulate a click to open the modal
            dispatch(generateSkillsGapAnalysis({ onSuccess: () => {} }));
            // .unwrap()
            // .then((response) => {
            //   if (response) {
            //     dispatch(setOnboardStep(0));
            //     success("Saved Successfully");
            //     navigate("/home");
            //   }
            // });
            dispatch(setOnboardStep(0));
          }
        } else {
          setAnswer("");
          dispatch(setOnboardStep(step));
          setActiveStep(step);
        }
      }
    };

    if (savedAnswer) {
      console.log("i am in if");
      dispatch(updateOnboarding(payload))
        .unwrap()
        .then((response) => {
          // console.log("update", payload);
          // dispatch(getOnBoardingAction());
          setAnswer("");
          setSavedAnswer(null);
          dispatch(setOnboardStep(step));
          setActiveStep(step);
        });
    } else {
      console.log("i am in else");
      dispatch(onBoardingAction(payload))
        .unwrap()
        .then((response) => {
          handleResponse(response);
        })
        .catch((err) => {
          error(err?.response?.statusText);
        });
    }
  };

  const handleSubmitLater = async (step) => {
    setDoLaterLoading(true);
    await dispatch(generateSkillsGapAnalysis({ onSuccess: () => {} }))
      .unwrap()
      .then((response) => {
        if (response) {
          dispatch(setOnboardStep(0));
          success("Saved Successfully");
          navigate("/home");
        }
      });
    setDoLaterLoading(false);
  };
  useEffect(() => {
    dispatch(getOnBoardingAction());
  }, [activeStep]);
  useEffect(() => {
    if (onBoardStep > 0) {
      setActiveStep(onBoardStep);
    }
    // if (onBoardStep === 1) {
    //   dispatch(getCurrentUser());
    // }
  }, [onBoardStep]);

  useEffect(() => {
    const currentSlug = slugs[activeStep - 1]?.slug;
    if (onboardingData && onboardingData?.length > 0) {
      const currentAnswer = onboardingData?.find(
        (item) => item.question_slug === currentSlug
      );
      setSavedAnswer(currentAnswer);
      setAnswer(currentAnswer?.answer);
    }
  }, [activeStep]);

  useEffect(() => {
    if (user) {
      setProfileInfo((prev) => ({
        ...prev,
        //  name: user?.fullName || "",
        image: user?.image || "",
        address: user?.address || "",
        linkedIn_profile: user?.linkedIn_profile || "",
        contact: user?.contact || "",
        education: user?.education || "",
        employment_status: user?.employment_status || "",
      }));
    }
  }, [user]);
  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <div>
      {doLaterLoading
        ? isloading && (
            <CircularLoader
              title="Nexa is hard at work to analyze your results, give us a minute"
              icon={true}
            />
          )
        : loading && <Loader />}

      {activeStep === 0 && (
        <Terms2
          handleNext={() => {
            dispatch(setOnboardStep(1));
            setActiveStep(1);
          }}
        />
      )}
      {activeStep !== 0 && !doLaterLoading && !isloading && !loading && (
        <OnboardingLayout
          activeStep={activeStep}
          handleNext={handleNext}
          goBack={goBack}
          submitLater={handleSubmitLater}
          answer={answer}
        >
          {activeStep === 1 && (
            <ProfileInfo
              profileInfo={profileInfo}
              handleProfileChange={handleProfileChange}
              setProfileInfo={setProfileInfo}
              errors={errors}
            />
          )}
          {activeStep === 2 && (
            <>
              <div className="ic-heading py-3">
                <h2 className="">
                  What specific skills are you confident in that are directly
                  related to your target role?
                </h2>
                <div className="text-area">
                  <textarea
                    v-model="description"
                    placeholder="Type your answer here..."
                    rows={4}
                    value={answer}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="radio-container">
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      id="use"
                      name="use"
                      value="Interview"
                      className="radio"
                      checked={answer === "Interview"}
                      onChange={handleChange}
                    />
                    <label htmlFor="use">Interview Prep</label>
                  </div>
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      id="workUse"
                      name="use"
                      value="Resume"
                      className="radio"
                      checked={answer === "Resume"}
                      onChange={handleChange}
                    />
                    <label htmlFor="workUse">Resume Building</label>
                  </div>
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      id="job"
                      name="use"
                      value="Job apps"
                      checked={answer === "Job apps"}
                      className="radio"
                      onChange={handleChange}
                    />
                    <label htmlFor="job">For Job Applications</label>
                  </div>
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      id="notSure"
                      name="use"
                      value="Not sure"
                      className="radio"
                      checked={answer === "Not sure"}
                      onChange={handleChange}
                    />
                    <label htmlFor="notSure">I'm not sure</label>
                  </div>
                </div> */}
              </div>
            </>
          )}
          {activeStep === 3 && (
            <div className="ic-heading py-3">
              <h2 className="h2 onBoard_heading mb-4">
                Which skills or knowledge areas do you feel least confident in
                for this role?
              </h2>
              <div className="text-area">
                <textarea
                  v-model="description"
                  placeholder="Type your answer here..."
                  rows={4}
                  value={answer}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          {activeStep === 4 && (
            <div className="ic-heading py-3">
              <h2 className="h2 onBoard_heading mb-4">
                How do you prefer to learn new skills? (e.g., online courses,
                workshops, hands-on projects, mentoring)
              </h2>
              <div className="text-area">
                <textarea
                  v-model="description"
                  placeholder="Type your answer here..."
                  rows={4}
                  value={answer}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          {activeStep === 5 && (
            <div className="ic-heading py-3">
              <h2 className="h2 onBoard_heading mb-4">
                Are there any certifications, industry tools, or technologies in
                your field that you’re unfamiliar with but want to learn?
              </h2>
              <div className="text-area">
                <textarea
                  v-model="description"
                  placeholder="Type your answer here..."
                  rows={4}
                  value={answer}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          {activeStep === 6 && (
            <div className="ic-heading py-3">
              <h2 className="h2 onBoard_heading mb-4">
                What is your ultimate career goal, and how does this role fit
                into that path?
              </h2>
              <div className="text-area">
                <textarea
                  v-model="description"
                  placeholder="Type your answer here..."
                  rows={4}
                  value={answer}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          {activeStep === 7 && (
            <div className="ic-heading py-3">
              <h2 className="h2 onBoard_heading mb-4">
                What challenges or obstacles have prevented you from learning
                new skills in the past?
              </h2>
              <div className="text-area">
                <textarea
                  v-model="description"
                  placeholder="Type your answer here..."
                  rows={4}
                  value={answer}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          {activeStep === 8 && (
            <div className="ic-heading py-3">
              <h2 className="h2 onBoard_heading mb-4">
                Is there anything else you feel we should know to best help you
                reach your career goal?
              </h2>
              <div className="text-area">
                <textarea
                  v-model="description"
                  placeholder="Type your answer here..."
                  rows={4}
                  value={answer}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          {activeStep === 9 && (
            <div className="ic-mockInterview">
              <div className="ic-mock-interview mt-3">
                <h4>Please Upload your Resume to Tailor your Experience</h4>
                <form action="#">
                  <input
                    type="file"
                    name="file"
                    ref={fileInput}
                    accept=".pdf"
                    hidden
                    onChange={(e) => {
                      setResume(e.target.files[0]);
                    }}
                  />
                  <div
                    className="ic-file-upload"
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  >
                    <div className="ic-icon">
                      <img
                        src="/images/document-upload.png"
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <p className="support-text">
                      <span className="primary">Click to Upload </span>
                    </p>
                  </div>
                </form>
                {resume && <h4>File selected: {resume?.name}</h4>}
              </div>
            </div>
          )}
        </OnboardingLayout>
      )}
      <div
        className="modal fade onboarding-modal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
        data-show="true"
      >
        <div className="modal-dialog onboarding-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="header headers layout-header">
                <div className="container">
                  <div className="ic-navbar-heads">
                    <div className="ic-logos">
                      <button
                        type="button"
                        data-dismiss="modal"
                        onClick={() => navigate("/")}
                      >
                        <img src="/images/logo.png" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="video-container">
                <YoutubeEmbed embedId="J_S34tZd9Ao" />
              </div>
            </div>
            <div className="p-1">
              <button
                type="button"
                className="ic-btn chip-btn"
                data-dismiss="modal"
                onClick={() => navigate("/home")}
              >
                Skip
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
