import { Button, Form, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";

export default function Section5() {
  const [successMessage, setSuccessMessage] = useState(false);
  const onFinish = async (values) => {
    console.log("Success:", values);
    try {
      // Make a POST request to the Brevo API
      const response = await axios.post(
        "https://api.brevo.com/v3/contacts",
        {
          email: values.email, // Adjust key to match Brevo's expected payload
          attributes: {
            FIRSTNAME: "User",
          },
          listIds: [17], // Replace with your actual list ID
          updateEnabled: true, // To update existing contacts
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key":
              "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", // Use a secure environment variable
          },
        }
      );
      console.log("Subscription successful:", response);
      if (response.data.id) {
        setSuccessMessage(true);
      } else {
        setSuccessMessage(false);
      }
    } catch (error) {
      console.error("Subscription failed:", error.response?.data || error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex justify-center mb-20">
      <div className="text-center sec5">
        <p className="text-4xl font-extrabold Raleway mx-auto">
          Career Insights & Opportunities - For Free!
        </p>

        {successMessage ? (
          <p
            className="text-2xl font-extrabold Raleway mx-auto my-8"
            style={{ color: "#2B6BE2" }}
          >
            Welcome to Nexa's Mailing List!
          </p>
        ) : (
          <>
            <p className="text-lg font-normal my-8 leading-5 OpenSans sec5-para mx-auto">
              Sign up to receive expert advice, job leads, and exclusive tips to
              boost your career. Stay connected for resources that help you
              stand out in the market, all no cost to you!
            </p>

            <div>
              <Form
                name="validateOnly"
                className="flex justify-center items-center"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: "12px 0px 0px 12px" }}
                    type="email"
                    placeholder="Enter your email"
                    className="input-width1 h-12 py-4 border-2"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{
                      borderRadius: "0px 12px 12px 0px",
                      background:
                        "linear-gradient(94.37deg, #2B6BE2 0%, #1E4B9E 100%)",
                    }}
                    htmlType="submit"
                    className="input-btn h-12 text-white py-2 transition ease-in-out Satoshi"
                  >
                    Subscribe
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
}