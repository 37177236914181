import React, { useState } from "react";
import Footer from "../../Components/footer_new/footer";
import Header from "../../Components/header_new/navbar";

const Faq = () => {
  const [active, setActive] = useState(null);

  const questions = [
    {
      title: "What is Nexa?",
      answer:
        "Nexa is a  platform that helps training organizations streamline and enhance their career services, & hiring teams streamline recruitment for a pipeline of the best talent.",
    },
    {
      title: "Who is Nexa designed for?",
      answer:
        "Our platform is broken down into two main products. Nexa Elevate is ideal for training organizations, bootcamps, and educational institutions looking to improve job placement rates and career prep services for their students. Nexa RU is an end to end platform for sourcing, screening and hiring top 5% talent all while never leaving existing ATS. Both products compliment each other. ",
    },
    {
      title:
        "Why is interview preparation more important now than ever before?",
      answer:
        "The job market has become increasingly competitive, with many qualified candidates vying for the same positions. Effective interview preparation is crucial because it not only helps you present your qualifications and experience effectively but also demonstrates your commitment and enthusiasm for the role. Nexa’s comprehensive preparation tools ensure that you are not only ready to answer typical interview questions but also prepared for more dynamic and challenging scenarios, giving you a significant advantage in securing your desired job.",
    },
    {
      title:
        "What makes Nexa Elevate different from other career services tools?",
      answer:
        "Unlike other platforms, Nexa offers an all-in-one solution with AI-driven personalization, centralized management, and scalability, tailored specifically for training organizations.",
    },
    {
      title: "What makes Nexa RU different from other hiring tools? ",
      answer:
        "Simply the ease of use. RU has 0 friction, we do not force anyone to switch their ATS and spends weeks transferring data and knowledge. We integrate in your platform and start saving you costs in hiring the best people eager to work for you! ",
    },
    {
      title: "What tools are included in the Nexa Elevate platform?",
      answer:
        "Our platform includes resume building, interview simulations, job-matching tools, career counseling management, and real-time data reporting.",
    },
    {
      title: "How does Nexa help with job placement?",
      answer:
        "Nexa’s tools are designed to prepare students thoroughly and match them with job opportunities that align with their skills, leading to faster and more successful placements.",
    },
    {
      title: "Can Nexa support multiple cohorts or locations?",
      answer:
        "Yes, Nexa is built to scale with your organization, whether you’re supporting a single cohort or multiple locations.",
    },
    {
      title: "Is Nexa customizable?",
      answer:
        "Yes, we offer white-labeling options to allow your organization to brand the platform as your own, creating a cohesive experience for your students.",
    },
    {
      title: "What pricing plans are available?",
      answer:
        "We offer two pricing options: a low-cost $6/month per user plan and a revenue-share model with no upfront costs, where users are charged $10/month, along with a $100 monthly management fee and optional $299 white-label fee. We also offer custom pricing models if needed.",
    },
    {
      title: "What is the benefit of the revenue-share model?",
      answer:
        "The revenue-share model allows organizations to offer Nexa at no initial cost, generating revenue by charging students directly, which is ideal for budget-conscious institutions.",
    },
    {
      title: "Can I switch between pricing plans later?",
      answer:
        "Yes, we allow flexibility in choosing or switching plans to suit your organization’s needs as they evolve.",
    },
    {
      title: "How does onboarding work?",
      answer:
        "We provide a guided onboarding process that includes training sessions, documentation, and a dedicated support team to ensure a smooth transition for your team and students for the first 30 days.",
    },
    {
      title: "What support options are available?",
      answer:
        "Our dedicated support team is available via email and chat to assist with any technical or operational questions, and we offer ongoing support for you and your students. Additionally, you will also receive a dedicated account manager to help support you with consulting your staff, product support and feature request support with dedicated bi-weekly check ins.  ",
    },
    {
      title:
        "Can students get direct support if they need help with the platform?",
      answer:
        "Yes, students have access to our help center and support team to resolve any issues they may encounter.",
    },
    {
      title: "How secure is Nexa?",
      answer:
        "We prioritize security with industry-standard encryption and secure data storage to protect both your organization’s and students’ information.",
    },
    {
      title: "Does Nexa offer reporting and data analytics?",
      answer:
        "Yes, we provide comprehensive analytics that track student progress, job placement rates, and engagement to help you measure outcomes effectively. Our custom machine learning models will be able to understand patterns and insights helping you better understand your own data.",
    },
    {
      title: "Who has access to student data?",
      answer:
        "Only authorized organization admins and our support team (as needed) have access to student data. We comply with data privacy regulations to ensure confidentiality.",
    },
    {
      title: "How do resume-building and interview simulation tools work?",
      answer:
        "Our AI-driven tools guide students in crafting personalized resumes and provide real-time feedback in interview simulations, helping them improve quickly and efficiently.",
    },
    {
      title: "Is Nexa mobile-friendly?",
      answer:
        "Yes, our platform is fully optimized for mobile devices, allowing students and staff to access it from anywhere, anytime. An app is in progress for the end of Q1 2025.",
    },
    {
      title: "Can Nexa integrate with other systems?",
      answer:
        "We offer integration options to connect with CRM, LMS, and other career services tools, ensuring smooth workflows for your organization. For Nexa RU, we integrate with every major ATL like greenhouse, lever, bullhorn etc so you never have to worry about leaving your ATS!",
    },
    {
      title: "How do I get started with Nexa?",
      answer:
        "Simply reach out to our team through our website’s “Contact Us” form, and we’ll schedule a demo to show you how Nexa can transform your career services!",
    },
  ];

  return (
    <>
      <Header />
      <div>
        <div
          className="ic-faq"
          style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
        >
          <div className="container">
            <div className="col-xxl-6 col-xl-7 col-lg-8 m-auto">
              <div className="faq-header mt-4 md:!mt-0">
                <h1>Frequently Asked Questions</h1>
              </div>
              <div className="ic-according">
                {questions.map((question, index) => (
                  <div
                    className={`ic-according-item ${
                      active === index ? "active" : ""
                    }`}
                    key={question.title}
                  >
                    <button
                      className={`${active === index ? "active" : ""}`}
                      onClick={() => setActive(active === index ? null : index)}
                    >
                      <span className="flex mr-5 md:!mr-0">
                        {question.title}
                      </span>
                    </button>
                    <div className={`body ${active === index ? "active" : ""}`}>
                      {active === index ? <p>{question.answer}</p> : ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Faq;
