import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addResumeProfile,
  getResumeProfile,
  udpateResumeProfile,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { SaveIcon } from "../../Components/Resume/svgimages";
const MyDetails = () => {
  const { id } = useParams();
  const { profile, contactId } = useSelector((state) => state.ResumeBuilder);
  const dispatch = useDispatch();
  const [editing, setIsEditing] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const getUserInfo = () => {
    const resumeId = id;
    dispatch(getResumeProfile({ resumeId, onSuccess: () => {} }));
  };

  const onSubmit = (data) => {
    data.zip = null;
    console.log("data :", data);

    if (editing) {
      const userInfo = {
        contactId: data?.id,
        data,
      };
      console.log("userInfo :", userInfo);
      dispatch(
        udpateResumeProfile({
          userInfo,
          onSuccess: (res) => {
            success(res);
            getUserInfo();
          },
        })
      )
        .unwrap()
        .then((res) => {})
        .catch((err) => {
          console.log(err.response, "rejected");
          error(err.response.data.detail);
        });
    } else {
      const userInfo = data;
      dispatch(
        addResumeProfile({
          userInfo,
          id,
          onSuccess: (res) => {
            success(res);
            getUserInfo();
          },
        })
      )
        .unwrap()
        .then((res) => {})
        .catch((err) => {
          console.log(err.response, "rejected");
          error(err.response.data.detail);
        });
    }
    reset();
    setIsEditing(false);
    reset();
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const onCancel = () => {
    reset();
    setIsEditing(false);
  };

  useEffect(() => {
    if (editing && profile) {
      Object.keys(profile).forEach((key) => {
        setValue(key, profile[key]);
      });
    }
  }, [editing, profile, setValue]);
  useEffect(() => {
    if (id) {
      getUserInfo();
    }
  }, [id]);

  return (
    <div className="mydetails">
      {profile && !editing ? (
        <div className="profile-data">
          <div className="profile-data-header">
            <button onClick={handleEditClick}>
              <i className="ri-edit-line"></i>
              <span>Edit</span>
            </button>
          </div>
          <div className="profile-data-item">
            <i className="ri-user-shared-2-line"></i>
            <span>{profile?.first_name}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-user-received-2-line"></i>
            <span>{profile?.last_name}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-mail-open-line"></i>
            <span>{profile?.email}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-phone-line"></i>
            <span>{profile?.phone}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-linkedin-box-line"></i>
            <span>{profile?.linked_in ? profile.linked_in : "---"}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-twitter-x-line"></i>
            <span>{profile?.twitter ? profile.twitter : "---"}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-home-8-line"></i>
            <span>{profile?.address}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-building-2-line"></i>
            <span>{profile?.city}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-earth-line"></i>
            <span>{profile?.state}</span>
          </div>
          <div className="profile-data-item">
            <i className="ri-links-line"></i>
            <span>{profile?.website ? profile?.website : "---"}</span>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                id="first_name"
                className="form-control"
                placeholder="John"
                {...register("first_name", {
                  required: "First Name is required",
                })}
              />
              {errors && errors.first_name && (
                <span className="text-danger">{errors.first_name.message}</span>
              )}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                id="last_name"
                className="form-control"
                placeholder="Doe"
                {...register("last_name", {
                  required: "Last Name is required",
                })}
              />
              {errors && errors.last_name && (
                <span className="text-danger">{errors.last_name.message}</span>
              )}
            </div>
          </div>

          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="nexa@test.com"
                {...register("email", {
                  required: "This field is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please provide a valid email address!",
                  },
                })}
              />
              {errors && errors.email && (
                <span className="text-danger">{errors.email.message}</span>
              )}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                id="phone"
                className="form-control"
                placeholder="+320238322"
                {...register("phone", {
                  required: "This field is required",
                })}
              />
              {errors && errors.phone && (
                <span className="text-danger">{errors.phone.message}</span>
              )}
            </div>
          </div>

          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50">
              <label htmlFor="linked_in">LinkedIn</label>
              <input
                type="text"
                id="linked_in"
                className="form-control"
                placeholder="https://example.com"
                {...register("linked_in", {
                  required: "This field is required",
                })}
              />
              {errors && errors.linked_in && (
                <span className="text-danger">{errors.linked_in.message}</span>
              )}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="twitter">Twitter</label>
              <input
                type="text"
                id="twitter"
                className="form-control"
                placeholder="https://example.com"
                {...register("twitter", {
                  required: "This field is required",
                })}
              />
              {errors && errors.twitter && (
                <span className="text-danger">{errors.twitter.message}</span>
              )}
            </div>
          </div>

          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                className="form-control"
                placeholder="Street #05, House"
                {...register("address", { required: "Address is required" })}
              />
              {errors && errors.address && (
                <span className="text-danger">{errors.address.message}</span>
              )}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                className="form-control"
                placeholder="New York"
                {...register("city", {
                  required: "City is required",
                })}
                onKeyPress={(e) => {
                  if (!/^[a-zA-Z\s]$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />

              {errors && errors.city && (
                <span className="text-danger">{errors.city.message}</span>
              )}
            </div>
          </div>

          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                className="form-control"
                placeholder="America"
                {...register("state", {
                  required: "State is required",
                })}
                onKeyPress={(e) => {
                  if (!/^[a-zA-Z\s]$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors && errors.state && (
                <span className="text-danger">{errors.state.message}</span>
              )}{" "}
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="website">Website</label>
              <input
                type="text"
                id="website"
                className="form-control"
                placeholder="https://example.com"
                {...register("website", {
                  required: "This field is required",
                })}
              />
              {errors && errors.website && (
                <span className="text-danger">{errors.website.message}</span>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end py-2 px-2 border-bottom border-primary">
            <button
              className="cancel-btn mr-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <SaveIcon></SaveIcon>
              Save
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default MyDetails;
