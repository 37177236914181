import React from "react";

export default function AdditionalSections({ getSingleResumeDoc, section }) {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  return (
    <section className="cv-certifications">
      <h2
        className="cv-section-title apply-font"
        style={{
          color: `${
            getSingleResumeDoc?.heading_color
              ? getSingleResumeDoc?.heading_color
              : "#2b6be2"
          }`,
          fontSize: `${
            getSingleResumeDoc?.font_size ? getSingleResumeDoc?.font_size : ""
          }`,
        }}
      >
        {section?.heading}
      </h2>
      <div className="cv-publication-item  ">
        <div className="flex justify-between w-full">
          {section?.title && (
            <h3
              className="cv-item-title mb-0 apply-font"
              style={{
                color: `${
                  getSingleResumeDoc?.subheading_color
                    ? getSingleResumeDoc?.subheading_color
                    : "#000"
                }`,
              }}
            >
              {section?.title}
            </h3>
          )}
          {section?.date && (
            <p className="cv-item-description apply-font mt-2">
              {formatDate(section?.date)}
            </p>
          )}
        </div>
        {section?.description && (
          <p className="cv-item-date apply-font">{section?.description}</p>
        )}
      </div>
    </section>
  );
}
