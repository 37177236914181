// import { Card, Grid } from "@mui/material";
// import Modal from "@mui/material/Modal";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
// import { getSubscription } from "store/Slices/authSlice";
// import { reActivatePackage } from "store/Slices/authSlice";
// import ChangePaymentModal from "./ChangePaymentModal";
import { useEffect, useState } from "react";
import { upgradeSubscription } from "../../Redux/DashboardSlice";
import { getUserSubscription } from "../../Redux/authenticate/authenticate.action";
import { cardBrands } from "../../Pages/Payments/data/paymentCardsData";
import ChangePaymentModal from "../ChangePaymentModal";
// import { checkOutSession } from "store/Slices/subscriptionSlice";
// import { toast } from "react-toastify";
// import { getPaymentCard } from "store/Slices/profileSlice";
// import MDBadge from "components/MDBadge";
// import { cardBrands } from "layouts/payments/data/CardsData";
// import { error, success } from "../../Utilities/toast";

const UpdateSubscriptionModal = ({
  openModal,
  setopenModal,
  activePricingId,
}) => {
  const dispatch = useDispatch();
  const { paymentCards } = useSelector((state) => state.paymentMethods);
  //   const { loading } = useSelector((state) => state.auth);
  //   const { declineError } = useSelector((state) => state.subScription);
  //   const { payments } = useSelector((state) => state.orgProfile);
  const [modal, setModal] = useState(false);

  const [modifiedPayment, setModifiedPayment] = useState(null);
  const onClose = () => {
    setModal(!modal);
  };
  //   const style = {
  //     position: "absolute",
  //     top: "50%",
  //     left: "50%",
  //     transform: "translate(-50%, -50%)",
  //     width: { xs: "90%", sm: "60%", md: "60%", lg: "50%" },
  //     boxShadow: 24,
  //     p: 4,
  //   };

  // const getCurrentSubscription = () => {
  // setbuttonStatusLoader(true);
  // dispatch(getSubscription())
  //   .unwrap()
  //   .then((res) => {
  //     setbuttonStatusLoader(false);
  //   })
  //   .catch((rejected) => {
  //     error(rejected?.response?.data?.detail);
  //     setbuttonStatusLoader(false);
  //   });
  // };

  const handleReactivate = () => {
    dispatch(upgradeSubscription(activePricingId))
      .unwrap()
      .then((res) => {
        console.log({ res });
        if (res.checkout_url) {
          window.location.href = res.checkout_url;
        } else {
          dispatch(getUserSubscription());
        }
      });
    setopenModal(false);
  };

  useEffect(() => {
    const data = paymentCards?.filter((item) => item?.isDefault);
    setModifiedPayment(data);
  }, [paymentCards]);
  const loading = false;
  return (
    <div>
      {openModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-4/5 md:w-2/3 lg:w-3/5 px-6 py-8 relative min-h-[200px]">
            <h3 className="text-center text-4xl font-medium mt-2">
              Are you sure you want to update your subscription?
            </h3>
            {modifiedPayment && (
              <div className="flex justify-between items-center p-4 rounded-md border mb-2 mt-4">
                <div className="flex items-center">
                  <img
                    src={
                      cardBrands.find(
                        (brand) => brand?.brand === modifiedPayment?.[0]?.brand
                      )?.img
                    }
                    alt="cardImg"
                    className="w-[50px] h-[40px] rounded mr-4"
                  />
                  <p>
                    {" "}
                    ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                    {modifiedPayment?.[0]?.last4}
                  </p>
                </div>
                <div>
                  {/* {item?.isDefault ? ( */}
                  <span className="inline-flex mx-3 items-center rounded-md bg-green-50 px-2 py-1 text-md font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Active
                  </span>
                  {/* ) : (
     <button
       className="mx-3 bg-primary px-3 py-2 text-md rounded-md font-medium text-white"
       // onClick={() =>
       //   disptach(setDefaultPaymentCard(item.id))
       //     .unwrap()
       //     .then(() => disptach(getAllPaymentCards()))
       //     .catch((err) => console.error(err))
       // }
     >
       Make Default
     </button>
   )} */}
                  {/* {paymentCards?.length > 1 && (
     <i
       className="ri-delete-bin-7-fill cursor-pointer text-lg"
       style={{ color: "#BA0707" }}
       onClick={() =>
         disptach(deletePaymentCard(item.id))
           .unwrap()
           .then(() => disptach(getAllPaymentCards()))
           .catch((err) => console.error(err))
       }
     />
   )} */}
                </div>
              </div>
            )}

            <div className="mt-6 mb-1 flex gap-4 justify-end">
              <button
                className={`${
                  loading
                    ? "bg-blue-500 text-white cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 text-white"
                } py-2 px-4 rounded-lg focus:outline-none`}
                onClick={handleReactivate}
                disabled={loading}
              >
                Continue with same payment method
              </button>
              <button
                className={`${
                  loading
                    ? "bg-blue-500 text-white cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 text-white"
                } py-2 px-4 rounded-lg focus:outline-none`}
                onClick={onClose}
                disabled={loading}
              >
                Update Payment Method
              </button>
              <button
                className={`${
                  loading
                    ? "bg-blue-500 text-white cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 text-white"
                } py-2 px-4 rounded-lg focus:outline-none`}
                onClick={() => setopenModal(false)}
                disabled={loading}
              >
                close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox mx={1} p={1} mt={1}>
            <MDTypography variant="h3" mt={1} sx={{ textAlign: "center", fontWeight: "medium" }}>
              Are you sure you want to update your subscription?
            </MDTypography>
            <Grid item xs={12} md={12} mt={2}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                p={3}
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: { xs: "center", sm: "space-between" },
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDBox
                  component="img"
                  // src={`/images/logos/${item?.brand}.png`}
                  src={
                    cardBrands.find((brand) => brand?.brand === modifiedPayment?.[0]?.brand)?.img
                  }
                  alt={modifiedPayment?.[0]?.brand}
                  width="10%"
                  // mr={2}
                  sx={{
                    marginRight: { xs: "0", sm: "20px" },
                    marginBottom: { xs: "20px", sm: "0" },
                  }}
                />
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  sx={{
                    marginBottom: { xs: "20px", sm: "0" },
                  }}
                >
                  ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{modifiedPayment?.[0]?.last4}
                </MDTypography>
                <MDBox
                  // ml="auto"
                  lineHeight={0}
                  // color={darkMode ? "white" : "dark"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: { xs: "0", sm: "auto" },
                  }}
                >
                  <MDBadge
                    badgeContent="Active"
                    color="success"
                    variant="gradient"
                    size="md"
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                </MDBox>
              </MDBox>
            </Grid>
            <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "15px", justifyContent: "end" }}>
              <MDButton variant="gradient" color="info" onClick={() => setopenModal(false)}>
                Close
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                disabled={loading}
                onClick={handleReactivate}
              >
                Continue with same payment method
              </MDButton>
              <MDButton variant="gradient" color="info" disabled={loading} onClick={onClose}>
                Update Payment Method
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal> */}
      {/* <ChangePaymentModal
        modal={modal}
        onClose={onClose}
        setopenModal={setopenModal}
        role="subscription"
        activePricingId={activePricingId}
      /> */}
      {/* {modal && ( */}
      <ChangePaymentModal
        modal={modal}
        setopenModal={setopenModal}
        onClose={onClose}
        role="subscriptionModal"
        activePricingId={activePricingId}
      />
      {/* )} */}
    </div>
  );
};

export default UpdateSubscriptionModal;
