import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addPoffesionalSummary,
  addResumeBlurb,
  deleteProfessionalSummary,
  deleteResumeBlurb,
  getResumeBlurb,
  udpateResumeBlurb,
  updateProffessionalSummary,
} from "../../Redux/ResumeBuilderSlice";
import { createMarkup } from "../../Utilities/CreateMarkup";
import RichTextEditor from "../RichTextEditor";
import { error, success, warning } from "../../Utilities/toast";
import { useParams } from "react-router-dom";
import {
  SaveIcon,
  MagicWandIcon,
  PersonalSummaryIcon,
  TestIcon,
} from "../../Components/Resume/svgimages";
const ProfessionalSummary = () => {
  // const { register, handleSubmit } = useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [proffSummary, setProffSummary] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [defaultContent, setDefaultContent] = useState("");
  console.log(
    editorContent,
    defaultContent,
    "defaultContentdefaultContentdefaultContent"
  );
  const [isFormShown, setisFormShown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [updateDocId, setUpdateDocId] = useState(null);
  const { professionalSummaries } = useSelector((state) => state.ResumeBuilder);

  useEffect(() => {
    if (professionalSummaries?.length > 0) {
      setProffSummary(professionalSummaries);
    }
  }, [professionalSummaries]);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleEdit = (index, id) => {
    setUpdateDocId(id);
    setSelectedIndex(index);
    const foundObj = proffSummary.find((obj, idx) => idx === index);
    setDefaultContent(foundObj.text);
    setisFormShown(true);
  };

  function onCancel() {
    setisFormShown(false);
    setSelectedIndex(-1);
    setEditorContent("");
    setDefaultContent("");
  }

  const validateContent = (editorContent) => {
    if (editorContent.trim() === "") {
      return "Please write something";
    }

    const words = editorContent.trim().split(/\s+/);

    if (words.length < 10) {
      return "Please write more than 10 words";
    }

    // for (let word of words) {
    //   if (word.length < 10) {
    //     return "Each word should be at least 10 characters long";
    //   }
    // }

    return null;
  };

  const submitSummary = () => {
    const err = validateContent(editorContent);
    if (err) {
      warning(err);
      console.error(err);
      return;
    }

    if (selectedIndex !== -1) {
      const data = {
        is_active: "false",
        text: editorContent !== "" ? editorContent : defaultContent,
      };
      const payload = {
        updateDocId,
        data,
      };
      dispatch(
        udpateResumeBlurb({
          payload,
          onSuccess: (res) => {
            success(res);
            getBlorbInfo();
          },
        })
      );
    } else {
      const payload = {
        is_active: "false",
        text: editorContent,
      };
      dispatch(
        addResumeBlurb({
          payload,
          id,
          onSuccess: (res) => {
            success(res);
            getBlorbInfo();
          },
        })
      );
    }
    onCancel();
  };

  const getBlorbInfo = () => {
    const resumeId = id;
    dispatch(getResumeBlurb({ resumeId, onSuccess: () => {} }));
  };

  const handleDelete = (payload) => {
    dispatch(
      deleteResumeBlurb({
        payload,
        onSuccess: (res) => {
          success(res);
          getBlorbInfo();
        },
      })
    );
  };

  return (
    <div className=" ">
      {isFormShown && (
        <>
          <div className=" customized-editor mt-2  ">
            <RichTextEditor
              defaultContent={defaultContent}
              setEditorContent={setEditorContent}
            />
          </div>

          <div className="d-flex justify-content-end py-2 px-2 border-bottom border-primary">
            <button
              type="button"
              className="cancel-btn mr-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                display: "flex",
              }}
              onClick={submitSummary}
            >
              <SaveIcon style={{ marginRight: "16px" }}></SaveIcon>
              Save
            </button>
          </div>
        </>
      )}

      {/* {!isFormShown && (
        <div className="professionalsummary-item">
          <button
            className="professionalitem-button"
            onClick={() => setisFormShown(true)}
          >
            <i className="ri-add-circle-line"></i>
            <span className="ml-2">Add Professional Summary</span>
          </button>
        </div>
      )} */}
      {professionalSummaries?.length > 0 &&
        professionalSummaries.map((item, index) => (
          <div
            key={index}
            className="professionalsummary-item"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <p dangerouslySetInnerHTML={createMarkup(item?.text)} />
            {hoveredIndex === index && (
              <div className="professionalitem-actions">
                <button
                  className="professionalitem-button"
                  onClick={() => handleEdit(index, item?.id)}
                >
                  <i className="ri-edit-line"></i>
                </button>
                <button
                  className="professionalitem-button"
                  onClick={() => handleDelete(item?.id)}
                >
                  <i className="ri-delete-bin-line"></i>
                </button>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ProfessionalSummary;
