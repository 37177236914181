import React from "react";
import { useRecoilState } from "recoil";
import { IoMdAdd } from "react-icons/io";
import { produce } from "immer";
import TemplateSelctedFields from "./TemplateSelctedFields";
import { StepStateAtom } from "../../../../recoil/ResumeAdditionalSection/StepsState";

const ModifyTemplate = () => {
  const [stepState, setStepState] = useRecoilState(StepStateAtom);
  const selectedTempPerArr = stepState?.selectedTheme?.permissions;

  // ================  Add New Section  =================
  const handleAddSection = () => {
    const payload = [...selectedTempPerArr];
    if (payload.length > 0) {
      const lastObject = payload[payload.length - 1];
      payload.push(lastObject);
    }
    setStepState(
      produce((draft) => {
        draft.selectedTheme.permissions = payload;
        const lastSectionDetail =
          draft.selectedTheme.sectionDetail[
            draft.selectedTheme.sectionDetail.length - 1
          ];
        const emptySectionDetail = Object.keys(lastSectionDetail).reduce(
          (acc, key) => {
            acc[key] = "";
            return acc;
          },
          {}
        );
        draft.selectedTheme.sectionDetail = [
          ...draft.selectedTheme.sectionDetail,
          emptySectionDetail,
        ];
      })
    );
  };

  return (
    <div className="p-2 w-full mt-1 mb-16">
      {/* ================  Field  =================  */}
      <TemplateSelctedFields />
      <div className="flex justify-end mt-2">
        <button
          className="px-2 py-2 bg-blue-600 text-white rounded-md flex gap-1 justify-center items-center"
          onClick={handleAddSection}
        >
          <IoMdAdd color="white" size={20} />
          Add New
        </button>
      </div>
    </div>
  );
};

export default ModifyTemplate;
