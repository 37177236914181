import React, { useCallback, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import { CiSettings } from "react-icons/ci";
import { IoCloseSharp } from "react-icons/io5";
import { StepStateAtom } from "../../../../recoil/ResumeAdditionalSection/StepsState";
const ManageToggle = ({ handleToggleDropdown, openStates, index }) => {
  const dropdownRefs = useRef([]);
  const [stepState, setStepState] = useRecoilState(StepStateAtom);
  const selectedTempPerArr = stepState?.selectedTheme?.permissions;
  const selectedSectionArr = stepState?.selectedTheme?.sectionDetail;

  //================ Mange Permissions =================
  const handleToggle = (key, index) => {
    const payload = {
      ...selectedTempPerArr[index],
      [key]: !selectedTempPerArr[index][key],
    };
    setStepState(
      produce((draft) => {
        if (draft.selectedTheme.permissions) {
          draft.selectedTheme.permissions[index] = payload;
          if (
            draft.formErrors &&
            draft.formErrors[index] &&
            draft.formErrors[index][key]
          ) {
            draft.formErrors[index][key] = "";
          }
        }
      })
    );
  };

  //================= Handle Click Outside =================
  const handleClickOutside = useCallback(
    (event) => {
      if (
        dropdownRefs.current[index] &&
        !dropdownRefs.current[index].contains(event.target)
      ) {
        handleToggleDropdown(index);
      }
    },
    [dropdownRefs, index, handleToggleDropdown]
  );

  useEffect(() => {
    if (openStates[index]) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openStates, index, handleClickOutside]);

  return (
    <div className="flex w-full justify-end -mb-2 relative">
      <div
        className="px-3 py-1 text-sm font-semibold rounded-md bg-[#2a6be3] text-white shadow-md cursor-pointer flex gap-2 justify-center items-center relative mr-2"
        onClick={() => handleToggleDropdown(index)}
      >
        <CiSettings size={22} />
        Manage Fields
      </div>
      {selectedSectionArr &&
        selectedSectionArr[index] &&
        selectedSectionArr.length > 1 && (
          <div
            className="px-[0.10rem] rounded-full py-[0.10rem] cursor-pointer bg-[#FF0000] absolute -right-6 -top-4"
            onClick={() => {
              setStepState(
                produce((draft) => {
                  draft.isDeleteSection = {
                    index: index,
                    value: true,
                  };
                })
              );
            }}
          >
            <IoCloseSharp size={20} color="#fff" />
          </div>
        )}
      {openStates[index] && (
        <div
          ref={(el) => (dropdownRefs.current[index] = el)}
          className="absolute right-0 mt-7 w-64 bg-white border border-gray-200 rounded-md shadow-lg z-[999]"
        >
          {Object.entries(selectedTempPerArr[index]).map(([key, value]) => (
            <div
              key={key}
              className="flex items-center justify-between py-2 px-4 border-b last:border-b-0"
            >
              <span className="text-gray-800 capitalize">
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </span>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={value}
                  onChange={() => handleToggle(key, index)}
                  className="sr-only peer"
                />
                <div
                  className={`relative w-11 h-6 rounded-full transition-all ${
                    value ? "bg-blue-600" : "bg-gray-200"
                  } peer-focus:outline-none peer-focus:ring-4 ${
                    value
                      ? "peer-focus:ring-blue-300"
                      : "peer-focus:ring-gray-300"
                  }`}
                >
                  <span
                    className={`absolute top-[2px] left-[2px] h-5 w-5 bg-white border border-gray-300 rounded-full transition-transform ${
                      value ? "translate-x-full border-white" : "translate-x-0"
                    }`}
                  />
                </div>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManageToggle;
