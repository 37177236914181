import React from "react";

const RoundedContainer = ({ children, className = "", isPadding = true }) => {
  let paddingClass = isPadding ? "p-5 sm:p-10" : "";
  return (
    <div className={`container my-11 ${className}`}>
      <div
        className={`border min-h-screen border-black rounded-3xl border-opacity-40 ${paddingClass}`}
      >
        {children}
      </div>
    </div>
  );
};

export default RoundedContainer;