import ReactModal from "react-modal";
import { IoMdClose } from "react-icons/io";

const RejectionResponseModal = ({ isOpen, onClose, text }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0px",
          maxWidth: "500px",
          width: "90%",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <div>
        <div className="flex items-center border-b border-gray-300 py-3 px-4 w-full justify-between">
          <h2 className="text-lg font-semibold">Reason for Rejection</h2>
          <IoMdClose onClick={onClose} className="cursor-pointer" />
        </div>
        <div className="flex flex-col py-3 px-4 w-full">
          <p>{text}</p>
        </div>
      </div>
    </ReactModal>
  );
};

export default RejectionResponseModal;
