import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/header/index";
import backIcon from "../../assets/icons/backIcon.svg";

function Hospitality() {
  const [department, setDepartment] = useState("");
  const [challenge, setChallenge] = useState("");
  const [industry, setIndustry] = useState("");
  const [situation, setSituation] = useState("");
  const [progress, setProgress] = useState(0);
  const [animateCard, setAnimateCard] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let filledFields = 0;
    if (department) filledFields++;
    if (challenge) filledFields++;
    if (industry) filledFields++;
    if (situation) filledFields++;
    setProgress((filledFields / 4) * 100); // Dividing by 4 because you have 4 fields
  }, [department, challenge, industry, situation]);

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(100);
      }, 300); // Slight delay to simulate smooth loading
    }
  }, [progress]);

  return (
    <>
      <Header />
      <div style={styles.container}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "0 20px",
          }}
        >
          <button
            onClick={() => {
              navigate({ pathname: `/octagon` }, { replace: true });
            }}
            className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 mt-2"
          >
            <img src={backIcon} alt="Back" />
          </button>
        </div>
        <div style={styles.progressBarContainer}>
          <div style={{ ...styles.progressBar, width: `${progress}%` }}></div>
        </div>
        <div
          style={{
            ...styles.card,
            opacity: animateCard ? 1 : 0,
            transform: animateCard ? "translateY(0)" : "translateY(20px)",
          }}
        >
          <h2 style={styles.title}>Create your Management Scenario</h2>

          <div style={styles.inputGroup}>
            <label style={styles.label}>Choose your Department</label>
            <select
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              style={styles.select}
            >
              <option value="">Select...</option>
              <option value="1">Technology/IT</option>
              <option value="2">Sales</option>
              <option value="3">HR</option>
              <option value="4">Operations</option>
            </select>
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}>Management Challenge</label>
            <select
              value={challenge}
              onChange={(e) => setChallenge(e.target.value)}
              style={styles.select}
            >
              <option value="">Select...</option>
              <option value="1">Team Conflict Resolution</option>
              <option value="2">Project Deadline Management</option>
              <option value="3">Performance Review and Feedback</option>
              <option value="4">Resource Allocation</option>
            </select>
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}> Industry</label>
            <select
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              style={styles.select}
            >
              <option value="">Select...</option>
              <option value="1">Technology</option>
              <option value="2">Healthcare</option>
              <option value="3">Financial Services</option>
              <option value="4">Manufacturing</option>
              <option value="5">Retail & Ecommerce</option>
              <option value="6">Automotive</option>
              <option value="7">Real Estate</option>
              <option value="8">Telecommunications</option>
              <option value="9">Insurance</option>
              <option value="10">Government</option>
            </select>
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}>
              Any Specific Situational Parameters?
            </label>
            <input
              type="text"
              value={situation}
              onChange={(e) => setSituation(e.target.value)}
              placeholder="Personalize your Scenario"
              style={styles.input}
            />
          </div>

          <button style={styles.button}>Start My Management Simulation</button>
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f0f2f5",
    paddingTop: "100px",
    boxSizing: "border-box",
  },
  progressBarContainer: {
    height: "8px",
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
    zIndex: 100,
    maxWidth: "600px",
    width: "90%",
    margin: "40px auto 0",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#0077cc",
    borderRadius: "4px",
    transition: "width 0.3s ease",
  },
  card: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
    padding: "20px",
    maxWidth: "600px",
    width: "90%",
    margin: "0px auto",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    boxSizing: "border-box",
    height: "560px",
    maxHeight: "85vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Ensure the content is spaced and the button stays at the bottom
  },
  title: {
    textAlign: "center",
    color: "#0077cc",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  inputGroup: {
    marginBottom: "25px", // Increase this for more space between fields
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  select: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    backgroundColor: "#0077cc",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "auto", // Ensure it sticks to the bottom
  },
};

export default Hospitality;
