import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/footer_new/footer";
import Header from "../../Components/header_new/navbar";

const ComingSoon = () => {
  const [timer, setTimer] = useState({
    days: "20",
    hours: "22",
    minutes: "32",
    seconds: "45",
  });
  const Ref = useRef(null);

  useEffect(() => {
    clearTimer(getDeadTime());
    return () => {
      if (Ref.current) clearInterval(Ref.current);
    };
  }, []);

  const startTimer = (e) => {
    const t = Date.parse(e) - Date.parse(new Date());

    var dd = Math.floor(t / (1000 * 60 * 60 * 24));
    var hh = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var mm = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var ss = Math.floor((t % (1000 * 60)) / 1000);

    var days = dd < 10 ? "0" + dd : dd;
    var hours = hh < 10 ? "0" + hh : hh;
    var minutes = mm < 10 ? "0" + mm : mm;
    var seconds = ss < 10 ? "0" + ss : ss;

    setTimer({ days, hours, minutes, seconds });
  };

  const clearTimer = (e) => {
    setTimer({
      days: "20",
      hours: "22",
      minutes: "32",
      seconds: "45",
    });

    if (Ref.current) clearInterval(Ref.current);
    Ref.current = setInterval(() => {
      startTimer(e);
    }, 1000);
  };

  const getDeadTime = () => {
    let deadline = new Date("Apr 26, 2024 00:00:00");

    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  return (
    <div>
      <Header />

      <div
        style={{
          textAlign: "center",
          padding: "20px",
          maxWidth: "600px",
          margin: "100px auto 320px",
          background: "rgba(255, 255, 255, 0.75)", // Glassmorphism effect
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(10px)", // Blur effect for glassmorphism
          borderRadius: "16px",
          border: "1px solid rgba(255, 255, 255, 0.18)",
        }}
      >
        <div
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            marginBottom: "10px",
            color: "#222",
            textShadow: "0 1px 3px rgba(0, 0, 0, 0.2)", // Slight shadow for text depth
          }}
        >
          Coming Soon
        </div>
        <p
          style={{
            fontSize: "1.25rem",
            color: "#444",
            lineHeight: "1.8",
            marginBottom: "20px",
          }}
        >
          We love that you are interested in seeing our awesome use cases. They
          should be uploaded this week. In the meantime, come chat with us and
          we can show you in real-time!
        </p>
        <Link to="/start-interview" style={{ textDecoration: "none" }}>
          <button
            style={{
              padding: "15px 30px",
              fontSize: "1.25rem",
              color: "#fff",
              background: "linear-gradient(90deg, #007bff, #0056b3)",
              border: "none",
              borderRadius: "50px",
              cursor: "pointer",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            onMouseOver={(e) => {
              e.target.style.transform = "scale(1.05)";
              e.target.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.3)";
            }}
            onMouseOut={(e) => {
              e.target.style.transform = "scale(1)";
              e.target.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
            }}
          >
            Learn More
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default ComingSoon;
