import React from "react";

const SubTitleField = ({ handleInputChange, fieldValue }) => {
  return (
    <>
      <label
        htmlFor="subtitle"
        className="block text-sm font-medium text-gray-700"
      >
        Sub Title
      </label>
      <input
        type="text"
        name="subtitle"
        id="subtitle"
        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        placeholder="AIESEC"
        value={fieldValue || ""}
        onChange={handleInputChange}
      />
    </>
  );
};

export default SubTitleField;
