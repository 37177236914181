import React from "react";
import SimulatorPro from "../SimulatorPro";
import { useParams } from "react-router-dom";
import Loader from "../../Components/main-loader";
import { useSelector } from "react-redux";

export default function SmartScreenInterview() {
  const { token } = useParams();
  const { isSessionLoading } = useSelector(
    (state) => state.SimulatorProSession
  );
  console.log(isSessionLoading, "isSessionLoading");
  return (
    <>
      {isSessionLoading && <Loader />}
      <SimulatorPro sessionsId={token} />;
    </>
  );
}
