import React from 'react';

const TabComponent = () => {
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '50px',
      borderBottom: '1px solid #ddd',
      padding: '0 10px',
    },
    tabButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '5px',
      fontSize: '14px',
      color: '#333',
      padding: '10px 20px',
      borderRadius: '5px 5px 0 0',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      position: 'relative',
      marginRight: '5px',
    },
    selectedTabButton: {
      backgroundColor: '#f7f7f7',
      borderBottom: '3px solid #007BFF', // Blue line to indicate selected
      color: '#007BFF',
    },
    icon: {
      display: 'inline-block',
      width: '14px',
      height: '14px',
      marginRight: '5px',
    },
    descriptionIcon: {
      color: '#007BFF',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    mainText: {
      fontSize: '14px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    subText: {
      fontSize: '12px',
      color: '#666',
      position: 'absolute',
      top: '20px',
      left: '5px',
      opacity: '0.7',
    },
  };

  return (
    <div style={styles.container}>
      {/* Description Tab */}
      <div style={{ ...styles.tabButton, ...styles.selectedTabButton }}>
        <div style={{ ...styles.icon, ...styles.descriptionIcon }}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="memo"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            style={{ width: '18px', height: '18px' }}
          >
            <path
              fill="currentColor"
              d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm120 64H264c13.3 0 24 10.7 24 24s-10.7 24-24 24H120c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 96H264c13.3 0 24 10.7 24 24s-10.7 24-24 24H120c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 96h48c13.3 0 24 10.7 24 24s-10.7 24-24 24H120c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
            />
          </svg>
        </div>
        <div style={styles.textContainer}>
          <div style={styles.mainText}>Description</div>

        </div>
      </div>

      {/* Editorial Tab */}
      
    </div>
  );
};

export default TabComponent;
