import React, { useState, useRef, useEffect } from "react";
import { MdDone, MdClose, MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { success } from "../../../Utilities/toast";
import {
  getResumeLanguages,
  updateResumeLanguages,
} from "../../../Redux/ResumeBuilderSlice";
import { set } from "immutable";

export default function Languages({ languages, getSingleResumeDoc }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeLanguageIndex, setActiveLanguageIndex] = useState(null);
  const [hoveredLanguageIndex, setHoveredLanguageIndex] = useState(null);
  const [languageState, setLanguageState] = useState([]);
  const editableRefs = useRef({});

  const iconStyle = {
    position: "absolute",
    top: "-10px",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.4rem",
    color: "#fff",
    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    cursor: "pointer",
  };

  const handleFieldClick = (index) => {
    if (activeLanguageIndex !== null) submitChanges();
    setActiveLanguageIndex(index);
    setTimeout(() => {
      editableRefs.current[index]?.focus();
    }, 0);
  };

  const handleFieldInput = (index, event) => {
    const value = event.currentTarget.textContent;
    const selection = window.getSelection();
    const currentNode = editableRefs.current[index];
    if (
      selection.rangeCount > 0 &&
      currentNode.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const cursorPosition = range.startOffset;
      setLanguageState((prev) =>
        prev?.map((language, idx) => (idx === index ? value : language))
      );
      setTimeout(() => {
        const node = currentNode.firstChild;
        if (node) {
          const newPosition = Math.min(cursorPosition, node.textContent.length);
          range.setStart(node, newPosition);
          range.setEnd(node, newPosition);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    } else {
      setLanguageState((prev) =>
        prev?.map((language, idx) => (idx === index ? value : language))
      );
    }
  };

  const fetchLanguages = () => {
    dispatch(getResumeLanguages({ resumeId: id, onSuccess: () => {} }));
  };
  const submitChanges = () => {
    const originalLanguage = languages?.languages?.[activeLanguageIndex];
    const updatedLanguage = languageState[activeLanguageIndex];
    const hasChanges =
      originalLanguage && originalLanguage !== updatedLanguage?.trim();
    if (hasChanges) {
      setActiveLanguageIndex(null);
      setHoveredLanguageIndex(null);
      dispatch(
        updateResumeLanguages({
          languageId: languages?.id,
          payload: { languages: languageState },
          onSuccess: (res) => {
            success(res);
            fetchLanguages();
          },
          onError: () => {
            setLanguageState(languages?.languages);
          },
        })
      );
    } else {
      setActiveLanguageIndex(null);
      setHoveredLanguageIndex(null);
    }
  };

  const renderLanguage = (language, index) => {
    const isActive = activeLanguageIndex === index;
    const isHovered = hoveredLanguageIndex === index;
    const originalLanguage = languages?.languages?.[index];
    const hasChanges =
      originalLanguage && originalLanguage !== language?.trim();

    return (
      <span
        className={`editable-field ${isActive || isHovered ? "active" : ""}`}
        onMouseEnter={() => setHoveredLanguageIndex(index)}
        onMouseLeave={() => setHoveredLanguageIndex(null)}
        style={{
          position: "relative",
          border: isActive || isHovered ? "1px solid #ffff" : "none",
          backgroundColor: isActive || isHovered ? "#ffff" : "transparent",
          fontSize: "inherit",
          cursor: "text",
        }}
      >
        <span
          contentEditable={isActive}
          className={`editable-field `}
          ref={(el) => (editableRefs.current[index] = el)}
          onInput={(e) => handleFieldInput(index, e)}
          onBlur={submitChanges}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          suppressContentEditableWarning={true}
        >
          {language}
        </span>
        {isActive && hasChanges && (
          <MdDone
            onClick={submitChanges}
            style={{ ...iconStyle, left: "-20px", background: "#4caf50" }}
          />
        )}
        {isActive && (
          <MdClose
            onClick={() => setActiveLanguageIndex(null)}
            style={{ ...iconStyle, right: "-20px", background: "#ff4d4d" }}
          />
        )}
        {isHovered && !isActive && (
          <MdOutlineModeEdit
            onClick={() => handleFieldClick(index)}
            style={{ ...iconStyle, right: "-20px", background: "#2b6be2" }}
          />
        )}
      </span>
    );
  };

  useEffect(() => {
    if (languages) setLanguageState(languages?.languages);
  }, [languages]);

  return (
    <section className="cv-languages">
      {languages && languages?.languages?.length > 0 && (
        <ul className="cv-skills-list d-flex justify-content-start">
          {languageState?.map((language, index) => (
            <li className="cv-skill_top apply-font" key={index}>
              <span className="font-bold text-lg">•</span>{" "}
              {renderLanguage(language, index)}
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
