const FormInput = ({
  label,
  name,
  type = "text",
  placeholder,
  value,
  onChange,
}) => (
  <div>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <input
      name={name}
      type={type}
      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md cutumPlaceholder bg-[#ffffff] text-black"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default FormInput;
