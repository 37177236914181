import React, { useEffect } from "react";
import Header from "../../Components/header";
import Chats from "../../Components/Chatting/index";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "../../Redux/ChatSlice";
import Loader from "../../Components/main-loader";
const ChatPage = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.chat);
  useEffect(() => {
    dispatch(getUserId());
  }, []);
  return (
    <div>
      <Header />
      {loading && <Loader />}
      <div className="pt-40">
        <Chats />
      </div>
    </div>
  );
};

export default ChatPage;
