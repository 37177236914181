import { Link } from "react-router-dom";
import Sec3 from "../../assets/Images/sec3.png";

export default function Section6() {
  return (
    <div className="space-y-20">
      <div className="space-y-5">
        <h1 className="Poppins text-4xl text-center mt-0">
          Your Data’s Security and Privacy
        </h1>
        <div className="flex justify-center items-center gap-x-1">
          <h1 className="Poppins text-4xl text-center h-12">Our</h1>
          <div className="h-12">
            <h1
              className="Poppins text-4xl text-center mt-0"
              style={{ color: "#2B6BE2" }}
            >
              Top Priority
            </h1>
            <img className="mx-auto my-0" src={Sec3} alt="" />
          </div>
        </div>
      </div>
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between  items-center bg-[#2B6BE2] p-10 rounded-3xl space-y-10 md:space-y-0">
          <ul className="list-disc pl-3 md:pl-10 space-y-2">
            <li className="text-xl md:text-2xl text-white font-semibold">
              End-to-End Encryption
            </li>
            <li className="text-xl md:text-2xl text-white font-semibold">
              24/7 Monitoring
            </li>
            <li className="text-xl md:text-2xl text-white font-semibold">
              GDPR & CCPA Compliance
            </li>
            <li className="text-xl md:text-2xl text-white font-semibold">
              Regular Security Audits
            </li>
          </ul>

          {/* <button style={{ background: "#FFFFFF", color: "#2B6BE2" }} className=" shadow-black shadow-2xl  font-extrabold w-52 h-12 text-lg rounded-full hover:bg-blue-700 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 Satoshi my-0 mx-auto text-center">
                        Read More
                    </button> */}
          <Link to="/privacy-policy">
            <button className="bg-white-color text-[#2B6BE2]  font-semibold  text-lg rounded-full  px-6 py-2 shadow-black shadow-2xl hover:bg-blue-700 transition ease-in-out delay-150 hover:text-white hover:scale-110 duration-700">
              Read More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
