import React, { useEffect, useRef } from "react";
import CountUp from "react-countup";
import { FaHandshake, FaHouseUser, FaUsers } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Typed from "typed.js";
import gif1 from "../../assets/Images/ai-web.gif";
import newElevate from "../../assets/Images/newElevate.png";
import SliderSection from "./SliderSection";

const HeroSection = () => {
  const navigate = useNavigate();
  const typedRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedRef.current, {
      strings: [
        "Cybersecurity",
        "IT",
        "Data Analytics",
        "Software",
        "Sales",
        "Finance",
        "Marketing",
      ],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 2000,
      startDelay: 500,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="hero-container">
      <div className="container hero-internal-container">
        <div className="gradient-right"></div>
        <div className="flex justify-start" style={{ marginTop: "-85px" }}>
          {/* <video
          className="object-contain gif"
          src={gif}
          alt="AI Illustration"
          autoPlay
          loop
          muted
        /> */}
          <img
            src={gif1}
            alt="AI Illustration"
            className="object-contain gif"
          />
        </div>

        <div className="text-center hero-content">
          <div className="flex flex-col items-center justify-center gap-5 relative px-4 gapResp">
            {/* Main Heading */}
            <div className="flex flex-wrap justify-center items-center gap-3 lg:gap-5">
              <div className="relative inline-block">
                <span className="relative z-10 font-bold Poppins text-3xl sm:text-4xl md:text-5xl lg:text-[48px]">
                  Unlock Faster
                </span>
                <span className="absolute -bottom-2 left-0 w-full border-b-4 border-black transform -rotate-3"></span>
              </div>
              <h1 className="Poppins font-bold text-3xl sm:text-4xl md:text-5xl lg:text-[48px] text-center">
                <span className="text-[#2B6BE2]">Job Placements</span>
              </h1>
            </div>

            {/* Subheadings */}
            <div className="text-center leading-relaxed">
              <h1 className="Poppins font-bold text-2xl sm:text-3xl md:text-4xl lg:text-[48px]">
                for Your <span className="text-[#2B6BE2]">Candidates</span> With{" "}
                <span className="text-[#2B6BE2]">Nexa’s</span>
              </h1>
              <h1 className="Poppins font-bold text-2xl sm:text-3xl md:text-4xl lg:text-[48px] Resp-mrg">
                <span className="text-[#2B6BE2]">All-in-One</span> Platform
              </h1>
            </div>
          </div>

          <p className="hero-description color-dark mt-4">
            Nexa Elevate® provides a comprehensive, AI-powered toolkit that
            takes care of every step from career preparation to job placement,
            seamlessly integrating into your training programs. We address your
            key pain points by offering personalized training paths, real-time
            feedback, and job-matching capabilities, ensuring that your learners
            are fully prepared and positioned to land jobs in
            <span>
              <h2 className="color-primary">
                <span ref={typedRef}></span>
              </h2>
            </span>
          </p>
          <br />
          <div
            className="button-group"
            style={{
              display: "flex",
              justifyContent: "center", // Center the buttons horizontally
              alignItems: "center", // Ensure vertical alignment is centered
              gap: "20px", // Gap between the buttons
              flexWrap: "wrap", // Allow wrapping on smaller screens
              marginTop: "20px",
            }}
          >
            <Link
              to="/register"
              className="ic-btn"
              style={{
                background: "none",
                flex: "1 1 auto",
                maxWidth: "320px",
              }}
            >
              <button
                class=" shadow-black shadow-2xl text-white font-bold w-56 py-2 text-lg rounded-full hover:bg-blue-700 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 Satoshi my-auto mx-0"
                style={{
                  background: "#2b6be2",
                }}
              >
                Start for Free
              </button>
            </Link>

            {/* <a
              href="https://tally.so/r/3yDKdg"
              className="ic-btn"
              style={{
                background: "none",
                flex: "1 1 auto",
                maxWidth: "320px",
              }}
            >
              <button
                className="my-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px 20px", // Adjust padding to match the size
                  borderRadius: "25px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  lineHeight: "1.4",
                  background: "linear-gradient(135deg, #4a6fe9, #7f5be0)",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  border: "none",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.3s ease",
                  margin: "0",
                  width: "100%",
                  height: "70px",
                  maxWidth: "320px", // Same max-width for both buttons
                }}
                onMouseOver={(e) => {
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseOut={(e) => {
                  e.target.style.transform = "scale(1)";
                }}
              >
                Career Center <br />
                Efficiency Assessment
              </button>
            </a> */}

            {/* Add styling for mobile responsiveness */}
            <style jsx>{`
              @media (max-width: 768px) {
                .button-group {
                  flex-direction: column; /* Stack buttons vertically */
                  align-items: center; /* Center buttons on mobile */
                }

                .ic-btn {
                  width: 100%; /* Make buttons fill the available space */
                  max-width: none; /* Ensure they use full width */
                  margin-bottom: 10px; /* Add spacing between stacked buttons */
                }
              }
            `}</style>
          </div>
        </div>

        <div
          className="margin-vid"
          style={{ marginTop: "-85px", marginBottom: "-20px" }}
        >
          {/* <video
          className="gif"
          src={gif}
          alt="AI Illustration"
          autoPlay
          loop
          muted
        /> */}
          <img
            src={gif1}
            alt="AI Illustration"
            className="object-contain gif mt-[-50px] md:mt-0"
          />
        </div>
        <div className="gradient-left"></div>
        <div className="mt-4 px-0 px-sm-5">
          <div className="mt-4 px-0 px-sm-5 h-sm-[500px] h-md-[750px] flex justify-center items-center">
            <img
              src={newElevate}
              className="transform transition-transform hover:scale-125 rounded-xl duration-500 h-[80%] w-[90%] md:w-[100%] bg-cover"
            />
          </div>
        </div>

        <SliderSection />

        <div className="hero-counter-card d-flex justify-content-center align-items-center gap-6 flex-col md:flex-row">
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaUsers size={65} />
              <CountUp end={724} duration={4} />
              <p>People helped land jobs</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaHouseUser size={65} />
              <CountUp end={52} duration={4} />
              <p>Training Institutes that use nexa</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 hero-counter_box">
            <div className="hero-counter_box-inner d-flex justify-content-center align-items-center flex-col h-full w-full">
              <FaHandshake size={65} />
              <CountUp end={565} duration={4} />
              <p>Active job seekers</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
