import { useDispatch, useSelector } from "react-redux";
import Attachment from "../../assets/media/images/Attachment.png";
import Smiley from "../../assets/media/images/Smiley.png";
import React, { useEffect, useRef, useState } from "react";
import { sendMessage } from "../../config/socketConfig";
import {
  sendMessageAction,
  updateChatMessages,
  updateConversations,
} from "../../Redux/ChatSlice";
import { toast } from "react-toastify";
import { error, success } from "../../Utilities/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faShareNodes,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import EmojiPicker from "emoji-picker-react";
import { CiPaperplane } from "react-icons/ci";
const ChatFooter = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const fileRef = useRef(null);
  const emojiRef = useRef(null);
  const { activeConversation } = useSelector((state) => state.chat);
  const [sendLoader, setSendLoader] = useState(false);
  let [isSmiley, setisSmiley] = useState(false);
  const [message, setMessage] = useState("");
  const [media, setMedia] = useState(null);
  const handleClick = () => {
    fileRef.current.click();
  };
  const handleClearFile = () => {
    setMedia(null);
    if (fileRef.current) {
      fileRef.current.value = null;
    }
  };
  const handleEmojiSelect = (emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.emoji);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setisSmiley(false);
      }
    };
    if (isSmiley) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSmiley]);
  return (
    <>
      <div className="w-full flex-shrink-0 rounded-br-3xl bg-gray-500 bg-opacity-5 p-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSendLoader(true);
            let formData = new FormData();
            formData.append("chat_id", activeConversation?.id);
            formData.append("content", message);
            formData.append("media", media == null ? "" : media);
            if (media != null && message != "") {
              formData.append("message_type", "both");
            } else if (media != null && message == "") {
              formData.append("message_type", "media");
            } else if (message != "" && media == null) {
              formData.append("message_type", "text");
            } else {
              error("Please upload a file or enter a message");
              setSendLoader(false);
              return;
            }
            dispatch(
              sendMessageAction({
                formData,
                onSuccess: (data) => {
                  if (data.status === true) {
                    let senderMessages = {
                      ...data?.response,
                      is_my_message: true,
                      updatedConversation: {
                        ...activeConversation,
                        last_message: data?.response,
                        unread_messages: data?.response?.unread_messages,
                      },
                    };
                    let receiverMessages = {
                      ...data?.response,
                      is_my_message: false,
                      updatedConversation: {
                        ...activeConversation,
                        last_message: data?.response,
                        unread_messages: data?.response?.unread_messages,
                        members: [
                          {
                            name: activeConversation?.is_group
                              ? activeConversation?.members[0]?.name
                              : data?.response?.sender_name,
                            image: activeConversation?.is_group
                              ? activeConversation?.members[0]?.image
                              : data?.response?.sender_image,
                            user_id: activeConversation?.is_group
                              ? activeConversation?.members[0]?.user_id
                              : data?.response?.sender_id,
                          },
                        ],
                      },
                    };
                    console.log(receiverMessages, "receiverMessages");
                    dispatch(updateChatMessages(senderMessages));
                    dispatch(
                      updateConversations(senderMessages?.updatedConversation)
                    );
                    sendMessage(receiverMessages);
                    setMessage("");
                    setMedia(null);
                    setSendLoader(false);
                  } else {
                    setSendLoader(false);
                  }
                },
              })
            );
          }}
        >
          <textarea
            className="bg-gray-500 bg-opacity-10 w-full resize-none rounded-lg p-3"
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevent new line
                if (!sendLoader) {
                  e.target.form.requestSubmit(); // Trigger form submission
                }
              }
            }}
          />
          <div className="flex">
            <div className="flex">
              <button type="button" className="mr-3 relative">
                <img
                  type="button"
                  alt="FooterImage"
                  width={29}
                  src={Smiley}
                  onClick={() => setisSmiley((prevState) => !prevState)}
                />
                {isSmiley && (
                  <div className="absolute emoji " ref={emojiRef}>
                    <EmojiPicker
                      onEmojiClick={(e) => {
                        handleEmojiSelect(e);
                      }}
                    />
                  </div>
                )}
              </button>

              <button type="button" className="mr-3" onClick={handleClick}>
                <img alt="FooterImage" width={20} src={Attachment} />
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const fileSizeInMB = file.size / 1024 / 1024; // Convert bytes to MB
                      if (fileSizeInMB > 1) {
                        error("File size must be less than 1 MB");
                        e.target.value = null; // Reset the input if the file is too large
                      } else {
                        setMedia(file);
                      }
                    }
                  }}
                  ref={fileRef}
                  hidden
                  accept=".pdf, .docx, .doc, .zip, .txt, .jpg, .jpeg, .png, .svg, .mp4, .webm, .jfif"
                />
              </button>
              {media && (
                <button
                  type="text"
                  className="bg-[#e9ebed] text-[#626262] px-4 rounded-lg ml-3"
                  title={media.name}
                >
                  <FontAwesomeIcon
                    className="ml-auto cursor-pointer mr-2"
                    icon={faFileAlt}
                  />
                  {media.name?.length > 10
                    ? `${media?.name?.slice(0, 10)}...`
                    : media.name}
                  <FontAwesomeIcon
                    className="ml-auto cursor-pointer text-red-600"
                    icon={faTimes}
                    onClick={() => {
                      handleClearFile();
                    }}
                  />
                </button>
              )}
            </div>
            <button
              type="submit"
              className="ml-auto text-gray-500  font-semibold"
              disabled={sendLoader}
            >
              {sendLoader ? (
                "Sending..."
              ) : (
                <CiPaperplane className="icon-class font-bold text-gray-500" />
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChatFooter;
