import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/main-loader";
import {
  authenticateAction,
  createUser,
  ssoLoginAction,
} from "../../Redux/authenticate/authenticate.action";
import { error } from "../../Utilities/toast";
import "../Login/style.css";
import { useGoogleLogin } from "@react-oauth/google";
import {
  getPricingBundles,
  stripeCheckoutAction,
} from "../../Redux/DashboardSlice";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.authenticate);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullname, setFullname] = useState("");

  const form = {
    errors: {},
  };
  const status = null;
  const registerForTrialBundle = async () => {
    let freePlanID = "2lNbMnPO8W";
    return dispatch(stripeCheckoutAction(freePlanID))
      .unwrap()
      .then((res) => {
        console.log("Registered for TRIAL plan: ", res);
        // navigate("/onboarding");
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const registerForTrialBundle = async () => {
  //   return dispatch(getPricingBundles())
  //     .unwrap()
  //     .then((res) => {
  //       console.log(res);
  //       const trialBundlePlanId = res.plans.filter(
  //         (plan) => plan.planName === "TRIAL"
  //       )[0].bundlePlanId;
  //       console.log(trialBundlePlanId);
  //       return dispatch(stripeCheckoutAction(trialBundlePlanId))
  //         .unwrap()
  //         .then((res) => {
  //           console.log("Registered for TRIAL plan: ", res);
  //           return res;
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     });
  // };

  const registerUser = (e) => {
    e.preventDefault();
    let payload = {
      fullName: fullname,
      email: email,
      password: password,
    };
    if (password && password.length < 6) {
      error("Password must be at least 6 characters long");
      return;
    }

    if (password && password == confirmPassword) {
      dispatch(createUser(payload))
        .unwrap()
        .then((resolvedData) => {
          if (resolvedData?.isNewUser) {
            setCheckoutLoader(true);
            registerForTrialBundle()
              .then((res) => {
                navigate("/onboarding");
              })
              .catch((err) => {
                console.log("Cannot checkout for TRIAL bundle");
                navigate("/onboarding");
              });
          } else {
            navigate("/home");
          }
          // navigatedafsa"/login");
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
          // error("Please Fill In All the Fields");
          error(rejectedValueOrSerializedError);
          console.error("Register failed", rejectedValueOrSerializedError);
        });
    } else {
      error("Password do not match");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        await dispatch(authenticateAction(response.access_token))
          .unwrap()
          .then((res) => {
            let payload = {
              displayName: res.displayName,
              email: res.email,
              picture: res.picture,
              uid: response.access_token,
            };
            dispatch(ssoLoginAction(payload))
              .unwrap()
              .then((ssoResponse) => {
                if (ssoResponse?.isNewUser) {
                  registerForTrialBundle()
                    .then((res) => {
                      setCheckoutLoader(true);
                      navigate("/onboarding");
                    })
                    .catch((err) => {
                      console.log("Cannot checkout for TRIAL bundle");
                      navigate("/onboarding");
                    });
                } else {
                  console.log("==========home=================");
                  navigate("/home");
                }
              });
          });
      } catch (err) {
        console.log(err);
      }
    },
    onError: (err) => console.log("Login Failed:", err),
    redirectUri: "http://localhost:3001",
  });

  return (
    <div>
      <div className="ic-login">
        <div className="ic-login-box">
          <Link to="" className="ic-auth-logo">
            <img src="/images/logo.png" alt="logo" style={{ width: "220px" }} />
          </Link>
          <p className="text-center auth-sub-title">
            Nexa, Your AI Career Platform
          </p>
          <h4 className="text-center auth-title">Create your account</h4>
          <div className="auth-social">
            <button onClick={googleLogin} className="ic-google">
              <img src="/images/google.png" alt="" /> Sign up with Google
            </button>
          </div>
          <div className="or">
            <p>Or</p>
          </div>

          {status ? <p className="text-success">{status}</p> : ""}

          <form onSubmit={(e) => registerUser(e)}>
            <div className="form-group">
              <label htmlFor="fullname">Full Name</label>
              <input
                type="text"
                id="fullname"
                className={`form-control ${
                  form.errors.fullname ? "is-invalid" : ""
                }`}
                placeholder="Enter your full name"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
                required
              />
              {form.errors.fullname ? (
                <div className="invalid-feedback">{form.errors.fullname}</div>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <label htmlFor="#">Email Address</label>
              <input
                type="email"
                id="email"
                className={`form-control ${
                  form.errors.email ? "is-invalid" : ""
                }`}
                placeholder="example@nexa.com"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              {form.errors.email ? (
                <div className="invalid-feedback">{form.errors.email}</div>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="position-relative">
                <input
                  type={passwordFieldType}
                  id="password"
                  className={`form-control ${
                    form.errors.password ? "is-invalid" : ""
                  }`}
                  placeholder="Type password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i
                  className={`eye-password ${
                    passwordFieldType === "text"
                      ? "ri-eye-off-line"
                      : "ri-eye-line"
                  }`}
                  onClick={() =>
                    setPasswordFieldType(
                      passwordFieldType === "text" ? "password" : "text"
                    )
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password</label>

              <div className="position-relative">
                <input
                  type={passwordFieldType}
                  id="password"
                  className={`form-control ${
                    form.errors.password ? "is-invalid" : ""
                  }`}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <i
                  className={`eye-password ${
                    passwordFieldType === "text"
                      ? "ri-eye-off-line"
                      : "ri-eye-line"
                  }`}
                  onClick={() =>
                    setPasswordFieldType(
                      passwordFieldType === "text" ? "password" : "text"
                    )
                  }
                />
              </div>
            </div>
            <button
              type="submit"
              className="ic-btn w-100"
              disabled={loading}
              style={{ fontSize: "16px", fontWeight: "500" }}
            >
              {loading || checkoutLoader ? <Loader /> : "Sign Up"}
            </button>
          </form>
          <p className="have-text">
            Already have account? <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
