import React, { useRef } from "react";
import Hint from "./hint";
import TabComponent from "./TabComponent";
import Topics from "./topics";

const QuestionComponent = ({ question }) => {
  const topicsRef = useRef(null);
  const hintRef = useRef(null);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      color: "#333",
      lineHeight: "1.6",
      padding: "20px",
      maxWidth: "1000px",
      margin: "0 auto",
      marginTop: "30px",
    },
    box: {
      padding: "0px 20px 20px 20px",
      borderRadius: "10px",
      // border: '1px solid #ddd',
      backgroundColor: "#fff",
      // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginBottom: "20px",
    },
    titleSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    buttonGroup: {
      display: "flex",
      gap: "10px",
      marginBottom: "20px",
    },
    tabButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      textDecoration: "none",
      textTransform: "capitalize",
      fontSize: "14px",
      padding: "4px 4px",
      borderRadius: "15px",
      cursor: "pointer",
      backgroundColor: "#F1F1F1",
      transition: "all 0.3s ease-in-out",
      border: "1px solid #ddd",
      color: "#333",
    },
    code: {
      backgroundColor: "#F7F7F8",
      color: "#5A5A5A",
      padding: "2px 4px",
      borderRadius: "3px",
    },
    exampleWrapper: {
      display: "flex",
      alignItems: "flex-start",
      margin: "5px 0",
    },
    verticalLine: {
      height: "100px",
      width: "2px",
      backgroundColor: "#EBEBEB",
      borderRadius: "5px",
      marginRight: "10px",
    },
    codeSection: {
      fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace',
      fontSize: "14px",
      backgroundColor: "#f9f9f9",
      padding: "10px",
      borderRadius: "5px",
      color: "#333",
      whiteSpace: "pre-wrap",
    },
    exampleContent: {
      display: "flex",
      flexDirection: "column",
    },
    coolfont: {
      fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace',
      fontSize: "16px",
    },
    exteriorWrapper: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  };

  return (
    <div style={styles.container}>
      {/* Title Section */}
      <div
        style={{
          padding: "0 20px 20px 20px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          marginBottom: "20px",
          height: "68vh",
          overflow: "auto",
        }}
      >
        <TabComponent />
        <div style={styles.titleSection}>
          <h1>
            {question.id}. {question.title}
          </h1>
        </div>

        {/* Topics and Hint Buttons */}
        <div style={styles.buttonGroup}>
          <div style={styles.tabButton}>
            <div
              style={{
                fontSize: "16px",
                marginTop: "1px",
                color: getDifficultyColor(question.difficulty),
              }}
            >
              {/* Difficulty Icon or Indicator */}
            </div>
            <span
              style={{
                color: getDifficultyColor(question.difficulty),
                fontSize: "12px",
              }}
            >
              {question.difficulty}
            </span>
          </div>
          {/* Topics Button */}
          <div
            style={styles.tabButton}
            onClick={() => scrollToSection(topicsRef)}
          >
            <div
              style={{ fontSize: "16px", marginTop: "1px", color: "#FFB800" }}
            >
              {/* Tag Icon */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="tag"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style={{ width: "16px", height: "16px", fill: "#FFB800" }}
              >
                <path
                  fill="currentColor"
                  d="M197.5 32c17 0 33.3 6.7 45.3 18.7l176 176c25 25 25 65.5 0 90.5L285.3 450.7c-25 25-65.5 25-90.5 0l-176-176C6.7 262.7 0 246.5 0 229.5V80C0 53.5 21.5 32 48 32H197.5z"
                ></path>
              </svg>
            </div>
            Topics
          </div>

          {/* Hint Button */}
          <div
            style={styles.tabButton}
            onClick={() => scrollToSection(hintRef)}
          >
            <div
              style={{ fontSize: "16px", marginTop: "1px", color: "#FFD700" }}
            >
              {/* Lightbulb Icon */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="lightbulb"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                style={{ width: "16px", height: "16px", fill: "#FFD700" }}
              >
                <path
                  fill="currentColor"
                  d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"
                ></path>
              </svg>
            </div>
            Hint
          </div>
        </div>

        {/* Problem Description */}
        <div style={{ marginBottom: "50px" }}>
          <p>{question.description}</p>
          <div style={styles.exteriorWrapper}>
            {/* Examples */}
            {question.examples.map((example, index) => (
              <div key={index}>
                <h4>Example {index + 1}:</h4>
                <div style={styles.exampleWrapper}>
                  <div style={styles.verticalLine}></div>
                  <div style={styles.exampleContent}>
                    <pre style={styles.codeSection}>
                      <strong style={styles.coolfont}>Input:</strong>{" "}
                      {example.input}
                      <br />
                      <strong style={styles.coolfont}>Output:</strong>{" "}
                      {example.output}
                      <br />
                      {example.explanation && (
                        <>
                          <strong style={styles.coolfont}>Explanation:</strong>{" "}
                          {example.explanation}
                        </>
                      )}
                    </pre>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Constraints */}
          <p>
            <strong>Constraints:</strong>
          </p>
          <ul>
            {question.constraints.map((constraint, index) => (
              <li key={index}>
                <code style={styles.code}>{constraint}</code>
              </li>
            ))}
          </ul>
        </div>

        {/* Topics and Hint Sections */}
        <div ref={topicsRef}>
          <Topics topics={question.topics} />
        </div>
        <div ref={hintRef}>
          <Hint hints={question.hints} />
        </div>
      </div>
    </div>
  );
};

// Helper function to get color based on difficulty
const getDifficultyColor = (difficulty) => {
  switch (difficulty.toLowerCase()) {
    case "easy":
      return "#38a169"; // Green
    case "medium":
      return "#FFB800"; // Yellow/Gold
    case "hard":
      return "#E53E3E"; // Red
    default:
      return "#333";
  }
};

export default QuestionComponent;
