import React from "react";

export default function Projects({ projects, getSingleResumeDoc }) {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  return (
    projects &&
    projects?.length > 0 && (
      <section className="cv-projects">
        {projects?.map((pro, index) => (
          <div className="cv-project-item" key={index}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3
                  className="cv-item-title mb-0 apply-font"
                  style={{
                    color: `${
                      getSingleResumeDoc?.subheading_color
                        ? getSingleResumeDoc?.subheading_color
                        : "#000"
                    }`,
                  }}
                >
                  {pro?.project_name}
                </h3>
                <p className="cv-item-date apply-font">{pro?.organization}</p>
              </div>
              <p className="cv-item-date apply-font">
                {formatDate(pro?.start_date)} - {formatDate(pro?.end_date)}
              </p>
            </div>
            <p
              className="cv-item-date px-2 apply-font"
              dangerouslySetInnerHTML={{
                __html: pro?.additional_information,
              }}
            />
          </div>
        ))}
      </section>
    )
  );
}
