import { useState } from "react";
import DebounceSelect from "../dropdowns/DebounceSelect";
import { useDispatch } from "react-redux";
import {
  addPreferenceforJobs,
  getProfessions,
  setPreference,
  updatePreferenceforJobs,
} from "../../Redux/UserProfileSlice";
import { getJobListings, scrapJobs } from "../../Redux/NexaIqSlice";
import { Input, Select, Spin } from "antd";
import { Autocomplete } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { error } from "../../Utilities/toast";
import AdditionSelect from "../dropdowns/AdditionSelect";

const PresonalPreference = ({
  onClose,
  formData,
  setFormData,
  setStep,
  setScrapText,
}) => {
  const dispatch = useDispatch();

  const { jobProfessions, jobsPreference } = useSelector(
    (state) => state.UserProfile
  );

  const [autocomplete, setAutocomplete] = useState(null);
  const [loader, setLoader] = useState(false);

  const professionsdata = jobProfessions?.professions?.map((item) => ({
    label: item?.display_name,
    value:
      item?.name +
      "/" +
      (item?.preferred_industry ? item?.preferred_industry : ""),
  }));

  const handleInputs = (name, value) => {
    if (name === "title") {
      if (value === "") {
        setFormData({ ...formData, title: "", industry_preference: "" });
      } else {
        const title = value?.split("/")[0]?.trim();
        const industry = value?.split("/")[1]?.trim();
        setFormData({
          ...formData,
          title: title,
          industry_preference: industry,
        });
      }
      return;
    }
    if (name === "location_type") {
      setFormData({
        ...formData,
        location_type: value,
        location: value === "remote" ? "remote" : "",
      });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const city = place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      setFormData((prev) => ({
        ...prev,
        location: city || place.formatted_address,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData?.title || !formData?.title.trim()) {
      error("Job title is required");
      return;
    }
    let payload = {
      title: formData?.title,
      industry_preference: formData?.industry_preference,
      location: formData?.location,
      workplace_type: formData?.workplace_type,
      work_schedule: formData?.work_schedule,
    };
    setLoader(true);
    dispatch(
      !jobsPreference?.title && Object.keys(jobsPreference).length === 0
        ? addPreferenceforJobs({
            payload: {
              ...payload,
              location_type: formData?.location ? formData.location_type : "",
            },
            onSuccess: () => {
              setLoader(false);
              onClose();
              dispatch(
                setPreference({
                  ...payload,
                  location_type: formData?.location
                    ? formData.location_type
                    : "",
                })
              );
              dispatch(
                getJobListings({
                  payload,
                  page: 1,
                  jobApplied: false,
                  smartScreen: false,
                  nexa_jobs: true,
                  onSuccess: (data) => {
                    if (data.length == 0) {
                      dispatch(
                        scrapJobs({
                          payload: payload,
                          onSuccess: () => {
                            setScrapText(true);
                          },
                        })
                      );
                    }
                  },
                })
              );
            },
          })
        : updatePreferenceforJobs({
            id: jobsPreference?.id,
            payload: {
              ...payload,
              location_type: formData?.location ? formData.location_type : "",
            },
            onSuccess: () => {
              setLoader(false);
              onClose();
              dispatch(
                setPreference({
                  ...payload,
                  location_type: formData?.location
                    ? formData.location_type
                    : "",
                })
              );
              dispatch(
                getJobListings({
                  payload,
                  page: 1,
                  jobApplied: false,
                  smartScreen: false,
                  nexa_jobs: true,
                  onSuccess: (data) => {
                    console.log('data: ', data);
                    if (data.length == 0) {
                      dispatch(
                        scrapJobs({
                          payload: payload,
                          onSuccess: () => {
                            setScrapText(true);
                          },
                        })
                      );
                    }
                  },
                })
              );
            },
          })
    );
  };

  const fetchJobTitles = async (value, page) => {
    await dispatch(
      getProfessions({
        page: page,
        search: value,
        onSuccess: () => {},
      })
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="py-3 px-4 overflow-auto h-[496px]">
        <div className="mb-3">
          <label className="text-sm font-semibold text-gray-700">
            Preferred Job Title
            <span className="text-red-500 ml-1">*</span>
          </label>
          <AdditionSelect
            className="w-full mt-2 h-[48px] capitalize"
            value={formData?.title}
            data={professionsdata ? professionsdata : []}
            placeholder="Select Job Title"
            placeholderAdd="Add Your Own Job Title"
            fetchOptions={fetchJobTitles}
            onChange={(value) => handleInputs("title", value)}
            currentPage={jobProfessions?.page}
            totalPages={jobProfessions?.total_pages}
          />
        </div>

        <div className="mb-3">
          <label className="text-sm font-semibold text-gray-700">
            Industry Preferences
          </label>
          <Input
            value={formData?.industry_preference}
            className="w-full mt-2 h-[48px] capitalize"
            readOnly
            disabled
          />
        </div>

        <div className="mb-3">
          <label className="text-sm font-semibold text-gray-700">
            Location Preferences
          </label>
          <div className="flex gap-2 mt-2">
            <div className="w-1/3">
              <Select
                value={formData?.location_type}
                placeholder="Select Location Preference"
                onChange={(value) => {
                  handleInputs("location_type", value);
                }}
                className="w-full h-[48px] capitalize"
              >
                <Select.Option value="">Select</Select.Option>
                {["remote", "city", "region"].map((location, ind) => (
                  <Select.Option
                    className="capitalize"
                    key={ind}
                    value={location}
                  >
                    {location}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="w-2/3">
              {formData?.location_type === "remote" ? (
                <Input
                  value={formData?.location_type}
                  className="w-full h-[48px] capitalize"
                  readOnly
                  disabled
                />
              ) : formData?.location_type === "city" ? (
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  options={{
                    types: ["(cities)"],
                    componentRestrictions: { country: ["us", "gb", "ca"] },
                  }}
                >
                  <Input
                    className="w-full h-[48px] p-2 text-[16px] font-normal border rounded capitalize"
                    type="text"
                    placeholder="Enter City"
                    value={formData?.location}
                    onChange={(e) =>
                      setFormData({ ...formData, location: e.target.value })
                    }
                  />
                </Autocomplete>
              ) : formData?.location_type === "region" ? (
                <Select
                  value={formData?.location}
                  placeholder="Select Region"
                  onChange={(value) => {
                    handleInputs("location", value);
                  }}
                  className="w-full h-[48px] capitalize"
                >
                  <Select.Option value="">Select Region</Select.Option>
                  {["united-kingdom", "canada", "united-states"].map(
                    (region, ind) => (
                      <Select.Option
                        className="capitalize"
                        key={ind}
                        value={region}
                      >
                        {region.replace("-", " ")}
                      </Select.Option>
                    )
                  )}
                </Select>
              ) : (
                <Input
                  value=""
                  className="w-full h-[48px] capitalize"
                  readOnly
                  disabled
                />
              )}
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label className="text-sm font-semibold text-gray-700">
            Workplace Type
          </label>
          <Select
            value={formData?.workplace_type}
            placeholder="Select type"
            onChange={(value) => {
              handleInputs("workplace_type", value);
            }}
            className="w-full mt-2 h-[48px] capitalize"
          >
            <Select.Option value="">Select Workplace Type</Select.Option>
            {["remote", "hybrid", "onsite"].map((type, ind) => (
              <Select.Option className="capitalize" key={ind} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="">
          <label className="text-sm font-semibold text-gray-700">
            Work Schedule
          </label>
          <Select
            value={formData?.work_schedule}
            placeholder="Select Schedule"
            onChange={(value) => {
              handleInputs("work_schedule", value);
            }}
            className="w-full mt-2 h-[48px] capitalize"
          >
            <Select.Option value="">Select Work Schedule</Select.Option>
            {["full-time", "part-time", "contract", "freelance"].map(
              (schedule, ind) => (
                <Select.Option
                  className="capitalize"
                  key={ind}
                  value={schedule}
                >
                  {schedule.replace("-", " ")}
                </Select.Option>
              )
            )}
          </Select>
        </div>
      </div>
      <div className="flex gap-3 border-t border-gray-300 py-3 px-4 w-full justify-end">
        <button
          type="button"
          onClick={() => setStep(1)}
          className="modal-button cancel-button"
        >
          Back
        </button>
        <button
          type="submit"
          disabled={loader}
          className="modal-button save-button"
        >
          {jobsPreference?.title && Object.keys(jobsPreference).length !== 0
            ? "Update"
            : "Apply"}{" "}
          {loader && <Spin size="small" className="pl-2" />}
        </button>
      </div>
    </form>
  );
};

export default PresonalPreference;
