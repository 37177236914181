import React, { useState } from "react";
import Header from "../../Components/header";
import Tab from "./components/Tab";
import FormInput from "./components/FormInput";
import SubmitButton from "./components/SubmitButton";
import JobSearchCriteria from "./components/JobSearchCriteria";
import { toast } from "react-toastify";
import { success } from "../../Utilities/toast";

const CreateAutoPilot = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isVerifying, setIsVerifying] = useState(false);

  // Form state for each tab
  const [linkedinInfo, setLinkedinInfo] = useState({ email: "", password: "" });
  const [jobSearchCriteria, setJobSearchCriteria] = useState({
    positions: [],
    locations: [],
    experience: [],
    remoteJob: false,
    distance: "",
    jobTypes: [],
    company: "",
  });

  // Generic handleChange function for form inputs
  const handleChange = (setState) => (e) => {
    console.log(e.target.name);
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Handle submit based on the active tab
  const handleSubmit = (e) => {
    e.preventDefault();
    switch (activeTab) {
      case 1:
        console.log("LinkedIn Profile Info:", linkedinInfo);
        setIsVerifying(true);
        setTimeout(() => {
          setIsVerifying(false);
          success("LinkedIn Profile Info Verified");
          setActiveTab(2);
        }, 2000);
        break;
      case 2:
        console.log("User Personal Information:", jobSearchCriteria);
        break;
      default:
        console.log("No tab selected.");
    }
  };

  const toggleTab = (index) => {
    setActiveTab(activeTab === index ? null : index);
  };

  return (
    <>
      <Header />
      <div className="relative p-6 mt-28">
        <div className="w-full max-w-3xl mx-auto p-6">
          <h1 className="text-2xl font-bold mb-4">
            Create Your New Auto Pilot
          </h1>

          {/* LinkedIn Profile Info */}
          <Tab
            index={1}
            activeTab={activeTab}
            toggleTab={toggleTab}
            title="LinkedIn Profile Info"
          >
            <form className="space-y-4" onSubmit={handleSubmit}>
              <FormInput
                label="Email"
                name="email"
                type="email"
                placeholder="Enter your email"
                value={linkedinInfo.email}
                onChange={handleChange(setLinkedinInfo)}
              />
              <FormInput
                label="Password"
                name="password"
                type="password"
                placeholder="Enter your password"
                value={linkedinInfo.password}
                onChange={handleChange(setLinkedinInfo)}
              />
              <SubmitButton isLoading={isVerifying} label="Verify" />
            </form>
          </Tab>

          {/* Jobs Search Criteria */}
          <Tab
            index={2}
            activeTab={activeTab}
            toggleTab={toggleTab}
            title="Jobs Search Criteria"
          >
            <JobSearchCriteria
              jobSearchCriteria={jobSearchCriteria}
              setJobSearchCriteria={setJobSearchCriteria}
              handleSubmit={handleSubmit}
            />
          </Tab>
        </div>
      </div>
    </>
  );
};

export default CreateAutoPilot;
