import React from "react";
import CVTemplate from "./CVTemplate";

const Preview = () => {
  return (
    <div className="py-3">
      <div className="cv-main-container w-full min-[760px]:w-[760px]">
        <CVTemplate />
      </div>
    </div>
    // <div className="companyinformation">
    //   <div className="mydetails w-100 md:w-50">
    //   </div>
    // </div>
  );
};

export default Preview;
