import { Link } from "react-router-dom";
import Sec3 from "../../assets/Images/sec3.png";

export default function Section3() {
  return (
    <div className="w-full slide-in my-20">
      <div className="flex justify-center items-center">
        <h1 className="Poppins text-4xl text-center h-12">Our</h1>
        <div className="h-12">
          <h1
            className="Poppins text-4xl text-center mt-0"
            style={{ color: "#2B6BE2" }}
          >
            Solutions
          </h1>
          <img className="mx-auto my-0" src={Sec3} alt="" />
        </div>
      </div>

      <div className="flex justify-center flex-wrap gap-12 my-12">
        <div className="card  p-6 bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden">
          <div className="background absolute inset-0"></div>
          <div className="relative z-10  flex flex-column h-full">
            <h2 className="text-3xl font-bold Raleway">
              Nexa <span className="color-hr Raleway">Elevate</span>
            </h2>
            <h4 className="text-2xl font-medium OpenSans mb-8">
              For Training Organizations
            </h4>
            <p className="text-lg font-normal OpenSans mb-2">
              Empower your learners with career readiness tools that enhance
              their job placement potential.
            </p>
            <ul className="list-disc pl-5 space-y-2 text-lg font-normal OpenSans">
              <li>Streamlined operations for career services and staff</li>
              <li>
                Personalized career services for any type of job seeker from
                preparation to job placement
              </li>
              <li>Comprehensive advisor dashboard for real-time insights</li>
            </ul>
            <div className="flex justify-end mt-auto">
              <Link to="/landing">
                <button
                  style={{
                    background:
                      "linear-gradient(94.37deg, #2B6BE2 0%, #1E4B9E 100%)",
                  }}
                  className=" text-white font-semibold px-4 py-2 rounded-full transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 Satoshi"
                >
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="card  p-6 bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden">
          <div className="background absolute inset-0"></div>
          <div className="relative z-10 flex flex-column h-full">
            <h2 className="text-3xl font-bold Raleway">
              Nexa <span className="color-hr Raleway">RU</span>
            </h2>
            <h4 className="text-2xl font-medium OpenSans mb-8">
              For Enterprises
            </h4>
            <p className="text-lg font-normal OpenSans mb-2">
              Say goodbye to manual hiring processes with our recruiter agent.
              Nexa RU handles candidate screening, interviewing, and feedback in
              one seamless flow.
            </p>
            <ul className="list-disc pl-5 space-y-2 text-lg font-normal OpenSans">
              <li>Smart-screening with live interview simulations</li>
              <li>Integrated AI-driven candidate feedback</li>
              <li>
                Never leave your existing ATS with dedicated custom integrations
              </li>
            </ul>
            <div className="flex justify-end mt-auto">
              <Link to="/rucoming">
                <button
                  style={{
                    background:
                      "linear-gradient(94.37deg, #2B6BE2 0%, #1E4B9E 100%)",
                  }}
                  className="text-white font-semibold px-4 py-2 rounded-full transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 Satoshi "
                >
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
