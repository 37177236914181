import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useSound from "use-sound";
import DialerAudio from "../../assets/sound/dialer.mp3";
import Header from "../../Components/header/index";
import { error } from "../../Utilities/toast";
import SalesMeetScreen from "./sales/salesmeet";

function SalesOptions() {
  const [showMeet, setShowMeet] = useState(false);
  const [callType, setCallType] = useState("");
  const [product, setProduct] = useState("");
  const [play, { stop }] = useSound(DialerAudio);
  const [difficulty, setDifficulty] = useState("");
  const [industry, setIndustry] = useState("");
  const [situation, setSituation] = useState("");
  const [progress, setProgress] = useState(0);
  const [animateCard, setAnimateCard] = useState(false);
  const [options, setOptions] = useState({});
  const [personna, setPersonna] = useState("");
  // Separate states for audio and video streams
  const [audioStream, setAudioStream] = useState(null);
  const [videoStream, setVideoStream] = useState(null);
  const [muted, setMuted] = useState(false);
  const [sessionId, setSessionId] = useState(""); // Initialize as needed
  const [jobDescription, setJobDescription] = useState("");
  const [contactDetails, setContactDetails] = useState("");

  const dispatch = useDispatch();
  // Remove or replace any incorrect useSelector calls
  // const { someState } = useSelector(state => state.someReducer); // Remove or replace

  const handleGenerate = () => {
    const generatedOptions = {
      callType,
      product,
      difficulty,
      industry,
      situation,
      personna,
    };
    setOptions(generatedOptions);
    play();
    setTimeout(() => {
      stop();
      setShowMeet(true);
    }, 2500);
    setShowMeet(true);
    console.log(generatedOptions);
  };

  const handleEndCall = () => {
    // Stop audio stream
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);
    }

    // Stop video stream
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      setVideoStream(null);
    }

    // Reset options and states
    setOptions({});
    setShowMeet(false);
    setCallType("");
    setProduct("");
    setDifficulty("");
    setIndustry("");
    setSituation("");
    setProgress(0);
    setJobDescription("");
    setContactDetails("");
    setMuted(false);
  };

  const handleMute = () => {
    if (audioStream) {
      audioStream.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      setMuted(!muted);
    }
  };

  const handleCamera = () => {
    if (videoStream) {
      videoStream.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
    }
  };

  useEffect(() => {
    let filledFields = 0;
    if (callType) filledFields++;
    if (product) filledFields++;
    if (difficulty) filledFields++;
    if (industry) filledFields++;
    if (situation) filledFields++;
    setProgress((filledFields / 5) * 100);
  }, [callType, product, difficulty, industry, situation]);

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(100);
      }, 300); // Slight delay to simulate smooth loading
    }
  }, [progress]);

  // Request media streams when starting the meeting
  useEffect(() => {
    if (showMeet) {
      const initiateStreams = async () => {
        try {
          // Create audio constraints
          const constraintsAudio = { audio: true, video: false };

          // Create video constraints
          const constraintsVideo = { video: true, audio: false };

          // Get audio stream
          const audio = await navigator.mediaDevices.getUserMedia(
            constraintsAudio
          );
          setAudioStream(audio);

          // Get video stream
          const video = await navigator.mediaDevices.getUserMedia(
            constraintsVideo
          );
          setVideoStream(video);
        } catch (err) {
          console.error("Error accessing media devices.", err);
          error("Unable to access camera or microphone.");
          setShowMeet(false);
          // Cleanup any partially acquired streams
          if (audioStream) {
            audioStream.getTracks().forEach((track) => track.stop());
            setAudioStream(null);
          }
          if (videoStream) {
            videoStream.getTracks().forEach((track) => track.stop());
            setVideoStream(null);
          }
        }
      };

      initiateStreams();
    }

    return () => {
      // Cleanup on component unmount or when showMeet changes
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMeet]);

  return (
    <>
      <Header />
      {showMeet ? (
        // Render the SalesMeetScreen when options are set
        <SalesMeetScreen
          salesOptions={options}
          handleEndCall={handleEndCall}
          videoStream={videoStream}
          handleMute={handleMute}
          handleCamera={handleCamera}
          muted={muted}
          setMuted={setMuted}
          sessionId={sessionId}
          jobDescription={jobDescription}
          contactDetails={contactDetails}
        />
      ) : (
        <div style={styles.container}>
          <div style={styles.progressBarContainer}>
            <div style={{ ...styles.progressBar, width: `${progress}%` }}></div>
          </div>
          <div
            style={{
              ...styles.card,
              opacity: animateCard ? 1 : 0,
              transform: animateCard ? "translateY(0)" : "translateY(20px)",
            }}
          >
            <h2 style={styles.title}>Create your Live Sales Call</h2>

            <div className="md:flex justify-between">
              <div style={styles.inputGroup} className="!w-full md:!w-[48%]">
                <label style={styles.label}>Call Type</label>
                <select
                  value={callType}
                  onChange={(e) => setCallType(e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select...</option>
                  <option value="Cold Call">Cold Call</option>
                  <option value="Warm Call">Warm Call</option>
                  <option value="Gatekeeper Call">Gatekeeper Call</option>
                  <option value="Closing Call">Closing Call</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div style={styles.inputGroup} className="!w-full md:!w-[48%]">
                <label style={styles.label}>Prospect's Role</label>
                <select
                  value={personna}
                  onChange={(e) => setPersonna(e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select...</option>
                  <option value="Front Desk">Front Desk</option>
                  <option value="Director">Director</option>
                  <option value="C-Level">C-Level</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div style={{ ...styles.inputGroup, width: "100%" }}>
              <label style={styles.label}>
                What Product/Service Are You Selling?
              </label>
              <input
                type="text"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                placeholder="Enter Your Product/Service"
                style={styles.input}
              />
            </div>

            <div className="md:flex justify-between">
              <div style={styles.inputGroup} className="!w-full md:!w-[48%]">
                <label style={styles.label}>Difficulty Level</label>
                <select
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select...</option>
                  <option value="Easy">Easy</option>
                  <option value="Medium">Medium</option>
                  <option value="Hard">Hard</option>
                  {/* Add more options as needed */}
                </select>
              </div>

              <div style={styles.inputGroup} className="!w-full md:!w-[48%]">
                <label style={styles.label}>Prospect's Industry</label>
                <select
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  style={styles.select}
                >
                  <option value="">Select...</option>
                  <option value="Technology">Technology</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Financial Services">Financial Services</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Retail & Ecommerce">Retail & Ecommerce</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Telecommunications">Telecommunications</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Government">Government</option>
                  {/* Add more options as needed */}
                </select>
              </div>
            </div>

            <div style={{ ...styles.inputGroup, width: "100%" }}>
              <label style={styles.label}>
                Any Specific Situational Parameters?
              </label>
              <input
                type="text"
                value={situation}
                onChange={(e) => setSituation(e.target.value)}
                placeholder="Add a standard"
                style={styles.input}
              />
            </div>

            <button style={styles.button} onClick={handleGenerate}>
              Generate
            </button>
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f0f2f5",
    paddingTop: "100px",
    boxSizing: "border-box",
  },
  progressBarContainer: {
    height: "8px",
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
    zIndex: 100,
    maxWidth: "600px",
    width: "90%",
    margin: "40px auto 0",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#0077cc",
    borderRadius: "4px",
    transition: "width 0.3s ease",
  },
  card: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
    padding: "20px",
    maxWidth: "600px",
    width: "90%",
    margin: "0px auto",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    boxSizing: "border-box",
    height: "560px", // Set a fixed height or max-height
    maxHeight: "85vh", // Optional: limits height based on viewport height
    overflowY: "auto", // Change to 'auto' to show scrollbar only when needed
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    color: "#0077cc",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  inputGroup: {
    marginBottom: "15px",
    width: "48%",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  select: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    backgroundColor: "#0077cc",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "8%",
  },
};

export default SalesOptions;
