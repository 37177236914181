// import React, { useState, useEffect, useRef } from "react";
// import { format, isValid } from "date-fns";
// import { DayPicker } from "react-day-picker";
// import "react-day-picker/dist/style.css";

// const DatePicker = ({ selectedDate, onSelect }) => {
//   console.log(selectedDate, onSelect);
//   const [selected, setSelected] = useState(selectedDate);
//   console.log(selected,'selected')
//   const [datepickerShow, setDatepickerShow] = useState(false);
//   const calendarRef = useRef(null);

//   useEffect(() => {
//     setSelected(selectedDate);
//   }, [selectedDate]);

//   const toggleDatePicker = () => {
//     setDatepickerShow(!datepickerShow);
//   };

//   const handleDateSelect = (date) => {
//     setSelected(date);
//     onSelect(date);
//   };

//   const handleClickOutside = (event) => {
//     if (calendarRef.current && !calendarRef.current.contains(event.target)) {
//       setDatepickerShow(false);
//     }
//   };

//   useEffect(() => {
//     if (datepickerShow) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [datepickerShow]);

//   const formattedDate = isValid(selected)
//     ? format(selected, "PP")
//     : "Select Date";

//   return (
//     <div className="w-200 position-relative cursor-pointer">
//       <div
//         className="workcontrol d-flex justify-content-between"
//         onClick={toggleDatePicker}
//       >
//         <p className="py-2">{formattedDate}</p>
//         <button type="button" style={{ backgroundColor: "transparent" }}>
//           <i className="ri-calendar-line" style={{ fontSize: "26px" }} />
//         </button>
//       </div>
//       {datepickerShow && (
//         <div className="custom-day-picker" ref={calendarRef}>
//           <DayPicker
//             mode="single"
//             captionLayout="dropdown"
//             fromYear={new Date().getFullYear() - 20}
//             toYear={new Date().getFullYear()}
//             selected={selected}
//             onSelect={handleDateSelect}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DatePicker;

import React, { useState, useEffect, useRef } from "react";
import { format, isValid } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const DatePicker = ({ selectedDate, onSelect }) => {
  console.log('selectedDate: ', selectedDate);
  const [selected, setSelected] = useState(selectedDate);
  const [datepickerShow, setDatepickerShow] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    setSelected(selectedDate);
  }, [selectedDate]);

  const toggleDatePicker = () => {
    setDatepickerShow(!datepickerShow);
  };

  const handleDateSelect = (date) => {
    // Ensure date is valid and non-null
    if (date) {
      setSelected(date);
      onSelect(date);
    }
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setDatepickerShow(false);
    }
  };

  useEffect(() => {
    if (datepickerShow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datepickerShow]);

  const formattedDate = isValid(selected) ? format(selected, "PP") : "Select Date";

  return (
    <div className="w-200 position-relative cursor-pointer">
      <div
        className="workcontrol d-flex justify-content-between"
        onClick={toggleDatePicker}
      >
        <p className="py-2">{formattedDate}</p>
        <button type="button" style={{ backgroundColor: "transparent" }}>
          <i className="ri-calendar-line" style={{ fontSize: "26px" }} />
        </button>
      </div>
      {datepickerShow && (
        <div className="custom-day-picker" ref={calendarRef}>
          <DayPicker
            mode="single"
            captionLayout="dropdown"
            fromYear={new Date().getFullYear() - 20}
            toYear={new Date().getFullYear()}
            selected={selected}
            onSelect={handleDateSelect}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
