import { Button, Select, Form, Input, InputNumber } from "antd";

export default function Section5() {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="flex justify-center mb-20">
      <div className="text-center sec5">
        <p className="text-4xl font-extrabold Raleway mx-auto">
          Career Insights & Opportunities - For Free!
        </p>
        <p className="text-lg font-normal my-8 leading-5 OpenSans sec5-para mx-auto">
          Sign up to receive expert advice, job leads, and exclusive tips to
          boost your career. Stay connected for resources that help you stand
          out in the market, all no cost to you!
        </p>

        <div>
          <Form
            name="validateOnly"
            className="flex justify-center items-center"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                style={{ borderRadius: "12px 0px 0px 12px" }}
                type="email"
                placeholder="Enter your email"
                className="input-width1 h-12 py-4 border-2"
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{
                  borderRadius: "0px 12px 12px 0px",
                  background:
                    "linear-gradient(94.37deg, #2B6BE2 0%, #1E4B9E 100%)",
                }}
                htmlType="submit"
                className="input-btn h-12 text-white py-2 transition ease-in-out Satoshi"
              >
                Subscribe
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
