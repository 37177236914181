import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import gif1 from "../../assets/Images/ai-web.gif";

export default function Section1() {
  const words = [" seamless.", " efficient.", " effective."];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [prevWordIndex, setPrevWordIndex] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setPrevWordIndex(currentWordIndex);
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      setIsAnimating(true);

      // End animation after transition duration (500ms)
      setTimeout(() => {
        setIsAnimating(false);
        setPrevWordIndex(null); // Clear previous word after animation
      }, 500); // Duration matches the CSS transition
    }, 3000); // Change word every 3 seconds

    return () => clearInterval(interval);
  }, [currentWordIndex, words.length]);
  return (
    <div className="sec-1 max-w-[1600px] m-auto">
      <div className="flex justify-start">
        {/* <video
          className="object-contain gif"
          src={gif}
          alt="AI Illustration"
          autoPlay
          loop
          muted
        /> */}
        <img src={gif1} alt="AI Illustration" className="object-contain gif" />
      </div>
      <div className="flex flex-col items-center justify-center gap-5 margin-top z-50 relative">
        <div className="flex justify-center items-center gap-3 lg:gap-5">
          <div className="relative inline-block">
            <span className="relative z-10 Poppins text-4xl sm:text-5xl md:text-6xl lg:text-[80px]">
              Career
            </span>
            <span className="absolute -bottom-5 left-0 w-full border-b-4 border-black transform -rotate-3"></span>
          </div>
          <h1 className="Poppins text-4xl sm:text-5xl md:text-6xl lg:text-[80px] text-center">
            <span className="text-[#2B6BE2]">Solutions</span> for
          </h1>
        </div>
        <h1 className="Poppins text-4xl sm:text-5xl md:text-6xl lg:text-[80px] text-center">
          <span className="text-[#2B6BE2]">Training</span> and{" "}
          <span className="text-[#2B6BE2]">Hiring</span>
        </h1>
      </div>

      <div className="flex justify-center relative margin-minus z-50 gap-y-16 flex-wrap">
        <div>
          <p className="Jejugothic text-lg text-center z-50 relative font-normal width-para mx-auto mb-14 mt-8">
            Whether you're preparing job seekers or hiring the best talent, our
            AI-driven platforms make career management {" "}
            <span
              className="animated-word-container"
              style={{ color: "#2B6BE2" }}
              aria-live="polite" // For accessibility
            >
              {/* Current Word */}
              <span
                className={`Jejugothic text-lg animated-word current-word ${isAnimating ? 'fade-in' : 'visible'}`}
                aria-hidden={false}
              >
                {words[currentWordIndex]}
              </span>
        
              {/* Previous Word */}
              {isAnimating && prevWordIndex !== null && (
                <span
                  className="animated-word previous-word fade-out"
                  aria-hidden={true}
                >
                  {words[prevWordIndex]}
                </span>
              )}
            </span>
          </p>
          <div className="flex justify-center">
            <Link to="/register">
              <button
                style={{ background: "#2B6BE2" }}
                className=" shadow-black shadow-2xl text-white font-bold w-56 py-2 text-lg rounded-full hover:bg-blue-700 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 Satoshi my-auto mx-0"
              >
                Start for Free
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="margin-vid">
        {/* <video
          className="gif"
          src={gif}
          alt="AI Illustration"
          autoPlay
          loop
          muted
        /> */}
        <img
          src={gif1}
          alt="AI Illustration"
          className="object-contain gif mt-[-50px] md:mt-0"
        />
      </div>
      <div className="margin-vid1">
        <img className="gif" src={gif1} alt="AI Illustration" />
      </div>
    </div>
  );
}
