import React from "react";

const FeedbackModal = ({ open, handleClose, handleNavigation }) => {
  if (!open) return null;

  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-6 w-80 shadow-lg max-w-lg">
        <h2 className="text-xl font-semibold mb-4">Interview Submitted</h2>
        <p className="text-gray-700 mb-4">
          Your interview has been submitted. Feedback will be ready within few
          minutes. You can access it from the{" "}
          <button
            onClick={handleNavigation}
            className="text-blue-500 underline"
          >
            Feedback Page
          </button>
          .
        </p>
        <button
          onClick={handleClose}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex ml-auto"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default FeedbackModal;
