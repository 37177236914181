// SalesDictaphone.js

import React, { useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { createCallScript } from "../../../Redux/OctagonSlice";
import { success } from "../../../Utilities/toast";
import FeedbackModal from "./FeedbackModal";
import machineSpeaks, { stopMachineSpeaks } from "./MachineSpeaks";
const SalesDictaphone = ({
  handleEndCall,
  muted,
  setMuted,
  setFeedbackLoading,
  sessionId,
  setLoading,
  quickQuit,
  setQuickQuit,
  handleCamera,
  salesOptions, // Receive salesOptions from props
}) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prompt } = useSelector((state) => state.SimulatorProSession);

  const [currentPrompt, setCurrentPrompt] = useState("");
  const [counter, setCounter] = useState(10); // Start from 10
  const [speaking, setSpeaking] = useState(false); // Initially not speaking
  const [noResponseModel, setNoResponseModel] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [lastUserMessage, setLastUserMessage] = useState(""); // Track last user message

  const { callType, product, difficulty, industry, situation, personna } =
    salesOptions;
  const calledRef = useRef(false);

  const loaders = useSelector((state) => state.octagon.loaders);
  const errors = useSelector(
    (state) => state.octagon.errors.createCallScriptError
  );
  // Generate the dynamic sales prompt
  // Descriptions for Call Types
  const callTypeDescriptions = {
    "Cold Call":
      'You are not expecting the call and are unfamiliar with the salesperson and their company. You may be cautious, skeptical, or curious. Initial reactions might include questioning who is calling or how they obtained your contact information. You might say things like, "Who\'s this?" or "I\'m not sure how you got this number."',
    "Warm Call":
      "You have some prior awareness of the salesperson or their company—perhaps you've interacted before, attended a webinar, or downloaded a resource. You recognize them and are somewhat familiar with what they are talking about. You're open to the conversation and may express interest.",
    "Gatekeeper Call":
      'You are the front desk receptionist or an administrative assistant. Your role is to manage incoming calls and decide whether to forward them. You might ask for more details, such as, "May I ask what this is regarding?" or "Do you have an appointment?"',
    "Closing Call":
      "You have already discussed the product/service in previous conversations and are expecting this call to finalize details or make a decision. You are informed and may have final questions or be ready to proceed.",
  };

  // Descriptions for Prospect Roles
  const personnaDescriptions = {
    "Front Desk":
      "You handle calls and visitors at the front desk. Your responsibility is to filter communications and protect the time of others in the organization.",
    Director:
      "You oversee a specific department or function within the company. You're responsible for departmental goals and have influence over purchasing decisions related to your area.",
    "C-Level":
      "You are an executive leader (e.g., CEO, CFO, CTO). You focus on high-level strategy, company performance, and return on investment. Decisions you make have significant impact on the organization.",
    Other:
      "Specify your role, including responsibilities and influence within the company.",
  };

  // Descriptions for Difficulty Levels
  const difficultyDescriptions = {
    Easy: "You are open, friendly, and show genuine interest. You engage positively, ask relevant questions, and are receptive to the salesperson's information.",
    Medium:
      "You are neutral and composed. You listen attentively but may express reservations or need more information. You ask questions to clarify and may require convincing.",
    Hard: "You are skeptical and may challenge the salesperson. You question the value, relevance, or credibility of the product/service. You might bring up objections, past negative experiences, or competitive offers.",
  };

  // Function to generate the sales prompt
  function generateSalesPrompt({
    callType,
    personna,
    product,
    difficulty,
    industry,
    situation,
  }) {
    // Retrieve descriptions based on selected values
    const callTypeDescription =
      callTypeDescriptions[callType] ||
      "No description available for this Call Type.";
    const personnaDescription =
      personnaDescriptions[personna] ||
      "No description available for this Prospect Role.";
    const difficultyDescription =
      difficultyDescriptions[difficulty] ||
      "No description available for this Difficulty Level.";

    // Construct the salesPrompt using template literals
    return `
  You are to simulate a realistic sales call by acting as the prospect. Your goal is to engage in a natural and fluent conversation with the salesperson, based on the following parameters. Throughout the conversation, stay in character and provide responses that reflect these parameters. Do not mention the parameters explicitly.
  
  1. **Call Type:**
  
     - **${callType}**
     
       ${callTypeDescription}
  
  2. **Prospect's Role:**
  
     - **${personna}**
     
       ${personnaDescription}
  
  3. **Product/Service Being Sold:**
  
     - **${product}**
     
       Consider how this product/service relates to your role, responsibilities, and current challenges. Think about potential benefits it could offer you or your organization, as well as any concerns or objections you might have.
  
  4. **Difficulty Level:**
  
     - **${difficulty}**
     
       ${difficultyDescription}
  
  5. **Prospect's Industry:**
  
     - **${industry}**
     
       Incorporate industry-specific language, challenges, and scenarios into your responses. Consider how industry trends might affect your perspective on the product/service.
  
  6. **Specific Situational Parameters:**
  
     - **${situation}**
     
       Include any particular details or circumstances that could influence your behavior or decisions. This might involve recent company events, market conditions, personal experiences, or specific needs.
  
  ---
  
  **Instructions for the Simulation:**
  
  - **Stay In Character:** Remain consistent with the persona described by the parameters throughout the conversation.
  - **Natural Language:** Use language and tone appropriate for your role, industry, and the call type. Your responses should be realistic and contribute to a natural flow of conversation.
  - **Engagement Level:** Adjust your level of engagement based on the difficulty level.
  - **Do Not Reveal Parameters:** Do not mention or hint at these instructions or parameters during the conversation.
  - **Provide Realistic Responses:** Answer questions honestly based on your character's knowledge and perspective.
  - **Aim for Fluency:** The goal is to create a lifelike interaction. Your responses should help build a coherent and engaging dialogue.
  `;
  }
  const salesPrompt = generateSalesPrompt({
    callType,
    personna,
    product,
    difficulty,
    industry,
    situation,
  });
  // Note 1: If the ${personna} == 'Front Desk', please start by saying 'Hello, you've reached the front desk, who's this?'. This is a very important rule for you.

  // console.log(callType);

  // Initialize liveInterview with the system prompt and assistant's initial message
  const [liveInterview, setLiveInterview] = useState([
    {
      role: "system",
      content: salesPrompt,
    },
    {
      role: "assistant",
      content: "Hello, I am a salesperson , How can I assist you today?",
    },
  ]);

  // Ref to track if component is mounted to prevent state updates on unmounted component
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      stopMachineSpeaks();
    };
  }, []);

  async function callAssistant() {
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`, // Use env variable
          },
          body: JSON.stringify({
            messages: liveInterview,
            model: "gpt-4", // Correct model name
          }),
        }
      );

      const data = await response.json();

      if (data.choices && data.choices.length > 0) {
        return data.choices[0].message.content;
      } else {
        console.error("No valid choices in response:", data);
        return "An error occurred while fetching the response.";
      }
    } catch (err) {
      console.error("Error:", err);
      return "An error occurred while fetching the response.";
    }
  }

  // Clean the response from the assistant
  function cleanResponse(resStr) {
    let input;

    // Remove ```json``` if it exists
    if (typeof resStr === "string") {
      resStr = resStr.replace(/```json\s*|\s*```/g, ""); // Remove ```json``` and ```
    }

    try {
      input = typeof resStr === "string" ? JSON.parse(resStr) : resStr;
    } catch (e) {
      if (typeof resStr === "string") {
        input = resStr;
      } else {
        throw new Error("Input must be a string or JSON-formatted string.");
      }
    }

    let values = [];

    function extractFromObject(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (key === "") {
          continue;
        }
        if (typeof value === "object" && value !== null) {
          extractFromObject(value);
        } else {
          values.push(value);
        }
      }
    }

    function extractFromArray(arr) {
      for (const item of arr) {
        if (typeof item === "object" && item !== null) {
          extractFromObject(item);
        } else {
          values.push(item);
        }
      }
    }

    if (typeof input === "string") {
      values.push(input);
    } else if (Array.isArray(input)) {
      extractFromArray(input);
    } else if (typeof input === "object" && input !== null) {
      extractFromObject(input);
    } else {
      throw new Error("Input must be a string, object, or an array.");
    }

    const zeroIndexVal = values[0];
    return zeroIndexVal;
  }

  // Handle sending questions data
  const questionsData = async () => {
    try {
      setNoResponseModel(false);
      setCounter(10);

      // Step 1: Remove the system message and any initial assistant messages if necessary
      const filteredData = liveInterview.filter(
        (item) =>
          !(
            item.role === "assistant" &&
            item.content.startsWith("Hello, this is")
          )
      );

      // Step 2: Create the questions array by pairing 'assistant' and 'user' objects
      const questions = [];

      for (let i = 0; i < filteredData.length; i++) {
        if (
          filteredData[i].role === "assistant" &&
          filteredData[i + 1] &&
          filteredData[i + 1].role === "user"
        ) {
          questions.push({
            question_text: filteredData[i].content,
            answer_text: filteredData[i + 1].content,
          });
        }
      }

      const payload = {
        questions: questions,
        call_type: callType,
        product: product,
        difficulty: difficulty,
        industry: industry,
        situation: situation,
        personna: personna,
      };

      setFeedbackLoading(true);
      const res = await dispatch(createCallScript(payload));

      if (!isMounted.current) return;
      setFeedbackLoading(false);
      handleCamera();
      if (!quickQuit) {
        setIsModal(true);
      } else {
        handleEndCall();
        setQuickQuit(false);
        success(res?.detail);
      }
      SpeechRecognition.stopListening();
      resetTranscript();
      setFinalTranscript(false);
      setLiveInterview([]); // Clear the conversation
      setLastUserMessage(""); // Reset last user message
      stopMachineSpeaks();
      setMuted(undefined);
    } catch (error) {
      console.log("is this called>>", error);
      handleEndCall();

      if (!isMounted.current) return;
      setFeedbackLoading(false);
    }
  };

  // Prevent duplicate speaking and mute during speaking
  const speak = async (text) => {
    setMuted(true); // Mute speech recognition while assistant is speaking
    setSpeaking(true);
    machineSpeaks(text, null, null, () => {
      if (!isMounted.current) return;
      setSpeaking(false);
      resetTranscript();
      setFinalTranscript(false);
      setMuted(false); // Unmute after speaking
      SpeechRecognition.startListening({ continuous: true });
    });
  };

  // Monitor for no response
  useEffect(() => {
    if (!speaking && !listening && !finalTranscript && !noResponseModel) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setNoResponseModel(true);
          setCounter(10);
        }
      }, 8000); // Wait 8 seconds before showing the popup

      return () => clearTimeout(timer);
    }
    console.log("useEffect called with dependencies:", {
      speaking,
      listening,
      finalTranscript,
      noResponseModel,
    });
  }, [speaking, listening, finalTranscript, noResponseModel]);

  // Countdown for no response modal
  useEffect(() => {
    if (noResponseModel && counter > 0) {
      const timer = setTimeout(() => {
        if (isMounted.current) {
          setCounter((prevCount) => prevCount - 1);
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else if (noResponseModel && counter === 0) {
      // Handle ending the conversation
      questionsData();
    }
  }, [noResponseModel, counter]);

  // Manage listening based on mute state
  useEffect(() => {
    if (muted === true) {
      SpeechRecognition.stopListening();
    } else if (muted === false) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [muted]);

  // Function to add a user message to liveInterview and prevent duplicates
  const addUserMessage = (userMessage) => {
    if (userMessage === lastUserMessage) {
      console.log("Duplicate user message detected, not adding.");
      return;
    }

    console.log("Adding user message:", userMessage);
    setLiveInterview((prev) => [
      ...prev,
      {
        role: "user",
        content: userMessage,
      },
    ]);
    setLastUserMessage(userMessage);
    setFinalTranscript(false);
  };

  // Handle transcript finalization
  useEffect(() => {
    if (finalTranscript) {
      const userMessage = transcript.trim();
      if (userMessage) {
        // Only add the user's message if the last message was from the assistant
        if (
          liveInterview.length === 0 ||
          liveInterview[liveInterview.length - 1].role === "assistant"
        ) {
          addUserMessage(userMessage);
        }
      }
    }
  }, [finalTranscript, transcript]);

  // Handle transcript delay
  useEffect(() => {
    const delay = 1500;
    if (transcript && !finalTranscript) {
      const timer = setTimeout(() => {
        SpeechRecognition.stopListening();
        setFinalTranscript(true); // Set final transcript
        stopMachineSpeaks();
        setSpeaking(false);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [transcript, finalTranscript]);

  // Handle assistant response after user input
  useEffect(() => {
    if (finalTranscript && !speaking && !listening) {
      console.log("Calling assistant...");

      // Check if the last message was from the user
      if (
        liveInterview.length === 0 ||
        liveInterview[liveInterview.length - 1].role !== "user"
      ) {
        return; // If the last message is not from a user, do not call assistant
      }

      callAssistant().then((response) => {
        if (!isMounted.current) return;
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveInterview((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
          setFinalTranscript(false);
        } else {
          console.error("No response received from callAssistant");
        }
      });
    }
  }, [finalTranscript, speaking, listening]);

  // Initialize the conversation on component mount
  useEffect(() => {
    if (salesPrompt) {
      setCurrentPrompt(salesPrompt);
      callAssistant().then((response) => {
        if (!isMounted.current) return;
        setLoading(false);
        if (response) {
          let filteredRes = cleanResponse(response);
          setLiveInterview((prev) => [
            ...prev,
            {
              role: "assistant",
              content: filteredRes,
            },
          ]);
          speak(filteredRes);
        } else {
          console.error("No response received from callAssistant");
        }
      });
    }
  }, [salesPrompt]);

  // Handle quick quit
  useEffect(() => {
    if (quickQuit) {
      questionsData();
    }
  }, [quickQuit]);

  const getModal = () => {
    return (
      <FeedbackModal
        open={isModal}
        handleClose={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
        }}
        handleNavigation={() => {
          handleEndCall();
          setQuickQuit(false);
          setIsModal(false);
          navigate("/user/analytics");
        }}
      />
    );
  };

  return (
    <div>
      <div className="absolute max-w-36 w-full max-h-36 h-full bottom-10 right-14">
        <div className="rounded-xl p-2 flex flex-col gap-3 h-full justify-center items-center relative backdrop-blur bg-black bg-opacity-20">
          <div className="relative">
            <FaUser size={48} className="object-cover text-white" />
          </div>
          {speaking ? (
            <button
              onClick={() => {
                stopMachineSpeaks();
                setSpeaking(false);
                if (!muted) {
                  SpeechRecognition.startListening({ continuous: true });
                }
                resetTranscript();
                setFinalTranscript(false);
              }}
              className="text-white text-xs"
            >
              Tap to interrupt
            </button>
          ) : listening ? (
            <button className="text-white text-xs">
              Prospect is listening...
            </button>
          ) : (
            <button className="text-white text-xs"></button>
          )}
        </div>
      </div>
      <div className={`ic-modal ${noResponseModel ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            <div className="ic-modal-body">
              <div className="ic-modal-content">
                Due to no response from you, we will end this interview in{" "}
                {counter} seconds
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setNoResponseModel(false);
                    SpeechRecognition.startListening({ continuous: true });
                    setCounter(10);
                    setMuted(false);
                    resetTranscript();
                    setFinalTranscript(false);
                  }}
                >
                  Continue
                </button>
                <button
                  onClick={() => {
                    questionsData();
                  }}
                >
                  End
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getModal()}
    </div>
  );
};

export default SalesDictaphone;
