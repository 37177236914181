import React, { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import ManageToggle from "./ManageToggle";
import { fieldComponents } from "../TemplateAssets/FieldComponents";
import {
  defaultState,
  StepStateAtom,
} from "../../../../recoil/ResumeAdditionalSection/StepsState";
import { error, success } from "../../../../Utilities/toast";
import { useDispatch } from "react-redux";
import {
  createNewSection,
  getSingleResume,
  updateAdditionalSection,
} from "../../../../Redux/ResumeBuilderSlice";
import { useParams } from "react-router-dom";

const TemplateSelctedFields = ({
  setIsFormVisible,
  activeSessionId,
  activeSessionDataId,
  setActiveSessionDataId,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const fieldRefs = useRef([]);
  const [stepState, setStepState] = useRecoilState(StepStateAtom);
  const [openStates, setOpenStates] = useState({});
  const permissionArr = stepState?.selectedTheme?.permissions;
  const sectionDetailArr = stepState?.selectedTheme?.sectionDetail;

  // ================ Handle input change =================
  const handleInputChange = (index, fieldKey, value) => {
    setStepState(
      produce((draft) => {
        draft.selectedTheme.sectionDetail[index][fieldKey] = value;
      })
    );
    validateField(index, fieldKey, value);
  };

  // ================ Validate Field =================
  const validateField = (index, fieldKey, value) => {
    let error = "";
    if (!value) {
      error = "This field is required.";
    }
    setStepState(
      produce((draft) => {
        if (!draft.formErrors) {
          draft.formErrors = {};
        }
        if (!draft.formErrors[index]) {
          draft.formErrors[index] = {};
        }
        draft.formErrors[index][fieldKey] = error;
      })
    );
  };

  // ================ Open Permission Dropdown as per card =================
  const handleToggleDropdown = (index) => {
    setOpenStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // ================ Cancel =================
  const onCancel = () => {
    setStepState(defaultState);
    setIsFormVisible(false);
    setActiveSessionDataId(null);
  };

  // ================ Submit Single Section Validation =================
  const validateAllFields = () => {
    let hasErrors = false;
    const newErrors = {};
    let firstErrorIndex = null;
    let firstErrorFieldKey = null;
    sectionDetailArr?.forEach((section, index) => {
      Object.keys(section).forEach((fieldKey) => {
        const permission = permissionArr?.[index]?.[fieldKey];
        const value = section[fieldKey];
        if (permission) {
          let error = "";
          if (!value) {
            error = "This field is required.";
            hasErrors = true;
            if (firstErrorIndex === null) {
              firstErrorIndex = index;
              firstErrorFieldKey = fieldKey;
            }
          }
          if (!newErrors[index]) {
            newErrors[index] = {};
          }
          newErrors[index][fieldKey] = error;
        }
      });
    });
    setStepState(
      produce((draft) => {
        draft.formErrors = newErrors;
      })
    );

    if (firstErrorIndex !== null && firstErrorFieldKey !== null) {
      fieldRefs.current[firstErrorIndex]?.[firstErrorFieldKey]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    return !hasErrors;
  };

  // =============== Save =================
  const handleSubmitSingleSection = () => {
    if (!validateAllFields()) {
      return error("Please fill in all required fields.");
    }
    const filteredSections = stepState?.selectedTheme?.sectionDetail?.map(
      (section, index) => {
        return Object.keys(permissionArr[index])
          .filter((key) => permissionArr[index][key])
          .reduce((acc, key) => {
            acc[key] = section[key];
            return acc;
          }, {});
      }
    );
    if (stepState?.selectedTheme?.isNewSection) {
      const payload = {
        sectionId: activeSessionId,
        data: filteredSections,
      };
      dispatch(
        createNewSection({
          payload,
          onSuccess: (res) => {
            success(res?.detail);
            setIsFormVisible(false);
            setStepState(defaultState);
            dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
          },
        })
      );
    } else {
      const payload = {
        sectionId: activeSessionDataId,
        data: filteredSections,
      };
      dispatch(
        updateAdditionalSection({
          payload,
          onSuccess: (res) => {
            success(res?.detail);
            setIsFormVisible(false);
            setActiveSessionDataId(null);
            setStepState(defaultState);
            dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
          },
        })
      );
    }
  };
  return (
    <>
      {stepState?.selectedTheme?.isNewSection === null && (
        <div className="py-4 px-3 w-full mt-2 bg-white rounded-lg shadow-md">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Heading
          </label>
          <input
            type="text"
            name="sectionHeading"
            id="sectionHeading"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Executive Member"
            value={stepState?.selectedTheme?.sectionHeading}
            onChange={(e) =>
              setStepState(
                produce((draft) => {
                  draft.selectedTheme.sectionHeading = e.target.value;
                })
              )
            }
          />
        </div>
      )}

      {sectionDetailArr?.map((section, index) => (
        <div
          className="py-4 px-3 w-full mt-2 bg-white rounded-lg shadow-md"
          key={index}
          ref={(el) => {
            if (!fieldRefs.current[index]) {
              fieldRefs.current[index] = {};
            }
          }}
        >
          <ManageToggle
            handleToggleDropdown={handleToggleDropdown}
            openStates={openStates}
            index={index}
          />
          {Object?.keys(stepState?.selectedTheme?.permissions[index])?.map(
            (fieldKey) => {
              const fieldValue =
                stepState?.selectedTheme?.permissions[index][fieldKey];
              if (fieldValue) {
                const matchedComponent = fieldComponents.find(
                  (component) => component.key === fieldKey
                );
                if (matchedComponent) {
                  return (
                    <div
                      key={fieldKey}
                      className="py-2"
                      ref={(el) => {
                        if (!fieldRefs.current[index]) {
                          fieldRefs.current[index] = {};
                        }
                        fieldRefs.current[index][fieldKey] = el;
                      }}
                    >
                      {matchedComponent.component && (
                        <matchedComponent.component
                          handleInputChange={(e) =>
                            handleInputChange(index, fieldKey, e.target.value)
                          }
                          index={index}
                          fieldValue={sectionDetailArr[index][fieldKey]}
                        />
                      )}
                      {stepState?.formErrors &&
                        stepState?.formErrors[index]?.[fieldKey] && (
                          <p className="text-red-500 text-xs mt-1">
                            {stepState?.formErrors[index]?.[fieldKey]}
                          </p>
                        )}
                    </div>
                  );
                }
              }
              return null;
            }
          )}
          {stepState?.selectedTheme?.isNewSection !== null && (
            <div className="d-flex justify-content-end py-2 px-2">
              <button
                type="button"
                className="cancel-btn mr-4"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100px",
                  height: "40px",
                }}
                onClick={() => onCancel()}
              >
                Cancel
              </button>
              <button
                onClick={() => handleSubmitSingleSection()}
                className="save-btn"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100px",
                  height: "40px",
                }}
              >
                {stepState?.selectedTheme?.isNewSection ? "Save" : "Update"}
              </button>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default TemplateSelctedFields;
