import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import backIcon from "../../assets/icons/backIcon.svg";
import Header from "../../Components/header";
import octoStack from "../../DummyData/octoStack.json";
import { OctaCatCards, OctagonCards } from "./octagonCards";

const Octagon = () => {
  const navigate = useNavigate();
  const { category, subcategory } = useParams();
  const location = useLocation();

  // State to hide/show the intro text
  const [showIntro, setShowIntro] = useState(true);

  const cards = [
    {
      label: "Software Projects",
      delay: 300,
      image: "/images/icon.png",
      link: "/codingdojo",
    },
    {
      label: "Sales Simulations",
      delay: 500,
      image: "/images/icon.png",
      link: "/salesoptions",
    },
    {
      label: "Customer Hospitality",
      delay: 700,
      image: "/images/icon.png",
      link: "/hospitality",
    },
    {
      label: "Management & Communications",
      delay: 900,
      image: "/images/icon.png",
      link: "/management",
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const introParam = queryParams.get("intro"); // Get the 'intro' query parameter

    if (introParam == "false") {
      setShowIntro(false); // If 'intro' is 'false', set showIntro to false
    } else {
      setShowIntro(true); // Default to true if 'intro' param is not false
    }
  }, [location.search]);

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrolling
    return () => {
      document.body.style.overflow = ""; // Reset scrolling on unmount
    };
  }, []);

  // Effect to reset intro text when no category or subcategory is selected
  useEffect(() => {
    if (!category && !subcategory) {
      setShowIntro(true); // Show intro when no card is selected
    }
  }, [category, subcategory]);

  const [projects, setProjects] = useState([]);
  useEffect(() => {
    // Define an asynchronous function to fetch projects
    const fetchProjects = async () => {
      try {
        // Retrieve the token from localStorage (adjust the key if different)
        const token = localStorage.getItem("auth");
        const base = process.env.REACT_APP_API_URL;
        if (!token) {
          console.log("No authentication token found. Please log in.");
          console.log(false);
          return;
        }

        // Make the GET request to fetch projects
        const response = await axios.get(`${base}/octagon/projects`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json",
          },
        });

        // Destructure the response data
        const { status_code, reason, detail, data } = response.data;

        // Check if the response status is successful
        if (status_code === 200) {
          setProjects(data);
        } else {
          console.log(`Failed to retrieve projects: ${detail}`);
        }
      } catch (error) {
        // Handle errors (e.g., network issues, server errors)
        if (error.response) {
          // Server responded with a status other than 2xx
          console.log(`Error: ${error.response.data.detail || "Server Error"}`);
        } else if (error.request) {
          // Request was made but no response received
          console.log("No response received from server.");
        } else {
          // Something else caused the error
          console.log(`Error: ${error.message}`);
        }
      } finally {
        console.log(false); // Ensure loading state is set to false after request completes
      }
    };

    // Call the fetchProjects function
    fetchProjects();
  }, []);

  const transformProjectIdForDisplay = (projectId) => {
    return projectId
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="h-screen flex flex-col pb-20 md:pb-0">
      <Header />
      <div className="flex-1 overflow-y-scroll pb-2">
        <div className="ic-interviews voyce py-0 h-full">
          <div className="container pt-32">
            <h1 className="text-center font-extrabold text-3xl mb-10">
              Enter Nexa's Octagon & Face Real-World Scenarios
            </h1>
            <br />

            {/* Intro text */}
            {showIntro && (
              // <div className="text-center text-4xl mb-12 px-8 max-w-2xl mx-auto font-bold text-gray-800 leading-relaxed transition-all duration-300 ease-in-out transform hover:scale-105">
              <div className="leading-relaxed transition-all duration-300 ease-in-out transform hover:scale-105">
                <Heading className="text-center mx-auto text-gray-800">
                  Nexa's Octagon allows you to gain experience in a specific
                  role, making you stand out as a better candidate. Our engine
                  uses AI to simulate hundreds of on-the-job scenarios based on
                  specific companies.
                </Heading>
                <Heading className="text-center mx-auto text-gray-800 mt-8">
                  Let’s get to work!
                </Heading>
              </div>
              // </div>
            )}

            {/* Initial Options */}
            {!category && !subcategory && (
              <div>
                <div className="ic-interview-card-container">
                  {cards.map((card, index) => (
                    <OctagonCards
                      index={index}
                      key={index}
                      card={card}
                      onCardClick={() => {
                        setShowIntro(false); // Hide the intro text on card click
                        navigate(
                          { pathname: `/octagon${card.link}` },
                          { replace: true }
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            )}

            {/* Category Options */}
            {category && !subcategory && (
              <div>
                <div className="mt-6 flex justify-between items-center">
                  <button
                    onClick={() => {
                      setShowIntro(true); // Hide the intro text on card click
                      navigate({ pathname: `/octagon` }, { replace: true });
                    }}
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    <img src={backIcon} alt="Back" />
                  </button>
                  <select
                    onChange={(e) => {
                      const selectedProjectId = e.target.value;
                      if (selectedProjectId) {
                        const navigationPath = selectedProjectId.replace(
                          /-/g,
                          "/"
                        );
                        navigate(
                          `/intervieworcustom/codingdojo/${navigationPath}`
                        );
                      }
                    }}
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    <option value="" style={{ color: "gray" }}>
                      Select Saved Project
                    </option>
                    {projects.map((project) => (
                      <option
                        key={project.id}
                        value={project.project_id}
                        style={{ color: "black" }}
                      >
                        {transformProjectIdForDisplay(project.project_id)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="ic-interview-card-container">
                  {octoStack[category].map((card, index) => (
                    <OctaCatCards
                      index={index}
                      key={index}
                      card={card}
                      onCardClick={() => {
                        setShowIntro(false); // Hide the intro text on card click
                        navigate(
                          { pathname: `/octagon/${category}${card.link}` },
                          { replace: true }
                        );
                      }}
                    />
                  ))}
                </div>
              </div>

              // <div>
              //   <div className="mt-6 flex justify-end">
              //     <select
              //       onChange={(e) => {
              //         const selectedProjectId = e.target.value;
              //         if (selectedProjectId) {
              //           const navigationPath = selectedProjectId.replace(
              //             /-/g,
              //             "/"
              //           );
              //           navigate(
              //             `/intervieworcustom/codingdojo/${navigationPath}`
              //           );
              //         }
              //       }}
              //       className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
              //     >
              //       <option value="" style={{ color: "gray" }}>
              //         Select Saved Project
              //       </option>
              //       {projects.map((project) => (
              //         <option
              //           key={project.id}
              //           value={project.project_id}
              //           style={{ color: "black" }}
              //         >
              //           {transformProjectIdForDisplay(project.project_id)}
              //         </option>
              //       ))}
              //     </select>
              //   </div>
              //   <div className="ic-interview-card-container">
              //     {octoStack[category].map((card, index) => (
              //       <OctaCatCards
              //         index={index}
              //         key={index}
              //         card={card}
              //         onCardClick={() => {
              //           setShowIntro(false); // Hide the intro text on card click
              //           navigate(
              //             { pathname: `/octagon/${category}${card.link}` },
              //             { replace: true }
              //           );
              //         }}
              //       />
              //     ))}
              //   </div>
              // </div>
            )}

            {/* Subcategory Options */}
            {category && subcategory && (
              <div>
                {/* Back Button */}
                <div className="flex justify-start">
                  <button
                    onClick={() => {
                      setShowIntro(false); // Show the intro text on button click
                      navigate(
                        { pathname: `/octagon/${category}` },
                        { replace: true }
                      );
                    }}
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    <img src={backIcon} alt="Back" />
                  </button>
                </div>

                {/* Cards Container */}
                <div className="ic-interview-card-container flex flex-wrap justify-center">
                  {octoStack["techstacks"].map((card, index) => (
                    <OctagonCards
                      index={index}
                      key={index}
                      card={card}
                      onCardClick={() => {
                        setShowIntro(false); // Hide the intro text on card click
                        navigate(
                          `/intervieworcustom/${category}/${subcategory}${card.link}`
                        );
                      }}
                    />
                  ))}
                </div>
              </div>

              // <div className="ic-interview-card-container flex flex-wrap justify-center">
              //   {octoStack["techstacks"].map((card, index) => (
              //     <OctagonCards
              //       index={index}
              //       key={index}
              //       card={card}
              //       onCardClick={() => {
              //         setShowIntro(false); // Hide the intro text on card click
              //         navigate(
              //           `/intervieworcustom/${category}/${subcategory}${card.link}`
              //         );
              //       }}
              //     />
              //   ))}
              // </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Octagon;

const Heading = styled.p`
  font-size: 22px;
  font-family: "Libre Baskerville", serif;
  max-width: 720px;
  letter-spacing: 0.3px;
  color: #3a3a3a;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
`;
