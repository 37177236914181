import React from "react";

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="flex space-x-4 border-b-2 border-gray-200">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          className={`py-2 px-4 ${
            activeTab === tab.value
              ? "text-blue-500 border-blue-500 font-semibold border rounded-t-lg"
              : "text-gray-600 border-transparent"
          } hover:text-blue-500 focus:outline-none`}
          onClick={() => setActiveTab(tab.value)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
