import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addResumeProfile,
  getResumeProfile,
  udpateResumeProfile,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { SaveIcon } from "../../Components/Resume/svgimages";
const ResumeInfo = () => {
  const { id } = useParams();
  const { profile, contactId, workExperience } = useSelector(
    (state) => state.ResumeBuilder
  );
  const dispatch = useDispatch();
  const [editing, setIsEditing] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const getUserInfo = () => {
    const resumeId = id;
    dispatch(getResumeProfile({ resumeId, onSuccess: () => {} }));
  };

  const onSubmit = (data) => {
    data.zip = null;
    console.log("data :", data);

    if (editing) {
      const userInfo = {
        contactId: data?.id,
        data,
      };

      dispatch(
        udpateResumeProfile({
          userInfo,
          onSuccess: (res) => {
            success(res);
            getUserInfo();
          },
        })
      )
        .unwrap()
        .then((res) => {})
        .catch((err) => {
          console.log(err.response, "rejected");
          error(err.response.data.detail);
        });
    } else {
      const userInfo = data;
      dispatch(
        addResumeProfile({
          userInfo,
          id,
          onSuccess: (res) => {
            success(res);
            getUserInfo();
          },
        })
      )
        .unwrap()
        .then((res) => {})
        .catch((err) => {
          console.log(err.response, "rejected");
          error(err.response.data.detail);
        });
    }
    reset();
    setIsEditing(false);
    reset();
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const onCancel = () => {
    reset();
    setIsEditing(false);
  };

  useEffect(() => {
    if (editing && profile) {
      Object.keys(profile).forEach((key) => {
        setValue(key, profile[key]);
      });
    }
  }, [editing, profile, setValue]);
  useEffect(() => {
    if (id) {
      getUserInfo();
    }
  }, [id]);

  return (
    <div className="mydetails">
      {profile && !editing ? (
        <div className="profile-data">
          <div className="profile-data-header">
            <button onClick={handleEditClick}>
              <i className="ri-edit-line"></i>
              <span>Edit</span>
            </button>
          </div>
          <div
            className="profile-data-item flex flex-col"
            style={{
              height: 50,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 0,
            }}
          >
            <label className="text-sm">Resume Name</label>
            {/* <i className="ri-user-shared-2-line"></i> */}
            <span>{profile?.resumeName ?? "---"}</span>
          </div>

          <div
            className="profile-data-item flex flex-col"
            style={{
              height: 50,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 0,
            }}
          >
            {/* <i className="ri-mail-open-line"></i> */}
            <label className="text-sm">Job Title</label>
            <span>{profile?.jobTitle ?? "---"}</span>
          </div>
          <div
            className="profile-data-item flex flex-col"
            style={{
              height: 50,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 0,
            }}
          >
            <label className="text-sm">Company Name</label>

            {/* <i className="ri-phone-line"></i> */}
            <span>{profile?.companyName ?? "---"}</span>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-full">
              <label htmlFor="first_name" style={{ float: "left" }}>
                Resume Name
              </label>
              <input
                type="text"
                id="resumeName"
                className="form-control"
                placeholder=""
                {...register("resumeName", {
                  required: "Resume Name is required",
                })}
              />
              {errors && errors.resumeName && (
                <span className="text-danger">{errors.resumeName.message}</span>
              )}
            </div>
          </div>

          <div className="d-flex mb-1">
            <div className="form-group mr-2 w-50">
              <label htmlFor="email">Job Title</label>
              <input
                type="text"
                id="jobTitle"
                className="form-control"
                placeholder="job title"
                {...register("jobTitle", {
                  required: false,
                })}
              />
            </div>
            <div className="form-group ml-3 w-50">
              <label htmlFor="phone">Company Name</label>
              <input
                type="text"
                id="companyName"
                className="form-control"
                placeholder="company name"
                {...register("companyName", {
                  required: false,
                })}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end py-2 px-2 border-bottom border-primary">
            <button
              className="cancel-btn mr-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <SaveIcon></SaveIcon>
              Save
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ResumeInfo;
