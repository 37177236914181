import React from "react";
import { useSelector } from "react-redux";

const Guidance = () => {
  const isEducationFormVisible = useSelector(
    (state) => state.educationForm.isEducationFormVisible
  );
  const isSkillsFormVisible = useSelector(
    (state) => state.educationForm.isSkillsFormVisible
  );
  const isSkillFormVisible = useSelector(
    (state) => state.educationForm.isSkillFormVisible
  );

  const isWorkFormVisible = useSelector(
    (state) => state.educationForm.isWorkFormVisible
  );

  const isProfessionalSumamryFormVisible = useSelector(
    (state) => state.educationForm.isProfessionalSummaryFormVisible
  );
  const isProjectFormVisible = useSelector(
    (state) => state.educationForm.isProjectFormVisible
  );

  const isCertificationFormVisible = useSelector(
    (state) => state.educationForm.isCertificationFormVisible
  );

  const isPublicationFormVisible = useSelector(
    (state) => state.educationForm.isPublicationFormVisible
  );

  const activeField = useSelector((state) => state?.educationForm?.activeField);

  console.log(activeField, isEducationFormVisible, "activeField");

  return (
    <div className="guidance">
      <div className="guidance-header">Guidance</div>
      {isEducationFormVisible && activeField === "education" && (
        <div className="guidance-details">
          <h3>Education</h3>
          <p>
            Keep your education section clean with only the schools / colleges /
            universities which really matter and relate to your profession and
            job search goals.
          </p>
          <ul>
            <li>Include your most recent degree or education in progress.</li>
            <li>Add the name of school’s you have or are attending.</li>
            <li>Specify the field of study and type of degree.</li>
            <li>
              Check if the job posting asks for a specific degree and make sure
              to highlight that if applicable in your resume.
            </li>
          </ul>
        </div>
      )}
      {isProfessionalSumamryFormVisible &&
        activeField === "professionalSummary" && (
          <div className="guidance-details">
            <h3>Professional Summary</h3>
            <p>
              The Professional Summary section of your resume is a place for you
              to write a narrative or summary of your relevant professional
              experience, skills, and accomplishments. Think of your
              Professional Summary as a teaser for the employer to read the rest
              of your resume. Remember to use well known companies or products
              and numbers when possible.
            </p>
            <ul>
              <li>Get clear on audience.</li>
              <li>
                Use first person and make sure your first sentence hooks the
                reader in.
              </li>
            </ul>
          </div>
        )}
      {activeField === "profile" && (
        <div className="guidance-details">
          <h3>Profile</h3>
          <p>
            The goal of your contact information is to make it easy for an
            employer or hiring manager to learn more about you and be able to
            easily reach out if they are interested in your application
          </p>
          <ul>
            <li>
              Include your First and Last name along with any nicknames you use
              professionally i.e. Christina "Tina" Miller.
            </li>
            <li>Use your preferred Email address.</li>
            <li>
              Add your LinkedIn Profile url to make it easy to learn more about
              you
            </li>
          </ul>
        </div>
      )}
      {(isSkillsFormVisible || isSkillFormVisible) &&
        activeField === "skills" && (
          <div className="guidance-details">
            <h3>Skills</h3>
            <p>
              The skills section of your resume shows employers you have the
              abilities required for the role you are applying to. Applicant
              Tracking Systems (ATS) will leverage the skills in your resume to
              see how high of a match score your resume is to the job posting
              and prioritize higher match resumes first.
            </p>
            <ul>
              <li>
                Review the top keywords (hard and soft skills) listed in jobs
                you are targeting.
              </li>
              <li>
                Add any of these skills that you have in your skills section.
              </li>
              <li>
                Use our Skills Workbook to brainstorm your full list of skills.
              </li>
            </ul>
          </div>
        )}

      {activeField === "work" && (
        <div className="guidance-details">
          <h3>Work Experience</h3>
          <p>
            Keep your work section clean with only the schools / colleges /
            universities which really matter and relate to your profession and
            job search goals.
          </p>
          <ul>
            <li>Include your most recent degree or work in progress.</li>
            <li>Add the name of school’s you have or are attending.</li>
            <li>Specify the field of study and type of degree.</li>
            <li>
              Check if the job posting asks for a specific degree and make sure
              to highlight that if applicable in your resume.
            </li>
          </ul>
        </div>
      )}

      {isProjectFormVisible && activeField === "project" && (
        <div className="guidance-details">
          <h3>Project</h3>
          <p>
            Adding relevant projects to your resume can significantly impact how
            hiring managers or recruiters view your qualifications and are a
            fantastic way to showcase your skills, creativity, initiative, and
            problem-solving abilities.
          </p>
          <ul>
            <li>
              We recommend adding projects to your resume if you are:
              <ul className="innerlist ml-2">
                <li>A freelancer or contract worker</li>
                <li>A job-seeker or recent graduate</li>
                <li>Unable to demonstrate relevant work experience</li>
                <li>Applying for project-based roles</li>
              </ul>
            </li>
            <li>
              Think about different types of projects that you may want to
              highlight:
              <ul className="innerlist ml-2">
                <li>
                  <strong>Academic projects</strong> demonstrate your ability to
                  apply theoretical knowledge to practical situations
                </li>
              </ul>
            </li>
          </ul>
        </div>
      )}
      {isCertificationFormVisible && activeField === "certification" && (
        <div className="guidance-details">
          <h3>Certifications</h3>
          <p>
            Earning a certification demonstrates your passion and provides proof
            or evidence of specific expertise and / or skills you that have.
            Including certifications on your resume can help make your job
            application stand out if they are relevant to the role you are
            interested in.
          </p>
          <ul>
            <li>
              Show certifications for skills that are specifically mentioned in
              the job posting.
            </li>
            <li>
              Enter any educational awards or honors you received only if they
              are relevant to your role or industry.
            </li>
          </ul>
        </div>
      )}
      {isPublicationFormVisible && activeField === "publication" && (
        <div className="guidance-details">
          <h3>Publications</h3>
          <p>
            Adding publications to your resume helps demonstrate your passion
            and provides proof or evidence of specific expertise and/or skills
            you have. They can also help make your job application stand out if
            they are relevant to the role you are interested in.
          </p>
          <ul>
            <li>
              Show publications for skills or industries that are specifically
              mentioned in the job posting.
            </li>
            <li>
              When including publications make sure to add:
              <ul className="innerlist ml-2">
                <li>Publication Name</li>
                <li>Publisher</li>
                <li>Date</li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Guidance;
