import React, { useEffect } from "react";
import dumyUser from "../../assets/media/images/dumyUser.png";
import dayjs from "dayjs";
import Attachment from "../../assets/media/images/Attachment.png";
import pdfIcon from "../../assets/media/images/pdfIcon.png";
import zipIcon from "../../assets/media/images/zipIcon.png";
import txtIcon from "../../assets/media/images/txtIcon.png";
import wordIcon from "../../assets/media/images/wordIcon.png";
const ChatBox = ({ message, index }) => {
  let containerClasses =
    "col-span-11 sm:col-span-8 md:col-span-10 lg:col-span-8 xl:col-span-8";
  let alignedClass = message?.is_my_message
    ? `${containerClasses} col-start-2 sm:col-start-5 md:col-start-3 lg:col-start-5 xl:col-start-5`
    : containerClasses;
  const getIconSrc = (fileType) => {
    //console.log(fileType, "fileType");
    switch (fileType) {
      case "pdf":
        return pdfIcon;
      case "x-zip-compressed":
      case "zip":
        return zipIcon;
      case "txt":
        return txtIcon;
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "doc":
      case "docx":
        return wordIcon;
      default:
        return Attachment; // Default icon for other types
    }
  };
  useEffect(() => {
    const element = document.getElementById(message?.created_at + "Z");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [message]);
  return (
    <div id={message?.created_at + "Z"} className="grid grid-cols-12 mt-4">
      <div className={alignedClass}>
        <div
          className={`w-full flex ${message?.is_my_message ? "pr-2" : "pl-2"}`}
        >
          {message?.sender_image ? (
            <img
              alt="Profile"
              src={message?.sender_image}
              className={`rounded-full border h-[3rem] w-[3rem]  ${
                message?.is_my_message ? "ml-3 order-2" : "mr-3 order-1"
              }`}
              height={50}
              width={50}
            />
          ) : (
            <img
              alt="dumy"
              src={dumyUser}
              className={`h-[3rem] w-[3rem]  rounded-full ${
                message?.is_my_message ? "ml-3 order-2" : "mr-3 order-1"
              }`}
            />
          )}

          <div
            className={`bg-gray-400 rounded-lg bg-opacity-10 px-4  flex flex-col gap-2 p-2 pr-3 md:pr-8  ${
              message?.is_my_message ? "order-1 ml-auto" : "order-2 mr-auto"
            }`}
          >
            <div className="flex justify-between">
              <h4
                className={`text-xl font-semibold whitespace-no-wrap${
                  message?.is_my_message ? "text-left" : "text-left"
                }`}
              >
                {message?.sender_name}
              </h4>
              <small className="mt-1 ml-6 whitespace-no-wrap">
                {" "}
                {dayjs(message?.created_at + "Z").format("h:mm a")}
              </small>
            </div>
            <p
              className={`text-sm font-light whitespace-no-wrap ${
                message?.is_my_message ? "text-left pl-0" : "text-left pr-0"
              }`}
            >
              {message?.content}
            </p>
            {message?.extension && (
              <>
                {message?.extension == "jpg" ||
                message?.extension == "jpeg" ||
                message?.extension == "png" ||
                message?.extension == "gif" ||
                message?.extension == "svg" ? (
                  <div className="flex align-center justify-center ">
                    <img
                      alt="dumy"
                      src={message?.media}
                      className="w-auto h-auto p-0 object-cover rounded-t-xl bg-blend-overlay"
                    />
                  </div>
                ) : message?.extension == "mp4" ||
                  message?.extension == "webm" ? (
                  <video controls>
                    <source src={message?.media} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : message?.extension == "pdf" ||
                  message?.extension == "zip" ||
                  message?.extension == "txt" ||
                  message?.extension == "docx" ||
                  message?.extension ==
                    "vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                  <>
                    <a
                      className="cursor-pointer"
                      href={message?.media}
                      target="_blank"
                    >
                      <div className="flex flex-col align-center items-center">
                        <img
                          alt="FooterImage"
                          className="h-[8rem] w-[8rem] mt-1 mr-2"
                          src={getIconSrc(message?.extension)}
                        />
                        <p
                          className="ml-4 text-lg mt-2"
                          title={message?.filename}
                        >
                          {message?.filename
                            ? `${message?.filename
                                ?.split("_")[1] // Split by underscore and take index 1
                                ?.slice(0, 20)}` // Take file extension
                            : "Attachment"}
                        </p>
                      </div>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      className="cursor-pointer"
                      href={message?.media}
                      target="_blank"
                    >
                      <div className="flex flex-col align-center items-center">
                        <img
                          alt="FooterImage"
                          className="h-[2rem] w-[2rem] mt-1 mr-2"
                          src={Attachment}
                        />
                        <p className="ml-4 text-lg"> attachment</p>
                      </div>
                    </a>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
