import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error } from "../Utilities/toast";
// import { error, success } from "../Utilities/toast";

const simulatorProRepo = RepositoryFactory.get("simulatorPro");
export const validateSessionId = createAsyncThunk(
  "simulatorPro/validateSessionId",
  async ({ sessionsId, onError }, { rejectWithValue }) => {
    try {
      const { data } = await simulatorProRepo.validateSessionId(sessionsId);
      console.log("data: ", data);
      return data;
    } catch (err) {
      error(err.data.detail || "Someting went wrong, please try again later");
      onError();
      return rejectWithValue(err.message);
    }
  }
);
export const getPromptById = createAsyncThunk(
  "simulatorPro/getPromptById",
  async ({ id, sessionId }, { rejectWithValue }) => {
    try {
      if (sessionId !== null) {
        return "Simulate a realistic and engaging mock interview experience, designed to closely mimic a human interaction. You are a hiring manager and you goal is to make sure if the candidate is good for the ${jobDescription}. This means you ask questions and wait for a response and respond realtime based on the input you receive. Focus on active listening with natural interjections to maintain a human conversational flow. The tone should be professional yet welcoming, with emphasis on the candidate's resume and the provided job description. Here are some details from my resume: ${contactDetails}. Follow this as guideline but respond based on realtime input. Introduction: Warm greeting, light small talk to build rapport. Behavioral Questions: Ask 2-5 questions about past work experiences, incorporating natural interjections to keep the conversation flowing. Technical Questions: Pose 3-5 job-related technical questions, encouraging the candidate to elaborate. Scenario-Based Questions: Present 2-3 situational challenges, assessing problem-solving and decision-making skills. Closing: Invite the candidate to ask questions and conclude with a friendly, encouraging statement. Guidelines: Base 33% of the conversation on the candidate's resume and 66% on the job description. Respond to real-time input without pre-determined answers make sure you wait for me to reply before moving ahead don't add warm smile and interviewer: in response give me in a json response. please make sure the questions asked by assistant never exceed beyond 500 characters, also beware do not let user distract from the interview if user gives invalid answer politely bring them back to the topic with { '' } like this on each response when the interview is completed give response to the user 'the interview has concluded' in json format.";
      } else {
        const { data } = await simulatorProRepo.getPromptById(id);
        return data?.prompt_text;
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const simulatorProBegin = createAsyncThunk(
  "simulatorPro/simulatorProBegin",
  async ({ payload, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await simulatorProRepo.simulatorProBegin(payload);
      console.log("data: ", data);
      if (data?.status_code === 200) {
        onSuccess(data.data);
        return data.data;
      }
    } catch (err) {
      console.log("...", err);
      onError(err);
      // return rejectWithValue(err.message);
    }
  }
);

export const simulatorProSave = createAsyncThunk(
  "simulatorPro/simulatorProSave",
  async ({ payload, sessionsId, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await simulatorProRepo.simulatorProSave(
        payload,
        sessionsId
      );
      if (data?.status_code === 200) {
        onSuccess(data);
        return data;
      }

      return rejectWithValue();
    } catch (err) {
      onError(err);
      // error("")
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  isPromptLoading: false,
  gettingSessionId: false,
  isFeedbackLoading: false,
  prompt: "",
  sessionId: null,
  feedbackData: null,
  isSessionLoading: false,
  sessionValidation: null,
};

const simulatorProSlice = createSlice({
  name: "simulatorPro",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getPromptById.pending, (state) => {
        state.isPromptLoading = true;
      })
      .addCase(getPromptById.fulfilled, (state, action) => {
        state.isPromptLoading = false;
        state.prompt = action.payload;
      })
      .addCase(getPromptById.rejected, (state) => {
        state.isPromptLoading = false;
      })
      .addCase(simulatorProBegin.pending, (state) => {
        state.gettingSessionId = true;
      })
      .addCase(simulatorProBegin.fulfilled, (state, action) => {
        state.gettingSessionId = false;
        state.sessionId = action.payload?.session_id;
      })
      .addCase(simulatorProBegin.rejected, (state) => {
        state.gettingSessionId = false;
      })
      .addCase(simulatorProSave.pending, (state) => {
        state.isFeedbackLoading = true;
      })
      .addCase(simulatorProSave.fulfilled, (state, action) => {
        state.isFeedbackLoading = false;
        state.feedbackData = action.payload?.data;
        console.log("state.feedbackData : ", state.feedbackData);
      })
      .addCase(simulatorProSave.rejected, (state) => {
        state.isFeedbackLoading = false;
      })
      .addCase(validateSessionId.pending, (state) => {
        state.isSessionLoading = true;
      })
      .addCase(validateSessionId.fulfilled, (state, action) => {
        state.isSessionLoading = false;
        state.sessionValidation = action.payload?.data;
      })
      .addCase(validateSessionId.rejected, (state) => {
        state.isSessionLoading = false;
      });
  },
});

export default simulatorProSlice.reducer;
