import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/header";
import Loader from "../../Components/main-loader";
import {
  generateSkillsGapAnalysis,
  getOnBoardingAction,
  onBoardingAction,
  updateOnboarding,
} from "../../Redux/authenticate/authenticate.action";
import { error, success } from "../../Utilities/toast";
import TypingEffect from "../../Utilities/TypingEffect";
import "./user.css";
const UserDashboard2 = () => {
  const dispatch = useDispatch();
  const { user, onboardingData, loading, isloading, skillsAnalysis } =
    useSelector((state) => state.authenticate);
  console.log(loading, "loading");
  const [isOpen, setIsOpen] = useState(false);
  const [storeFetchValue, setStoreFetchValue] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  console.log("selectedItem: ", selectedItem);
  const fileInput = useRef(null);
  const [resume, setResume] = useState(null);

  const localSlugs = [
    {
      slug: "confident-skills-target-role",
      value:
        "What specific skills are you confident in that are directly related to your target role?",
    },
    {
      slug: "areas-of-low-confidence",
      value:
        "Which skills or knowledge areas do you feel least confident in for this role?",
    },
    {
      slug: "preferred-learning-methods",
      value:
        "How do you prefer to learn new skills? (e.g., online courses, workshops, hands-on projects, mentoring)",
    },
    {
      slug: "desired-certifications-tools",
      value:
        "Are there any certifications, industry tools, or technologies in your field that you’re unfamiliar with but want to learn?",
    },
    {
      slug: "ultimate-career-goal",
      value:
        "What is your ultimate career goal, and how does this role fit into that path?",
    },
    {
      slug: "past-learning-challenges",
      value:
        "What challenges or obstacles have prevented you from learning new skills in the past?",
    },
    {
      slug: "additional-information-for-career-goal",
      value:
        "Is there anything else you feel we should know to best help you reach your career goal?",
    },
    {
      slug: "your-resume",
      value: "Your Resume",
    },
  ];

  const updateAnswer = (payload) => {
    dispatch(updateOnboarding(payload))
      .unwrap()
      .then((response) => {
        if (response) {
          dispatch(getOnBoardingAction());
          setSelectedItem(null);
          setSelectedItem({ answer: "" });
          setResume(null);
          success("Updated Successfully");
          setIsOpen(false);
        }
      });
  };
  const createAnswer = (payload) => {
    dispatch(onBoardingAction(payload))
      .unwrap()
      .then((response) => {
        if (response) {
          dispatch(getOnBoardingAction());
          setSelectedItem(null);
          setSelectedItem({ answer: "" });
          setResume(null);
          success("Updated Successfully");
          setIsOpen(false);
        }
      });
  };

  useEffect(() => {
    dispatch(getOnBoardingAction());
    // dispatch(getSkillsAnalysis());
  }, []);
  const handleUpdate = () => {
    let previousData = onboardingData?.find(
      (onboarding) => onboarding?.question_slug === selectedItem?.slug
    );
    let prevAnswer =
      previousData?.answer || previousData?.fileUrl ? true : false;

    if (selectedItem.answer || resume !== null) {
      let payload = {
        question_slug: selectedItem.slug,
      };
      if (selectedItem?.slug == "your-resume") {
        payload.file = resume;
      } else {
        payload.answer = selectedItem.answer;
      }
      if (prevAnswer) {
        updateAnswer(payload);
      } else {
        createAnswer(payload);
      }
    } else {
      error("Answer Cannot be Empty");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Header />
      {/* <div
        style={{
          position: "fixed",
          top: "10vh",
          bottom: "55vh",
          left: "15vw",
          right: "10vw",
          backgroundColor: "white",
          border: "1px solid",
          borderColor: "gray",
          borderRadius: "20px",
          zIndex: 10,
          boxShadow: "10px 10px 40px gray",
          padding: "30px",
          minHeight: "20rem",
          height: "fit-content",
          visibility: isOpen ? "visible" : "hidden",
        }}
      >
        <h3 className="inline text-bold text-primary text-md w-[100vw]">
          Edit Details
        </h3>
        <span
          onClick={() => {
            setIsOpen(false);
            setSelectedItem(null);
            setSelectedItem({ answer: "" });
          }}
          style={{
            position: "absolute",
            right: "4vw",
            fontSize: "30px",
            fontWeight: "800",
            top: "20px",
          }}
          className="inline cursor-pointer text-primary font-bold ml-auto"
        >
          &times;
        </span>

        <br />
        {selectedItem?.slug == "your-resume" ? (
          <>
            {resume ? (
              <p>{resume?.name}</p>
            ) : (
              <div className="ic-mockInterview d-flex justify-content-center align-items-center">
                <div className="ic-mock-interview mt-3">
                  <h4>Please Upload your Resume to Tailor your Experience</h4>
                  <form action="#">
                    <input
                      type="file"
                      name="file"
                      ref={fileInput}
                      accept=".pdf"
                      hidden
                      onChange={(e) => {
                        setResume(e.target.files[0]);
                      }}
                    />
                    <div
                      className="ic-file-upload"
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    >
                      <div className="ic-icon">
                        <img
                          src="/images/document-upload.png"
                          alt=""
                          style={{ width: "auto" }}
                        />
                      </div>
                      <p className="support-text">
                        <span className="primary">Click to Upload </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </>
        ) : (
          <textarea
            className="w-full p-1"
            placeholder="Change contents hereeee!"
            rows={6}
            value={selectedItem?.answer}
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                answer: e.target.value,
              });
            }}
          ></textarea>
        )}
        <br />
        <br />
        <button
          className="btn btn-primary"
          onClick={() => {
            handleUpdate();
          }}
        >
          Save
        </button>
      </div> */}
      <div
        className="ic-interview-full-body"
        style={{
          // backgroundImage: `url(${"/images/interview-banner.png"})`,
          paddingTop: "6em",
        }}
      >
        <main
          role="main"
          className=" ml-sm-auto col-lg-12 px-4 bg-cover"
          style={
            {
              // backgroundImage: `url(${"/images/interview-banner.png"})`,
            }
          }
        >
          <div className="relative bg-gradient-to-b from-white to-gray-50 rounded-2xl shadow-xl p-12 mx-auto max-w-5xl mt-6 mb-8">
            <div className="absolute inset-x-0 -top-4 flex justify-center">
              <div className="bg-gradient-to-r from-blue-600 to-purple-600 h-2 w-32 rounded-full" />
            </div>

            <div className="space-y-8">
              <div className="text-center space-y-4">
                <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                  Welcome to Nexa's Skills Gap Analysis
                </h1>

                <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                  Your pathway to professional excellence starts here. We
                  identify the exact skills you need to bridge the gap between
                  your current position and your dream role.
                </p>
              </div>

              <div className="grid md:grid-cols-3 gap-6">
                <div className="group bg-white hover:bg-blue-50 p-6 rounded-xl shadow-md transition-all duration-300">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2 group-hover:text-blue-600">
                    Precision Analysis
                  </h3>
                  <p className="text-gray-600">
                    Get detailed insights about your skill gaps
                  </p>
                </div>

                <div className="group bg-white hover:bg-blue-50 p-6 rounded-xl shadow-md transition-all duration-300">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2 group-hover:text-blue-600">
                    Personalized Roadmap
                  </h3>
                  <p className="text-gray-600">
                    Receive a custom learning path
                  </p>
                </div>

                <div className="group bg-white hover:bg-blue-50 p-6 rounded-xl shadow-md transition-all duration-300">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2 group-hover:text-blue-600">
                    Smart Job Matching
                  </h3>
                  <p className="text-gray-600">
                    Find opportunities matching your skills
                  </p>
                </div>
              </div>

              <div className="text-center">
                <p className="text-gray-600 italic">
                  The more information you provide, the more precise our
                  recommendations become
                </p>
              </div>
            </div>
          </div>

          <div className="grid-margin stretch-card my-3">
            <div className="card border-rounded-card card-padding-profile !w-[85%] m-auto max-w-[1000px]">
              <div className="card-body py-0">
                <h3 className="skills-heading">Skills Gap Assessment</h3>
                <TypingEffect storeFetchValue={storeFetchValue} />
                {/* <div
                  dangerouslySetInnerHTML={SkillsGapConvertion(
                    skillsAnalysis && skillsAnalysis.analysis
                  )}
                ></div> */}
                {/* <p>skills gap response </p> */}
                <div className="d-flex justify-content-end align-items-center mt-4">
                  <button
                    className="ic-btn"
                    onClick={() =>
                      dispatch(
                        generateSkillsGapAnalysis({
                          onSuccess: () => {
                            setStoreFetchValue(true);
                          },
                        })
                      )
                    }
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="content pb-5">
            {/* {localSlugs?.map((item, index) => {
                let answer = onboardingData?.find(
                  (onboarding) => onboarding?.question_slug === item?.slug
                )?.answer;
                let file = onboardingData?.find(
                  (onboarding) => onboarding?.question_slug === item?.slug
                )?.file_url;

                return (
                  <>
                    {item?.question_slug !== "nexa-usage-plan" && (
                      <div className="col-xl-12 col-lg-12 col-sm-12 grid-margin stretch-card mb-5 position-relative">
                        <div
                          className="card border-rounded-card card-padding-profile"
                          style={{ boxShadow: "10px 10px 40px gray" }}
                        >
                          <div className="card-body text-center d-flex justify-content-between ">
                            <div className="d-flex flex-column justify-content-left  ">
                              <h3 className="text-dark font-weight-normal text-left">
                                {item?.value}
                              </h3>
                              {item?.slug == "your-resume" ? (
                                <button
                                  className="btn btn-primary mx-0 my-3"
                                  disabled={file == null}
                                  onClick={() => window.open(file, "_blank")}
                                >
                                  Open PDF
                                </button>
                              ) : (
                                <p className="mb-2 text-dark font-weight-normal mt-4">
                                  {answer}
                                </p>
                              )}
                            </div>
                            <div>
                              <i
                                className="ri-pencil-line h1 text-primary"
                                onClick={() => {
                                  setSelectedItem({ ...item, answer });
                                  setIsOpen(true);
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })} */}
            {localSlugs?.map((item, index) => {
              let answer = onboardingData?.find(
                (onboarding) => onboarding?.question_slug === item?.slug
              )?.answer;
              let file = onboardingData?.find(
                (onboarding) => onboarding?.question_slug === item?.slug
              )?.file_url;
              return (
                <div className="career_slide-cover">
                  <div className="ic-question-start border-0">
                    <div className="question-header">
                      <span className="question-tags">
                        <i className="ri-error-warning-line"></i>
                        {item?.value}
                      </span>
                      <div
                        className="edit-arrow"
                        onClick={() => {
                          setSelectedItem({ ...item, answer });
                        }}
                      >
                        <i className="ri-pencil-line h1 text-primary mb-0"></i>
                      </div>
                    </div>
                    {item?.slug == "your-resume" ? (
                      <>
                        {resume ? (
                          <p>{resume?.name}</p>
                        ) : (
                          <div
                            className={`ic-mockInterview d-flex justify-content-center align-items-center `}
                          >
                            {selectedItem?.value == item?.value && (
                              <div className="ic-mock-interview mt-3">
                                <h4>
                                  Please Upload your Resume to Tailor your
                                  Experience
                                </h4>
                                <form action="#">
                                  <input
                                    type="file"
                                    name="file"
                                    ref={fileInput}
                                    accept=".pdf"
                                    hidden
                                    onChange={(e) => {
                                      setSelectedItem({ ...item, answer });
                                      setResume(e.target.files[0]);
                                    }}
                                  />
                                  <div
                                    className="ic-file-upload"
                                    onClick={() => {
                                      fileInput.current.click();
                                    }}
                                  >
                                    <div className="ic-icon">
                                      <img
                                        src="/images/document-upload.png"
                                        alt=""
                                        style={{ width: "auto" }}
                                      />
                                    </div>
                                    <p className="support-text">
                                      <span className="primary">
                                        Click to Upload{" "}
                                      </span>
                                    </p>
                                  </div>
                                </form>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : selectedItem?.value == item?.value ? (
                      <textarea
                        className="w-full p-1 mt-2"
                        placeholder="Change contents here!"
                        rows={6}
                        value={
                          selectedItem?.value == item?.value
                            ? selectedItem.answer
                            : answer
                        }
                        onChange={(e) => {
                          setSelectedItem({
                            ...item,
                            answer: e.target.value,
                          });
                        }}
                      ></textarea>
                    ) : (
                      <p className="mb-2 text-dark font-weight-normal mt-4">
                        {answer}
                      </p>
                    )}
                    <button
                      className="btn btn-primary"
                      disabled={
                        selectedItem?.value == item?.value ? false : true
                      }
                      onClick={() => {
                        handleUpdate();
                      }}
                    >
                      Save
                    </button>
                    {item?.slug == "your-resume" ? (
                      <button
                        className="btn btn-primary mx-0 my-3 mx-2"
                        disabled={file == null}
                        onClick={() => window.open(file, "_blank")}
                      >
                        Open PDF
                      </button>
                    ) : (
                      ""
                      // <h4>{answer}</h4>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </main>
      </div>
    </>
  );
};

export default UserDashboard2;
