import React from "react";
import BlogsSection from "../Components/aboutUs/BlogsSection";
import CareerSection from "../Components/aboutUs/CareerSection";
import HeroSection from "../Components/aboutUs/HeroSection";
import TeamAndCultureSection from "../Components/aboutUs/TeamAndCultureSection";
import { default as Footer } from "../Components/footer_new/footer";
import { default as Header } from "../Components/header_new/navbar";

import GetInTouchSection from "../Components/aboutUs/GetInTouchSection";

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="aboutUs">
        <HeroSection />
        <TeamAndCultureSection />
        <CareerSection />
        <BlogsSection />
        <GetInTouchSection />
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
