import { atom } from "recoil";

const defaultState = {
  activeStep: 0,
  selectedTheme: null,
  isDeleteSection: null,
  formErrors: null,
};

export const StepStateAtom = atom({
  key: "StepState",
  default: defaultState,
});

export { defaultState };
