// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stat-card {
  padding-left: 0;
  padding-top: 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}

.stat-card > div > h3 {
  font-size: 2.5rem;
}

.border-rounded-card {
  border-radius: 1.25rem;
}

.card-padding-profile {
  padding: 20px 20px 34px 30px;
}

.card-padding-profile2 {
  padding: 20px 20px 34px 30px;
}

.profile-card {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

@media (max-width: 425px) {
  .card-padding-profile2 {
    padding: 0 0;
  }
  .card-padding-profile2-button {
    font-size: 12px !important;
  }
}

.card-icon-box {
  position: absolute;
  top: -22px;
  padding: 19px 25px;
  border-radius: 10px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

.bg-color-black {
  background-color: #333;
}

.bg-color-blue {
  background-color: #007bff;
}

.bg-color-green {
  background-color: #28a745;
}

.ri-user-6-line:before,
.ri-chat-3-line:before,
.ri-profile-line:before {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/User/user.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,2CAA2C;EAC3C,gBAAgB;AAClB;;AAEA;EACE;IACE,YAAY;EACd;EAIA;IACE,0BAA0B;EAC5B;AALF;;AAQA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,mBAAmB;EACnB,0CAA0C;AAC5C;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;;EAGE,YAAY;AACd","sourcesContent":[".stat-card {\n  padding-left: 0;\n  padding-top: 0;\n  border-bottom: 1px solid #eee;\n  margin-bottom: 30px;\n}\n\n.stat-card > div > h3 {\n  font-size: 2.5rem;\n}\n\n.border-rounded-card {\n  border-radius: 1.25rem;\n}\n\n.card-padding-profile {\n  padding: 20px 20px 34px 30px;\n}\n\n.card-padding-profile2 {\n  padding: 20px 20px 34px 30px;\n}\n\n.profile-card {\n  background-color: white;\n  padding: 40px;\n  border-radius: 20px;\n  overflow: hidden;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n  text-align: left;\n}\n\n@media (max-width: 425px) {\n  .card-padding-profile2 {\n    padding: 0 0;\n  }\n}\n\n@media (max-width: 425px) {\n  .card-padding-profile2-button {\n    font-size: 12px !important;\n  }\n}\n\n.card-icon-box {\n  position: absolute;\n  top: -22px;\n  padding: 19px 25px;\n  border-radius: 10px;\n  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);\n}\n\n.bg-color-black {\n  background-color: #333;\n}\n\n.bg-color-blue {\n  background-color: #007bff;\n}\n\n.bg-color-green {\n  background-color: #28a745;\n}\n\n.ri-user-6-line:before,\n.ri-chat-3-line:before,\n.ri-profile-line:before {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
