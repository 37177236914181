import React, { useEffect, useState } from "react";
import { error, success } from "../../../Utilities/toast";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../Redux/authenticate/authenticate.action";
import {
  AddWorkSamples,
  deleteProjects,
} from "../../../Redux/UserProfileSlice";

const WorkSamplesLinks = ({ activeStep, setActiveStep }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const [initialUserWorkInfo, setInitialUserWorkInfo] = useState([]);
  console.log(initialUserWorkInfo, "initialUserWorkInfo");
  const [projects, setProjects] = useState([
    { title: "", description: "", link: "", id: null },
  ]);

  const handleChange = (index, event) => {
    const values = [...projects];
    values[index][event.target.name] = event.target.value;
    setProjects(values);
  };

  const handleAddProject = () => {
    setProjects([
      ...projects,
      { title: "", description: "", link: "", id: null },
    ]);
  };

  const handleRemoveProject = (index, obj) => {
    const selectedData = projects.find((item) => item === obj);
    if (selectedData?.id) {
      dispatch(
        deleteProjects({
          id: selectedData?.id,
          onSuccess: () => {
            success("Project deleted successfully");
            dispatch(getCurrentUser());
          },
        })
      );
    } else {
      const newprojects = [...projects];
      newprojects.splice(index, 1);
      setProjects(newprojects);
    }
  };

  const handleSave = () => {
     const hasChanged =
      JSON.stringify(projects) !== JSON.stringify(initialUserWorkInfo);

    if (hasChanged) {
    if (projects.some((project) => !project.title)) {
      return error("Title is required");
    }
    const filteredProjects = projects
      .filter((project) => project.title.trim() !== "")
      ?.map((project) => ({
        title: project.title,
        description: project.description.trim() || undefined,
        link: project.link.trim() || undefined,
        id: project.id,
      }));

    if (filteredProjects.length === 0) {
      return error("At least one project with a title is required.");
    }

    const formattedData = {
      project_samples: filteredProjects,
    };

    dispatch(
      AddWorkSamples({
        payload: formattedData,
        onSuccess: () => {
          success("Projects added successfully");
          dispatch(getCurrentUser());
          setActiveStep((prev) => prev + 1);
        },
      })
    );
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (user && user?.projects?.length > 0) {
      setProjects(user?.projects?.map((project) => ({ ...project })));
      setInitialUserWorkInfo(
        user?.projects?.map((project) => ({ ...project }))
      );
    }
  }, [user]);

  return (
    <div className="p-4">
      {projects?.map((project, index) => (
        <div key={index} className="mb-4 p-4 border rounded-lg shadow">
          <div className="mb-2">
            <label className="block mb-1">Project Title:</label>
            <input
              type="text"
              name="title"
              value={project.title}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              placeholder="Enter project title"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block mb-1">Description (optional):</label>
            <textarea
              name="description"
              value={project.description}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              placeholder="Enter project description"
              rows="3"
            />
          </div>
          <div className="mb-2">
            <label className="block mb-1">Link (optional):</label>
            <input
              type="url"
              name="link"
              value={project.link}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              placeholder="Enter project link"
            />
          </div>
          {projects.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemoveProject(index, project)}
              className="text-white bg-red-600 mt-3 px-4 py-2 rounded-lg"
            >
              Remove Project
            </button>
          )}
        </div>
      ))}
      <div className="w-full gap-2 flex justify-end">
        <button
          type="button"
          onClick={handleAddProject}
          className="mt-2 bg-blue-500 text-white p-2 rounded"
        >
          Add Another Project
        </button>
      </div>
      <div className={`w-full flex mt-6 justify-between`}>
        <button
          className="ic-btn"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          Previous
        </button>
        <button className="ic-btn" onClick={handleSave}>
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default WorkSamplesLinks;
