import React from "react";
import { HexColorPicker } from "react-colorful";

export default function Colors({
  activeTab,
  setActiveTab,
  selectedHeadingColor,
  setSelectedHeadingColor,
  selectedSubHeadingColor,
  setSelectedSubHeadingColor,
}) {
  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A6",
    "#FF9933",
    "#33FFF6",
  ];
  // Handler to set the selected color
  const handleColorSelect = (color) => {
    if (activeTab == "headingColor") {
      setSelectedHeadingColor(color);
    } else {
      setSelectedSubHeadingColor(color);
    }
  };
  return (
    <>
      <div
        className={`flex w-full bg-[#f5f5f5] border-b `}
        style={{ width: "auto" }}
      >
        <ul className="list-reset flex border-b">
          <li className="-mb-px">
            <a
              className={`border-b inline-block py-2 px-4 font-semibold ${
                activeTab === "headingColor"
                  ? " border-l border-t border-r rounded-t text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => setActiveTab("headingColor")}
            >
              Heading 
            </a>
          </li>
          <li className="-mb-px mr-1">
            <a
              className={`border-b inline-block py-2 px-4 font-semibold ${
                activeTab === "subheadingColor"
                  ? " border-l border-t border-r rounded-t text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => setActiveTab("subheadingColor")}
            >
              Subheading 
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h3>Colors</h3>
      </div>
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          width: "auto",
          borderRadius: "50%",
        }}
      >
        {colors.map((color) => (
          <div
            key={color}
            onClick={() => handleColorSelect(color)}
            style={{
              backgroundColor: color,
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              cursor: "pointer",
              border:
                activeTab == "headingColor"
                  ? selectedHeadingColor === color
                    ? "2px solid black"
                    : "none"
                  : selectedSubHeadingColor === color
                  ? "2px solid black"
                  : "none",
            }}
          ></div>
        ))}
      </div>
      <div>
        <h3 className="text-blue-500">Additional Custom Color</h3>
        <div className="mt-2">
          <HexColorPicker
            color={
              activeTab == "headingColor"
                ? selectedHeadingColor
                : selectedSubHeadingColor
            }
            onChange={
              activeTab == "headingColor"
                ? setSelectedHeadingColor
                : setSelectedSubHeadingColor
            }
          />
        </div>
      </div>
    </>
  );
}
