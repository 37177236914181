import Repository from "./Repository";
const JOB_APPLIED = "job_applicant/user_applied_jobs_count";
const TRAINING_SESSIONS = "/analytics/interview_training_sessions";
const SIMULATION_SESSIONS = "/analytics/interview_simulation_sessions";

const profileRepository = {
  trainingSessions() {
    return Repository.get(`${TRAINING_SESSIONS}`);
  },
  simulationSessions() {
    return Repository.get(`${SIMULATION_SESSIONS}`);
  },
  jobsAppliedStats() {
    return Repository.get(`${JOB_APPLIED}`);
  },
};

export default profileRepository;
