// src/repository/callScriptRepository.js
import Repository from './Repository'; // Ensure the path is correct

// Define API endpoint constants
const CREATE_CALL_SCRIPT = '/call-scripts/';
const GET_CALL_SCRIPT = (id) => `/call-scripts/${id}/`;
const LIST_CALL_SCRIPTS = '/call-scripts/';
const UPDATE_CALL_SCRIPT = (id) => `/call-scripts/${id}/`;
const DELETE_CALL_SCRIPT = (id) => `/call-scripts/${id}/`;

// CallScript Repository
const CallScriptRepository = {
  /**
   * Create a new CallScript
   * @param {Object} payload - The data for the new CallScript
   * @returns {Promise} - Axios response promise
   */
  createCallScript(payload) {
    return Repository.post(CREATE_CALL_SCRIPT, payload);
  },

  /**
   * Get a specific CallScript by ID
   * @param {number|string} callScriptId - The ID of the CallScript
   * @returns {Promise} - Axios response promise
   */
  getCallScript(callScriptId) {
    return Repository.get(GET_CALL_SCRIPT(callScriptId));
  },

  /**
   * List all CallScripts with optional query parameters
   * @param {Object} params - Query parameters for pagination or filtering
   * @returns {Promise} - Axios response promise
   */
  listCallScripts(params = {}) {
    return Repository.get(LIST_CALL_SCRIPTS, { params });
  },

  /**
   * Update a CallScript by ID
   * @param {number|string} callScriptId - The ID of the CallScript
   * @param {Object} payload - The updated data for the CallScript
   * @returns {Promise} - Axios response promise
   */
  updateCallScript(callScriptId, payload) {
    return Repository.put(UPDATE_CALL_SCRIPT(callScriptId), payload);
  },

  /**
   * Delete a CallScript by ID
   * @param {number|string} callScriptId - The ID of the CallScript
   * @returns {Promise} - Axios response promise
   */
  deleteCallScript(callScriptId) {
    return Repository.delete(DELETE_CALL_SCRIPT(callScriptId));
  },
};

export default CallScriptRepository;
