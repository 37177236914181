import Repository from "./Repository";
const buildParams = (payload, extraParams = {}) => {
  const params = new URLSearchParams(extraParams);
  if (payload) {
    const filters = [
      "location",
      "from_date",
      "title",
      "workplace_type",
      "experience_level",
    ];
    filters.forEach((key) => {
      if (payload[key]) params.append(key, payload[key]);
    });
  }
  return params;
};
const NexaIqRepository = {
  getJobListings(payload, page, jobApplied, smartScreen) {
    const params = buildParams(payload, {
      page,
      jobApplied,
      size: 10,
      smartScreen,
    });
    return Repository.get(`/smart_screen_jobs/search_jobs?${params}`);
  },

  scrapJobs(payload) {
    const params = buildParams(payload);
    return Repository.post(`/smart_screen_jobs/scrap_jobs?${params}`);
  },
  getSingleJob(id) {
    return Repository.get(`/smart_screen_jobs/jobs/${id}`);
  },
  applyJob(formData) {
    return Repository.post(`/job_applicant/apply`, formData);
  },
  appliedJobCount(formData) {
    return Repository.post(
      `customer/increase_applied_job_count?job_id=${formData}`
    );
  },
};

export default NexaIqRepository;
