export const positionOptions = [
  { value: "developer", label: "Developer" },
  { value: "designer", label: "Designer" },
  { value: "manager", label: "Manager" },
  // Add more positions as needed
];

export const locationOptions = [
  { value: "remote", label: "Remote" },
  { value: "new-york", label: "New York" },
  { value: "los-angeles", label: "Los Angeles" },
  // Add more locations as needed
];

export const experienceOptions = [
  { value: "entry-level", label: "Entry Level" },
  { value: "associate", label: "Associate" },
  { value: "mid-senior", label: "Mid-Senior" },
  { value: "director", label: "Director" },
  { value: "executive", label: "Executive" },
];

export const jobTypeOptions = [
  { value: "full-time", label: "Full Time" },
  { value: "part-time", label: "Part Time" },
  { value: "voluntary", label: "Voluntary" },
  { value: "contract", label: "Contract" },
  { value: "temporary", label: "Temporary" },
  { value: "internship", label: "Internship" },
];
