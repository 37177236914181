import React from "react";

export default function WorkExperience({ workExperience, getSingleResumeDoc }) {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  return (
    workExperience &&
    workExperience?.length > 0 && (
      <section className="cv-professional_summary">
        {workExperience?.map((experience, index) => (
          <>
            <div
              key={index}
              className="cv-professional_summary-item d-flex justify-content-between align-items-center"
            >
              <div className="text-capitalize">
                <h3
                  className="cv-item-title mb-0 apply-font"
                  style={{
                    color: `${
                      getSingleResumeDoc?.subheading_color
                        ? getSingleResumeDoc?.subheading_color
                        : "#000"
                    }`,
                  }}
                >
                  {experience?.company?.name}
                </h3>
                {experience?.roles?.map((item, index) => (
                  <>
                    <p className="cv-item-date mb-0 apply-font">{item?.name}</p>
                  </>
                ))}
              </div>
              <div className="text-capitalize">
                {experience?.roles?.map((item, index) => (
                  <>
                    <p className="cv-item-date apply-font">
                      {formatDate(item?.started_at)}
                      {item?.ended_at == null
                        ? " - present"
                        : `${""} - ${formatDate(item?.ended_at)}`}
                    </p>
                  </>
                ))}
              </div>
            </div>
            <div>
              <p
                className="cv-item-date apply-font"
                dangerouslySetInnerHTML={{
                  __html: experience?.company?.description,
                }}
              />
            </div>
          </>
        ))}
      </section>
    )
  );
}
