import React from "react";
import { useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import { produce } from "immer";
import TemplateCard from "./TemplateCard";
import { StepStateAtom } from "../../../../recoil/ResumeAdditionalSection/StepsState";
import { error } from "../../../../Utilities/toast";

const TemplatePreviews = () => {
  const { templates } = useSelector((state) => state?.ResumeBuilder);
  const [stepState, setStepState] = useRecoilState(StepStateAtom);

  const handleSelect = (template) => {
    if (!template) {
      error("Something went wrong on selecting Template");
    }
    if (stepState?.selectedTheme?.id !== template?.id) {
      setStepState(
        produce((draft) => {
          draft.selectedTheme = {
            ...template,
            permissions: [template?.fields],
            sectionDetail: [
              Object.keys(template?.fields || {}).reduce(
                (acc, key) => ({ ...acc, [key]: "" }),
                {}
              ),
            ],
            sectionHeading: "",
            isNewSection: null,
          };
        })
      );
    }
  };

  return (
    <div className="w-full">
      {!stepState?.selectedTheme?.id && (
        <h2 className="text-sm font-medium text-gray-700 mt-2 ml-3">
          <b>Note</b>: Choose a template first to enhance your Addition Section.
        </h2>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 px-3 py-3">
        {templates?.map((template) => (
          <TemplateCard
            key={template.id}
            handleSelect={handleSelect}
            template={template}
            stepState={stepState}
          />
        ))}
      </div>
    </div>
  );
};

export default TemplatePreviews;
