import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveShowcaseTalent } from "../../../Redux/UserProfileSlice";
import { getCurrentUser } from "../../../Redux/authenticate/authenticate.action";
import { success } from "../../../Utilities/toast";
const { Option } = Select;

const ShowcaseTalent = ({ activeStep, setActiveStep }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const { allResume } = useSelector((state) => state.ResumeBuilder);
  const { simulatorCount, interviewSessions } = useSelector(
    (state) => state.UserProfile
  );
  const [initialData, setInitialData] = useState();

  const [selectedResumeID, setSelectedResumeID] = useState("");
  const [selectedInterviewSimulator, setSelectedInterviewSimulator] =
    useState("");
  const [practiceRound, setPracticeRound] = useState("");

  const handleSaveShowcase = () => {
    const data = {
      resume_feedback_id: selectedResumeID,
      interview_simulator_id: selectedInterviewSimulator,
      interview_training_session_id: practiceRound,
    };
    console.log(data, "datadatadata");
    console.log(initialData, "datadatadatainitialData");
    if (JSON.stringify(data) === JSON.stringify(initialData)) {
      setActiveStep(activeStep + 1);
      return;
    }
    dispatch(
      saveShowcaseTalent({
        payload: data,
        onSuccess: () => {
          success("Show Case added successfully");
          dispatch(getCurrentUser());
          setActiveStep(activeStep + 1);
        },
      })
    );
    console.log(selectedResumeID, selectedInterviewSimulator, practiceRound);
  };

  useEffect(() => {
    if (user && user.customer_stats) {
      setInitialData(user.customer_stats);
      setPracticeRound(user?.customer_stats?.interview_training_session_id);
      setSelectedInterviewSimulator(
        user?.customer_stats?.interview_simulator_id
      );
      setSelectedResumeID(user?.customer_stats?.resume_feedback_id);
    }
  }, [user]);

  return (
    <div className="p-4">
      <div className="mb-4 p-4 border rounded-lg shadow">
        <div className="mb-2">
          <label className="block mt-2">Practice Round:</label>
          <Select
            placeholder="Select Practice Round"
            value={practiceRound || "Select Practice Round"}
            onChange={(value) => setPracticeRound(value)}
            className="h-[46px] mt-1 w-full"
            optionFilterProp="children"
            showSearch
            required
          >
            {interviewSessions?.map((resume, index) => {
              return (
                <Option key={index} value={resume.id}>
                  {resume.category_name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="mb-2">
          <label className="block mt-2">Interview Simulator:</label>
          <Select
            placeholder="Select Interview Simulator"
            value={selectedInterviewSimulator || "Select Interview Simulator"}
            onChange={(value) => setSelectedInterviewSimulator(value)}
            className="h-[46px] mt-1 w-full"
            optionFilterProp="children"
            showSearch
            required
          >
            {simulatorCount?.map((resume, index) => {
              return (
                <Option key={index} value={resume.id}>
                  {resume?.job_description}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="mb-2">
          <label className="block mt-2">Resume Feedback:</label>
          <Select
            placeholder="Select Resume Feedback"
            value={selectedResumeID || "Select Resume"}
            onChange={(value) => setSelectedResumeID(value)}
            className="h-[46px] mt-1 w-full"
            optionFilterProp="children"
            showSearch
            required
          >
            {allResume
              ?.filter((item) => item?.isCompleted)
              ?.map((resume) => {
                return (
                  <Option key={resume.id} value={resume.id}>
                    {resume?.resume_name
                      ? `${resume.resume_name}(${resume?.iterations})${
                          resume.company_name ? ` - ${resume.company_name}` : ""
                        }`
                      : `${resume?.contact?.first_name} ${resume?.contact?.last_name}`}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
      <div className={`w-full mt-2 flex justify-between`}>
        <button
          className="ic-btn"
          onClick={() => {
            setActiveStep((prev) => prev - 1);
          }}
        >
          Previous
        </button>
        <button
          className="ic-btn"
          onClick={() => {
            handleSaveShowcase();
            // setActiveStep((prev) => prev + 1);
          }}
        >
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default ShowcaseTalent;
