import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import { FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { getTasks } from "../../Redux/TaskSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../Components/main-loader";
import { error } from "../../Utilities/toast";
import Pagination from "../../Utilities/Pagination";

const Tasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authenticate);
  const { tasks, loading, totalPages, currentPage } = useSelector(
    (state) => state.tasks
  );
  console.log("totalPages: ", totalPages);
  const [storeActivePagination, setStoreActivePagination] = useState(1);
  console.log("storeActivePagination: ", storeActivePagination);

  const handlePaginationChange = (value) => {
    console.log(value, "valuebyahsan");
    setStoreActivePagination(value);
    dispatch(getTasks({ payload: value }));
  };
  useEffect(() => {
    if (!user?.organizationId) {
      error("This Section is only for organization users");
      return navigate(-1);
    }
    dispatch(getTasks({ payload: storeActivePagination || 1 }));
  }, []);

  return (
    <>
      <Header />
      {loading && <Loader />}
      <div
        className="ic-interview-full-body overflow-y-scroll max-h-screen"
        style={{
          backgroundImage: `url(${"/images/background.svg"})`,
          paddingTop: "6em",
        }}
      >
        <div
          className="ic-question-start border-0 overflow-y-scroll max-h-screen py-30 my-14"
          style={{ maxHeight: "80vh" }}
        >
          <div className="d-flex justify-between items-center -mt-3 mb-4 gap-x-1">
            <h1 className="font-bold">Nexa Tasks</h1>
          </div>

          {tasks?.pagination?.total_tasks == 0 && (
            <div className="text-center ">
              <h2>There is no tasks assigned yet...</h2>
            </div>
          )}
          {tasks?.tasks?.map((task, index) => (
            <div key={index} className="w-100">
              <div className="question-header2 mb-3">
                <span
                  className={`question-tags2 ${
                    task.task_status == "COMPLETED"
                      ? "text-success"
                      : task.task_status == "IN_PROGRESS"
                      ? "text-warning"
                      : "text-danger"
                  }`}
                >
                  {task.task_status}
                </span>
                <span
                  className={`question-tags3 ${
                    task.priority == "LOW"
                      ? "text-success"
                      : task.priority == "HIGH"
                      ? "text-danger"
                      : "text-warning"
                  }`}
                >
                  <i className="ri-error-warning-line"></i>
                  {task.priority}
                </span>
              </div>
              <div className="d-flex justify-between items-center">
                <div>
                  <h2 className="mb-0 text-1xl">
                    {task.title}{" "}
                    <span className="due-date_class">
                      - due {moment(task.due_date).format("DD-MM-YYYY")}
                    </span>
                  </h2>
                  <p
                    className="mb-0 clamp-3"
                    style={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {task.description}
                  </p>
                </div>
                <div className="d-flex justify-center items-center gap-2">
                  <Link to={`/task/view/${task.id}`}>
                    <div className="eye-arrow">
                      <FaRegEye size={24} />
                    </div>
                  </Link>
                </div>
              </div>
              <hr className="resume_hr" />
            </div>
          ))}
          {tasks?.pagination?.total_tasks > 0 && (
            <div className="d-flex justify-center items-center w-100">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePaginationChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Tasks;
