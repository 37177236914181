import React from "react";
import badge from "../../../assets/icons/verifiedGold.gif";
const CongratulationsPopup = ({ isVisible, setIsVisible }) => {
  const handleClosePopup = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10"
        onClick={handleClosePopup}
      >
        <div
          className="bg-white p-8 rounded-lg max-w-lg text-center relative"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={handleClosePopup}
            className="absolute top-1 right-3 text-2xl font-bold"
          >
            &times;
          </button>
          <h2 className="text-3xl font-semibold text-gray-900 mb-4">
            Congratulations!
          </h2>
          <p className="text-lg text-gray-700 mb-4">
            You are now Nexa verified and ready to be showcased in the Nexa
            Talent Hub. Get ready for a new career.
          </p>
          <div className="my-4">
            <img
              src={badge}
              alt="Gold Badge"
              className="w-44 h-44 mx-auto"
            />
          </div>
        </div>
      </div>
    )
  );
};

export default CongratulationsPopup;
