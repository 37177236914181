import React, { useEffect, useRef, useState } from "react";
import { MdVerified } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { FaArrowLeftLong } from "react-icons/fa6";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { error } from "../../Utilities/toast";
import dayjs from "dayjs";
import { FileUploader } from "react-drag-drop-files";
import { applyOnJob, getJobListings } from "../../Redux/NexaIqSlice";
import Loader from "../main-loader";
const NexaIqSideBar = ({
  isOpen,
  toggleSidebar,
  status,
  setLoading,
  setIsOpenConfirmModal,
  setActivegoogleJobLink,
}) => {
  const { getSingleJobData } = useSelector((state) => state.nexaIq);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoadings] = useState(false);
  const fileTypes = ["PDF"];
  const [moreJobs, setMoreJobs] = useState(false);

  const handleChange = (file) => {
    if (file) {
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > 1) {
        error("File size must be less than 1 MB");
        file = null;
      } else {
        setFile(file);
      }
    }
  };
  const handleFileOpen = () => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    }
  };
  const handleAnswerChange = (value, index) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };
  const handleSubmit = () => {
    if (activeStep === 1) {
      setActiveStep(2);
    } else {
      setLoadings(true);
      if (file == null) {
        error("Please upload your resume!");
        setLoadings(false);
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("job_id", getSingleJobData?.id);
      if (getSingleJobData?.preset_questions != null) {
        const answersString = answers.join(","); // Join the answers with a comma
        formData.append("answers", answersString); // Append the answers string to formData
      }
      dispatch(
        applyOnJob({
          formData,
          onSuccess: (res) => {
            setLoading(true);
            toggleModal();
            setLoadings(false);
            toggleSidebar();
            dispatch(
              getJobListings({
                payload: null,
                page: 1,
                jobApplied: false,
                onSuccess: () => {
                  setLoading(false);
                },
              })
            );
          },
        })
      );
    }
  };

  const toggleModal = () => {
    setActiveStep(1);
    if (getSingleJobData?.preset_questions != null) {
      setAnswers(getSingleJobData?.preset_questions?.map(() => ""));
    }
    setIsOpen(!isOpenModal);
    setFile(null);
  };
  useEffect(() => {
    setMoreJobs(false);
    if (getSingleJobData?.preset_questions == null) return;
    setAnswers(getSingleJobData?.preset_questions?.map(() => ""));
  }, [getSingleJobData]);
  return (
    <>
      {loading && <Loader />}
      <div
        className={`z-[999] fixed top-0 right-0 bg-white shadow-lg overflow-auto transition-transform duration-300 w-[95%] lg:w-[70%] xl:w-[65%] ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="py-6 border flex h-[100vh] overflow-y-scroll justify-center">
          <div className="w-[65%] lg:w-[65%] xl:w-[70%] h-[100%]  flex flex-col">
            <div className="px-6 w-full border-r-2">
              <button onClick={toggleSidebar}>
                <FaArrowLeftLong size={20} color="black" />
              </button>
            </div>
            <div className="h-auto w-full border-b-2 px-6 pb-8 border-r-2">
              <h2 className="text-2xl mt-8 mb-2">{getSingleJobData?.title}</h2>
              <div className="flex justify-left item-center gap-5 mt-4">
                <span className="text-sm font-light">
                  {getSingleJobData?.employment_type ||
                    getSingleJobData?.tonality}
                </span>
                <h2 className="flex gap-1 items-center text-sm font-light">
                  <CiLocationOn size={20} color="black" />
                  {getSingleJobData?.location}
                </h2>
              </div>
            </div>
            <div
              className={`h-auto w-full ${
                getSingleJobData?.type !== "google" ? "border-b-2" : ""
              } px-6 py-6 border-r-2`}
            >
              <p
                className="text-md"
                dangerouslySetInnerHTML={{
                  __html:
                    getSingleJobData?.description ||
                    getSingleJobData?.job_description,
                }}
              >
                {/* {getSingleJobData?.description ||
                  getSingleJobData?.job_description} */}
              </p>
            </div>
            {getSingleJobData?.type !== "google" && (
              <>
                <div className="h-auto flex justify-center items-start w-full border-b-2 px-6 py-6 border-r-2">
                  <div className="w-[50%] flex justify-start item-center">
                    <div className="flex justify-center item-center gap-2">
                      <div className="">
                        <SlCalender size={24} />
                      </div>
                      <div className="flex justify-center item-center flex-col gap-1">
                        <span className="text-md font-bold">
                          Prescreening Deadline
                        </span>
                        <span className="text-md font-medium">
                          {dayjs(
                            getSingleJobData?.prescreening_deadline
                          ).format("DD MMM YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-[50%] flex justify-start item-center">
                    <div className="flex justify-center item-center gap-2">
                      <div className="">
                        <SlCalender size={24} />
                      </div>
                      <div className="flex justify-center item-center flex-col gap-1">
                        <span className="text-md font-bold">
                          Interview Deadline
                        </span>
                        <span className="text-md font-medium">
                          {dayjs(getSingleJobData?.interview_deadline).format(
                            "DD MMM YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-auto w-full px-6 py-6 border-r-2">
                  <h2 className="text-xl mb-3">Skills and Expertise</h2>
                  <div className="flex justify-start items-center">
                    {getSingleJobData?.skills &&
                      getSingleJobData.skills.split(",").map((skill, index) => (
                        <div
                          key={index}
                          className="chip"
                          style={{
                            margin: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {skill.trim()}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="w-[35%] lg:w-[35%] xl:w-[30%] h-[100%] flex flex-col px-3">
            {/* <h2 className="flex gap-2 items-center -mt-2">
            <LuExternalLink size={20} color="black" />
            Open job in new window
          </h2> */}
            <div className="flex justify-center w-full flex-col gap-3">
              {getSingleJobData?.type !== "google" && (
                <div
                  className="flex w-full justify-center cursor-pointer flex-row items-center rounded-[8px] border-[2px] border-[rgba(255,255,255,0.3)] bg-gradient-auto-apply py-[8px] px-[46px] font-semibold text-white opacity-50"
                  onClick={() => {
                    if (status === null) {
                      if (getSingleJobData?.preset_questions == null) {
                        setActiveStep(2);
                      }
                      setIsOpen(!isOpenModal);
                    } else {
                      error("You have already applied for this job");
                    }
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-[8px]"
                  >
                    <path
                      d="M7.167 1.332a.5.5 0 0 0-.439.26L3.062 8.257a.5.5 0 0 0 .438.74L6.8 8.4l-3.096 5.576a.5.5 0 0 0 .816.544l8.334-8.333a.5.5 0 0 0-.354-.854l-2.9.309 2.665-3.557a.5.5 0 0 0-.432-.752H7.167Z"
                      fill="#F7AB38"
                    ></path>
                  </svg>
                  <div>{status != null ? "Applied" : " Apply now"}</div>
                </div>
              )}
              {getSingleJobData?.type === "google" &&
                (() => {
                  const uniqueTexts = new Set();
                  // Determine the list to render based on `moreJobs` state
                  const linksToShow = moreJobs
                    ? getSingleJobData?.apply_links
                    : getSingleJobData?.apply_links?.slice(0, 5);
                  return linksToShow?.map((data, index) => {
                    const text = data?.text?.trim();
                    const domain = new URL(data.url).hostname.replace(
                      "www.",
                      ""
                    );

                    // Check if the text is unique, if not skip rendering
                    if (text && uniqueTexts.has(text)) return null;

                    // Add the text to the unique set if present
                    if (text) uniqueTexts.add(text);

                    const displayText = text
                      ? text.split(" ").slice(0, 3).join(" ") +
                        (text.split(" ").length > 3 ? "..." : "")
                      : `Apply on ${domain.split(".")[0]}`;

                    return (
                      <div
                        key={index}
                        className="flex w-full justify-center cursor-pointer flex-row items-center rounded-[8px] border-[2px] border-[rgba(255,255,255,0.3)] bg-gradient-auto-apply py-[8px] px-[26px] font-semibold text-white opacity-50 text-sm"
                        // onClick={() => window.open(data?.url, "_blank")}
                        onClick={() => {
                          setActivegoogleJobLink(data?.url);
                          setIsOpenConfirmModal(true);
                        }}
                      >
                        <div title={data.text}>{displayText}</div>
                      </div>
                    );
                  });
                })()}
              {getSingleJobData?.type == "google" && (
                <div
                  className="w-full h-[40px] border-t-[#E6E6E6] border-t sm:flex flex-row items-center justify-center cursor-pointer"
                  onClick={() => setMoreJobs(!moreJobs)}
                >
                  <div className="flex flex-row items-center justify-center underline text-[#4D4D4D]">
                    {moreJobs ? "Hide" : "Show More"}{" "}
                    <svg
                      width="17"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`ml-1 ${moreJobs ? "rotate-180" : ""}`}
                    >
                      <path
                        d="m4.5 5.334-.715.781L8.5 10.83l4.714-4.714-.714-.781-4 4-4-4Z"
                        fill="#4D4D4D"
                      ></path>
                    </svg>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full mb-8">
              <h2 className="text-xl mt-2 mb-2">About the Company</h2>
              <h2 className="flex gap-1 items-center mt-1 text-sm mb-3">
                <MdVerified size={18} color="#7c96fd" />
                {getSingleJobData?.company ||
                  getSingleJobData?.organization?.name}
              </h2>
              {getSingleJobData?.organization && (
                <>
                  <h2 className="flex gap-1 items-center mt-1 text-sm">
                    <MdVerified size={18} color="#7c96fd" />
                    {getSingleJobData?.company ||
                      getSingleJobData?.organization?.address}
                  </h2>
                  <h2 className="flex gap-1 items-center mt-1 text-sm">
                    <MdVerified size={18} color="#7c96fd" />
                    {getSingleJobData?.company ||
                      getSingleJobData?.organization?.phone}
                  </h2>
                </>
              )}
            </div>
            {/* <div className="w-full">
            <h2 className="text-xl mt-8 mb-2">Job link</h2>
            <input
              type="text"
              id="resumeName"
              className="form-control"
              disabled
              placeholder="https://www.upwork.com/jobs/~021839217863126417414"
            />
            <h2 className="flex gap-1 items-center mt-2 text-sm">Copy link</h2>
          </div> */}
          </div>
        </div>
        <ReactModal
          isOpen={isOpenModal}
          onRequestClose={toggleModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px 40px",
              maxWidth: "750px",
              width: "95%",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: 1001,
              maxHeight: "90vh",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            },
          }}
        >
          <>
            {loading && <Loader />}
            <h2 className="text-xl font-bold mt-2">Apply Job</h2>
            {/* <div className="modal-body-iq flex flex-col gap-4 mt-2">
          <label className="modal-label">
            <span className="text-xl">
              How do blockchain technologies enhance security in digital
              transactions?
            </span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
          <label className="modal-label">
            <span className="text-xl">What is quantum computing?</span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
          <label className="modal-label">
            <span className="text-xl">How does 3D printing work?</span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
          <label className="modal-label">
            <span className="text-xl">What is the purpose of a VPN?</span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
          <label className="modal-label">
            <span className="text-xl">What is augmented reality (AR)?</span>
            <textarea
              rows="4"
              className="modal-input-Answers w-full h-20"
              placeholder="Answer here..."
            />
          </label>
        </div> */}
            <div className="modal-body-iq flex flex-col gap-4 mt-2">
              {activeStep == 1 ? (
                getSingleJobData?.preset_questions?.map((question, index) => (
                  <label key={index} className="modal-label">
                    <span className="text-xl">{question}</span>
                    <textarea
                      rows="4"
                      className="modal-input-Answers w-full h-20"
                      placeholder="Answer here..."
                      value={answers[index]} // Set the value from the answers state
                      onChange={(e) =>
                        handleAnswerChange(e.target.value, index)
                      } // Update the specific answer
                    />
                  </label>
                ))
              ) : (
                <label className="modal-label modalNexaIQ">
                  <span className="text-xl">Upload Your Resume For Apply</span>
                  <div className="pt-4 group-open:animate-fadeIn">
                    <form action="#">
                      <FileUploader
                        name="file"
                        handleChange={handleChange}
                        types={fileTypes}
                      />
                      {file && (
                        <div
                          onClick={handleFileOpen}
                          className="mt-4 p-2  text-blue-500 cursor-pointer"
                        >
                          {file.name} (Click to preview)
                        </div>
                      )}
                      {/* <input
                    type="file"
                    name="file"
                    ref={fileInput}
                    accept=".pdf"
                    hidden
                    onChange={(e) => getFile(e)}
                  />
                  <div
                    className="ic-file-upload"
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  >
                    <div className="ic-icon">
                      <img
                        src="/images/document-upload.png"
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                    <p className="support-text">
                      <span className="primary">Click to Upload </span>
                      or drag and drop
                    </p>
                    <span className="ic-file-size">
                      {" "}
                      (Max. File size: 2.5 MB)
                    </span>
                  </div> */}
                    </form>

                    {/* {uploadedFile && (
                  <div className="ic-loading-area">
                    <ul>
                      <li>
                        <div className="file-name">
                          <div className="file-icon">
                            <img src="/images/document-text-area.png" alt="" />
                          </div>
                          <div className="file-size">
                            <div>
                              <p>{uploadedFile.name}</p>
                              {uploadedFile.size && (
                                <span>{uploadedFile.size}</span>
                              )}
                            </div>
                            {uploadedFile.loading === 100 && (
                              <i className="ri-checkbox-circle-fill"></i>
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                )} */}
                  </div>
                </label>
              )}
            </div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  if (activeStep == 1) {
                    toggleModal();
                  } else {
                    setActiveStep(1);
                  }
                }}
                className={`modal-button cancel-button ${
                  getSingleJobData?.preset_questions == null ? "hidden" : ""
                }`}
              >
                {activeStep == 1 ? "Cancel" : "Back"}
              </button>
              <button
                className="modal-button save-button"
                disabled={loading}
                onClick={handleSubmit}
              >
                {activeStep == 1
                  ? "Next"
                  : activeStep == 2 && !loading
                  ? "Submit"
                  : "Submiting..."}
              </button>
            </div>
          </>
        </ReactModal>
      </div>
    </>
  );
};

export default NexaIqSideBar;
