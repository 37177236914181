import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getChat,
  getUserId,
  updateActiveConversation,
  updateChatMessages,
  updateConversations,
} from "../../../../Redux/ChatSlice";
import chevronDoubleRightIcon from "../../../../assets/icons/chevronDoubleRight.svg";
import userIcon from "../../../../assets/icons/userIcon.svg";
import notification from "../../../../assets/sound/notification.mp3";
import { pageRefresh, socket } from "../../../../config/socketConfig";
import { SidebarContext } from "../../../../context/sidebarContext";
import { navigationHomeLinks } from "../../data/data";
import { personsImgs } from "../../utils/images";
import "./user.css";
// const { Budget, Home, Plane, Wallet, Gears, Search, Voyceicon } = iconsImgs;

const SidebarProfile = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  console.log("pathname: ", pathname);
  const dispatch = useDispatch();
  const { connectedChats, userId } = useSelector((state) => state.chat);
  const { user } = useSelector((state) => state.authenticate);
  console.log("connectedChats: ", connectedChats);
  const [totalUnreadChats, setTotalUnreadChats] = useState(0);
  console.log("totalUnreadChats: ", totalUnreadChats);
  const getActiveLinkIndex = (pathname) => {
    for (let i = 0; i < navigationHomeLinks.length; i++) {
      if (pathname === navigationHomeLinks[i].link)
        return navigationHomeLinks[i].id;
    }
    return 1;
  };

  const [activeLinkIdx, setActiveLinkIdx] = useState(
    getActiveLinkIndex(pathname)
  );
  const [sidebarClass, setSidebarClass] = useState("");
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);

  // const location = useLocation();
  // console.log({ path: location.pathname });
  const { sidebar_colorBtn, sidebar_colorBg } = useSelector(
    (state) => state.globalState
  );

  const filteredNavigationLinks = navigationHomeLinks.filter(
    (link) =>
      ((link.id !== 6 && link.id !== 7) || user?.organizationId) &&
      (link.visible === undefined ||
        link.visible === process.env.REACT_APP_ENVIRONMENT)
  );

  console.log(filteredNavigationLinks, "filteredNavigationLinks");

  useEffect(() => {
    if (isSidebarOpen) {
      setSidebarClass("sidebar-change");
    } else {
      setSidebarClass("");
    }
  }, [isSidebarOpen]);
  useEffect(() => {
    if (user && userId) {
      pageRefresh({
        token: localStorage.getItem("auth"),
        encrypted_user_id: userId?.user_id,
      });
    }
  }, [userId]);
  useEffect(() => {
    if (connectedChats && userId) {
      let totalUnreadCount = 0;
      connectedChats.forEach((chat) => {
        chat.unread_messages?.forEach((unread) => {
          if (unread.user_id === userId.user_id) {
            totalUnreadCount += unread.unread_messages; // Sum unread messages
          }
        });
      });
      setTotalUnreadChats(totalUnreadCount);
    }
  }, [connectedChats, userId]);
  useEffect(() => {
    if (!user) {
      return;
    }
    dispatch(getChat(() => {}));
    dispatch(getUserId());
    socket.on("get_message", async (msg) => {
      try {
        const sound = new Audio(notification);
        await sound.play();
        dispatch(updateChatMessages(msg?.data));
        dispatch(updateConversations(msg?.data?.updatedConversation));
      } catch (error) {
        console.error("Audio play failed:", error);
      }
    });
  }, [user]);
  return (
    <div
      className={`dashboard-sidebar ${sidebarClass} rounded-r-[50px]  drop-shadow-xl relative`}
      style={{ backgroundColor: sidebar_colorBg }}
    >
      <button
        onClick={toggleSidebar}
        className={`absolute top-12 -right-3 bg-white rounded-full size-10 p-1 transition-all duration-500 ${
          isSidebarOpen ? "" : "-translate-x-5 rotate-180"
        }`}
      >
        <img src={chevronDoubleRightIcon} alt="" />
      </button>
      <div className="user-info">
        <div className="info-img img-fit-cover">
          <img
            className="rounded-full"
            src={user?.picture || userIcon}
            alt={"profile" + personsImgs.person_two}
          />
        </div>
        <div className="flex flex-col">
          <span
            className={`info-name ${
              sidebar_colorBg === "#000000" ? "text-white" : ""
            }`}
          >
            {user ? user?.fullName : "alice-doe"}
          </span>
          <span
            className={` ${sidebar_colorBg === "#000000" ? "text-white" : ""}`}
          >
            {user ? user?.organizationName : "organization-name"}
          </span>
        </div>
      </div>

      <nav className="navigation">
        <ul className="nav-list">
          {filteredNavigationLinks
            .filter(
              ({ title }) =>
                !(
                  [
                    "My Profile",
                    "Career Goals",
                    "Analytics",
                    "Tasks",
                    "Chats",
                    "Skills Gap Assessment",
                  ].indexOf(title) > -1 && pathname.includes("user2/settings")
                )
            ) 
            .filter(({ title }) => {
              const excludedRoutes = [
                "user/profile",
                "user/analytics",
                "career-goals",
                "chats",
                "tasks",
              ];

              return !(
                ["Billing", "payments"].includes(title) &&
                excludedRoutes.some((route) => pathname.includes(route))
              );
            })
            .map(({ Image, ...navigationLink }) => (
              <li
                className={`nav-item ${
                  navigationLink.visible !== "test" &&
                  navigationLink.visible !== undefined
                    ? "hidden"
                    : "block"
                } ${
                  navigationLink.isIndividual === undefined
                    ? "block"
                    : navigationLink.isIndividual && !user.organizationId
                    ? "block"
                    : "hidden"
                }`}
                key={navigationLink.id}
              >
                <div onClick={() => setActiveLinkIdx(navigationLink.id)}>
                  {" "}
                  <div
                    role="button"
                    onClick={() => {
                      navigation(navigationLink?.link);
                      dispatch(updateActiveConversation(null));
                    }}
                    // to={navigationLink?.link}
                    className={`nav-link ${
                      navigationLink.id === activeLinkIdx
                        ? `active text-white`
                        : sidebar_colorBg === "#000000"
                        ? "text-white"
                        : ""
                    }`}
                    style={{
                      backgroundColor:
                        navigationLink.id === activeLinkIdx
                          ? sidebar_colorBtn
                          : "",
                    }}
                  >
                    <span className="nav-link-text">
                      <i
                        className={`${Image} nav-link-icon ${
                          navigationLink.id === activeLinkIdx
                            ? `active bg-transparent`
                            : null
                        } `}
                      ></i>{" "}
                      {navigationLink.title}
                    </span>
                    {navigationLink.title === "Chats" &&
                      totalUnreadChats > 0 && (
                        <span className="w-7 h-7 bg-blue-600 flex items-center  justify-center  text-sm rounded-full text-white">
                          {totalUnreadChats > 99 ? "+99" : totalUnreadChats}
                        </span>
                      )}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default SidebarProfile;
