import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

const Tab = ({ index, activeTab, toggleTab, title, children }) => {
  const isOpen = activeTab === index;

  return (
    <div
      className={`border-b rounded-lg overflow-hidden mb-4 ${
        isOpen ? " ring-1 ring-opacity-50  ring-[#2B6BE2]" : ""
      }`}
    >
      <button
        className="w-full text-[#2B6BE2] flex justify-between items-center text-left p-3 text-lg font-medium bg-gray-100 hover:bg-gray-200"
        onClick={() => toggleTab(index)}
      >
        {title}
        <span>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
      </button>
      {isOpen && <div className="p-4 bg-gray-50">{children}</div>}
    </div>
  );
};

export default Tab;
