import React from "react";
import { useRecoilState } from "recoil";
import {
  defaultState,
  StepStateAtom,
} from "../../../../recoil/ResumeAdditionalSection/StepsState";

const ExitConfirmationModal = ({
  confirmationModal,
  setConfirmationModal,
  setAdditionalSectionModal,
}) => {
  const [_, setStepState] = useRecoilState(StepStateAtom);
  return (
    <div className={`ic-modal ${confirmationModal ? "show" : ""}`}>
      <div className="ic-modal-dialog">
        <div className="modal-main-content w-100">
          <div className="ic-modal-body">
            <div className="ic-modal-content">
              <p className="text-xl">
                Are you sure you want to exit? Any unsaved changes in the modal
                will be lost.
              </p>
            </div>
            <div className="ic-footer-modal d-flex justify-content-end">
              <button
                onClick={() => {
                  setConfirmationModal(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setConfirmationModal(false);
                  setAdditionalSectionModal(false);
                  setStepState(defaultState);
                }}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitConfirmationModal;
