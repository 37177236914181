import { createSlice } from "@reduxjs/toolkit";

export const StepsSlice = createSlice({
  name: "Steps",
  initialState: {
    step: 1,
    step1: false,
    activePreview: false,
    skiped: false,
  },
  reducers: {
    addStep: (state, action) => {
      state.step = action.payload;
    },
    skipStep: (state, action) => {
      state.skiped = action.payload;
    },
    setStep1: (state, action) => {
      state.step1 = action.payload;
    },
    setActivePreview(state, action) {
      console.log(action.payload, "action.payload");
      state.activePreview = action.payload.preview;
      state.step = action.payload.step;
      state.step1 = action.payload.step1;
    },
  },
});

export const { addStep, setStep1, setActivePreview, skipStep } =
  StepsSlice.actions;

export const selectStep = (state) => state?.Steps?.step;
export const selectStep1 = (state) => state?.Steps?.step1;
export const checkSkip = (state) => state?.Steps?.skiped;
export default StepsSlice.reducer;
