import React from "react";
import { fieldComponents } from "../TemplateAssets/FieldComponents";

const TemplateCard = ({ handleSelect, template, stepState }) => {
  const dynamicValues = fieldComponents.reduce((acc, field) => {
    if (field.render) {
      acc[field.key] = field.render();
    }
    return acc;
  }, {});

  const populateTemplate = (html, values) => {
    if (!html || !values) return html;
    return html.replace(/{(.*?)}/g, (match, key) => {
      return values[key.trim()] || match;
    });
  };

  const populatedTemplate = populateTemplate(
    template.template_ui,
    dynamicValues
  );

  return (
    <div
      key={template.id}
      className={`${
        stepState?.selectedTheme?.id === template.id
          ? "border-2 border-blue-500 inset-0 bg-blue-500 bg-opacity-40 shadow-2xl"
          : "bg-white"
      } mx-auto shadow-lg rounded-lg overflow-hidden cursor-pointer w-full`}
      onClick={() => handleSelect(template)}
    >
      <div className="px-3 py-4">
        <h2 className="text-base font-bold uppercase text-black border-b border-gray-400 pb-1 mb-2">
          {template?.name}
        </h2>
        <div dangerouslySetInnerHTML={{ __html: populatedTemplate }} />
      </div>
    </div>
  );
};

export default TemplateCard;
