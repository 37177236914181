import { faAngleLeft, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveConversation } from "../../Redux/ChatSlice";
import dumyUser from "../../assets/media/images/dumyUser.png";
const ChatHeader = () => {
  const dispatch = useDispatch();
  const { activeConversation } = useSelector((state) => state.chat);
  return (
    <div className="bg-gray-500 p-4 items-center flex bg-opacity-10 rounded-tr-3xl">
      <button
        onClick={() => {
          dispatch(updateActiveConversation(null));
        }}
        className="block md:hidden text-2xl text-primary mr-3"
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      {activeConversation?.members[0]?.image ? (
        <img
          alt="dumy"
          src={activeConversation?.members[0]?.image}
          className="h-[3rem] w-[3rem] border rounded-full mr-2"
          height={50}
          width={50}
        />
      ) : (
        <img
          alt="dumy"
          src={dumyUser}
          className="h-[3rem] w-[3rem]  rounded-full mr-2"
        />
      )}
      <div className="flex flex-col">
        <h5>{activeConversation?.members[0]?.name}</h5>
      </div>
    </div>
  );
};

export default ChatHeader;
