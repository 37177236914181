import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/header";
import {
  getCurrentUser,
  getUserSubscription,
} from "../../Redux/authenticate/authenticate.action";
import "./DashBoard.css";

const DashboardHome = () => {
  const { user } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(getUserSubscription());
      dispatch(getCurrentUser());
    }
  }, []);

  return (
    <div
      style={
        {
          // backgroundImage: `url(${"/images/interview-banner.png"})`,
          // backgroundSize: "cover",
          // height: "100vh",
          // position: "relative",
        }
      }
    >
      <Header />
      <div className="ic-interviews overflow-y-scroll h-screen">
        <div className="container">
          <div className="ic-interview-container">
            <div className="dashboard-home-text text-center">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mt-6">
                Welcome to Nexa, Where All Your Career Goals Become Reality
              </h1>
            </div>

            <br />
            <div className="text-center">
              <h1 className="text-lg md:text-xl lg:text-2xl shadow-lg rounded-full font-medium text-gray-800 border border-gray-300 rounded-full px-6 py-2 shadow-sm inline-block">
                <i>No Pressure</i> - Get Started in Your Career Journey:
              </h1>
            </div>

            <br />
            <div className="flex flex-col md:flex-row justify-center gap-6 mt-8">
              <div
                className="flex flex-col items-center p-6 bg-gray-200 rounded-xl cursor-pointer w-full md:w-52 h-52 transition-all duration-300 hover:bg-blue-500 hover:scale-105 group relative"
                onClick={() => navigate("/career-goals")}
              >
                <div className="w-12 h-12 mb-3 bg-white rounded-full flex items-center justify-center">
                  <img
                    src="https://nexa-web-portal.s3.amazonaws.com/icons/IMG_0114.png"
                    className="w-6 h-6 object-contain"
                    alt="Skills Gap"
                  />
                </div>
                <p className="text-center text-gray-800 group-hover:text-white text-base font-medium">
                  Take Your Skills Gap Assessment
                </p>
                <svg
                  className="absolute bottom-6 w-5 h-5 text-gray-800 group-hover:text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </div>

              <div
                className="flex flex-col items-center p-6 bg-gray-200 rounded-xl cursor-pointer w-full md:w-52 h-52 transition-all duration-300 hover:bg-blue-500 hover:scale-105 group relative"
                onClick={() => navigate("/user/profile")}
              >
                <div className="w-12 h-12 mb-3 bg-white rounded-full flex items-center justify-center">
                  <img
                    src="https://nexa-web-portal.s3.amazonaws.com/icons/IMG_0114.png"
                    className="w-6 h-6 object-contain"
                    alt="Talent Showcase"
                  />
                </div>
                <p className="text-center text-gray-800 group-hover:text-white text-base font-medium">
                  Showcase Your Skills
                </p>
                <svg
                  className="absolute bottom-6 w-5 h-5 text-gray-800 group-hover:text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </div>

              <div
                className="flex flex-col items-center p-6 bg-gray-200 rounded-xl cursor-pointer w-full md:w-52 h-52 transition-all duration-300 hover:bg-blue-500 hover:scale-105 group relative"
                onClick={() => navigate("/resumes")}
              >
                <div className="w-12 h-12 mb-3 bg-white rounded-full flex items-center justify-center">
                  <img
                    src="https://nexa-web-portal.s3.amazonaws.com/icons/IMG_0114.png"
                    className="w-6 h-6 object-contain"
                    alt="Create Resume"
                  />
                </div>
                <p className="text-center text-gray-800 group-hover:text-white text-base font-medium">
                  Craft Your Rockstar Resume
                </p>
                <svg
                  className="absolute bottom-6 w-5 h-5 text-gray-800 group-hover:text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </div>
            </div>

            {/* Video Section */}
            <div className="w-full flex justify-center mt-12">
              <div
                className="relative w-full sm:w-3/4 md:w-2/3 lg:w-3/4 xl:w-2/3"
                style={{
                  aspectRatio: "16 / 9", // Maintain proportional width and height
                }}
              >
                <iframe
                  src="https://www.youtube.com/embed/YjRQ3QbykNA"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full rounded-lg"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
