import React, { useState, useRef, useEffect } from "react";
import AICVTemplate from "./AICVTemplate";
import { useNavigate, useParams } from "react-router-dom";
import {
  generateAIResume,
  getSingleResume,
  saveAiGeneratedResume,
} from "../../Redux/ResumeBuilderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addStep,
  checkSkip,
  selectStep,
  skipStep,
} from "../../features/companyinformation/StepsSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Loader from "../main-loader";
import { GenerateResIcon } from "../../Components/Resume/svgimages";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import CircularLoader from "../loader";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";

const AiGeneration = ({ tempRef, settempRef, onResumeGenerated }) => {
  const step = useSelector(selectStep);
  const skipped = useSelector(checkSkip);
  const {
    isAILoading,
    AiResume,
    professionalSummaries,
    workExperience,
    educations,
    projects,
    certificates,
    profile,
    categories,
    publications,
    interests,
    softskills,
    languages,
    getSingleResumeDoc,
  } = useSelector((state) => state.ResumeBuilder);
  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  console.log(show, "showbyahsan");
  const [saveButton, setSaveButton] = useState(false);
  const [skipAiGeneration, setSkipAiGeneration] = useState(false);
  console.log("saveButton: ", saveButton);
  // const [skip, setSkip] = useState(false);
  tempRef = useRef();

  const getResumeData = async () => {
    dispatch(skipStep(false));
    dispatch(addStep(3));
    const resumeId = id;
    const aiResumeResponse = await dispatch(
      generateAIResume({
        resumeId,
      })
    );
    if (aiResumeResponse.type === "resume/AIResume/fulfilled") {
      setShow(true);
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  // Define styles for PDF

  // const handleDownload = async () => {
  //   try {
  //     setLoader(true);
  //     const element = tempRef.current;
  //     console.log(element, "tempReftempRef");
  //     const canvas = await html2canvas(element, { scale: 2 });
  //     const data = canvas.toDataURL("image/png");

  //     const pdf = new jsPDF();
  //     const imgProperties = pdf.getImageProperties(data);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //     const margin = 10;
  //     let heightLeft = pdfHeight;
  //     let position = 0;
  //     pdf.addImage(data, "PNG", 0, position, pdfWidth, pdfHeight + margin);
  //     heightLeft -= pdf.internal.pageSize.getHeight();

  //     while (heightLeft > 0) {
  //       position = heightLeft - pdfHeight + margin;
  //       pdf.addPage();
  //       pdf.addImage(data, "PNG", 0, position, pdfWidth, pdfHeight);
  //       heightLeft -= pdf.internal.pageSize.getHeight();
  //     }
  //     pdf.save(`${profile?.first_name + " " + profile?.last_name}.pdf`);
  //     setLoader(false);
  //     navigate("/resumes");
  //   } catch (error) {
  //     console.log(error);
  //     setLoader(false);
  //   }
  // };
  const handleDownload = async () => {
    const isValidField = (field) => {
      return (
        field !== null &&
        field !== undefined &&
        (!Array.isArray(field) || field.length > 0)
      );
    };
    let resume = {};
    if (saveButton.page == 1) {
      resume = {
        ...(isValidField(professionalSummaries) && { professionalSummaries }),
        ...(isValidField(workExperience) && { workExperience }),
        ...(isValidField(educations) && { educations }),
        ...(isValidField(projects) && { projects }),
        ...(isValidField(certificates) && { certificates }),
        ...(isValidField(profile) && { profile }),
        ...(isValidField(categories) && { categories }),
        ...(isValidField(publications) && { publications }),
        ...(isValidField(interests) && { interests }),
        ...(isValidField(softskills) && { softskills }),
        ...(isValidField(languages) && { languages }),
        font_style: getSingleResumeDoc.font_style,
        heading_color: getSingleResumeDoc.heading_color,
        subheading_color: getSingleResumeDoc.subheading_color,
        font_size: getSingleResumeDoc.font_size,
      };
    } else {
      // resume = { ...AiResume };
      resume = {
        ...(isValidField(AiResume.professionalSummaries) && {
          professionalSummaries: AiResume.professionalSummaries,
        }),
        ...(isValidField(AiResume.workExperience) && {
          workExperience: AiResume.workExperience,
        }),
        ...(isValidField(AiResume.educations) && {
          educations: AiResume.educations,
        }),
        ...(isValidField(AiResume.projects) && { projects: AiResume.projects }),
        ...(isValidField(AiResume.certificates) && {
          certificates: AiResume.certificates,
        }),
        ...(isValidField(AiResume.profile) && { profile: AiResume.profile }),
        ...(isValidField(AiResume.skills) && {
          categories: AiResume.skills,
        }),
        ...(isValidField(AiResume.publications) && {
          publications: AiResume.publications,
        }),
        ...(isValidField(AiResume.interests) && {
          interests: AiResume.interests,
        }),
        ...(isValidField(AiResume.softskills) && {
          softskills: AiResume.softskills,
        }),
        ...(isValidField(AiResume.languages) && {
          languages: AiResume.languages,
        }),
      };
    }

    console.log(resume, "resume");
    const doc = <MyDocument AiResume={resume} />;
    const pdfBlob = await pdf(doc).toBlob();

    // Create a download link and trigger it programmatically
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = `${getSingleResumeDoc?.resume_name}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the link after download
    // navigate("/resumes");
  };
  const handleFinalResult = () => {
    if (saveButton.page == 1) {
      dispatch(addStep(4));
    } else {
      const resumeId = id;
      dispatch(
        saveAiGeneratedResume({
          resumeId,
          onSuccess: () => {
            dispatch(addStep(4));
            const resumeId = id;
            dispatch(getSingleResume({ resumeId, onSuccess: () => {} }));
          },
        })
      );
    }
  };
  useEffect(() => {
    if (skipped) {
      setShow(true);
      dispatch(addStep(3));
    }
  }, [skipped]);

  return (
    <>
      {loader && <Loader />}
      {isAILoading ? (
        <CircularLoader
          title="Nexa is hard at work to generate the perfect resume for you to absolutely blow the hiring managers away with your skills, please give us a minute!"
          icon={true}
        />
      ) : (
        !show && (
          <div className="companyinformation">
            <div
              className="flex items-center justify-between"
              style={{
                // fontFamily: "Inter",
                // fontSize: "18px",
                paddingBottom: "20px",
                // fontWeight: "600",
                // color: "#445164",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  // paddingBottom: "20px",
                  fontWeight: "600",
                  color: "#445164",
                }}
              >
                Create Resume Through Nexa
              </p>
              <div className="flex">
                <button
                  type="button"
                  className="cancel-btn"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "100px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6px",
                  }}
                  onClick={() => {
                    dispatch(addStep(2));
                  }}
                >
                  <FaLongArrowAltLeft size={18} />
                  Previous
                </button>
                <button
                  type="button"
                  className="cancel-btn mx-1"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "100px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6px",
                  }}
                  onClick={() => {
                    setShow(true);
                    dispatch(skipStep(true));
                    setSkipAiGeneration(true);
                    dispatch(addStep(3));
                  }}
                >
                  Skip <FaLongArrowAltRight size={18} />
                </button>
              </div>
            </div>
            <br></br>

            <p
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "600",
                color: "#445164",
              }}
            >
              Nexa AI
            </p>
            <div
              className="mydetails w-95 md:w-50 d-flex justify-content-center align-items-center"
              style={{
                height: "200px",
                backgroundColor: "white",
                border: "2px solid #2B6BE2",
                borderRadius: "8px",
                margin: "20px 20px 20px 20px",
              }}
            >
              <button
                className="resume-header-button-active"
                onClick={getResumeData}
                style={{
                  margin: "15px",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 20px",
                  borderRadius: "8px",
                  border: "2px solid #2B6BE2",
                  backgroundColor: "#2B6BE2",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <GenerateResIcon style={{ marginRight: "15px" }} />
                Generate Resume
              </button>
            </div>
          </div>
        )
      )}

      {show && (
        <div className="mydetails w-100 md:w-50">
          <div className="d-flex justify-content-center py-2">
            <div className="py-3">
              <AICVTemplate
                tempRef={tempRef}
                setSaveButton={setSaveButton}
                // skip={skip}
              />

              {step == 4 && (
                <div className="d-flex justify-content-end mt-2 mr-4 gap-3 mt-3">
                  <button
                    type="button"
                    className="cancel-btn"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "100px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "6px",
                    }}
                    onClick={() => {
                      if (skipped && skipAiGeneration == false) {
                        dispatch(addStep(2));
                      } else {
                        dispatch(addStep(3));
                      }
                    }}
                  >
                    <FaLongArrowAltLeft size={18} />
                    Previous
                  </button>
                  <button
                    // disabled={loader}
                    className="resume-header-button-active px-5 py-2 rounded"
                    onClick={() => handleDownload()}
                  >
                    Download & Exit
                  </button>
                </div>
              )}

              <div className="d-flex justify-content-end mt-4 gap-3 px-10">
                {step == 3 && (
                  <button
                    type="button"
                    className="cancel-btn"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "100px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "6px",
                    }}
                    onClick={() => {
                      if (skipped && skipAiGeneration == false) {
                        dispatch(addStep(2));
                      } else {
                        setShow(false);
                      }
                    }}
                  >
                    <FaLongArrowAltLeft size={18} />
                    Previous
                  </button>
                )}

                {saveButton.val && step == 3 && (
                  <button
                    // disabled={loader}
                    className="resume-header-button-active px-3 py-2 rounded"
                    onClick={() => handleFinalResult()}
                  >
                    {saveButton.page == 1
                      ? "Save Existing Resume"
                      : "Save AI Resume"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AiGeneration;
export const MyDocument = ({ AiResume }) => {
  console.log("AiResume: ", AiResume);
  const styles = StyleSheet.create({
    mainContainer: {
      width: "760px",
      backgroundColor: "#F9F9F9",
      margin: "0 auto",
      padding: "20px",
      overflowY: "scroll",
      height: "510px",
      // fontFamily: "Arial, sans-serif",
      backgroundColor: "#fff",
      borderLeft: "1px solid #52515136",
      marginBottom: "20px",
      // fontFamily: `var(--dynamic-font-family, ${AiResume?.font_style})`,
    },
    header: {
      color: "#555",
      textAlign: "center",
      marginBottom: "20px",
      lineHeight: 1.5,
      fontSize: 12,
    },
    name: {
      fontSize: 18,
      marginBottom: 5,
      color: "#2b6be2",
    },
    email: {
      fontSize: 12,
      textAlign: "center",
    },
    sectionTitle: {
      fontSize: 18,
      marginTop: "10px",
      marginBottom: "10px",
      paddingBottom: "5px",
      color: "#2b6be2",
      borderBottom: "1px solid #2b6be2",
    },
    itemTitle: {
      fontSize: 18,
      marginBottom: 5,
    },
    itemDate: {
      color: "#777",
      marginBottom: 5,
      fontSize: 12,
    },
    itemDescription: {
      color: "#555",
      fontSize: 12,
      lineHeight: 1.5,
    },
    skillsList: {
      listStyleType: "none",
      padding: 0,
      fontSize: 14,
    },
    skill: {
      color: "#333",
      padding: "5px 10px",
      margin: "5px",
      borderRadius: 5,
      fontSize: 12,
    },
    List: {
      color: "#777",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 5,
    },
    listItem: {
      color: "#777",
      fontSize: 12,
      padding: "3px 8px",
      marginRight: 5,
      marginBottom: 5,
      borderRadius: 3,
    },
    oneLine: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  return (
    <Document>
      <Page size="A4" style={styles.mainContainer}>
        {/* Render profile data */}
        {AiResume?.profile && (
          <View style={styles.header}>
            <Text
              className="apply-font"
              style={{
                ...styles.name,
                color: `${
                  AiResume?.heading_color ? AiResume?.heading_color : "#2b6be2"
                }`,
                fontSize: `${AiResume?.font_size ? AiResume?.font_size : ""}`,
              }}
            >
              {AiResume.profile.first_name} {AiResume.profile.last_name}
            </Text>
            <Text style={styles.email}>
              {AiResume?.profile?.email && `${AiResume.profile.email}`}
              {AiResume?.profile?.email &&
                (AiResume.profile?.address ||
                  AiResume.profile?.city ||
                  AiResume.profile?.phone ||
                  AiResume.profile?.linked_in ||
                  AiResume.profile?.website) &&
                " | "}
              {(AiResume.profile?.address || AiResume.profile?.city) &&
                `${AiResume.profile.address ?? ""}${
                  AiResume.profile.address && AiResume.profile.city ? ", " : ""
                }${AiResume.profile.city ?? ""}`}
              {(AiResume.profile?.address || AiResume.profile?.city) &&
                (AiResume.profile?.phone ||
                  AiResume.profile?.linked_in ||
                  AiResume.profile?.website) &&
                " | "}
              {AiResume?.profile?.phone && `${AiResume.profile.phone}`}
              {AiResume?.profile?.phone &&
                (AiResume.profile?.linked_in || AiResume.profile?.website) &&
                " | "}
              {AiResume?.profile?.linked_in && `${AiResume.profile.linked_in}`}
              {AiResume?.profile?.linked_in &&
                AiResume.profile?.website &&
                " | "}
              {AiResume?.profile?.website && `${AiResume.profile.website}`}
            </Text>
          </View>
        )}

        {/* Professional Summary */}
        {AiResume?.professionalSummaries && (
          <View>
            <Text style={styles.sectionTitle}>Professional Summary</Text>
            {AiResume?.professionalSummaries?.map((item, index) => (
              <Text key={index} style={styles.itemDescription}>
                {item?.text
                  ?.replace(/<\/?p>/g, "")
                  ?.replace(/<br\s*[\/]?>/gi, "")}
              </Text>
            ))}
          </View>
        )}

        {/* Certifications */}
        {AiResume?.certificates && (
          <View>
            <Text style={styles.sectionTitle}>Certifications</Text>
            <View style={styles.List}>
              {AiResume.certificates.map((certificate, index) => (
                <Text style={styles.listItem} key={index}>
                  • {certificate.name}
                </Text>
              ))}
            </View>
            {/* {AiResume.certificates.map((certification, index) => (
              <View key={index}>
                <View style={styles.oneLine}>
                  <Text style={styles.itemTitle}>{certification.name}</Text>
                  <Text style={styles.itemDate}>
                    {formatDate(certification?.start_date)} -{" "}
                    {formatDate(certification?.end_date)}
                  </Text>
                </View>
                <Text style={styles.itemDescription}>
                  {certification?.provider}
                </Text>
              </View>
            ))} */}
          </View>
        )}

        {/* Top Skills */}
        {AiResume?.categories && (
          <View>
            <Text style={styles.sectionTitle}>Top Skills</Text>
            <View style={styles.List}>
              {AiResume?.categories?.map((category, index) => {
                if (category.resume_id) {
                  return category.skills.map((skill, skillIndex) => (
                    <Text style={styles.listItem} key={skillIndex}>
                      • {skill?.name}
                    </Text>
                  ));
                } else {
                  return (
                    <Text style={styles.listItem} key={index}>
                      • {category?.name}
                    </Text>
                  );
                }
              })}
            </View>
          </View>
        )}

        {/* Work Experiences */}
        {AiResume?.workExperience && (
          <View>
            <Text style={styles.sectionTitle}>Work Experiences</Text>
            {AiResume?.workExperience?.map((experience, index) => (
              <View key={index}>
                <View style={styles.oneLine}>
                  <Text style={styles.itemTitle}>
                    {experience?.company?.name}
                  </Text>
                  {experience?.roles?.map((item, roleIndex) => (
                    <Text key={roleIndex} style={styles.itemDate}>
                      {formatDate(item?.started_at)} -{" "}
                      {item?.ended_at == null
                        ? "present"
                        : formatDate(item?.ended_at)}
                    </Text>
                  ))}
                </View>
                {experience?.roles?.map((item, roleIndex) => (
                  <Text key={roleIndex} style={styles.itemDate}>
                    {item?.name}
                  </Text>
                ))}
                <Text style={styles.itemDescription}>
                  {experience?.company?.description
                    ?.replace(/<\/?p>/g, "")
                    ?.replace(/<br\s*[\/]?>/gi, "")}
                </Text>
              </View>
            ))}
          </View>
        )}

        {/* Educations */}
        {AiResume?.educations && (
          <View>
            <Text style={styles.sectionTitle}>Educations</Text>
            {AiResume.educations.map((education, index) => (
              <View key={index}>
                <View style={styles.oneLine}>
                  <Text style={styles.itemTitle}>{education.school_name}</Text>
                  <Text style={styles.itemDate}>
                    {education?.ended_at == null
                      ? "present"
                      : formatDate(education?.ended_at)}
                  </Text>
                </View>
                <Text style={styles.itemDate}>
                  {education.degree && `${education.degree} Degree | `}
                  {education?.field}
                </Text>
              </View>
            ))}
          </View>
        )}

        {/* Projects */}
        {AiResume?.projects && (
          <View>
            <Text style={styles.sectionTitle}>Projects</Text>
            {AiResume.projects.map((pro, index) => (
              <View key={index}>
                <View style={styles.oneLine}>
                  <Text style={styles.itemTitle}>{pro?.project_name}</Text>
                  <Text style={styles.itemDate}>
                    {formatDate(pro?.start_date)} - {formatDate(pro?.end_date)}
                  </Text>
                </View>
                <Text style={styles.itemDate}>{pro?.organization}</Text>

                <Text style={styles.itemDescription}>
                  {pro?.additional_information
                    ?.replace(/<\/?p>/g, "")
                    ?.replace(/<br\s*[\/]?>/gi, "")}
                </Text>
              </View>
            ))}
          </View>
        )}

        {/* Publications */}
        {AiResume?.publications && (
          <View>
            <Text style={styles.sectionTitle}>Publications</Text>
            {AiResume.publications.map((publication, index) => (
              <View key={index}>
                <View style={styles.oneLine}>
                  <Text style={styles.itemTitle}>{publication.title}</Text>
                  <Text style={styles.itemDescription}>
                    {formatDate(publication?.date)}
                  </Text>
                </View>
                <Text style={styles.itemDate}>{publication.publisher}</Text>
              </View>
            ))}
          </View>
        )}

        {/* Interests */}
        {AiResume?.interests?.interests &&
          AiResume?.interests?.interests.length > 0 && (
            <View>
              <Text style={styles.sectionTitle}>Interests</Text>
              <View style={styles.List}>
                {AiResume.interests.interests.map((interest, index) => (
                  <Text style={styles.listItem} key={index}>
                    • {interest}
                  </Text>
                ))}
              </View>
            </View>
          )}

        {/* Soft Skills */}
        {AiResume?.softskills?.skills &&
          AiResume?.softskills?.skills.length > 0 && (
            <View>
              <Text style={styles.sectionTitle}>Soft Skills</Text>
              <View style={styles.List}>
                {AiResume.softskills.skills.map((skill, index) => (
                  <Text style={styles.listItem} key={index}>
                    • {skill}
                  </Text>
                ))}
              </View>
            </View>
          )}

        {/* Languages */}
        {AiResume?.languages?.languages &&
          AiResume?.languages?.languages.length > 0 && (
            <View>
              <Text style={styles.sectionTitle}>Languages</Text>
              <View style={styles.List}>
                {AiResume.languages.languages.map((language, index) => (
                  <Text style={styles.listItem} key={index}>
                    • {language}
                  </Text>
                ))}
              </View>
            </View>
          )}
      </Page>
    </Document>
  );
};
