import React, { useEffect, useState } from "react";
import { plans } from "../../DummyData/PricingData";
import { useDispatch, useSelector } from "react-redux";
import {
  stripeCheckoutAction,
  upgradeSubscription,
} from "../../Redux/DashboardSlice";
import {
  continueIndividualAction,
  getUserSubscription,
} from "../../Redux/authenticate/authenticate.action";
import { Link } from "react-router-dom";
import { info, success } from "../../Utilities/toast";
import PricingCard from "./PricingCard";
import UpdateSubscriptionModal from "../UpdateSubscriptionModal";
import Modal from "./Modal";

const Plans = ({ isOrganization, orgUser }) => {
  console.log(isOrganization, "isOrganization");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const [isCheckYearly, setIsCheckYearly] = useState(false);
  const [planValue, setPlanValue] = useState("individual");
  const [openModal, setopenModal] = useState(false);
  const [activePricingId, setActivePricingId] = useState(null);
  console.log(orgUser, "orgUser");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const getPricingButton = (plan) => {
    let amounts;
    let currentPlan;
    if (isCheckYearly) {
      amounts = plans.individual.year?.map(({ id, price }) => ({
        id,
        price: Number(price),
      }));
    } else {
      amounts = plans.individual.month?.map(({ id, price }) => ({
        id,
        price: Number(price),
      }));
    }

    currentPlan = amounts?.find((item) => item.id === plan.id);

    const userBundle = user?.activeBundle;
    const allAmounts = [
      ...(plans.individual.month?.map(({ id, price }) => ({
        id,
        price: Number(price),
      })) || []),
      ...(plans.individual.year?.map(({ id, price }) => ({
        id,
        price: Number(price),
      })) || []),
    ];
    const currentActiveBundle = allAmounts.find(
      (item) => item.id === userBundle
    );

    let buttonText;
    if (plan.id == "enterprise" || plan.id == "custom") {
      buttonText = "Contact Us";
      if (
        user?.activeBundle !== "ey3PX9En4R" &&
        user?.activeBundle !== "2lNbMnPO8W" &&
        user?.activeBundle !== "kdqEYpPVvp" &&
        user?.organizationId == null
      ) {
        buttonText = "Subscribed";
      }
    } else if (userBundle === plan.id) {
      buttonText = "Subscribed";
    } else if (currentPlan && currentActiveBundle) {
      buttonText =
        currentPlan.price < currentActiveBundle.price ? "Downgrade" : "Upgrade";
    } else {
      buttonText = "Select Plan";
    }

    const updateSubscription = (ID) => {
      setActivePricingId(ID);
      if (ID !== "2lNbMnPO8W") {
        if (user?.is_trial_consumed) {
          setopenModal(true);
          return;
        } else {
          dispatch(stripeCheckoutAction(ID))
            .unwrap()
            .then((resolvedData) => {
              if (resolvedData.redirectUrl !== null) {
                console.log(resolvedData);
                window.location = resolvedData.redirectUrl;
              } else {
                success(resolvedData.detail);
              }
              dispatch(getUserSubscription());
            });
        }
      } else {
        console.log("Run Update Subscription", ID);
        dispatch(upgradeSubscription(ID))
          .unwrap()
          .then((res) => {
            console.log({ res });
            if (res.checkout_url) {
              window.location.href = res.checkout_url;
            } else {
              dispatch(getUserSubscription());
            }
          });
      }
    };

    return (
      <>
        <button
          className="ic-btn-go-to-pro w-100"
          onClick={() => {
            if (user && plan.id !== "enterprise") {
              if (orgUser) {
                dispatch(continueIndividualAction())
                  .unwrap()
                  .then((res) => {
                    // updateSubscription(plan.id);
                    dispatch(stripeCheckoutAction(plan.id))
                      .unwrap()
                      .then((resolvedData) => {
                        if (resolvedData.redirectUrl !== null) {
                          console.log(resolvedData);
                          window.location = resolvedData.redirectUrl;
                        } else {
                          success(resolvedData.detail);
                        }
                        dispatch(getUserSubscription());
                      });
                  });
              } else {
                updateSubscription(plan.id);
              }
            } else if (plan.id !== "custom") {
            } else {
              info("please SignIn to subscribe bundles");
            }
          }}
          disabled={userBundle === plan.id || plan.id == "custom"}
        >
          {buttonText}
        </button>
        <Modal
          isOpen={isModalOpen}
          onClose={toggleModal}
          updateSubscription={updateSubscription}
        />
      </>
    );
  };

  // const getPricingButton = (plan) => {
  //   if (subscribed_plan && subscribed_plan == plan.id) {
  //     return (
  //       <>
  //         {" "}
  //         {plan?.idealFor && (
  //           <div className="mt-5 text-black">
  //             <p>{plan.idealFor}</p>
  //           </div>
  //         )}
  //         <button disabled className="ic-btn-go-to-pro w-100">
  //           Subscribed
  //         </button>
  //       </>
  //       // <Link to="/" className="ic-btn w-100">
  //       //   Cancel <i className="ri-arrow-right-line"></i>
  //       // </Link>
  //     );
  //   } else if (
  //     subscribed_plan &&
  //     Number(subscribed_plan.price) <= Number(plan.price)
  //   ) {
  //     return (
  //       <>
  //         <a
  //           href="route('subscription.upgrade',{'plan_id' : plan.id})"
  //           className="ic-btn w-100"
  //         >
  //           Upgrade <i className="ri-arrow-right-line"></i>
  //         </a>
  //       </>
  //     );
  //   } else if (subscribed_plan == null && plan.id != "enterprise") {
  //     return (
  //       <>
  //         {" "}
  //         {plan?.idealFor && (
  //           <div className="mt-5 text-center">
  //             <p className="idealFor">{plan.idealFor}</p>
  //           </div>
  //         )}
  //         <button
  //           className="ic-btn-go-to-pro w-100"
  //           onClick={() => {
  //             if (
  //               subscribed_plan == "2lNbMnPO8W" ||
  //               user?.activeBundle === null
  //             ) {
  //               dispatch(stripeCheckoutAction(plan.id))
  //                 .unwrap()
  //                 .then((resolvedData) => {
  //                   if (resolvedData.redirectUrl !== null) {
  //                     console.log(resolvedData);
  //                     window.location = resolvedData.redirectUrl;
  //                   } else {
  //                     success(resolvedData.detail);
  //                   }
  //                   dispatch(getUserSubscription());
  //                 })
  //                 .catch((rejectedValueOrSerializedError) => {
  //                   console.error(
  //                     "Register failed",
  //                     rejectedValueOrSerializedError
  //                   );
  //                 });
  //             } else {
  //               dispatch(upgradeSubscription(plan.id))
  //                 .unwrap()
  //                 .then((res) => {
  //                   dispatch(getUserSubscription());
  //                 });
  //             }
  //           }}
  //         >
  //           Subscribe
  //         </button>
  //       </>
  //     );
  //   } else if (
  //     subscribed_plan &&
  //     Number(subscribed_plan.price) > Number(plan.price)
  //   ) {
  //     return (
  //       <>
  //         {" "}
  //         {plan?.idealFor && (
  //           <div className="mt-5 text-center">
  //             <p className="idealFor">{plan.idealFor}</p>
  //           </div>
  //         )}
  //         <a
  //           href="route('subscription.downgrade',{'plan_id' : plan.id})"
  //           className="ic-btn w-100"
  //         >
  //           Downgrade <i className="ri-arrow-right-line"></i>
  //         </a>
  //       </>
  //     );
  //   } else if (plan.id == "enterprise") {
  //     return (
  //       <>
  //         {" "}
  //         {plan?.idealFor && (
  //           <div className="mt-5 text-center">
  //             <p className="idealFor">{plan.idealFor}</p>
  //           </div>
  //         )}
  //         <button className="ic-btn-go-to-pro w-100">Contact Us</button>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         {plan?.idealFor && (
  //           <div className="mt-5 text-center">
  //             <p className="idealFor">{plan.idealFor}</p>
  //           </div>
  //         )}
  //         <button
  //           onClick={() => {
  //             if (
  //               subscribed_plan == "2lNbMnPO8W" ||
  //               user?.activeBundle === null
  //             ) {
  //               dispatch(stripeCheckoutAction(plan.id))
  //                 .unwrap()
  //                 .then((resolvedData) => {
  //                   if (resolvedData.redirectUrl !== null) {
  //                     console.log(resolvedData);
  //                     window.location = resolvedData.redirectUrl;
  //                   } else {
  //                     success(resolvedData.detail);
  //                   }
  //                   dispatch(getUserSubscription());
  //                 })
  //                 .catch((rejectedValueOrSerializedError) => {
  //                   console.error(
  //                     "Register failed",
  //                     rejectedValueOrSerializedError
  //                   );
  //                 });
  //             } else {
  //               dispatch(upgradeSubscription(plan.id))
  //                 .unwrap()
  //                 .then((res) => {
  //                   dispatch(getUserSubscription());
  //                 });
  //             }
  //           }}
  //           className="ic-btn-go-to-pro w-100"
  //         >
  //           Upgrade
  //         </button>
  //       </>
  //     );
  //   }
  // };

  useEffect(() => {
    if (!isOrganization) {
      setPlanValue("individual");
    } else {
      setPlanValue("organization");
    }
  }, [isOrganization]);

  // let className =
  //   plans?.[planValue]?.month?.length >= 4
  //     ? "lg:!grid-cols-4"
  //     : "lg:!grid-cols-3";
  let className = "";

  return (
    <>
      <div className="switch-plan mb-12">
        <div className="form-check form-switch">
          <span className={`plan-text ${isCheckYearly ? "" : "text-dark"}`}>
            Monthly{" "}
          </span>
          <div className="switch-pricing-plan">
            <label className="switch">
              <input
                type="checkbox"
                checked={isCheckYearly}
                role="switch"
                id="checkbox"
                onChange={(e) => setIsCheckYearly(e.target.checked)}
              />
              <span className="slider"></span>
            </label>
          </div>
          <span className={`plan-text ${isCheckYearly ? "text-dark" : ""}`}>
            Yearly
          </span>
        </div>
      </div>
      {/* <h2 className="text-center font-semibold">OR</h2>
      <div className="flex justify-center">
        <button
          onClick={toggleModal}
          className="border py-3 px-4 font-bold text-white text-base rounded-[10px] mb-4 bg-[#2c6be3] hover:bg-[#2a5cbf] hover:text-white"
        >
          Subscribe Custom Plan
        </button>
      </div> */}
      {!isCheckYearly ? (
        <div className="ic-pricing-plan-monthly">
          <div className={`pricing-plan-container ${className}`}>
            {plans?.[planValue]?.month.map((plan, index) => (
              <PricingCard
                key={index}
                plan={plan}
                getPricingButton={getPricingButton}
                planType={"month"}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="pricing-plan-container !flex m-auto flex-wrap items-center justify-center">
          {plans?.[planValue]?.year.map((plan, index) => (
            <PricingCard
              key={index}
              plan={plan}
              getPricingButton={getPricingButton}
              planType={planValue !== "organization" ? "year" : "month"}
            />
          ))}
        </div>
      )}
      <UpdateSubscriptionModal
        openModal={openModal}
        setopenModal={setopenModal}
        activePricingId={activePricingId}
      />
    </>
  );
};

export default Plans;
