import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import backIcon from "../../assets/icons/backIcon.svg";
import Header from "../../Components/header/index";
import octoStack from "../../DummyData/octoStack.json";
import { setinterviewMode } from "../../Redux/GlobalStates";
import Interview from "./octaInterview";

const Intervieworcustom = () => {
  const navigate = useNavigate();
  const { category, subcategory, role } = useParams();
  const dispatch = useDispatch();
  const [isIndustry, setisIndustry] = useState(false);

  const questionType = [
    { name: "Industry Standard", icon: "../images/icon.png" },
    {
      name: "Create Custom Project",
      icon: "../images/icon.png",
      link: "/custominput",
    },
  ];

  const onQuestionTypeSelect = (name, link) => {
    if (name === "Create Custom Project") {
      navigate(link); // Navigate to the custom input page
    } else {
      // Perform any other necessary actions for "Industry Standard"
      console.log("Selected Industry Standard");
      setisIndustry(true);
      dispatch(setinterviewMode("interview_start_developer"));
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${"/images/interview-banner.png"})`,
        backgroundSize: "cover",
        position: "relative",
      }}
      className={isIndustry ? "" : "ic-interviews"}
    >
      {!isIndustry && <Header />}

      {!isIndustry ? (
        <div className="container">
          <div className="ic-interview-container">
            <h3 className="text-center text-3xl">
              Select your Industry Project Style
            </h3>

            <h6 className="text-center px-5 mt-3">
              Your industry coding project falls under two main categories:
              Industry Standard & Custom. Here you can have us pick for you or
              you can use the custom option to create your own project. Enjoy!
            </h6>
            {!isIndustry ? (
              <>
                <div className="flex justify-start">
                  <button
                    onClick={() => {
                      navigate(
                        {
                          pathname: `/octagon/${category}/${subcategory}`,
                          search: `?intro=false`,
                        },
                        { replace: true }
                      );
                    }}
                    className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    <img src={backIcon} alt="Back" />
                  </button>
                </div>
                <div className="ic-interview-card-container">
                  {questionType.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => onQuestionTypeSelect(item.name, item.link)}
                      className={`ic-interview-items`}
                      data-aos="fade-up"
                      data-aos-delay="50"
                      data-aos-duration={index * 200 + 500}
                    >
                      <div className="ic-icons">
                        <img
                          src={`/images/${item.icon}`}
                          className="img-fluid"
                          alt={item.name}
                          style={{ width: "36px", height: "36px" }}
                        />
                      </div>
                      <p>{item.name}</p>
                    </button>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {isIndustry && (
        <Interview techStack={octoStack[`${subcategory}_${role}`]} />
      )}
    </div>
  );
};

export default Intervieworcustom;
