import MonacoEditor from "@monaco-editor/react";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  ApiResponseContainer,
  ApiResponseContent,
  ButtonContainer,
  LoaderOverlay,
  Timer,
  TimerContainer,
  TimerIcon,
} from "./index.style.js";

import "@nlux/themes/nova.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { files, themes } from "../../Components/QuestionList/data.js";
import {
  setInterviewCompleted,
  setinterviewMode,
} from "../../Redux/GlobalStates";

import {
  ExportOutlined,
  FileOutlined,
  FolderOutlined,
  GithubOutlined,
  MenuOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useUnsafeChatAdapter } from "@nlux/openai-react";
import { AiChat, AiChatUI } from "@nlux/react";
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Tabs,
  Tree,
} from "antd";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import MDX from "../../Components/MDX.jsx";
import Modal from "../../Components/modal/index.js";
import {
  loadDir,
  loadFile,
  loadProgress,
  saveProgress,
} from "../../Redux/OctagonSlice.js";
import "../../assets/CSS/index.css";
import { fetchDir, fetchFileContent } from "../../config/socketConfig.js";
import useChatLogs from "../../hook/chatStream";
import { WavesBackground } from "./Waves";

let currentAudio = null;

const BlueCircle = () => (
  <span className="size-2 bg-blue-500 inline-block rounded-full"></span>
);

const { Header, Content, Sider } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const fetchAndPlayTTS = async (text, playTTSRef) => {
  if (!playTTSRef) return;
  if (!playTTSRef.current) return;

  try {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio = null;
    }

    const response = await axios.post(
      "https://trynexa.com/api/api/tts",
      { text },
      { responseType: "blob" }
    );

    const audioUrl = URL.createObjectURL(response.data);
    currentAudio = new Audio(audioUrl);
    currentAudio.volume = 1.0;
    currentAudio.play();
    currentAudio.addEventListener("ended", () => {
      currentAudio = null;
    });
  } catch (err) {
    console.error("Failed to fetch TTS audio:", err);
  }
};

const RealTimeResponseBox = ({
  questions,
  answers,
  techStack,
  currentQuestionIndex,
}) => {
  const {
    messages,
    forceSetMessages,
    abortResponse,
    setMessages,
    submitPrompt,
    loading,
  } = useChatLogs({ storeChats: false, url: "/dashboard/hint" });

  let promptMessage = "";

  if (questions?.length) {
    promptMessage = `TECH STACK: ${techStack} \n QUESTION: ${
      questions[currentQuestionIndex].question
    } \n CODE: ${answers[currentQuestionIndex + 1]}`;
  }

  useEffect(() => {
    forceSetMessages([]);
  }, [currentQuestionIndex]);

  return (
    <ApiResponseContainer>
      <button
        className="next-button get-hint-button"
        onClick={() => {
          submitPrompt(promptMessage);
        }}
      >
        Get hint
      </button>
      <ApiResponseContent className="overflow-y-scroll">
        {loading && messages?.at(1)?.content === "" ? (
          <div className="flex justify-center mt-10">
            <img
              className="w-32"
              src="https://cdn.dribbble.com/users/3742211/screenshots/9195657/media/6796a544d6f9ef1293d8d8d9e60d38d5.gif"
              alt="loader"
            />
          </div>
        ) : (
          <div className="w-full h-[270px] text-wrap overflow-y-scroll">
            <MDX
              children={
                messages.at(1) &&
                messages.at(1).content.replaceAll("\n\n", "\n")
              }
            />
          </div>
        )}
      </ApiResponseContent>
    </ApiResponseContainer>
  );
};

// Convert remaining seconds to mm:ss format
const formatTime = (seconds) => {
  if (seconds <= 0) {
    return "00:00";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

const DSAQuestion = memo(({ question }) => {
  return (
    <div className="h-96 overflow-y-scroll">
      <h4 className="text-2xl my-1 font-semibold">{question?.title ?? ""}</h4>
      <div dangerouslySetInnerHTML={{ __html: question?.content }}></div>
    </div>
  );
});

const Interview = memo(({ techStack }) => {
  const location = useLocation();
  const getProjectIdFromPath = (pathname) => {
    const segments = pathname.split("/").filter(Boolean); // Split and remove empty segments
    if (segments.length < 2) {
      return ""; // Return empty string or handle as needed
    }
    return `${segments[segments.length - 2]}-${segments[segments.length - 1]}`; // Combine last two segments
  };

  const projectId = getProjectIdFromPath(location.pathname);
  const initialTreeData = [
    {
      title: "src",
      key: "0-0",
      icon: <FolderOutlined />,
      children: [
        {
          title: "index.js",
          key: "0-0-0",
          icon: <FileOutlined />,
          content: "// This is the content of index.js",
          language: "javascript",
        },
        {
          title: "dashboard.js",
          key: "0-0-1",
          icon: <FileOutlined />,
          content: "// This is the content of dashboard.js",
          language: "javascript",
        },
      ],
    },
    {
      title: "package.json",
      key: "0-1",
      icon: <FileOutlined />,
      content: `{
        "name": "project",
        "version": "1.0.0"
      }`,
      language: "json",
    },
  ];
  const [showFullCard, setShowFullCard] = useState(false);
  const [showAiHelp, setShowAiHelp] = useState(false);

  const toggleAiHelpDisplay = () => {
    setShowAiHelp((prevState) => !prevState);
  };

  const [showFullQuestion, setShowFullQuestion] = useState(false);
  const playTTSRef = useRef(true);
  const [showInputField, setShowInputField] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const toggleCardDisplay = () => {
    setShowFullCard(!showFullCard);
  };
  const {
    messages,
    forceSetMessages,
    abortResponse,
    setMessages,
    submitPrompt,
    getFeedback,
  } = useChatLogs({ storeChats: false, url: "/dashboard/evaluate" });
  const ColourfulResponseRenderer = (props) => {
    console.log("Response Renderer Props:");
    console.dir(props);

    return (
      <div className="colourful-response-renderer">
        <div className="response-container" ref={props.containerRef} />
        <WavesBackground />
      </div>
    );
  };
  const dispatch = useDispatch();
  const [realTimeResponse, setRealTimeResponse] = useState("");
  const [fileLanguage, setFileLanguage] = useState("javascript");

  const questions = techStack;
  console.log(questions);
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [fileName, setFileName] = useState("python");
  const state = useSelector((state) => state.octagon);
  console.log(state);
  const [theme, setTheme] = useState("vs");
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();
  const file = files.filter((ele) => ele.language === fileName)?.[0];
  const [treeData, setTreeData] = useState([]);
  const stateTreeData = useSelector((state) => state.octagon.treeData);

  const [newFilePath, setNewFilePath] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fileList } = state;
  const [activeFileKey, setActiveFileKey] = useState(null);
  const selectedFile =
    fileList && activeFileKey !== null ? fileList[activeFileKey] : null;
  console.log(fileList, selectedFile);
  const [selectedKeys, setSelectedKeys] = useState(["1"]);
  const [menuVisible, setMenuVisible] = useState(false);
  const getSelectedFile = (key) => fileList.filter((file) => file.key === key);

  const [feedback, setFeeback] = useState([]);
  const [feedbackLoading, setFeedbackloading] = useState(false);
  const findFileByKey = (data, key) => {
    for (let item of data) {
      if (item.key === key) return item;
      if (item.children) {
        const found = findFileByKey(item.children, key);
        if (found) return found;
      }
    }
    return null;
  };

  const handleAddFile = () => {
    if (newFilePath) {
      const updatedTreeData = findOrCreateDirectory(newFilePath, treeData);
      setTreeData(updatedTreeData);
      setNewFilePath("");
      setShowInputField(false);
    }
  };
  useEffect(() => {
    if (stateTreeData && stateTreeData.length > 0) {
      setTreeData(stateTreeData);
      setSelectedKeys([stateTreeData?.[0]?.key]); // Select the root node by default
    }
  }, [stateTreeData]);
  function getFileLanguage(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();
    const extensionToLanguageMap = {
      js: "javascript",
      jsx: "javascript",
      ts: "typescript",
      tsx: "typescript",
      py: "python",
      java: "java",
      // Add other mappings as needed
    };
    return extensionToLanguageMap[extension] || "plaintext";
  }

  const findOrCreateDirectory = (path, nodeList) => {
    const dirs = path.split("/").filter(Boolean);
    if (dirs.length === 0) return nodeList;

    const dir = dirs[0];
    const restPath = dirs.slice(1).join("/");

    let nodeIndex = nodeList.findIndex((n) => n.title === dir);
    let foundNode = nodeList[nodeIndex];

    if (!foundNode) {
      if (restPath === "") {
        // It's a file
        const newNode = {
          title: dir,
          key: path,
          icon: <FileOutlined />,
          content: "// Default content",
          language: "javascript",
          children: [],
        };
        return [...nodeList, newNode];
      } else {
        // It's a directory
        const newDirNode = {
          title: dir,
          key: path,
          icon: <FolderOutlined />,
          children: [],
        };
        newDirNode.children = findOrCreateDirectory(
          restPath,
          newDirNode.children
        );
        return [...nodeList, newDirNode];
      }
    } else {
      // Update existing node
      if (foundNode.children) {
        const updatedChildren = findOrCreateDirectory(
          restPath,
          foundNode.children
        );
        const updatedNode = { ...foundNode, children: updatedChildren };
        return [
          ...nodeList.slice(0, nodeIndex),
          updatedNode,
          ...nodeList.slice(nodeIndex + 1),
        ];
      } else {
        // Found a file node where we expected a directory
        return nodeList;
      }
    }
  };

  const handleFileSelect = (selectedKeys, { node }) => {
    if (node.children && node.children.length > 0) return;

    const selectedFileKey = selectedKeys?.[0];
    const selectedFile = findFileByKey(treeData, selectedFileKey);

    if (selectedFile) {
      setActiveFileKey(selectedFileKey);
      setFileName(selectedFile.title);
      setFileLanguage(selectedFile.language || "javascript");
    }
  };

  const handleFeedback = async (summary) => {
    try {
      setFeedbackloading(true);
      const resposne = await getFeedback(
        `${summary}\nconvert above summary to array of object with key name question(its inlcudes question), answer(includes code), feedback(inlcudes Evaluation or Reasoning ). Please retrun result in json format with array named "results"`
      );

      if (resposne) {
        let results = JSON.parse(resposne);

        if (results?.results) {
          setFeeback(results?.results);
        }
        if (results?.summary) {
          setFeeback(results?.summary);
        }
        if (results?.responses) {
          setFeeback(results?.responses);
        }
      }

      setFeedbackloading(false);
    } catch (err) {
      setFeedbackloading(false);
    }
  };
  const getCodeContent = (treeData) => {
    let content = "";
    const traverse = (nodes) => {
      nodes.forEach((node) => {
        if (node.content) {
          content += `File: ${node.title}\nContent:\n${node.content}\n\n`;
        }
        if (node.children) {
          traverse(node.children);
        }
      });
    };
    traverse(treeData);
    return content;
  };

  const codeContent = useMemo(() => getCodeContent(treeData), [treeData]);
  useEffect(() => {
    if (modalIsOpen && !loading) {
      handleFeedback(messages.at(1)?.content);
    }
  }, [modalIsOpen, loading]);

  const handleChangeFile = (e) => {
    setFileName(e?.value);
  };

  const handleChangeTheme = (e) => {
    setTheme(e?.value);
  };
  console.log(theme);

  const handleBeforeUnload = (evt) => {
    evt.preventDefault();
    let msg = "You cannot exit the interview.";
    evt.returnValue = msg;
    return msg;
  };
  const handleUnload = (evt) => {
    evt.preventDefault();
    localStorage.removeItem("dev_questions");
  };

  const handleAnswerChange = (fileKey, code) => {
    const updateFileContent = (nodes) => {
      return nodes.map((node) => {
        if (node.key === fileKey) {
          return { ...node, content: code };
        } else if (node.children && node.children.length > 0) {
          return { ...node, children: updateFileContent(node.children) };
        } else {
          return node;
        }
      });
    };

    setTreeData((prevTreeData) => updateFileContent(prevTreeData));
  };
  const getTreeStructure = (treeData) => {
    const traverse = (nodes, depth = 0) => {
      let structure = "";
      nodes.forEach((node) => {
        structure += "  ".repeat(depth) + node.title + "\n";
        if (node.children) {
          structure += traverse(node.children, depth + 1);
        }
      });
      return structure;
    };
    return traverse(treeData);
  };

  // Memoize codeContent and treeStructure
  const treeStructure = useMemo(() => getTreeStructure(treeData), [treeData]);
  const messageTemplate = useCallback(() => {
    const systemPrompt = `You are a helpful assistant with access to the user's current project structure and code. Here is the project structure:\n${treeStructure}\nHere is the code content:\n${codeContent}\nProvide assistance based on this context.`;
    return systemPrompt;
  }, [codeContent, treeStructure]);

  const adapter = useUnsafeChatAdapter({
    apiKey:
      "sk-PkuxB63G5Xdu71XBeq_rE-P6a0pv3yajE8BMMSgyeHT3BlbkFJ12i1BdWw8VBkr6utWGCJOSpJquPLYm1GK1borcq5oA",
    systemMessage: messageTemplate(),
  });
  const submitTest = async () => {
    playTTSRef.current = false; // Disable TTS playback
    setLoading(true);
    const answersArray = Object.keys(answers).map((questionId) => ({
      [questionId]: answers[questionId],
    }));

    const promptMessage = questions
      ?.map((item, i) => {
        return `QUESTION ${i + 1}: ${JSON.stringify(item?.question)}
      CODE: ${answers[item.id] || ""}`;
      })
      .join("\n-------------------------------\n");

    console.log("promptMessage>>", promptMessage);
    try {
      setIsOpen(true);

      await submitPrompt(promptMessage);

      console.log("after prompt messages>>", messages);
    } catch (err) {
      setResponseData("Could not evaluate the test. Some error occured");
      throw err;
    } finally {
      setLoading(false); // Ensure loading state is set to false after submission
    }
  };
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current && questions?.length > 0) {
      const playTTSOnLoad = async () => {
        await fetchAndPlayTTS(questions?.[0]?.question, playTTSRef);
      };
      playTTSOnLoad();
      isInitialMount.current = false; // Ensure this runs only once
    }
  }, [questions]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: state.isFocused ? "2px solid #007bff" : "2px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #007bff",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#007bff" : "white",
      color: state.isFocused ? "white" : "black",
      "&:hover": {
        backgroundColor: "#007bff",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "1000px",
      height: "720px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const editorRef = useRef(null);
  const containerRef = useRef(null);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver loop")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.remove("style", "display: none");
        }
      }
    });
  }, []);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (editorRef.current) {
        editorRef.current.layout();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const onChangeTab = (fileKey) => {
    setActiveFileKey(fileKey);
    // If needed, handle fetching file content here
    const selectedFile = findFileByKey(treeData, fileKey);
    if (selectedFile && selectedFile.content === null) {
      fetchFileContent(selectedFile.path, (content) => {
        dispatch(
          loadFile({
            path: selectedFile.path.split("/").slice(0, -1).join("/"),
            value: content,
            fileName: selectedFile.title,
          })
        );
      });
    }
  };

  const monoEditor = useCallback(() => {
    const selectedFile = findFileByKey(treeData, activeFileKey);
    const selectedFileLanguage = selectedFile
      ? selectedFile.language
      : "javascript";
    const selectedFileValue = selectedFile ? selectedFile.content : "";

    return (
      <>
        {/* Code editor component */}
        <div
          className={`editor-wrapper h-full ${
            theme === "vs-dark" ? "dark" : ""
          }`}
          style={{ flexGrow: 1, maxHeight: "300px" }}
        >
          <div className={`top-bar ${theme === "vs-dark" ? "dark" : ""}`}>
            <div className="icon text-sd-green-500">
              {/* SVG Icon */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="code"
                className="svg-inline--fa fa-code"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                style={{ width: "1em", height: "1em" }}
              >
                <path
                  fill="#38a169"
                  d="M399.1 1.1c-12.7-3.9-26.1 3.1-30 15.8l-144 464c-3.9 12.7 3.1 26.1 15.8 30s26.1-3.1 30-15.8l144-464c3.9-12.7-3.1-26.1-15.8-30zm71.4 118.5c-9.1 9.7-8.6 24.9 1.1 33.9L580.9 256 471.6 358.5c-9.7 9.1-10.2 24.3-1.1 33.9s24.3 10.2 33.9 1.1l128-120c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-13-7.6-17.5l-128-120c-9.7-9.1-24.9-8.6-33.9 1.1zm-301 0c-9.1-9.7-24.3-10.2-33.9-1.1l-128 120C2.7 243 0 249.4 0 256s2.7 13 7.6 17.5l128 120c9.7 9.1 24.9 8.6 33.9-1.1s8.6-24.9-1.1-33.9L59.1 256 168.4 153.5c9.7-9.1 10.2-24.3 1.1-33.9z"
                ></path>
              </svg>
            </div>
            <div className="text">Code</div>
          </div>

          <div className="code-editor-container flex items-center justify-center h-full bg-gray-100 rounded-md overflow-hidden">
            {/* Show a message if no file is selected */}
            {!selectedFile ? (
              <div className="text-gray-500 text-center px-4 py-2 bg-white rounded max-w-xs mx-auto break-words">
                Select a file
              </div>
            ) : (
              <MonacoEditor
                height="100%"
                width="100%"
                theme={theme}
                path={selectedFileLanguage}
                defaultLanguage={selectedFileLanguage}
                defaultValue={selectedFileValue}
                value={selectedFileValue}
                onChange={(value) => {
                  handleAnswerChange(activeFileKey, value || "");
                }}
                options={{
                  wordWrap: "on",
                  minimap: { enabled: false },
                }}
                onMount={handleEditorDidMount}
              />
            )}
          </div>
        </div>
      </>
    );
  }, [
    answers,
    currentQuestionIndex,
    theme,
    handleAnswerChange,
    questions,
    activeFileKey,
    selectedFile,
  ]);

  const currentQues = useMemo(
    () => questions?.[currentQuestionIndex]?.question,
    [questions, currentQuestionIndex]
  );

  const renderModal = useCallback(() => {
    if (!modalIsOpen || loading) return <></>;

    const header = (
      <div className="mockInterview-modal-header">
        <button
          className="modal-close"
          onClick={() => {
            forceSetMessages([]);
            dispatch(setinterviewMode("interview_exit"));
            dispatch(setInterviewCompleted(true));
            localStorage.removeItem("dev_questions");
            navigate("/interview/role/developer");
          }}
        >
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-12 order-md-1 order-2">
          {feedback?.map((item, index) => (
            <div className="cardBody" key={index + 100}>
              <h5 className="mx-3 my-3 ">Project:</h5>
              <hr />
              <p className="mx-3 my-3">{currentQues?.des}</p>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Answer:</h5>
              <hr />
              <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
              <hr />

              <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
              <p className="mx-3 my-3">{item?.feedback}</p>
            </div>
          ))}
        </div>
      </div>
    );

    if (feedbackLoading) {
      return <LoaderOverlay>Loading...</LoaderOverlay>;
    }

    return (
      <Modal
        type="feedback"
        show={modalIsOpen}
        header={header}
        content={content}
      />
    );
  }, [modalIsOpen, messages, feedback, feedbackLoading]);

  const downloadFile = (label, value, type) => {
    const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
    saveAs(blob, label);
  };

  const handleDownloadAll = () => {
    fileList.forEach((file) => downloadFile(file.label, file.value, file.type));

    setTimeout(() => {
      window.open("https://github.com/", "_blank"); // Replace with your desired URL
    }, 1000); // Adjust the delay as needed
  };

  useEffect(() => {
    fetchDir(".", (data) => {
      dispatch(loadDir({ path: ".", ...data }));
    });
  }, []);

  const handleMenuClick = (e) => {
    setMenuVisible(false);
    // Handle menu item clicks here
  };

  const headerMenu = (
    <div className="flex justify-between">
      <div className="flex space-x-3 my-0 items-center">
        {/* <TimerComponent submitTest={submitTest} /> */}
        <button
          className="bg-red-600 text-white p-2 px-4 rounded-lg mb-3"
          onClick={() => {
            dispatch(setinterviewMode("interview_exit"));
            dispatch(setInterviewCompleted(true));
            navigate(-1);
          }}
        >
          Exit
        </button>
      </div>
      <Menu>
        <Menu.Item>Exit</Menu.Item>
      </Menu>
    </div>
  );

  useEffect(() => {
    dispatch(loadProgress(projectId));
  }, [dispatch, projectId]);
  const handleSaveProgress = () => {
    const payload = {
      treeData: treeData,
    };
    dispatch(saveProgress({ projectId, payload }));
  };

  return (
    <div className="ic-all-question flex flex-col">
      <Header style={{ padding: "0 16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Button
            type="primary"
            danger
            icon={<></>}
            onClick={() => {
              dispatch(setinterviewMode("interview_exit"));
              dispatch(setInterviewCompleted(true));
              navigate(-1);
            }}
          >
            Exit
          </Button>
          <Button
            type="primary"
            icon={<ExportOutlined />}
            onClick={handleSaveProgress}
          >
            Save Progress
          </Button>
          <Space wrap>
            <Button
              icon={<GithubOutlined />}
              className="hidden md:inline-block"
            >
              Save & Export to GitHub
            </Button>
            <Button
              type="primary"
              icon={<PlayCircleOutlined />}
              className="hidden md:inline-block"
            >
              Run
            </Button>
            <Select
              defaultValue="javascript"
              style={{ width: 150 }}
              className="hidden lg:inline-block"
            >
              <Option value="javascript">JavaScript</Option>
              <Option value="python">Python</Option>
              <Option value="r">R</Option>
            </Select>
            <Select
              defaultValue="light"
              options={themes}
              onChange={(val) => setTheme(val)}
              placeholder="Select theme"
              style={{ width: 150 }}
              className="hidden lg:inline-block"
            >
              <Option value="light">Light</Option>
              <Option value="dark">Dark</Option>
              <Option value="system">System</Option>
            </Select>
            <Dropdown
              overlay={headerMenu}
              trigger={["click"]}
              visible={menuVisible}
              onVisibleChange={setMenuVisible}
              className="md:hidden"
            >
              <Button
                icon={<MenuOutlined />}
                onClick={(e) => e.preventDefault()}
              />
            </Dropdown>
          </Space>
        </div>
      </Header>
      <Layout>
        <Content
          style={{ padding: "24px" }}
          className="overflow-y-scroll h-screen"
        >
          <Space
            direction="vertical"
            size="large"
            style={{ display: "flex" }}
            className="mb-96"
          >
            <Card
              title="Project 1"
              extra={
                <Button type="link" onClick={toggleCardDisplay}>
                  {showFullCard ? "Hide section" : "Show more"}
                </Button>
              }
            >
              {questions?.[currentQuestionIndex] instanceof Object ? (
                <>
                  {showFullCard ? (
                    // Full content
                    <>
                      <h1>{currentQues?.project}</h1>
                      <p>{currentQues?.des}</p>
                      <h2 className="mt-3 font-bold text-lg">Key Features</h2>
                      {currentQues?.keyFeatures?.map((e, i) => (
                        <div className="mt-3" key={i}>
                          {typeof e === "string" ? (
                            <p>
                              {i + 1}. {e}
                            </p>
                          ) : (
                            <>
                              <b>{e?.feature}</b>
                              <p>{e?.description}</p>
                            </>
                          )}
                        </div>
                      ))}

                      <h2 className="mt-3 font-bold text-lg">
                        Technologies to Use
                      </h2>
                      {Object.keys(currentQues?.technologiesToUse).map(
                        (e, i) => (
                          <div className="mt-3 flex space-x-1" key={i}>
                            <b>{e}:</b>
                            <p>{currentQues?.technologiesToUse[e]}</p>
                          </div>
                        )
                      )}

                      <h2 className="mt-3 font-bold text-lg">
                        Steps to Build the Project
                      </h2>
                      {currentQues?.stepsToBuildTheProject?.map((e, i) => (
                        <ul className="mt-3" key={i}>
                          <li>
                            {i + 1}. {e}
                          </li>
                        </ul>
                      ))}

                      {currentQues?.benefits && (
                        <>
                          <h2 className="mt-3 font-bold text-lg">Benefits</h2>
                          {currentQues?.benefits?.map((e, i) => (
                            <ul className="mt-3" key={i}>
                              <li>
                                {i + 1}. {e}
                              </li>
                            </ul>
                          ))}
                        </>
                      )}

                      {currentQues?.howToShowcaseTheProject && (
                        <>
                          <h2 className="mt-3 font-bold text-lg">
                            How to Showcase the Project
                          </h2>
                          {currentQues?.howToShowcaseTheProject?.map((e, i) => (
                            <ul className="mt-3" key={i}>
                              <li>
                                {i + 1}. {e}
                              </li>
                            </ul>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    // Shortened content for the preview
                    <p>{currentQues?.des.substring(0, 100)}...</p>
                  )}
                </>
              ) : (
                <DSAQuestion question={questions?.[currentQuestionIndex]} />
              )}

              <ButtonContainer className="button-container">
                {currentQuestionIndex === questions?.length - 1 && (
                  <Button className="next-button" onClick={submitTest}>
                    Submit
                  </Button>
                )}
              </ButtonContainer>
            </Card>
            <Card
              title="Code Editor"
              className="h-fit"
              extra={
                <Space>
                  <Button type="link" onClick={toggleAiHelpDisplay}>
                    {showAiHelp ? "Hide Nexa's AI Help" : "Ask Nexa For Help"}
                  </Button>
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                {/* File Tree Column */}
                <Col xs={24} md={6}>
                  <div className="p-2">
                    <h3 style={{ marginBottom: "8px" }}>Files</h3>
                    <Tree
                      treeData={treeData}
                      selectedKeys={selectedKeys}
                      onSelect={handleFileSelect}
                    />
                    <div>
                      <Button
                        icon={<PlusOutlined />}
                        style={{ marginTop: "16px", width: "100%" }}
                        onClick={() => setShowInputField(true)}
                      >
                        New File
                      </Button>

                      {/* Conditionally render the input field and save button */}
                      {showInputField && (
                        <div style={{ marginTop: "10px" }}>
                          <Input
                            value={newFilePath}
                            onChange={(e) => setNewFilePath(e.target.value)}
                            placeholder="File path (e.g. src/app/hi.js)"
                            style={{ marginBottom: "10px" }}
                          />
                          <Button type="primary" onClick={handleAddFile}>
                            Save
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>

                {/* Code Editor and Output Column */}
                <Col xs={24} md={18}>
                  {monoEditor()}
                  <Divider />
                  <div>
                    <h4>Code Output</h4>
                    <pre
                      style={{
                        backgroundColor: "#f0f0f0",
                        padding: "16px",
                        borderRadius: "4px",
                        maxHeight: "200px",
                        overflowY: "auto",
                      }}
                    >
                      Code output will appear here
                    </pre>
                  </div>
                </Col>
              </Row>

              {showAiHelp && (
                <Row style={{ marginTop: "30px", flexGrow: 0 }}>
                  <AiChat adapter={adapter}>
                    <AiChatUI.Greeting>
                      <div
                        className="custom-greeting"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/images/logo.png"
                          alt="Logo"
                          style={{ width: "150px", marginBottom: "20px" }}
                        />
                        <h2>Welcome To Your Coding Assistant!</h2>
                        <p>How can we help you today?</p>
                      </div>
                    </AiChatUI.Greeting>
                  </AiChat>
                </Row>
              )}
            </Card>
          </Space>
        </Content>
      </Layout>
    </div>
  );
});

/*Use memo to not re render time component each time */
const TimerComponent = memo(({ submitTest }) => {
  const [remainingTime, setRemainingTime] = useState(600); // 10 minutes
  const timerRef = useRef(null);
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = () => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          timerRef.current = null;
          submitTest();
          return 0;
        }
        return prevTime - 1;
      });
    };
  });

  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => savedCallback.current(), 1000);
    }

    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <TimerContainer>
      <TimerIcon>⏰</TimerIcon>
      <Timer>Time Remaining: {formatTime(remainingTime)}</Timer>
    </TimerContainer>
  );
});

export default Interview;
