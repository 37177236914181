export const files = [
  {
    language: "typescript",
    value: "// some comment",
    sign: "ts",
  },
  {
    language: "javascript",
    value: "// some comment",
    sign: "js",
  },
  {
    language: "python",
    value: "// some comment",
    sign: "py",
  },
  {
    language: "java",
    value: "// some comment",
    sign: "java",
  },
  {
    language: "css",
    value: "/* some css */",
    sign: "css",
  },
  {
    language: "less",
    value: "/* some css */",
    sign: "less",
  },
  {
    language: "scss",
    value: "/* some css */",
    sign: "scss",
  },
  {
    language: "json",
    value: "// some comment",
    sign: "json",
  },
  {
    language: "html",
    value: "// some comment",
    sign: "html",
  },
  {
    language: "Golang",
    value: "// some comment",
    sign: "go",
  },
];

export const themes = [
  {
    label: "Visual Studio",
    value: "light",
  },
  {
    label: "Visual Studio Dark",
    value: "vs-dark",
  },
];
